import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { AdminLayoutStateStyles } from './types';

const styles = {
  root: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: '100vh',
    }),
  header: ({ theme }) =>
    css({
      height: 68,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '16px 32px',
      backgroundColor: theme.colors.neutral.variant[0],
      '& > div': {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      },
      '& > div:nth-of-type(1)': {
        justifyContent: 'flex-start',
      },
      '& > div:nth-of-type(2)': {
        justifyContent: 'center',
      },
      '& > div:nth-of-type(3)': {
        justifyContent: 'flex-end',
      },
    }),
  body: ({ theme }) =>
    css({
      height: 0,
      minHeight: 'calc(100vh - 68px)',
      backgroundColor: theme.colors.neutral.variant[10],
    }),
} satisfies Styles<AdminLayoutStateStyles>;

export const useStyles = withStyles(styles);
