import { useQuery } from '@tanstack/react-query';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { FilterOrder } from 'clients/types';
import { Candidate } from 'model';
import { QueryKey } from 'types/query';

export const CANDIDATES_PER_PAGE = 3;

export const generateSearchRatedCandidateQueryKey = (search?: string) => [QueryKey.searchRatedCandidate, { search }];

export const useSearchRatedCandidateQuery = (props: { search?: string }) => {
  const labelsFilter: string[] = [];

  return useQuery(
    generateSearchRatedCandidateQueryKey(props.search),
    async () => {
      const response = await searchClient.getSearchRatedCandidates({
        labelsFilter,
        search: props.search,
        limit: CANDIDATES_PER_PAGE,
        sort: {
          by: 'updated_at',
          order: FilterOrder.Desc,
        },
      });

      return {
        ...response.data,
        results: response.data.results.map((candidate) => new Candidate(candidate.campaign.id, candidate)),
      };
    },
    { keepPreviousData: true, cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000 },
  );
};
