import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { cssBreakpoints } from 'styles/breakpoints';

const styles = {
  root: ({ theme }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      [cssBreakpoints.mobile]: {
        padding: 16,
      },
      '& > *': {
        margin: 8,
      },
      hr: {
        margin: 0,
        width: '100%',
        border: 0,
        borderBottom: `1px solid ${theme.colors.neutral.variant[30]}`,
      },
      '& > div': {
        maxWidth: 350,
      },
    }),
  logo: () =>
    css({
      height: 100,
      [cssBreakpoints.tablet]: {
        display: 'none',
      },
    }),
  title: () =>
    css({
      fontSize: 18,
      fontWeight: 500,
      minWidth: 200,
    }),
  keyVisual: () =>
    css({
      height: 100,
      [cssBreakpoints.mobile]: {
        height: 'auto',
        width: '100%',
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
