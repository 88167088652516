import { styleUtils } from 'utils';
import { CheckboxStyleStates } from './types';
import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { checked } }) =>
    css({
      display: 'block',
      transition: styleUtils.generateTransitionStyles(['background', 'border-color']),
      '& > label': {
        display: 'inline-flex',
        gap: 8,
        '& > .cb-label': {
          borderColor: theme.colors.neutral.variant[30],
        },
      },
      '&:hover': {
        '& > label > .cb-label': {
          borderColor: theme.colors.neutral.variant[40],
        },
      },
      '&:focus, &:focus-within': {
        '& > .cb-label': {
          borderColor: theme.colors.primary.fuchsia[40],
        },
      },
      ...(checked && {
        '&, &:hover': {
          '& > label > .cb-label': {
            borderColor: theme.colors.primary.fuchsia[40],
            backgroundColor: theme.colors.primary.fuchsia[40],
          },
        },
      }),
    }),
  label: css({
    display: 'block',
    width: 16,
    height: 16,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 2,
  }),
  iconWrapper: ({ theme, props: { checked } }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      color: checked ? theme.colors.neutral.variant[0] : 'transparent',
      transition: styleUtils.generateTransitionStyles(['color']),
    }),
} satisfies Styles<CheckboxStyleStates>;

export const useStyles = withStyles(styles);
