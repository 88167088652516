import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    gap: 33px;
    justify-content: center;
    align-items: center;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
