import { tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { CvSource } from 'clients/Admin/TenantClient/types';
import { Typography } from 'components/ui/atoms';
import { queryClient } from 'config/queryClient';
import { jobBoardLabel } from 'helpers/jobBoards';
import { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'shared/components/Modal';
import { Switch } from 'shared/components/form/Switch/Switch';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './EditCvSourceModal.styles';
import { EditCvSourceModalProps } from './types';

const EditCvSourceModal = ({ visible, onClose, tenantId }: EditCvSourceModalProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [allCvSources, setAllCvSources] = useState<CvSource[]>([]);

  useEffect(() => {
    if (visible) {
      tenantClient.getCvSources(tenantId).then((response) => {
        setAllCvSources(response.data);
      });

      return () => {
        setAllCvSources([]);
      };
    }
  }, [visible]);

  const onSwitch = useCallback(
    async (newStatus: any, id: any, cv_source: any) => {
      const data = {
        cv_source,
        enabled: newStatus,
      };

      if (id) {
        await tenantClient.updateCvSources(tenantId, id, data);
      } else {
        await tenantClient.createCvSources(tenantId, data);
      }
    },
    [tenantId],
  );

  const handleSaveChanges = async () => {
    await queryClient.invalidateQueries([tenantClientCacheKeys.getCvSources, parseInt(`${tenantId ?? 0}`)]);
  };

  return (
    <Modal title={dictionary.jobBoards} visible={visible} onClose={onClose} onSaveChanges={handleSaveChanges}>
      <div css={classes.root}>
        <div css={classes.cvSourcesWrapper}>
          {allCvSources.map(({ id, cv_source, enabled }) => (
            <div key={cv_source} css={classes.row}>
              <span>
                <Switch
                  id={id ?? undefined}
                  on={enabled}
                  onChange={(newStatus) => onSwitch(newStatus, id, cv_source)}
                />
              </span>
              <span>
                <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
                  {jobBoardLabel(cv_source)}
                </Typography>
              </span>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default EditCvSourceModal;
