import TimeAgo from 'javascript-time-ago';

export const extractLastRefresh = (lastRefresh: string | Date, language: string) => {
  const timeAgo = new TimeAgo(language);

  try {
    const date = typeof lastRefresh === 'string' ? new Date(lastRefresh) : lastRefresh;

    return timeAgo.format(date);
  } catch (e) {
    return undefined;
  }
};
