import { CampaignOverviewResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { FunctionComponentElement, SVGAttributes } from 'react';

export enum ActionNames {
  changeOwner = 'changeOwner',
  delete = 'delete',
  duplicate = 'duplicate',
  toggleStatus = 'toggleStatus',
}

export type CampaignListItemActionOptionProps = {
  onClick: (() => void) | undefined;
  renderIcon: (isLoading: boolean) => FunctionComponentElement<SVGAttributes<SVGElement>>;
  label: string;
  isLoading: boolean;
  name: ActionNames;
  campaign: CampaignOverviewResponse;
  handleChangeOwner: (campaignId: number, userId: number) => void;
};
