import { useQuery } from '@tanstack/react-query';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { useMemo } from 'react';

export const useIsWerknlActive = () => {
  const { data = false } = useQuery(
    ['crawler-is-werknl-active'],
    async () => {
      try {
        const response = await crawlerClient.isWerknlActive();

        return response.status === 200;
      } catch (e) {
        if (isAxiosError(e) && e.response?.status === 404) {
          return false;
        }

        throw e;
      }
    },
    { keepPreviousData: true, retry: 0 },
  );

  return useMemo(() => {
    return data;
  }, [data]);
};
