import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  clickable: () =>
    css({
      cursor: 'pointer',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
