import { RefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export const useOnClickOutside = (innerRef: RefObject<HTMLElement>, onOuterClick: Function) => {
  useEffect(() => {
    const handleClick: EventListener = (e) => {
      if (e.target instanceof Node) {
        innerRef.current && !innerRef.current.contains(e.target) && onOuterClick(e);
      }
    };
    if (innerRef.current) {
      document.addEventListener('mousedown', handleClick);
      document.addEventListener('touchstart', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [onOuterClick, innerRef]);
};
