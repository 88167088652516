import { TextInputProps } from './types';
import { useStyles } from './styles';
import { Column, ContentEditable, Row, Typography } from 'components/ui/atoms';
import { forwardRef, useRef } from 'react';
import { generateClassname } from 'helpers/helpers';
import { default as FormHelperError } from 'shared/components/FormHelperError';

const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  (
    {
      text,
      onChange,
      errorLabel,
      placeholder,
      className,
      maxLength,
      allowHtml = false,
      contained = false,
      disabled = false,
    },
    ref,
  ) => {
    const textInputIdRef = useRef(generateClassname());
    const classes = useStyles({ contained, hasError: !!errorLabel, isDisabled: disabled });

    return (
      <Column gap={6} css={classes.root} inline contained={contained}>
        <Column gap={8} contained={contained}>
          <ContentEditable
            id={textInputIdRef.current}
            css={classes.input}
            className={className}
            text={text}
            onChange={onChange}
            allowHtml={allowHtml}
            placeholder={placeholder}
            maxLength={maxLength}
            disabled={disabled}
            ref={ref}
          />
          {typeof maxLength === 'number' && (
            <Row right>
              <Typography variant="supporting.helper">
                {text.length}/{maxLength}
              </Typography>
            </Row>
          )}
        </Column>

        {errorLabel && <FormHelperError id={textInputIdRef.current} message={errorLabel} />}
      </Column>
    );
  },
);

export default TextInput;
