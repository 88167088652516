import request, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { isAxiosError } from 'helpers/clientHelpers';
import queryString from 'query-string';
import { getActiveTokenStatically } from 'shared/contexts/AuthContext';

export class BaseClient {
  constructor() {
    this.request = this.request.bind(this);
    this.fetchFile = this.fetchFile.bind(this);
  }
  serializeParams(params: any) {
    return queryString.stringify(params);
  }

  async attachOptions(options: AxiosRequestConfig, authenticate = true) {
    const [token] = await getActiveTokenStatically();

    return {
      ...options,
      // This sends the query params in the necessary format to API
      // We can update serializeParams to send arrays as
      // param[]=1&param[]=2 in application
      paramsSerializer: (params: any) => this.serializeParams(params),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
        ...(token &&
          authenticate && {
            Authorization: 'Bearer ' + token,
          }),
      },
    };
  }

  request<T = any>(options: AxiosRequestConfig, authenticate = true, maxRetries = 3): AxiosPromise<T> {
    return this.attachOptions(options, authenticate).then((optionsWithHeader) => {
      return new Promise((res, rej) =>
        request(optionsWithHeader)
          .then(res)
          .catch((e) => {
            if (isAxiosError(e) && [401, 403].includes(e.response?.status ?? -1)) {
              window.logout();
            }

            if (
              e.response &&
              e.response.status &&
              (e.response.status === 403 || e.response.status === 401) &&
              maxRetries > 0
            ) {
              if (e.response.status === 401) {
                //FIXME: Find a better way to receive logout method from React
                if (shouldLogoutByUrl(optionsWithHeader?.url) && window.logout) {
                  window.logout();
                }
              }
            }

            rej(e);
          }),
      );
    });
  }

  fetchFile(
    fileName: string,
    options: AxiosRequestConfig,
    download = true,
    fileType: string | undefined = undefined,
    authenticate = true,
    maxRetries = 3,
  ): Promise<void> {
    options['responseType'] = 'blob';
    return this.request(options, authenticate, maxRetries).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      if (download) {
        link.setAttribute('download', fileName);
      }
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    });
  }
}

const shouldLogoutByUrl = (url = '') => {
  const shouldLogoutUrl = process.env.REACT_APP_BASE_API_URL;

  if (!shouldLogoutUrl) {
    return false;
  }

  return url.includes(shouldLogoutUrl);
};
