import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    height: 100%;
    width: 100%;
  `,
  pdfWrapper: () => css`
    position: relative;
    height: 100%;
    max-width: 1000px;
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;

    & button {
      border: none;
      background: none;
      cursor: pointer;
    }

    & > div:first-of-type {
      & .react-pdf__Page__textContent {
        user-select: none;
      }
    }
  `,
  pdfWrapperCvFile: css`
    justify-content: flex-start;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
