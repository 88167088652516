import { ChevronDown, ChevronUp } from '@recruitrobin/robin-theme/web-icons';
import { Fragment, useCallback, useRef, useState } from 'react';
import { Card } from 'shared/components/layouts/Card/Card';
import { useOnClickOutside } from 'shared/hooks';
import { useStyles } from './DropDownMenu.styles';
import { ItemProps, Props } from './DropDownMenu.types';

export const DropDownMenu = ({
  onClick: onClickParam,
  showArrow = true,
  open: _open = false,
  rightClickOpen = false,
  closeOnClick = true,
  size = 'large',
  options: Options = () => <></>,
  className,
  ...props
}: Props) => {
  const [open, setOpen] = useState(_open);

  const ref = useRef<HTMLInputElement>(null);
  useOnClickOutside(ref as any, () => setOpen((prevState) => (prevState ? false : prevState)));

  const classes = useStyles({ open, size });

  const Title = ({ children }: { children: React.ReactNode }) => <div css={classes.title}>{children}</div>;
  const Actions = ({ children }: { children: React.ReactNode }) => <div css={classes.actions}>{children}</div>;
  const Item = ({ children, onClick = () => null, ...props }: ItemProps) => {
    return (
      <div css={classes.item} {...props} onClick={() => [onClick(), closeOnClick && setOpen(false)]}>
        {children}
      </div>
    );
  };

  const onContextMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (rightClickOpen) {
        event.preventDefault();
        setOpen((prevState) => !prevState);
      }
    },
    [rightClickOpen],
  );

  const onClick = useCallback(() => {
    if (!rightClickOpen) {
      setOpen((prevState) => !prevState);
    }
    onClickParam?.();
  }, [rightClickOpen]);

  return (
    <Fragment>
      <div css={classes.root} className={className} ref={ref} {...props}>
        <div css={classes.button} onClick={onClick} onContextMenu={onContextMenu}>
          <div css={classes.label}>{props.label}</div>
          {showArrow && open ? <ChevronUp size={12} /> : <ChevronDown size={12} />}
        </div>
        <Card css={classes.popup}>
          <Options open={open} setOpen={setOpen} Title={Title} Actions={Actions} Item={Item} />
        </Card>
      </div>
    </Fragment>
  );
};
