import { Typography as TypographyBase } from '@recruitrobin/robin-theme/web-components';
import Skeleton, { SkeletonProps } from 'components/ui/molecules/Skeleton';
import { capitalizeFirstLetterString } from 'utils/shared/capitalizeFirstLetterString';
import { useStyles } from './styles';
import { TypographyProps } from './types';

const Typography = ({
  variant,
  children: _children,
  color,
  colorv2,
  isLoading = false,
  nowrap = false,
  contained = false,
  pointer = false,
  capitalizeFirstLetter = false,
  underline = false,
  inline = false,
  breakSpaces = false,
  skeletonProps,
  fontSize,
  className,
  ellipsis = false,
  capitalize = false,
}: TypographyProps) => {
  const classes = useStyles({
    color,
    variant,
    nowrap,
    contained,
    pointer,
    underline,
    fontSize,
    inline,
    breakSpaces,
    ellipsis,
    capitalize,
  });

  if (isLoading) {
    return <Skeleton {...({ width: 150, ...skeletonProps, variant: 'text', textVariant: variant } as SkeletonProps)} />;
  }

  const children =
    capitalizeFirstLetter && typeof _children === 'string' && _children.match(/^[A-z]/)
      ? capitalizeFirstLetterString(_children)
      : _children;

  if (typeof variant === 'function') {
    return (
      <TypographyBase
        css={[classes.root, classes.overrides]}
        className={className}
        variant={variant}
        color={colorv2 || 'currentColor'}>
        {children}
      </TypographyBase>
    );
  }

  return (
    <div css={[classes.root, classes[variant], classes.overrides]} className={className} data-testid="typography">
      {ellipsis ? <span css={classes.ellipsis}>{children}</span> : children}
    </div>
  );
};

export default Typography;
