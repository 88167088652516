import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

export const styles = {
  root: ({ theme }) =>
    css({
      color: theme.colors.neutral.variant[50],
    }),
  content: () => css({}),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
