export class ErrorHandler {
  static checkAndThrowError(callback: () => void, { onReject }: { onReject?: (error: Error) => void } = {}) {
    try {
      callback();
    } catch (err) {
      if (err instanceof Error && onReject) {
        onReject(err);
      } else {
        throw err;
      }
    }
  }
}
