import { css, CSSObject } from '@emotion/react';
import { Styles, Theme, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { styleUtils } from 'utils';

import { ButtonIconAlignment, ButtonStyleStates, ButtonVariant, ConditionalStyles } from './types';

const buttonTonalVariant: ConditionalStyles<Theme, ButtonVariant> = (theme) => {
  return {
    default: {
      color: theme.colors.secondary.sky[60],
      backgroundColor: theme.colors.secondary.sky[10],
      borderColor: theme.colors.secondary.sky[10],
      '&:hover': {
        backgroundColor: theme.colors.secondary.sky[20],
        borderColor: theme.colors.secondary.sky[20],
      },
      '&.button-focused': {
        color: theme.colors.secondary.sky[70],
        backgroundColor: theme.colors.secondary.sky[20],
        borderColor: theme.colors.secondary.sky[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.secondary.sky[70],
        backgroundColor: theme.colors.secondary.sky[30],
        borderColor: theme.colors.secondary.sky[30],
      },
    },
    highlight: {
      color: theme.colors.primary.fuchsia[60],
      backgroundColor: theme.colors.primary.fuchsia[10],
      borderColor: theme.colors.primary.fuchsia[10],
      '&:hover': {
        backgroundColor: theme.colors.primary.fuchsia[20],
        borderColor: theme.colors.primary.fuchsia[20],
      },
      '&.button-focused': {
        color: theme.colors.primary.fuchsia[70],
        backgroundColor: theme.colors.primary.fuchsia[20],
        borderColor: theme.colors.primary.fuchsia[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.primary.fuchsia[70],
        backgroundColor: theme.colors.primary.fuchsia[30],
        borderColor: theme.colors.primary.fuchsia[30],
      },
    },
    warning: {
      color: theme.colors.tertiary.lime[60],
      backgroundColor: theme.colors.tertiary.lime[10],
      borderColor: theme.colors.tertiary.lime[10],
      '&:hover': {
        backgroundColor: theme.colors.tertiary.lime[20],
        borderColor: theme.colors.tertiary.lime[20],
      },
      '&.button-focused': {
        color: theme.colors.tertiary.lime[70],
        backgroundColor: theme.colors.tertiary.lime[20],
        borderColor: theme.colors.tertiary.lime[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.tertiary.lime[70],
        backgroundColor: theme.colors.tertiary.lime[30],
        borderColor: theme.colors.tertiary.lime[30],
      },
    },
    success: {
      color: theme.colors.secondary.emerald[50],
      backgroundColor: theme.colors.secondary.emerald[10],
      borderColor: theme.colors.secondary.emerald[50],
      '&:hover': {
        backgroundColor: theme.colors.secondary.emerald[50],
        color: theme.colors.neutral.variant[0],
      },
      '&.button-focused': {
        color: theme.colors.neutral.variant[0],
        backgroundColor: theme.colors.secondary.emerald[60],
        borderColor: theme.colors.secondary.emerald[60],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.neutral.variant[0],
        backgroundColor: theme.colors.secondary.emerald[60],
        borderColor: theme.colors.secondary.emerald[60],
      },
    },
    danger: {
      color: theme.colors.tertiary.red[60],
      backgroundColor: theme.colors.tertiary.red[10],
      borderColor: theme.colors.tertiary.red[10],
      '&:hover': {
        backgroundColor: theme.colors.tertiary.red[20],
        borderColor: theme.colors.tertiary.red[20],
      },
      '&.button-focused': {
        color: theme.colors.tertiary.red[70],
        backgroundColor: theme.colors.tertiary.red[20],
        borderColor: theme.colors.tertiary.red[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.tertiary.red[70],
        backgroundColor: theme.colors.tertiary.red[30],
        borderColor: theme.colors.tertiary.red[30],
      },
    },
    subtle: {
      color: theme.colors.primary.fuchsia[60],
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:hover': {
        backgroundColor: theme.colors.neutral.variant[10],
      },
      '&.button-focused': {
        backgroundColor: theme.colors.neutral.variant[10],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        backgroundColor: theme.colors.neutral.variant[20],
      },
    },
    'small-rounded': {
      height: 26,
      width: 26,
      svg: {
        height: 16,
        width: 16,
      },
      padding: 9,
      borderRadius: '50%',
      color: theme.colors.neutral.variant[60],
      '&:hover': {
        color: theme.colors.neutral.variant[80],
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
      },
      '&.button-focused, &:focus-visible': {
        color: theme.colors.neutral.variant[80],
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
        outline: 0,
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.neutral.variant[80],
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
      },
    },
  };
};

const buttonFilledVariant: ConditionalStyles<Theme, Extract<ButtonVariant, 'highlight'>> = (theme) => {
  return {
    highlight: {
      color: theme.colors.primary.fuchsia[0],
      backgroundColor: theme.colors.primary.fuchsia[50],
      borderColor: theme.colors.primary.fuchsia[50],
      '&:hover': {
        backgroundColor: theme.colors.primary.fuchsia[60],
        borderColor: theme.colors.primary.fuchsia[60],
      },
      '&.button-focused': {
        backgroundColor: theme.colors.primary.fuchsia[60],
        borderColor: theme.colors.primary.fuchsia[60],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.primary.fuchsia[0],
        backgroundColor: theme.colors.primary.fuchsia[70],
        borderColor: theme.colors.primary.fuchsia[70],
      },
    },
  };
};

const buttonDeleteVariant: ConditionalStyles<Theme, Extract<ButtonVariant, 'default'>> = (theme) => {
  return {
    default: {
      color: theme.colors.neutral.variant[70],
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:hover': {
        color: theme.colors.tertiary.red[60],
        backgroundColor: theme.colors.tertiary.red[10],
        borderColor: theme.colors.tertiary.red[10],
      },
      '&.button-focused': {
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        color: theme.colors.tertiary.red[70],
        backgroundColor: theme.colors.tertiary.red[20],
        borderColor: theme.colors.tertiary.red[20],
      },
    },
  };
};

const buttonIconVariant: ConditionalStyles<Theme, Extract<ButtonVariant, 'default'>> = (theme) => {
  return {
    default: {
      color: theme.colors.neutral.variant[70],
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:hover': {
        color: theme.colors.neutral.variant[70],
        backgroundColor: theme.colors.neutral.variant[10],
        borderColor: theme.colors.neutral.variant[10],
      },
      '&.button-focused': {
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
      },
      '&:active, &.m-active, &.force-pressed-state': {
        backgroundColor: theme.colors.neutral.variant[20],
        borderColor: theme.colors.neutral.variant[20],
      },
    },
  };
};

const disabledButtonStyle = ({ theme, variant }: { theme: Theme; variant: ButtonVariant }): CSSObject => ({
  color: theme.colors.neutral.variant[40],
  backgroundColor: theme.colors.neutral.variant[10],
  borderColor: theme.colors.neutral.variant[10],
  ...(variant === 'subtle' && {
    color: theme.colors.neutral.variant[30],
    backgroundColor: 'transparent',
    borderColor: 'transparent',
  }),
});

const iconAlignmentStyles: ConditionalStyles<Partial<ButtonStyleStates>, ButtonIconAlignment> = () => ({
  left: {
    order: 0,
  },
  right: {
    order: 1,
  },
});

const labelAlignmentStyles: ConditionalStyles<Partial<ButtonStyleStates>, ButtonIconAlignment> = () => ({
  left: {
    order: 1,
  },
  right: {
    order: 0,
  },
});

const styles = {
  root: ({ theme, props: { variant = 'default', style, disabled, iconExists, labelExists, contained } }) =>
    css({
      '&.MuiButtonBase-root': {
        fontSize: '28px',
        lineHeight: '18px',
        border: '1px solid transparent',
        borderRadius: 6,
        padding: '9px 16px',
        fontFamily: 'Outfit, helvetica',
        transition: styleUtils.generateTransitionStyles(['border', 'background-color', 'outline', 'color'], '80ms'),
        '&.MuiButtonBase-root:active': {
          cursor: 'default',
        },
        ...((iconExists && !labelExists && { padding: '8px' }) || {}),
        ...((style === 'tonal' && buttonTonalVariant(theme)[variant]) || {}),
        ...((style === 'filled' && (buttonFilledVariant(theme) as any)[variant]) || {}),
        ...(style === 'delete' && (buttonDeleteVariant(theme) as any)[variant]),
        ...(style === 'icon' && (buttonIconVariant(theme) as any)[variant]),
        ...((disabled && disabledButtonStyle({ theme, variant })) || {}),
        ...(contained && { width: '100%' }),
      },
    }),
  label: ({ props: { preserveText } }) =>
    css({
      '&.MuiTypography-button': {
        display: 'block',
        textTransform: preserveText ? 'unset' : 'lowercase',
      },
      ...{
        '&.MuiTypography-button:first-letter': {
          textTransform: 'uppercase',
        },
      },
    }),
  contentWrapper: ({ props: { iconAlignment = 'left', nowrap } }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      gap: '9px',
      '& > .content-wrapper--icon': {
        display: 'inline-flex',
        svg: {
          width: '14px',
          height: '14px',
        },
        ...iconAlignmentStyles({})[iconAlignment],
      },
      '& > .content-wrapper--label': {
        ...labelAlignmentStyles({})[iconAlignment],
        ...(nowrap && { whiteSpace: 'nowrap' }),
      },
    }),
  activeMenuItem: ({ theme }) =>
    css({
      backgroundColor: theme.colors.secondary.sky[20],
    }),
} satisfies Styles<ButtonStyleStates>;

export const useStyles = withStyles(styles);
