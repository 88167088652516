import { useQuery } from '@tanstack/react-query';
import { tenantClient } from 'clients/Admin';
import { useContext, useMemo } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

export type UseCvSourcesHook = () => {
  enabledCvSources: string[];
};

const useCvSources: UseCvSourcesHook = () => {
  const { currentUser } = useContext(UserContext);

  const { data: enabledCvSources = [] } = useQuery(['cv-sources'], async () => {
    if (currentUser) {
      const { data } = await tenantClient.getCvSources(currentUser.tenant.id);

      const filteredCvSources = data.filter((cvSource) => cvSource.enabled).map((cvSource) => cvSource.cv_source);

      return filteredCvSources;
    }
  });

  return useMemo(() => {
    return { enabledCvSources };
  }, [enabledCvSources]);
};

export default useCvSources;
