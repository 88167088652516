import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  skeleton: css`
    background-color: white;
    z-index: 1;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
