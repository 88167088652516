import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';

type GetShowNavbarType = (args: { showNavbarParam: string; setShowNavbarParam: (value: string) => void }) => boolean;

export const getShouldEnableTeamview = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const teamview = urlParams.get('teamview');

  return teamview === 'true' || teamview === null;
};

export const getShowNavbar: GetShowNavbarType = ({ showNavbarParam, setShowNavbarParam }) => {
  let showNavbar;

  if (showNavbarParam) {
    showNavbar = showNavbarParam;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    showNavbar = urlParams.get('showNavbar') || 'false';
    setShowNavbarParam(showNavbar);
  }

  return showNavbar === 'true';
};

export const getCampaignStatus = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get('status') as CampaignOverviewStatus;

  return status || CampaignOverviewStatus.active;
};

export const getShowCampaignOverviewButton = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const showCampaignOverviewButton = urlParams.get('showCampaignOverviewButton');

  return showCampaignOverviewButton === 'true' || showCampaignOverviewButton === null;
};
