import { useContext, useEffect, useState } from 'react';
import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { TablePagination } from 'shared/components/Table/components';
import { PlayCircle, Slash } from 'shared/icons';
import { useStyles } from './WerknlCredentialsModal.styles';
import { WerknlCredentialsModalProps } from './types';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as Modal } from 'shared/components/Modal';
import { default as TextField } from 'shared/components/TextField';
import { Credential } from 'clients/CrawlerClient/types';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';

const CREDENTIALS_PER_PAGE = 12;

const sortCredentialsByTenantId = (array: Credential[] = [], tenantId: number) => {
  return array.sort((a, b) => {
    const tenantIdA = Number(a.tenantOwnerId);
    const tenantIdB = Number(b.tenantOwnerId);

    if (tenantIdA === tenantId && tenantIdB !== tenantId) {
      return -1;
    } else if (tenantIdA !== tenantId && tenantIdB === tenantId) {
      return 1;
    } else {
      return 0;
    }
  });
};

const WerknlCredentialsModal = ({ visible, onClose, tenantId }: WerknlCredentialsModalProps) => {
  const platform = 'werknl';
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { dictionary } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);
  const [allCredentials, setAllCredentials] = useState<Credential[]>([]);
  const [credentials, setCredentials] = useState<Credential[]>([]);
  const { data: response = null, isLoading } = useQuery(
    ['get-all-credentials', platform],
    () => crawlerClient.getCredentials(platform),
    {
      enabled: visible,
    },
  );

  useEffect(() => {
    if (response?.data) {
      setAllCredentials(response.data);
    }
  }, [response]);

  useEffect(() => {
    const credentialsSorted = sortCredentialsByTenantId(allCredentials, tenantId);

    setCredentials(Array.from(credentialsSorted).splice((page - 1) * CREDENTIALS_PER_PAGE, CREDENTIALS_PER_PAGE));
  }, [allCredentials, page]);

  const handleSearch = (text: string) => {
    if (response?.data) {
      setAllCredentials(response?.data.filter(({ username }) => username.toLowerCase().includes(text.toLowerCase())));
    }
  };

  const handleToggleCredential = async (id: string, tenantOwnerId: number) => {
    const value = tenantOwnerId === tenantId ? null : tenantId;

    await crawlerClient.updateCredentialById({ platform, id, data: { tenantOwnerId: value } });
    queryClient.invalidateQueries(['get-all-credentials', platform]);
    queryClient.removeQueries(['crawler-is-werknl-active']);
  };

  return (
    <Modal title={'Werknl Credentials'} visible={visible} onClose={onClose} hideActions>
      <div css={classes.root}>
        <div css={classes.search}>
          <TextField label={dictionary.search} onChange={handleSearch} contained />
        </div>
        <div css={classes.usersWrapper}>
          {credentials.map(({ _id, username, tenantOwnerId }) => (
            <div key={_id} css={classes.row}>
              <div>
                <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
                  {username} {tenantOwnerId ? `- Tenant ID: ${tenantOwnerId}` : ''}
                </Typography>
              </div>
              <div>
                <Button
                  variant="subtle"
                  label={Number(tenantOwnerId) === tenantId ? 'Deactive' : 'Active'}
                  onClick={() => {
                    handleToggleCredential(_id, Number(tenantOwnerId));
                  }}
                  icon={Number(tenantOwnerId) === tenantId ? <Slash size={18} /> : <PlayCircle size={18} />}
                />
              </div>
            </div>
          ))}
        </div>
        <TablePagination
          perPage={CREDENTIALS_PER_PAGE}
          visibleButtons={3}
          total={allCredentials.length}
          currentPage={page}
          onChange={setPage}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default WerknlCredentialsModal;
