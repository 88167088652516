import { useGTM } from 'hooks/gtm';
import { useMemo } from 'react';
import { EducationDegree, JobTitle, Location } from 'shared/contexts/SearchContext/FiltersContext/types';
import { useLocalStorage } from './useLocalStorage';

export const useCampaignAiGTM = ({ campaignId }: { campaignId?: number }) => {
  const campaignLS = useLocalStorage<{
    createdAt?: string;
    added: Array<{ editedAt: string; value?: Record<string, any> }>;
    removed: Array<{ editedAt: string; value?: Record<string, any> }>;
    manual: Array<{ editedAt: string; value?: Record<string, any> }>;
  }>(`USE-CAMPAIGN-AI-GTM-${campaignId}`);
  const gtm = useGTM();

  const campaignCreated = () => {
    campaignLS.setValue({ createdAt: new Date().toISOString(), added: [], removed: [], manual: [] });
  };

  const aiSuggestionsAdded = ({
    campaignFilter,
    campaignId,
    filters,
  }: {
    campaignId: string | number;
    campaignFilter: string;
    filters: Array<JobTitle | Location | EducationDegree>;
  }) => {
    campaignLS.setValue((value) => {
      if (!value) return value;

      const gtmValue = { campaignFilter, campaignId, filters, length: filters.length };
      return {
        ...value,
        added: [...value.added, { editedAt: new Date().toISOString(), value: gtmValue }],
      };
    });

    gtm.aiSuggestionsAdded({ campaignFilter, campaignId, length: filters.length });
  };

  const aiSuggestionsRemoved = ({
    campaignFilter,
    campaignId,
    filters,
  }: {
    campaignId: string | number;
    campaignFilter: string;
    filters: Array<JobTitle | Location | EducationDegree>;
  }) => {
    campaignLS.setValue((value) => {
      if (!value) return value;

      const gtmValue = { campaignFilter, campaignId, filters, length: filters.length };
      return { ...value, removed: [...value.removed, { editedAt: new Date().toISOString(), value: gtmValue }] };
    });

    gtm.aiSuggestionsRemoved({ campaignFilter, campaignId, length: filters.length });
  };

  const manualJobTitleAdded = ({
    campaignId,
    filters,
  }: {
    campaignId: string | number;
    filters: Array<JobTitle | Location | EducationDegree>;
  }) => {
    if (filters.length > 0) {
      campaignLS.setValue((value) => {
        if (!value) return value;

        const gtmValue = { editedAt: new Date().toISOString(), value: { campaignId, filters, length: filters.length } };
        return { ...value, manual: [...value.manual, gtmValue] };
      });

      gtm.manualJobTitleAdded({
        campaignId,
        length: filters.length,
      });
    }
  };

  return useMemo(
    () => ({
      campaignCreated,
      aiSuggestionsAdded,
      aiSuggestionsRemoved,
      manualJobTitleAdded,
    }),
    [campaignId],
  );
};
