import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { StyleStates } from './types';

const styles = {
  root: () =>
    css({
      gap: 0,
    }),
  animatedBox: ({ props: { timeTransition } }) =>
    css({
      transition: `${timeTransition}ms cubic-bezier(0.77, -0.01, 0.34, 1.01)`,
    }),
  box1: ({ theme, props: { size } }) =>
    css({
      height: size,
      width: size,
      backgroundColor: theme.colors.primary.fuchsia[40],
      borderRadius: `0 0 ${size}px 0`,
    }),
  box2: ({ theme, props: { size } }) =>
    css({
      height: size,
      width: size,
      backgroundColor: theme.colors.secondary.emerald[60],
      borderRadius: `0 ${size}px 0 0`,
    }),
  box3: ({ theme, props: { size } }) =>
    css({
      height: size,
      width: size,
      backgroundColor: theme.colors.secondary.violet[60],
      borderRadius: `0 ${size}px 0 0`,
    }),
} satisfies Styles<StyleStates>;

export const useStyles = withStyles(styles);
