import { useContext, useState } from 'react';
import { useStyles } from './TableActions.styles';
import { Button } from 'components/ui/molecules';
import { Plus } from 'shared/icons';
import { CreateOrEditTenantModal } from '../TableBody/components';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

const TableActions = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [createTenantVisible, setCreateTenantVisible] = useState(false);

  const handleOpenModal = () => {
    setCreateTenantVisible(true);
  };

  const handleCloseModal = () => {
    setCreateTenantVisible(false);
  };

  return (
    <div css={classes.root}>
      <Button label={dictionary.createNewCompany} icon={<Plus />} variant="highlight" onClick={handleOpenModal} />
      <CreateOrEditTenantModal visible={createTenantVisible} onClose={handleCloseModal} />
    </div>
  );
};

export default TableActions;
