import { TooltipStyleStates } from './types';
import { withStyles, Styles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ props: { disabled, pointer } }) =>
    css({
      ...(disabled && {
        '& + .MuiTooltip-popper': {
          display: 'none',
        },
      }),
      ...(pointer && { cursor: 'pointer' }),
    }),
} satisfies Styles<TooltipStyleStates>;

export const useStyles = withStyles(styles);
