import { hosts } from 'config';
import { GetAvailableJobBoardsHandler, GetJobBoardCredentialsHandler } from './types';
import { BaseClient } from 'clients/BaseClient/BaseClient';

export class JobBoardsClient extends BaseClient {
  constructor() {
    super();
    this.addLicense = this.addLicense.bind(this);
    this.editLicense = this.editLicense.bind(this);
    this.deleteLicense = this.deleteLicense.bind(this);
  }

  getJobBoardCredentials: GetJobBoardCredentialsHandler = (platformId) => {
    return this.request({
      url: `${hosts.crawlerWebApi()}/userCredentials/`,
      method: 'GET',
      params: { platform: platformId },
    });
  };

  getAvailableJobBoards: GetAvailableJobBoardsHandler = () => {
    return this.request({
      url: `${hosts.crawlerWebApi()}/userCredentialJobBoards/`,
      method: 'GET',
    });
  };

  async addLicense({ platformId, username, password, secret: securityQuestionAnswer, cancelToken = undefined }: any) {
    return this.request({
      url: `${hosts.crawlerWebApi()}/userCredentials/`,
      cancelToken: cancelToken,
      method: 'POST',
      data: {
        platform: platformId,
        username,
        password,
        ...(securityQuestionAnswer && { securityQuestionAnswer }),
      },
    });
  }

  async editLicense({
    platformId,
    licenseId,
    username,
    password,
    secret: securityQuestionAnswer,
    cancelToken = undefined,
  }: any) {
    return this.request({
      url: `${hosts.crawlerWebApi()}/userCredentials/${licenseId}`,
      cancelToken: cancelToken,
      method: 'PUT',
      data: {
        username,
        password,
        platform: platformId,
        ...(securityQuestionAnswer && { securityQuestionAnswer }),
      },
    });
  }

  async deleteLicense(id: string) {
    return this.request({
      url: `${hosts.crawlerWebApi()}/userCredentials/${id}`,
      method: 'DELETE',
    });
  }
}

export const jobBoardsClient = new JobBoardsClient();
