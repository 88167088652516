import { routes } from 'config';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { CandidateStatus } from 'services/candidates';

export const usePathId = () => {
  const pathMatch = useMatch<'id' | 'tab', string>(routes.search({ campaignId: ':id', tab: ':tab?' }));
  const sharedPathMatch = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.sharedCandidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );
  const candidatePageMatch = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.candidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );

  return useMemo(() => {
    const sharedId = sharedPathMatch?.params.campaignId;
    const candidatePageId = candidatePageMatch?.params.campaignId;
    const searchV3Id = pathMatch?.params.id;
    const id = sharedId || candidatePageId || searchV3Id;
    const searchTab = pathMatch?.params.tab as CandidateStatus;

    const parsedId = parseInt(id ?? '');
    return { id: Number.isNaN(parsedId) ? undefined : parsedId, tab: searchTab };
  }, [pathMatch, sharedPathMatch, candidatePageMatch]);
};
