import { escapeRegExp } from 'lodash';
import { ReactNode, useContext, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';

import type { Dictionary, MetaExperience } from 'shared/contexts/LocalizationContext/types';

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const objectsAreEqual = (a: object | undefined | null, b: object | undefined | null) =>
  JSON.stringify(a) === JSON.stringify(b);

export const randomInt = (digits = 4) => Math.round(Math.random() * 10 ** digits);
export const calculateExperienceInMonths = (first: number | undefined, last: number | undefined | null, gap = 0) => {
  if (!first) {
    return 0;
  }
  const today = new Date().getFullYear() * 12 + (new Date().getMonth() + 1);
  const durationInMonths = (last || today) - (first || 12) - (gap || 0);

  return durationInMonths;
};

export const experienceInMonthsToHumanReadable = (months: number, dictionary: Dictionary): string => {
  const years = Math.ceil(months / 12);
  if (months < 12) {
    return `${months} ${months > 1 ? dictionary.months : dictionary.month}`;
  }
  return `${years} ${years > 1 ? dictionary.years : dictionary.year}`;
};

export const experienceInMonthsAndYearsToHumanReadable = (months: number, dictionary: Dictionary): string => {
  const years = Math.floor(months / 12);
  const leftOverMonths = months % 12;
  if (months < 12) {
    return `${months} ${months > 1 ? dictionary.months : dictionary.month}`;
  }

  if (leftOverMonths > 0) {
    return `${years} ${years > 1 ? dictionary.years : dictionary.year} ${dictionary.and} ${leftOverMonths} ${
      leftOverMonths > 1 ? dictionary.months : dictionary.month
    }`;
  }

  return `${years} ${years > 1 ? dictionary.years : dictionary.year}`;
};

export const sortedExperiences = (experiences: any) => {
  return experiences.sort((a: any, b: any) => {
    const today = new Date().getFullYear() * 12 + (new Date().getMonth() + 1);
    return (b.dates?.end_recalculated || today) - (a.dates?.end_recalculated || today);
  });
};

export const getDurationInYears = (dates: any, dictionary: Dictionary, showMonths = false): string | null => {
  if (!dates.start_recalculated) {
    return null;
  }
  const today = new Date().getFullYear() * 12 + (new Date().getMonth() + 1);
  const durationInMonths = (dates?.end_recalculated || today) - dates.start_recalculated || 12;

  if (showMonths) {
    return experienceInMonthsAndYearsToHumanReadable(durationInMonths, dictionary);
  }

  return experienceInMonthsToHumanReadable(durationInMonths, dictionary);
};

export const getMostRecentExperience = (experiences: any, fallback: any) => {
  if (experiences.length === 0) {
    return fallback;
  }
  return sortedExperiences(experiences)[0];
};

export const generateExperienceDates = (dates: any, currentString = 'Current') => {
  const { start = null, end = null } = dates;
  if (!start) {
    return '';
  }
  if (start && end) {
    if (!start.month && !end.month) {
      return `${start.year} - ${end.year}`;
    }
    return `${start.year} - ${end.year}`;
  }
  return `${start.year} - ${currentString}`;
};

export const distanceBetweenCoordinates = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: 'km' | 'n' = 'km',
): number => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'km') {
      dist = dist * 1.609344;
    }
    if (unit === 'n') {
      dist = dist * 0.8684;
    }
    return Math.ceil(dist);
  }
};

export const getClosestDistance = (
  coordinates: any & { lat: number; lon: number },
  coordinatesToCompare: any & { lat: number; lon: number }[],
): number | null => {
  if (coordinatesToCompare.length === 0) {
    return null;
  }
  const { lat, lon } = coordinates;
  const distances: number[] = [];
  for (const coordinateToCompare of coordinatesToCompare) {
    const { lat: lat2, lon: lon2 } = coordinateToCompare;
    distances.push(distanceBetweenCoordinates(lat, lon, lat2, lon2));
  }
  return Math.min(...distances);
};

type KeywordToHighlight = {
  needles: string[];
  color?: string;
};

/**
 * @deprecated import from `utils/shared` instead
 */
export const highlightKeywordsInString = (haystack?: string, keywords?: KeywordToHighlight[]) => {
  if (!haystack) {
    return {
      html: haystack,
      count: 0,
    };
  }
  let cleanedString = haystack;
  let count = 0;
  for (const keyword of keywords || []) {
    const parsedNeedles = [...new Set(keyword.needles.map((needle) => needle.toLowerCase()))];
    for (const needle of parsedNeedles || []) {
      if (needle && !['rrhighlight', 'span', 'class'].includes(needle.toLowerCase())) {
        const regex = /([+]+|[#]+|[-]+|[$]+)+/.test(needle)
          ? `(${escapeRegExp(needle)})+`
          : `(\\b${escapeRegExp(needle)}\\b)+`;
        const re = new RegExp(regex, 'gim');
        if (cleanedString && re) {
          count += (cleanedString || '').match(re)?.length || 0;
        }
        cleanedString = cleanedString.replace(re, `<span class='rrhighlight'>$1</span>`);
      }
    }
  }
  return {
    html: count > 0 ? ReactHtmlParser(cleanedString) : haystack,
    count,
  };
};

export const cleanUrl = (url: string): string => {
  const _url = url.replace('https://', '').replace('http://', '');
  return `http://${_url}`;
};

export const registrationRedirect = (country: number, type: number, recruiters: number, ats: number | null) => {
  const preferredCountry = [155, 23].includes(country);
  const isIdealCandidate = preferredCountry && type === 1 && ats !== 0 && recruiters >= 3;
  const isGoodCandidate = preferredCountry && type === 2 && ats !== 0 && recruiters >= 1;
  const isPotentialCandidate =
    (preferredCountry && (type === 1 || type === 3) && recruiters <= 2) ||
    (preferredCountry && type === 2 && recruiters === 0) ||
    (preferredCountry && type === 3) ||
    preferredCountry;

  if (isIdealCandidate) {
    return (window.location.pathname = '/search/campaigns');
  } else if (isGoodCandidate) {
    return (window.location.href = 'https://www.recruitrobin.com/corporate');
  } else if (isPotentialCandidate) {
    return (window.location.href = 'https://www.recruitrobin.com/hey-early-bird');
  } else {
    return (window.location.href = 'https://www.recruitrobin.com/en/hey-early-bird');
  }
};

export const getTextOfComponent = (component: (ReactNode & JSX.Element) | string): string => {
  if (Array.isArray(component)) {
    return Array.prototype.map.call(component, getTextOfComponent).join(' ').replace(/\s+/g, ' ');
  }

  if (typeof component === 'object') {
    return getTextOfComponent(component?.props?.children);
  }

  if (typeof component === 'string') {
    return component;
  }

  return '';
};

export const arraySymetricDifference = <T>(arr1: Array<T>, arr2: Array<T>, prop: keyof T) => {
  const removed = arr1.filter((x) => !arr2.some((y) => x[prop] === y[prop]));
  const added = arr2.filter((x) => !arr1.some((y) => x[prop] === y[prop]));

  return { removed, added };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const postpone = (cb: Function) => {
  return setTimeout(cb, 1);
};

export const closestNumber = (compareTo: number) => (prev: number, curr: number) =>
  Math.abs(curr - compareTo) < Math.abs(prev - compareTo) ? curr : prev;

export const generateDtiAttribute = (id: string | undefined, sufix?: string) => {
  if (id) {
    const path = ['dti', id, ...(sufix ? [sufix] : [])].join('-');

    return { 'data-tutorial-id': path };
  }

  return {};
};

/**
 * @deprecated import from `utils/shared` instead
 */
export const htmlToPlainText = (
  text: string,
  config?: {
    preserveLineBreak?: boolean;
  },
) => {
  const plainText = text
    .replace(/&nbsp;/g, ' ')
    .replace(/<\/div>/g, '</div>\n')
    .replace(/<br>/g, '\n')
    .replace(/<\/?[^>]+>/gi, '');

  if (config?.preserveLineBreak) {
    return plainText.replace(/\n/g, '<br>');
  }

  return plainText;
};

export const formatPlaintTextToHtml = (text: string) => {
  return text.replace(/\n/g, '<br>');
};

export const getHumanReadableDate = (metaExperience: MetaExperience, dicionary: Dictionary) => {
  const { first, last, gap } = metaExperience;
  if (!first) {
    return null;
  }
  return experienceInMonthsAndYearsToHumanReadable(calculateExperienceInMonths(first, last, gap), dicionary);
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const useMemoContext = <T, P>(context: React.Context<T>, selector: (context: T) => P): P => {
  const contextv = useContext(context);
  const memoizedValue = selector(contextv);

  return useMemo(() => memoizedValue, [memoizedValue]);
};

export const generateClassname = () =>
  String.fromCharCode(Math.floor(Math.random() * 26) + 65) +
  Array.from({ length: 5 }, () => Math.random().toString(36)[2]).join('');

export const isObject = (error: unknown): error is Record<string, unknown> => {
  return typeof error === 'object' && error !== null;
};
