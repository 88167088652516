import { DialogProps } from 'components/ui/molecules';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CanceledByUserException } from 'shared/exceptions';
import { LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { DialogEvents, PersistentDialogEvents } from './types';

const initialEvents: DialogEvents = {
  onConfirm: () => {
    throw new TypeError('You must provide onConfirm');
  },
  onCancel: () => {
    throw new TypeError('You must provide onCancel');
  },
};

export const useContainer = () => {
  const { dictionary } = useContext(LocalizationContext);

  const initialLabels = useMemo(
    () => ({ title: dictionary.areYouSure, description: dictionary.userDeactivateConfirmation }),
    [dictionary],
  );

  const [visible, setVisible] = useState(false);
  const [persist, setPersist] = useState(false);
  const [labels, setLabels] = useState<DialogProps['labels']>(initialLabels);
  const dialogListenersRef = useRef<DialogEvents>(initialEvents);

  const resetListeners = () => {
    dialogListenersRef.current = initialEvents;
  };

  const resetLabels = () => {
    setLabels(initialLabels);
  };

  const handleConfirm = () => {
    dialogListenersRef.current.onConfirm();
  };

  const handleCancel = () => {
    dialogListenersRef.current.onCancel();
  };

  const createDialog = useCallback(
    (labels?: Partial<DialogProps['labels']>) => {
      setLabels((oldLabels) => ({ ...oldLabels, ...labels }));
      setVisible(true);

      return new Promise<void>((res, rej) => {
        dialogListenersRef.current.onConfirm = res;
        dialogListenersRef.current.onCancel = () => rej(new CanceledByUserException());
      }).finally(() => {
        setVisible(false);
        resetListeners();
        resetLabels();
      });
    },
    [initialLabels],
  );

  const createPersistentDialog = useCallback((labels: Partial<DialogProps['labels']>): PersistentDialogEvents => {
    setLabels((oldLabels) => ({ ...oldLabels, ...labels }));
    setVisible(true);
    setPersist(true);

    return {
      dismiss: () => {
        setVisible(false);
        setPersist(false);
        resetLabels();
      },
    };
  }, []);

  useEffect(() => {
    if (visible) {
      document.body.classList.add('overflow-off');

      return () => {
        document.body.classList.remove('overflow-off');
      };
    }
  }, [visible]);

  return useMemo(
    () => ({
      states: { visible, labels, persist },
      functions: {
        handleConfirm,
        handleCancel,
        createDialog,
        createPersistentDialog,
      },
    }),
    [visible, labels, persist],
  );
};
