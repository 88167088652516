/* eslint-disable @typescript-eslint/no-unused-vars*/
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useMemo } from 'react';
import { NotifyCandidateMethod } from 'clients/CampaignsClient/CampaignsClient.types';

type CampaignIdParam = (params: { campaignId: number | string }) => void;

type CampaignAndEsPersonIdsParam = (params: { campaignId: number | string; esPersonId: string }) => void;

export type GTMMethods = {
  browserExtensionActivated: () => void;
  activateUser: () => void;
  deactivateUser: () => void;
  activateTenant: () => void;
  deactivateTenant: () => void;
  campaignOverviewSearch: () => void;
  createTenant: () => void;
  editTenant: () => void;
  activateAts: () => void;
  createUser: () => void;
  editUser: () => void;
  changeCampaignOwner: (campaign_id: number | string) => void;
  openCampaign: (campaign_id: number | string) => void;
  createCampaign: (campaign_id: number | string, reason: 'keyboard creation' | 'button click') => void;
  createSimpleSearchCampaign: (campaign_id: number | string) => void;
  expandFilter: (campaign_id: number | string) => void;
  shortlistCandidate: (campaign_id: number | string, es_person_id: string) => void;
  matchCandidate: (campaign_id: number | string, es_person_id: string) => void;
  contactCandidate: (campaign_id: number | string, es_person_id: string) => void;
  hiddenCandidate: (campaign_id: number | string, es_person_id: string) => void;
  editCampaignFilter: (campaign_id: number | string, filterName: string) => void;
  candidateSentToATS: (campaign_id: number | string, es_person_id: string) => void;
  contactSentMessage: (campaign_id: number | string, es_person_id: string, send_method: NotifyCandidateMethod) => void;
  contactCandidateFailed: (
    campaign_id: number | string,
    es_person_id: string,
    send_method: NotifyCandidateMethod,
  ) => void;
  callUnsuccessfull(campaign_id: number | string, es_person_id: string): void;
  candidateLabelAdded: (label: string) => void;
  candidateLabelRemoved: (label: string) => void;
  openedCandidateOverview: VoidFunction;
  openedSearchOverview: VoidFunction;
  openedMenu: VoidFunction;
  clickedNotificationBellIcon: VoidFunction;
  openedNotificationOverview: VoidFunction;
  notificationSeeSearchInListButtonClick: VoidFunction;
  openedNewSearchResultsTabInSearch: (campaign_id: number | string) => void;
  openedShortlistedTabInSearch: (campaign_id: number | string) => void;
  openedContactedTabInSearch: (campaign_id: number | string) => void;
  openedMatchedTabInSearch: (campaign_id: number | string) => void;
  openedHiddenTabInSearch: (campaign_id: number | string) => void;
  openedNotesOnCandidateCard: (campaign_id: number | string, es_person_id: string) => void;
  updatedNotesTabOnCandidateCard: (campaign_id: number | string, es_person_id: string) => void;
  openedProfileTabOnCandidateCard: (campaign_id: number | string, es_person_id: string) => void;
  openedCvTabOnCandidateCard: (campaign_id: number | string, es_person_id: string) => void;
  openedCandidateFullProfile: VoidFunction;
  clickedRobinLogo: VoidFunction;
  connectedCampaignToAtsJob: (campaign_id: number | string) => void; //need to recall on the ticket to create button
  markedOneNotificationAsRead: (params: { notificationId: number | string }) => void;
  markedAllNotificationsAsRead: VoidFunction;
  clickedHelpCenterButton: VoidFunction;
  copiedUrlToShareCandidate: CampaignAndEsPersonIdsParam;
  openedUserOverview: VoidFunction;
  openedUserProfileMenu: VoidFunction;
  campaignViewed: CampaignIdParam;
  campaignCreationFailed: VoidFunction;
  newAtsCampaignCreated: CampaignIdParam;
  recentSearchCampaignOpened: CampaignIdParam;
  aiSuggestionsAdded: (params: { length: number; campaignId: number | string; campaignFilter: string }) => void;
  aiSuggestionsRemoved: (params: { length: number; campaignId: number | string; campaignFilter: string }) => void;
  manualJobTitleAdded: (params: { length: number; campaignId: number | string }) => void;
  openedDashboard: VoidFunction;
  summaryAdded: VoidFunction;
};

export const useGTM = (): GTMMethods => {
  const gtmDispatch = useGTMDispatch();
  const sendDataToGTM = (params: {}) => {
    gtmDispatch({ environment: 'web', ...params });
  };

  return useMemo(() => {
    return {
      activateUser: () =>
        sendDataToGTM({
          event: 'user_activated',
        }),
      deactivateUser: () =>
        sendDataToGTM({
          event: 'user_deactivated',
        }),
      activateTenant: () =>
        sendDataToGTM({
          event: 'tenant_activated',
        }),
      deactivateTenant: () =>
        sendDataToGTM({
          event: 'tenant_deactivated',
        }),
      browserExtensionActivated: () =>
        sendDataToGTM({
          event: 'browser_extension_activated',
        }),
      campaignOverviewSearch: () =>
        sendDataToGTM({
          event: 'campaign_overview_search',
        }),
      candidateOverviewSearch: () =>
        sendDataToGTM({
          event: 'candidate_overview_search',
        }),
      createCampaign: (campaign_id, reason) =>
        sendDataToGTM({
          event: 'campaign_created',
          campaign_id,
          reason,
        }),
      createSimpleSearchCampaign: (campaign_id) =>
        sendDataToGTM({
          event: 'simple_search_campaign_created',
          campaign_id,
        }),
      expandFilter: (campaign_id) =>
        sendDataToGTM({
          event: 'filter_expanded',
          campaign_id,
        }),
      createTenant: () =>
        sendDataToGTM({
          event: 'tenant_created',
        }),
      editTenant: () =>
        sendDataToGTM({
          event: 'tenant_edited',
        }),
      activateAts: () =>
        sendDataToGTM({
          event: 'activate_ats',
        }),
      createUser: () =>
        sendDataToGTM({
          event: 'user_created',
        }),
      editUser: () =>
        sendDataToGTM({
          event: 'user_edited',
        }),
      changeCampaignOwner: (campaign_id) =>
        sendDataToGTM({
          event: 'campaign_owner_changed',
          campaign_id,
        }),
      openCampaign: (campaign_id) =>
        sendDataToGTM({
          event: 'campaign_opened',
          campaign_id,
        }),

      contactSentMessage: (campaign_id, es_person_id, send_method) => {
        const methods: Record<NotifyCandidateMethod, string> = {
          email: 'reachout_email_sent',
          whatsapp: 'reachout_whatsapp_sent',
          indeed: 'reachout_indeed_sent',
          linkedin: 'reachout_LI_request_sent',
          linkedin_inmail: 'reachout_LI_inmail_sent',
          call: 'reachout_call_connected',
          werknl: 'reachout_werknl_connected',
        };

        sendDataToGTM({
          event: methods[send_method],
          campaign_id,
          es_person_id,
        });
      },
      contactCandidateFailed: (campaign_id, es_person_id, send_method) => {
        const methods: Record<NotifyCandidateMethod, string> = {
          email: 'reachout_email_sent_failed',
          whatsapp: 'reachout_whatsapp_sent_failed',
          indeed: 'reachout_indeed_sent_failed',
          linkedin: 'reachout_LI_request_sent_failed',
          linkedin_inmail: 'reachout_LI_inmail_sent_failed',
          call: 'reachout_call_failed',
          werknl: 'reachout_werknl_failed',
        };
        sendDataToGTM({
          event: methods[send_method],
          campaign_id,
          es_person_id,
        });
      },
      shortlistCandidate: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'candidate_shortlisted',
          campaign_id,
          es_person_id,
        }),
      matchCandidate: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'candidate_matched',
          campaign_id,
          es_person_id,
        }),
      hiddenCandidate: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'candidate_hidden',
          campaign_id,
          es_person_id,
        }),
      candidateSentToATS: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'candidate_sent_to_ats',
          campaign_id,
          es_person_id,
        }),
      contactCandidate: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'candidate_contacted',
          campaign_id,
          es_person_id,
        }),
      callUnsuccessfull: (campaign_id, es_person_id) =>
        sendDataToGTM({
          event: 'reachout_call_not_connected',
          campaign_id,
          es_person_id,
        }),
      editCampaignFilter: (campaign_id, filterName) =>
        sendDataToGTM({
          event: 'campaign_filter_edited',
          campaign_id,
          campaign_filter: filterName,
        }),
      candidateLabelAdded: (label) => {
        sendDataToGTM({
          event: 'candidate_label_added',
          label_value: label,
        });
      },
      candidateLabelRemoved: (label) => {
        sendDataToGTM({
          event: 'candidate_label_removed',
          label_value: label,
        });
      },
      openedCandidateOverview: () => {
        sendDataToGTM({
          event: 'opened_candidate_overview',
        });
      },
      openedSearchOverview: () => {
        sendDataToGTM({
          event: 'opened_search_overview',
        });
      },
      openedMenu: () => {
        sendDataToGTM({
          event: 'opened_menu',
        });
      },
      clickedNotificationBellIcon: () => {
        sendDataToGTM({
          event: 'clicked_notification_bell_icon',
        });
      },
      openedNotificationOverview: () => {
        sendDataToGTM({
          event: 'opened_notification_overview',
        });
      },
      notificationSeeSearchInListButtonClick: () => {
        sendDataToGTM({
          event: 'notification_see_search_in_list_button_click',
        });
      },
      openedNewSearchResultsTabInSearch: (campaignId) => {
        sendDataToGTM({
          event: 'opened_new_search_results_tab_in_search',
          campaign_id: campaignId,
        });
      },
      openedShortlistedTabInSearch: (campaignId) => {
        sendDataToGTM({
          event: 'opened_shortlisted_tab_in_search',
          campaign_id: campaignId,
        });
      },
      openedContactedTabInSearch: (campaignId) => {
        sendDataToGTM({
          event: 'opened_contacted_tab_in_search',
          campaign_id: campaignId,
        });
      },
      openedMatchedTabInSearch: (campaignId) => {
        sendDataToGTM({
          event: 'opened_matched_tab_in_search',
          campaign_id: campaignId,
        });
      },
      openedHiddenTabInSearch: (campaignId) => {
        sendDataToGTM({
          event: 'opened_hidden_tab_in_search',
          campaign_id: campaignId,
        });
      },
      openedNotesOnCandidateCard: (campaignId, es_person_id) => {
        sendDataToGTM({
          event: 'opened_notes_on_candidate_card',
          campaign_id: campaignId,
          es_person_id,
        });
      },
      updatedNotesTabOnCandidateCard: (campaignId, es_person_id) => {
        sendDataToGTM({
          event: 'updated_notes_tab_on_candidate_card',
          campaign_id: campaignId,
          es_person_id,
        });
      },
      openedProfileTabOnCandidateCard: (campaignId, es_person_id) => {
        sendDataToGTM({
          event: 'opened_profile_tab_on_candidate_card',
          campaign_id: campaignId,
          es_person_id,
        });
      },
      openedCvTabOnCandidateCard: (campaignId, es_person_id) => {
        sendDataToGTM({
          event: 'opened_cv_tab_on_candidate_card',
          campaign_id: campaignId,
          es_person_id,
        });
      },
      openedCandidateFullProfile: () => {
        sendDataToGTM({
          event: 'opened_candidate_full_profile',
        });
      },
      clickedRobinLogo: () => {
        sendDataToGTM({
          event: 'clicked_robin_logo',
        });
      },
      connectedCampaignToAtsJob: (campaignId) => {
        sendDataToGTM({
          event: 'connected_campaign_to_ats_job',
          campaign_id: campaignId,
        });
      },
      markedOneNotificationAsRead: ({ notificationId }) => {
        sendDataToGTM({
          event: 'marked_one_notification_as_read',
          notification_id: notificationId,
        });
      },
      markedAllNotificationsAsRead: () => {
        sendDataToGTM({
          event: 'marked_all_notifications_as_read',
        });
      },
      clickedHelpCenterButton: () => {
        sendDataToGTM({
          event: 'clicked_help_center_button',
        });
      },
      copiedUrlToShareCandidate: ({ campaignId, esPersonId }) => {
        sendDataToGTM({
          event: 'copied_url_to_share_candidate',
          campaign_id: campaignId,
          es_person_id: esPersonId,
        });
      },
      openedNewSearchMenu: () => {
        sendDataToGTM({
          event: 'opened_new_search_menu',
        });
      },
      openedUserOverview: () => {
        sendDataToGTM({
          event: 'opened_user_overview',
        });
      },
      openedUserProfileMenu: () => {
        sendDataToGTM({
          event: 'opened_user_profile_menu',
        });
      },
      campaignViewed: ({ campaignId }) => {
        sendDataToGTM({
          event: 'campaign_viewed',
          campaign_id: campaignId,
        });
      },
      campaignCreationFailed: () => {
        sendDataToGTM({
          event: 'campaign_creation_failed',
        });
      },
      newAtsCampaignCreated: ({ campaignId }) => {
        sendDataToGTM({
          event: 'new_ats_campaign_created',
        });
      },
      recentSearchCampaignOpened: ({ campaignId }) => {
        sendDataToGTM({
          event: 'recent_search_campaign_opened',
          campaign_id: campaignId,
        });
      },
      aiSuggestionsAdded: ({ campaignFilter, campaignId, length }) => {
        sendDataToGTM({
          event: 'ai_suggestions_added',
          campaign_id: campaignId,
          campaign_filter: campaignFilter,
          length,
        });
      },
      aiSuggestionsRemoved: ({ campaignFilter, campaignId, length }) => {
        sendDataToGTM({
          event: 'ai_suggestions_removed',
          campaign_id: campaignId,
          campaign_filter: campaignFilter,
          length,
        });
      },
      manualJobTitleAdded: ({ campaignId, length }) => {
        sendDataToGTM({
          event: 'manual_job_title_added',
          campaign_id: campaignId,
          length,
        });
      },
      openedDashboard: () => {
        sendDataToGTM({
          event: 'dashboard_opened',
        });
      },
      summaryAdded: () => {
        sendDataToGTM({
          event: 'dashboard_add_summary',
        });
      },
    };
  }, [sendDataToGTM]);
};
