import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

export const useBrowserExtensionLocalStorage = () => {
  const get = async <T>(key: string): Promise<T> => {
    const { value } = await browserExtensionClient.sendMessage('readLocalStorage', { key });

    return value;
  };

  const set = <T>(key: string, value: T) => {
    browserExtensionClient.sendMessage('writeLocalStorage', { key, value });
  };

  return { get, set };
};
