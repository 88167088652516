import { themeOld } from 'styles/theme';

//Coming Soon -> Binnenkort beschikbaar
export const variants = {
  primary: themeOld.styleGuide.color.monochrome[70],
  primaryColor: themeOld.styleGuide.color.byzantine[80],
  secondary: themeOld.styleGuide.color.monochrome[0],
  tertiary: themeOld.styleGuide.color.mint[40],
  tertiaryDark: themeOld.styleGuide.color.mint[60],
  warning: themeOld.styleGuide.color.orange[60],
  quaternary: themeOld.styleGuide.platformColor.recruitrobin,
  quinary: themeOld.styleGuide.color.maroon[80],
  indeed: themeOld.styleGuide.platformColor.indeed,
  linkedin: themeOld.styleGuide.platformColor.linkedin,
  cvt: themeOld.styleGuide.platformColor.linkedin,
  monster: themeOld.styleGuide.platformColor.monster,
  monsterboard: themeOld.styleGuide.platformColor.monster,
  monsterboard_v2: themeOld.styleGuide.platformColor.monster,
  werknl: themeOld.styleGuide.platformColor.werknl,
  nvb: themeOld.styleGuide.platformColor.nvb,
  debanensite: themeOld.styleGuide.platformColor.debanensite,
  techniekwerkt: themeOld.styleGuide.platformColor.techniekwerkt,
  jobbird: themeOld.styleGuide.platformColor.jobbird,
  studentjob: themeOld.styleGuide.platformColor.studentjob,
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // werkzoeken: themeOld.styleGuide.platformColor.werkzoeken,
  uitzendbureau: themeOld.styleGuide.platformColor.uitzendbureau,
  resumeio: themeOld.styleGuide.platformColor.resumeio,
  ats: themeOld.styleGuide.platformColor.ats,
  white: themeOld.styleGuide.color.monochrome[0],
  error: themeOld.styleGuide.color.maroon[80],
  ok: themeOld.styleGuide.color.mint[40],
  saved: themeOld.styleGuide.color.orange[60],
  currentColor: 'currentColor',
  mint: themeOld.styleGuide.color.mint[60],
  strongMint: themeOld.styleGuide.color.mint[80],
  navy: themeOld.styleGuide.color.navy[60],
  byzantine: themeOld.styleGuide.color.byzantine[60],
  orange: themeOld.styleGuide.color.orange[60],
};
