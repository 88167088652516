import { styleUtils } from 'utils';
import { MenuItemStyleStates } from './types';
import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const MARGIN_TOP_WITH_DIVISOR = 12;

const styles = {
  root: ({ theme, props: { disabled, disableMouseEvents, withDivisor, highlight, variant } }) =>
    css({
      outline: 0,
      position: 'relative',
      cursor: 'pointer',
      color: theme.colors.neutral.variant[70],
      backgroundColor: theme.colors.neutral.variant[0],
      padding: '6px 16px',
      transition: styleUtils.generateTransitionStyles(['color', 'background']),
      ...(withDivisor && {
        marginTop: MARGIN_TOP_WITH_DIVISOR,
        '&:before': {
          position: 'absolute',
          content: '""',
          width: 'calc(100% - 20px)',
          height: 1,
          backgroundColor: theme.colors.neutral.variant[10],
          top: 5,
          left: 10,
          transform: `translateY(-${MARGIN_TOP_WITH_DIVISOR}px)`,
        },
      }),
      ...(highlight && {
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: 2,
        },
      }),
      ...(!disabled &&
        !disableMouseEvents && {
          '&:hover': {
            ...(variant === 'default' && {
              color: theme.colors.neutral.variant[80],
              backgroundColor: theme.colors.neutral.variant[5],
            }),
            ...(variant === 'destructive' && {
              color: theme.colors.tertiary.red[60],
              backgroundColor: theme.colors.neutral.variant[0],
            }),
            ...(variant === 'warning' && {
              color: theme.colors.tertiary.lime[60],
              backgroundColor: theme.colors.neutral.variant[0],
            }),
            ...(variant === 'success' && {
              color: theme.colors.secondary.emerald[60],
              backgroundColor: theme.colors.neutral.variant[0],
            }),
            ...(highlight && {
              '&::after': {
                backgroundColor: 'currentColor',
              },
            }),
          },
          '&:focus, &:focus-within': {
            ...(variant === 'default' && {
              color: theme.colors.neutral.variant[80],
              backgroundColor: theme.colors.neutral.variant[10],
            }),
            ...(variant === 'destructive' && {
              color: theme.colors.tertiary.red[60],
              backgroundColor: theme.colors.tertiary.red[10],
            }),
            ...(variant === 'warning' && {
              color: theme.colors.tertiary.lime[60],
              backgroundColor: theme.colors.tertiary.lime[10],
            }),
            ...(variant === 'success' && {
              color: theme.colors.secondary.emerald[60],
              backgroundColor: theme.colors.secondary.emerald[10],
            }),
            ...(highlight && {
              '&::after': {
                backgroundColor: 'currentColor',
              },
            }),
          },
        }),
      ...(disabled && { color: theme.colors.neutral.variant[30], pointerEvents: 'none' }),
    }),
  selected: ({ theme, props: { highlight } }) =>
    css({
      ...(highlight && {
        '&, *:hover &': {
          '&::after': {
            backgroundColor: theme.colors.primary.fuchsia[40],
          },

          '&:focus, &:focus-within': {
            backgroundColor: theme.colors.primary.fuchsia[20],
          },
        },
      }),
    }),
  prefix: ({ props: { disabled } }) =>
    css({
      ...(disabled && { opacity: 0.4 }),
    }),
  menuItemTypography: css`
    min-width: 0;
  `,
} satisfies Styles<MenuItemStyleStates>;

export const useStyles = withStyles(styles);
