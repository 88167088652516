import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
