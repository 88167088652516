import { AxiosError, AxiosResponse } from 'axios';
import { BaseError, PaginatedResponse } from 'clients/types';

type GenericFunction = (...props: any[]) => any;

type GenericPaginatedResponse<T> = AxiosResponse<T[] | PaginatedResponse<T>>;

/**
 * @deprecated - Do not use.
 */
export const bindCacheKey = <T extends GenericFunction>(cb: T) => {
  return (_cacheKey: string, ...props: any[]): ReturnType<T> => {
    return cb(...props);
  };
};

export const extractResultFromPaginatedResponse = <T>(response: GenericPaginatedResponse<T>): T[] => {
  const { data } = response;

  if (Array.isArray(data)) {
    return data;
  }

  return data.results;
};

export const extractErrorMessageFromAxiosResponse = <T = any>(error: BaseError<T>): string => {
  return (
    Object.entries(error.response?.data || {}).reduce((acc, [key, error]) => `${acc}\n${key}: ${error}`, '') ||
    error.message
  );
};

export const isAxiosError = <T>(obj: unknown): obj is AxiosError<T> => {
  return typeof obj === 'object' && obj !== null && 'isAxiosError' in obj;
};
