import type { Candidate } from 'model';
import type {
  CandidateOutreachModalContextState,
  CandidateOutreachModalType,
} from './CandidateOutreachModalContext.types';

import { OutreachEditorType } from '@recruitrobin/robin-theme/web-components';
import { OutreachModal } from 'components/ui/organisms/OutrachModal';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useContext, useEffect, useState } from 'react';
import { CANDIDATE_UNLOCK_REASON } from 'types/candidate';
import { LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { SnackbarContext } from '../SnackbarContext/SnackbarContext';
import { CandidateOutreachModalContext } from './CandidateOutreachModalContext';

export const CandidateOutreachModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { dictionary } = useContext(LocalizationContext);
  const { createSnackbar, closeSnackbar } = useContext(SnackbarContext);
  const [isUnlocking, setIsUnlocking] = useState(false);
  const [candidate, setCandidate] = useState<Candidate | null>(null);
  const [candidateOutreachModal, setCandidateOutreachModal] = useState<CandidateOutreachModalType | null>(null);
  const [candidateOutreachModalRef, setCandidateOutreachModalRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isUnlocking) return;

    if (candidateOutreachModal) {
      setIsUnlocking(true);

      candidateOutreachModal.actions
        .onUnlock({ reason: CANDIDATE_UNLOCK_REASON.CONTACT_INFO })
        .then((shouldUnlock) => {
          if (!shouldUnlock) {
            setCandidateOutreachModal(null);
            return;
          }

          setCandidate(candidateOutreachModal.candidate);
        })
        .finally(() => setIsUnlocking(false));
    } else {
      setCandidate(null);
    }
  }, [candidateOutreachModal?.candidate]);

  useEffect(() => {
    if (isUnlocking) {
      const snackbarId = createSnackbar(dictionary.workingOnIt, { variant: 'loading', persist: true });

      return () => {
        closeSnackbar(snackbarId);
      };
    }
  }, [dictionary, isUnlocking]);

  const updateCandidate = useCallback((updatedCandidate?: Candidate) => {
    setCandidate((candidate) => {
      if (updatedCandidate && updatedCandidate !== candidate && updatedCandidate.key === candidate?.key) {
        return updatedCandidate;
      }

      return candidate;
    });
  }, []);

  const value: CandidateOutreachModalContextState = {
    updateCandidate,
    candidateOutreachModalRef,
    setCandidateOutreachModal,
  };

  const handleOutreachModalRequestClose = useCallback(() => {
    setCandidateOutreachModal(null);
  }, []);

  const handleOutreachModalRequestChangeType = useCallback((type: OutreachEditorType) => {
    setCandidateOutreachModal((curr) => (curr ? { ...curr, type } : null));
  }, []);

  return (
    <CandidateOutreachModalContext.Provider value={value}>
      {children}

      <AnimatePresence>
        {candidate && candidateOutreachModal && (
          <OutreachModal
            candidate={candidate}
            ref={setCandidateOutreachModalRef}
            type={candidateOutreachModal.type}
            onRequestCloseModal={handleOutreachModalRequestClose}
            onRequestChangeType={handleOutreachModalRequestChangeType}
            actions={candidateOutreachModal.actions}
            currentUser={candidateOutreachModal.currentUser}
            tokens={candidateOutreachModal.tokens}
          />
        )}
      </AnimatePresence>
    </CandidateOutreachModalContext.Provider>
  );
};
