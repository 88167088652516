import * as icons from './icons';
import type { AnimatedIconKey } from './animated';
import * as animated from './animated';

export * from './icons';

export type IconKey = keyof typeof icons;

export const Animated: Record<AnimatedIconKey, any> = {
  LoaderSpinner1: animated.LoaderSpinner1,
};
