import { hosts } from 'config';

import type { Source } from 'clients/CampaignsClient/CandidateResponse';
import type {
  AdditionalRequestType,
  CrawlerErrorResponse,
  Credential,
  GetLinkedinRecruiterCandidateHandler,
  GetWerknlProfilesHandler,
  RefreshProfileHandler,
  UpdateCredentialByIdParams,
  WerknlProfiles,
} from './types';
import { isAxiosError } from 'helpers/clientHelpers';
import { CandidateNotFoundException, NoCreditsAvailableForMonsterboard } from 'shared/exceptions';
import { BaseClient } from 'clients/BaseClient/BaseClient';

export class CrawlerClient extends BaseClient {
  constructor() {
    super();
    this.downloadProfile = this.downloadProfile.bind(this);
  }

  async downloadProfile(fileId: string, candidateName: string) {
    const [extension] = fileId.split('.').reverse();
    const fileName = `${candidateName}.${extension}`;

    await this.fetchFile(fileName, {
      url: `${hosts.crawlerWebApi()}/files/cv/download/${fileId}`,
    });
  }

  refreshProfile: RefreshProfileHandler = async ({ es_person_id: id, platform, force, requestDetail = false }) => {
    let hostUrl: string | undefined;
    let additionalRequest = {};
    if (['cvt', 'linkedin', 'indeed'].includes(platform)) {
      hostUrl = hosts.crawlerSocketApi();
    } else {
      hostUrl = hosts.crawlerWebApi();
    }

    try {
      /// TODO WERKZOEKEN: Remove this after release new jobboard
      // if (['monsterboard_v2', 'werkzoeken'].includes(platform) && requestDetail) {
      if (['monsterboard_v2'].includes(platform) && requestDetail) {
        const platformParameter = platform === 'monsterboard_v2' ? 'monsterboard' : platform;

        additionalRequest = await new Promise<AdditionalRequestType>((resolve, reject) => {
          this.request<AdditionalRequestType>({
            url: `${hostUrl}/${platformParameter}/requestDetail/${id}`,
            method: 'GET',
          })
            .then((data) => {
              resolve(data);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }

      const response = await this.request<{ candidate: Source } | undefined>({
        url: `${hostUrl}/candidate/${platform}/${id}/refresh`,
        method: 'GET',
        params: {
          ...(force === true && { force }),
        },
      });

      return { ...response, ...additionalRequest };
    } catch (e) {
      if (isAxiosError<CrawlerErrorResponse>(e)) {
        if (e.response?.data.status === 412 || e.response?.data.status === 403) {
          const response = await this.request<{ candidate: Source } | undefined>({
            url: `${hostUrl}/candidate/${platform}/${id}/refresh`,
            method: 'GET',
            params: {
              ...(force === true && { force }),
            },
          });

          return { ...response, additionalRequest };
        }
        if (e.response?.status === 404) {
          throw new CandidateNotFoundException(`Failed to refresh candidate ${id} from ${platform}`);
        }
        if (e.response?.status === 402 && platform === 'monsterboard_v2') {
          throw new NoCreditsAvailableForMonsterboard();
        }
      }
      throw e;
    }
  };

  isWerknlActive = async () => {
    return this.request({
      url: `${hosts.crawlerSocketApi()}/werknl/message/is_active`,
    });
  };

  async getCredentials(platform: string) {
    return this.request<Credential[]>({
      url: `${hosts.crawlerWebApi()}/jwt/${platform}/credentials`,
      method: 'GET',
    });
  }

  async updateCredentialById({ platform, id, data }: UpdateCredentialByIdParams) {
    return this.request<void>({
      url: `${hosts.crawlerWebApi()}/jwt/${platform}/credentials/${id}`,
      method: 'PUT',
      data,
    });
  }

  getWerknlProfiles: GetWerknlProfilesHandler = async () => {
    return this.request<WerknlProfiles[]>({
      url: `${hosts.crawlerWebApi()}/werknl/profiles`,
      method: 'GET',
    });
  };

  getLinkedinRecruiterCandidate: GetLinkedinRecruiterCandidateHandler = async (linkedin_candidate_id: string) => {
    return this.request({
      url: `${hosts.crawlerWebApi()}/linkedin_recruiter_pro/recruiter/candidate/${linkedin_candidate_id}`,
      method: 'GET',
    });
  };

  getLinkedinCandidate: GetLinkedinRecruiterCandidateHandler = async (linkedin_candidate_id: string) => {
    return this.request({
      url: `${hosts.crawlerWebApi()}/linkedin_recruiter_pro/candidate/${linkedin_candidate_id}`,
      method: 'GET',
    });
  };
}

export const crawlerClient = new CrawlerClient();
