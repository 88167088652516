import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      height: 'auto',
      resize: 'none',
      '::placeholder': {
        color: theme.colors.neutral.variant[40],
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
