import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      maxWidth: 550,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
