/* eslint-disable @typescript-eslint/no-unused-vars*/

import { routes } from 'config';
import { MouseEventHandler, useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button } from 'components/ui/molecules';
import { Typography } from 'components/ui/atoms';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Activity, LogOut } from 'shared/icons';
import { useStyles } from './AdminLayout.styles';
import { AdminLayoutMenuItemType, AdminLayoutProps } from './types';
import { default as Logo } from 'shared/components/Logo';
import { default as Menu, MenuChangeHandler } from 'shared/components/Menu';

const AdminLayout = (props: AdminLayoutProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const menuOptions: AdminLayoutMenuItemType[] = [
    { label: dictionary.backToSearchCampaigns, icon: <LogOut size={18} />, value: 'back-to-search-campaigns' },
  ];

  const navigateToHome = () => {
    navigate(routes.search());
  };

  const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (e) => {
    setButtonRef(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setButtonRef(null);
  };

  const handleChangeMenu: MenuChangeHandler<AdminLayoutMenuItemType> = ({ value }) => {
    handleCloseMenu();

    if (value === 'back-to-search-campaigns') {
      return navigateToHome();
    }
  };

  return (
    <div css={classes.root}>
      <div css={classes.header}>
        <div>
          <Logo to={routes.search()} />
        </div>
        <div onClick={navigateToHome} style={{ cursor: 'pointer' }}>
          <Typography variant={(v) => v.heading[1]} colorv2={(c) => c.neutral.variant[80]}>
            Admin system
          </Typography>
        </div>
        <div>
          <Button icon={<Activity />} onClick={handleOpenMenu} tooltipLabel={dictionary.more} />
          <Menu anchorEl={buttonRef} menuItems={menuOptions} onChange={handleChangeMenu} onClose={handleCloseMenu} />
        </div>
      </div>
      <div css={classes.body}>
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
