import { forwardRef, Fragment } from 'react';
import { MenuItem as MuiMenuItem } from '@material-ui/core';
import { Typography } from 'components/ui/atoms';
import { useStyles } from './MenuItem.styles';
import { MenuItemProps, MenuItemStyleStates } from './types';
import { generateDtiAttribute } from 'helpers/helpers';

const createStateValues = (props: MenuItemProps): MenuItemStyleStates => ({
  variant: props.variant ?? 'default',
  disabled: props.disabled ?? false,
});

const MenuItem = forwardRef<HTMLElement, MenuItemProps>((originalProps, ref) => {
  const classes = useStyles(createStateValues(originalProps));
  const { label, icon, divisor, ...props } = originalProps;

  return (
    <Fragment>
      {divisor && <hr css={classes.divisor} />}
      <MuiMenuItem ref={ref as any} css={classes.root} {...props} {...generateDtiAttribute(`${props.value}-item`)}>
        {icon && <span className="menu-item--icon">{icon}</span>}
        <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.neutral.variant[70]}>
          {label}
        </Typography>
      </MuiMenuItem>
    </Fragment>
  );
});

export default MenuItem;
