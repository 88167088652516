import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useResendAts = (params: WrappedHookParams) => {
  return useCandidateAction<void>(
    async ({ candidate }) =>
      campaignsClient.postApproveCampaignCandidate({
        campaignId: candidate.campaignId as number,
        candidateId: candidate.es_person_id,
      }),
    {
      onSuccess: ({ fcParams: { candidate }, gtm }) => {
        gtm.candidateSentToATS(candidate.campaignId, candidate.es_person_id);
      },
    },
  )(params);
};
