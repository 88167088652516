import { createElement, useMemo, useRef } from 'react';
import { TablePaginationLabels, TablePaginationProps } from './types';
import { Button, ButtonProps } from 'components/ui/molecules';
import { ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'shared/icons';
import { useStyles } from './TablePagination.styles';

const DEFAULT_LABELS: TablePaginationLabels = {
  firstPage: 'First page',
  previousPage: 'Previous page',
  nextPage: 'Next page',
  lastPage: 'Last page',
};

const TablePagination = ({
  total: _total,
  onChange,
  currentPage = 1,
  perPage = 10,
  visibleButtons = 5,
  disableSkipButtons = false,
  disableSkipAllButtons = false,
  labels: l,
  maxPages: customMaxPages = 500,
}: TablePaginationProps) => {
  const lastDefinedTotal = useRef(_total ?? 0);
  const total = _total !== undefined ? (lastDefinedTotal.current = _total) : lastDefinedTotal.current;

  const classes = useStyles();
  const dictionary: TablePaginationLabels = {
    ...DEFAULT_LABELS,
    ...l,
  };
  const maxPages = useMemo(() => {
    const maxPages = Math.ceil(total / perPage) || 1;

    if (maxPages > customMaxPages) {
      return customMaxPages;
    }

    return maxPages;
  }, [customMaxPages, total, perPage]);
  const firstIndex = 1;
  const lastIndex = maxPages;
  const nextIndex = currentPage + 1 > lastIndex ? lastIndex : currentPage + 1;
  const previousIndex = currentPage - 1 < firstIndex ? firstIndex : currentPage - 1;

  const commonProps = useMemo<ButtonProps & { css: any }>(() => {
    return { css: classes.button, variant: 'subtle' };
  }, []);

  const buttons = useMemo(() => {
    const halfOfVisibleButtons = Math.floor(visibleButtons / 2);
    const offsetFromCurrentPage = currentPage - halfOfVisibleButtons;
    const offsetIndex = offsetFromCurrentPage < 1 ? 1 : offsetFromCurrentPage;
    const limitFromCurrentPage = offsetIndex + visibleButtons - 1;
    const disableLimitIndex = visibleButtons > maxPages;
    const limitIndex = disableLimitIndex
      ? 1 - offsetIndex
      : limitFromCurrentPage > maxPages
        ? maxPages - limitFromCurrentPage
        : 0;

    return Array.from({ length: Math.min(visibleButtons, maxPages) }).map((_, index) => {
      const buttonIndex = index + offsetIndex + limitIndex;

      return createElement(Button, {
        key: buttonIndex,
        label: `${buttonIndex}`,
        onClick: () => onChange?.(buttonIndex),
        ...commonProps,
        disabled: buttonIndex === currentPage || commonProps.disabled,
      });
    });
  }, [visibleButtons, currentPage, maxPages, onChange, commonProps]);

  const handleNextIndex = () => onChange?.(nextIndex);

  const handlePreviousIndex = () => onChange?.(previousIndex);

  const handleLastIndex = () => onChange?.(lastIndex);

  const handleFirstIndex = () => onChange?.(firstIndex);

  return (
    <div css={classes.root}>
      {!disableSkipAllButtons && (
        <Button
          icon={<ChevronsLeft />}
          onClick={handleFirstIndex}
          tooltipLabel={dictionary.firstPage}
          disabled={currentPage === 1}
          {...commonProps}
        />
      )}
      {!disableSkipButtons && (
        <Button
          icon={<ChevronLeft />}
          onClick={handlePreviousIndex}
          tooltipLabel={dictionary.previousPage}
          disabled={currentPage === 1}
          {...commonProps}
        />
      )}
      {buttons}
      {!disableSkipAllButtons && (
        <Button
          icon={<ChevronRight />}
          onClick={handleNextIndex}
          tooltipLabel={dictionary.nextPage}
          disabled={currentPage === maxPages}
          {...commonProps}
        />
      )}
      {!disableSkipButtons && (
        <Button
          icon={<ChevronsRight />}
          onClick={handleLastIndex}
          tooltipLabel={dictionary.lastPage}
          disabled={currentPage === maxPages}
          {...commonProps}
        />
      )}
    </div>
  );
};

export default TablePagination;
