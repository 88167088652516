import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    display: flex;
    height: 20px;
    width: max-content;
    gap: 4px;
    border-radius: 12px;
    background-color: ${theme.colors.primary.fuchsia[50]};
    color: ${theme.colors.neutral.variant[0]};
    justify-content: space-between;
    overflow: hidden;
  `,
  icon: ({ theme }) => css`
    display: flex;
    height: 100%;
    padding: 0 7px;
    align-items: center;
    gap: 3px;
    cursor: pointer;
    border-radius: 12px;
    :hover {
      background-color: ${theme.colors.primary.fuchsia[70]};
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
