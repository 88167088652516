import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

type ChangeCampaignOwnerStyles = {
  dropdownOpened: boolean;
};

const styles = {
  root: () =>
    css({
      width: 'fit-content',
    }),
  floatingRoot: ({ theme }) =>
    css({
      padding: '6px 0px',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[10],
      borderRadius: 8,
      background: theme.colors.neutral.variant[0],
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
    }),
  ownersName: ({ theme }) =>
    css({
      width: '100%',
      padding: 10,
      '&:hover': {
        backgroundColor: theme.colors.neutral.variant[5],
        color: theme.colors.neutral.variant[70],
      },
    }),
  spinnerChangeOwner: css({
    marginRight: 5,
  }),
  searchOwnerInput: ({ theme }) =>
    css({
      padding: 10,
      width: 310,
      '&:hover': {
        backgroundColor: theme.colors.neutral.variant[5],
        color: theme.colors.neutral.variant[70],
      },
    }),
  owner: ({ theme }) =>
    css({
      '&:hover': {
        color: theme.colors.secondary.sky[60],

        '& svg': {
          marginLeft: '4px',
        },
      },

      '&:not(:hover) svg': {
        display: 'none',
      },
    }),
  textEllipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  `,
} satisfies Styles<ChangeCampaignOwnerStyles>;

export const useStyles = withStyles(styles);
