import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      width: '100%',
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '&:first-of-type': {
          width: '100%',
        },
        '&:nth-of-type(2)': {
          width: '90%',
        },
        '&:nth-of-type(3)': {
          width: 'auto',
        },
        '&:not(:first-of-type), & > *:not(:first-of-type)': {
          marginLeft: 12,
        },
      },
    }),
  checkbox: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > label': {
        marginLeft: 0,
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
