import { SnackbarStyleStates, SnackbarVariant } from './types';
import { Theme, Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { CSSObject } from '@emotion/react';

const styles = {
  root: ({ theme, props: { variant } }) =>
    css({
      ...variantStyles(theme)[variant],
      padding: '12px 16px',
      borderRadius: 8,
    }),
  iconWrapper: ({ theme, props: { variant } }) =>
    css({
      ...variantIconStyles(theme)[variant],
      width: 22,
      height: 22,
      borderRadius: '50%',
      flexShrink: 0,
    }),
  closeButton: () => css`
    cursor: pointer;
    display: inline-flex;
    align-self: flex-start;
  `,
} satisfies Styles<SnackbarStyleStates>;

export const useStyles = withStyles(styles);

const variantStyles: (theme: Theme) => Record<SnackbarVariant, CSSObject> = (theme) => ({
  success: {
    color: theme.colors.secondary.emerald[60],
    backgroundColor: theme.colors.secondary.emerald[10],
  },
  loading: {
    color: theme.colors.neutral.variant[60],
    backgroundColor: theme.colors.neutral.variant[10],
  },
  danger: {
    color: theme.colors.tertiary.red[60],
    backgroundColor: theme.colors.tertiary.red[10],
  },
  warning: {
    color: theme.colors.tertiary.lime[60],
    backgroundColor: theme.colors.tertiary.lime[10],
  },
});

const variantIconStyles: (theme: Theme) => Record<SnackbarVariant, CSSObject> = (theme) => ({
  success: {
    backgroundColor: theme.colors.secondary.emerald[20],
  },
  loading: {
    backgroundColor: theme.colors.neutral.variant[20],
  },
  danger: {
    backgroundColor: theme.colors.tertiary.red[20],
  },
  warning: {
    backgroundColor: theme.colors.tertiary.lime[20],
  },
});
