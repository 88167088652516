import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from '@emotion/react';
import { muiTheme, themeOld } from 'styles/theme';
import { CustomGTMContextProvider } from 'shared/contexts/CustomGTMContext';
import { ATSIntegrationContextProvider } from 'views/LoggedIn/components/ATSIntegrations/contexts/ATSIntegrationContext';
import { useStyles } from 'App.styles';
import { BrowserRouter } from 'react-router-dom';
import { default as Routes } from 'views/Routes';
import { default as ReactModal } from 'react-modal';
import ErrorBoundary from 'components/ui/molecules/ErrorBoundary';
import { queryClient } from 'config/queryClient';
import { useEffect } from 'react';
import { memoStorage } from 'hooks/shared/memoStorage';
import { merge } from 'lodash';
import { theme } from '@recruitrobin/robin-theme/web-styles';
import { AuthContextProvider } from 'shared/contexts/AuthContext';
import { ConfigContextProvider } from 'shared/contexts/ConfigContext';
import { DialogContextProvider } from 'shared/contexts/DialogContext';
import { LayoutContextProvider } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContextProvider } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { NotificationsContextProvider } from 'shared/contexts/NotificationsContext/NotificationsContext';
import { UserContextProvider } from 'shared/contexts/UserContext/UserContext';
import { VersionContextProvider } from 'shared/contexts/VersionContext';
import { ResetPasswordContextProvider } from 'shared/contexts/ResetPasswordContext';
import { SnackbarContextProvider } from 'shared/contexts/SnackbarContext/SnackbarContext';

ReactModal.setAppElement('body');

const App = () => {
  const classes = useStyles();

  useEffect(() => {
    if (!memoStorage.isLoaded) {
      memoStorage.loadInitialData();
    }
  }, []);

  return (
    <ConfigContextProvider>
      <CustomGTMContextProvider>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <AuthContextProvider>
                <LayoutContextProvider>
                  <LocalizationContextProvider>
                    <MuiThemeProvider theme={muiTheme}>
                      <ThemeProvider theme={merge(themeOld, theme)}>
                        <UserContextProvider>
                          <ATSIntegrationContextProvider>
                            <NotificationsContextProvider>
                              <ResetPasswordContextProvider>
                                <DialogContextProvider>
                                  <SnackbarContextProvider>
                                    <VersionContextProvider>
                                      <div css={classes.root}>
                                        <Routes />

                                        {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                                          <ReactQueryDevtools initialIsOpen={false} />
                                        )}
                                      </div>
                                    </VersionContextProvider>
                                  </SnackbarContextProvider>
                                </DialogContextProvider>
                              </ResetPasswordContextProvider>
                            </NotificationsContextProvider>
                          </ATSIntegrationContextProvider>
                        </UserContextProvider>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  </LocalizationContextProvider>
                </LayoutContextProvider>
              </AuthContextProvider>
            </BrowserRouter>
          </QueryClientProvider>
        </ErrorBoundary>
      </CustomGTMContextProvider>
    </ConfigContextProvider>
  );
};

export default App;
