import type { EducationDates } from 'clients/CampaignsClient/CandidateResponse';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';

export const getDurationInYearsAbbr = (dates: EducationDates, dictionary: Dictionary) => {
  const durationInMonths = calculateExperienceInMonths(dates.start_recalculated, dates.end_recalculated);
  const durationInYearsAbbr = (durationInMonths / 12).toFixed(1);

  if (parseFloat(durationInYearsAbbr) < 1) {
    return `${durationInYearsAbbr}${dictionary.yearAbbr}`;
  }

  return `${durationInYearsAbbr}${dictionary.yearsAbbr}`;
};

export const getTotalDurationInYearsAbbr = (
  first: number,
  last: number | undefined,
  gap = 0,
  dictionary: Dictionary,
) => {
  const durationInMonths = calculateExperienceInMonths(first, last, gap);
  const durationInYearsAbbr = (durationInMonths / 12).toFixed(1);

  if (parseFloat(durationInYearsAbbr) < 1) {
    return `${durationInYearsAbbr}${dictionary.yearAbbr}`;
  }

  return `${durationInYearsAbbr}${dictionary.yearsAbbr}`;
};

export const calculateExperienceInMonths = (first: number, last: number | undefined, gap = 0) => {
  const today = new Date().getFullYear() * 12 + (new Date().getMonth() + 1);
  return (last || today) - (first || 1) - gap;
};
