import { useMemo } from 'react';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { useContextSelector } from 'use-context-selector';

export const useContainer = () => {
  const campaigns = useContextSelector(CampaignManagerContext, (state) => state.campaigns);
  const { teamview, setTeamview, setStatus } = useContextSelector(CampaignManagerContext, (state) => state.filters);

  const count = campaigns.count;
  const isLoading = campaigns.isLoading && !campaigns.isFetchingNextPage;

  return useMemo(
    () => ({
      teamview,
      setTeamview,

      setStatus,

      count,
      isLoading,
    }),
    [teamview, count, isLoading],
  );
};
