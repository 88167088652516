import { useContext, useMemo } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

export type UseFeatureTogglesHook = () => {
  isFeatureToggleEnabled: (name: string) => boolean;
  isLoading: boolean;
};

const useFeatureToggles: UseFeatureTogglesHook = () => {
  const { isFeatureToggleEnabled, featureTogglesIsLoading } = useContext(UserContext);

  return useMemo(() => {
    return { isFeatureToggleEnabled, isLoading: featureTogglesIsLoading };
  }, [isFeatureToggleEnabled, featureTogglesIsLoading]);
};

export default useFeatureToggles;
