import { isObject } from 'helpers/helpers';
import { ErrorHandler } from './ErrorHandler';

/**
 * Types
 */

enum LinkedinErrors {
  CUSTOM_INVITE_LIMIT_REACHED = 'CUSTOM_INVITE_LIMIT_REACHED',
  FUSE_LIMIT_EXCEEDED = 'FUSE_LIMIT_EXCEEDED',
  CSRF_CHECK_FAILED = 'CSRF check failed.',
}

/**
 * Exceptions
 */

export class LinkedinFreeInviteLimitReachedException extends Error {
  constructor() {
    super('Linkedin free invite limit reached');

    this.name = 'LinkedinFreeInviteLimitReachedException';
  }
}

export class LinkedinMaximumInviteLimitReachedException extends Error {
  constructor() {
    super('Linkedin maximum invite limit reached');

    this.name = 'LinkedinMaximumInviteLimitReachedException';
  }
}

export class LinkedinCSRFTokenCheckFailedException extends Error {
  constructor() {
    super('CSRF check failed');

    this.name = 'LinkedinCSRFTokenCheckFailedException';
  }
}

/**
 * Check methods and throw
 */

const checkAndThrowFreeInviteLimit = (error: unknown) => {
  if (
    isObject(error) &&
    typeof error.body === 'string' &&
    error.status === 429 &&
    error.body.includes(LinkedinErrors.CUSTOM_INVITE_LIMIT_REACHED)
  ) {
    throw new LinkedinFreeInviteLimitReachedException();
  }
};

const checkAndThrowMaximumInviteLimit = (error: unknown) => {
  if (
    isObject(error) &&
    typeof error.body === 'string' &&
    error.status === 429 &&
    error.body.includes(LinkedinErrors.FUSE_LIMIT_EXCEEDED)
  ) {
    throw new LinkedinMaximumInviteLimitReachedException();
  }
};

const checkAndThrowInvalidCSRFToken = (error: unknown) => {
  if (
    isObject(error) &&
    typeof error.body === 'string' &&
    (error.status === 500 || error.status === 403) &&
    error.body.includes(LinkedinErrors.CSRF_CHECK_FAILED)
  ) {
    throw new LinkedinCSRFTokenCheckFailedException();
  }
};

/**
 * Linkedin exception controller
 */

export class LinkedinException extends ErrorHandler {
  static checkAndThrowError = (
    error: unknown,
    { onReject }: { data?: unknown; onReject?: (error: Error) => void } = {},
  ) => {
    return super.checkAndThrowError(
      () => {
        checkAndThrowFreeInviteLimit(error);
        checkAndThrowMaximumInviteLimit(error);
        checkAndThrowInvalidCSRFToken(error);
      },
      { onReject },
    );
  };
}

/**
 * Helpers
 */
