/* eslint-disable @typescript-eslint/no-unused-vars*/

import { cx } from '@emotion/css';
import { FilterOrder } from 'clients/types';
import { Typography } from 'components/ui/atoms';
import { ReactElement, useState } from 'react';
import { CaratDown, CaratUp } from 'shared/icons';
import { nthColumn } from '../../helpers/attachAttributes';
import { useStyles } from './TableHeader.styles';
import { HeaderCurrentSortState, TableHeaderProps } from './types';

const DEFAULT_SORT_ICONS = {
  ASC: <CaratUp size={16} />,
  DESC: <CaratDown size={16} />,
};
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const TableHeader = <T extends unknown>({
  headers,
  onSort,
  emptyHeaders = 0,
  loading = false,
}: TableHeaderProps<T>): ReactElement<any, any> => {
  const classes = useStyles();
  const [headersSortState, setHeadersSortState] = useState<HeaderCurrentSortState<T> | null>(null);

  const handleHeaderClick = (identifier: T) => {
    const newSort: HeaderCurrentSortState<T> = {
      identifier,
      state:
        headersSortState?.identifier === identifier && headersSortState.state === FilterOrder.Desc
          ? FilterOrder.Asc
          : FilterOrder.Desc,
    };

    onSort?.(newSort);
    setHeadersSortState(newSort);
  };

  return (
    <div css={classes.root}>
      {headers.map(({ label, identifier, sortable = true }, i) => (
        <div
          key={identifier as string}
          onClick={() => sortable && handleHeaderClick(identifier)}
          className={cx(sortable && 'table-header--sortable')}
          {...nthColumn(i)}>
          <div className="table-header--wrapper">
            <Typography variant={(v) => v.heading[5]} colorv2={(c) => c.neutral.variant[80]}>
              {label}
            </Typography>

            {sortable && (
              <Typography
                variant={(v) => v.heading[5]}
                colorv2={(c) => c.neutral.variant[80]}
                className="table-header--icon">
                {DEFAULT_SORT_ICONS[(headersSortState?.identifier === identifier && headersSortState.state) || 'ASC']}
              </Typography>
            )}
          </div>
        </div>
      ))}
      {Array.from({ length: emptyHeaders }).map((_, i) => (
        <div key={`empty-header-${i}`} {...nthColumn(headers.length + i)} />
      ))}
    </div>
  );
};

export default TableHeader;
