export enum AccountStatus {
  NonExistent = 'non-existent',
  Invalid = 'invalid',
  Valid = 'valid',
}

export type UseContainerProps = {
  onClosePopup?: () => void;
};

export type CandidateEmailSettingsState = {
  handleCloseConnectEmailModal: () => void;
  handleOpenMailContactModal: () => void;
  currentEmailStatus: AccountStatus;
  isLoadingEmailStatus: boolean;
  openOAuthPopup: () => void;
};
