import { routes } from 'config';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirectWhenTranslateOn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.href.includes(routes.gtNotAvailable)) {
      return;
    }
    const htmlElement = document.documentElement;

    const checkIfgoogleTranslateIsEnabled = (element: HTMLElement | undefined) => {
      if (element?.className?.includes('translated-ltr')) {
        navigate(routes.gtNotAvailable);
      }
    };
    if (htmlElement) {
      checkIfgoogleTranslateIsEnabled(htmlElement);
    }

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'attributes' && mutation.target === htmlElement) {
          checkIfgoogleTranslateIsEnabled(mutation.target as HTMLElement);
        }
      }
    });

    observer.observe(htmlElement, { attributes: true });

    return () => {
      observer.disconnect();
    };
  }, []);
};
