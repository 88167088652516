import { useEffect, useRef, useState } from 'react';
import { Row, Column } from 'components/ui/atoms';
import { useStyles } from './styles';
import type { AnimatedRobinLogoProps } from './types';

const DELAY = 200;
const TIME_TRANSITION = 500;

const AnimatedRobinLogo = ({ size, enableAnimation: enabled }: AnimatedRobinLogoProps) => {
  const classes = useStyles({ size: size / 2, timeTransition: TIME_TRANSITION });
  const alreadyRunnedRef = useRef(false);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    if (enabled && !alreadyRunnedRef.current) {
      alreadyRunnedRef.current = true;

      const rotate = () => {
        setRotation(180);

        setTimeout(() => {
          setRotation(0);
        }, TIME_TRANSITION + DELAY);
      };

      setTimeout(() => {
        rotate();
      }, DELAY);

      const intervalId = setInterval(rotate, 6000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [enabled]);

  return (
    <Column css={classes.root}>
      <Row css={classes.root}>
        <div css={[classes.box1, classes.animatedBox, { transform: `rotate(${rotation}deg)` }]} />
        <div css={classes.box2} />
      </Row>
      <div css={[classes.box3, classes.animatedBox, { transform: `rotate(${rotation}deg)` }]} />
    </Column>
  );
};

export default AnimatedRobinLogo;
