import { sharedUtils } from 'utils';

import type { Volunteer } from 'clients/CampaignsClient/CandidateResponse';
import { Dictionary } from 'shared/contexts/LocalizationContext/types';

export const getLastVolunteeringExperiences = (volunteeringExperiences: Volunteer[]) => {
  return volunteeringExperiences.map(({ role, dates, company, description }) => {
    let name = role;

    if (company?.name) {
      name = `${role} @ ${company.name}`;
    }

    return {
      name,
      description,
      duration: sharedUtils.getDuration(dates) as any,
      startYear: dates.start?.year,
      endYear: getEndYear(dates.end?.year),
      fullDuration: sharedUtils.getFullDuration(dates) as any,
      companyName: company?.name,
      positionName: role,
    };
  });
};

export const getEndYear = (year: number | undefined) => (d: Dictionary) => {
  if (year) return year;

  return d.current;
};
