import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { PaddingStyleStates } from './types';

const styles = {
  root: ({
    props: {
      padding: [top, right, bottom, left],
      contained,
    },
  }) =>
    css({
      display: 'inline-flex',
      paddingTop: top,
      paddingRight: right,
      paddingBottom: bottom,
      paddingLeft: left,
      ...(contained && { width: '100%' }),
    }),
} satisfies Styles<PaddingStyleStates>;

export const useStyles = withStyles(styles);
