export const hexToRgb = (hex: string) => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }

    const h = `0x${c.join('')}` as unknown as number;
    return { r: (h >> 16) & 255, g: (h >> 8) & 255, b: h & 255 };
  }
  throw new Error('Bad Hex');
};
