import { useMemo } from 'react';
import { CompositeFilter, UseContainerProps } from './types';

export const useContainer = ({ searchFiltersControlState }: UseContainerProps) => {
  const searchesAsStringArray = useMemo(() => {
    const filtersToStringify = searchFiltersControlState.lastSavedState;
    const extractKeyFromCompositeFilter = (arr: string[], filter: CompositeFilter[]) => [
      ...arr,
      ...filter.filter(({ exclude }) => !exclude).map(({ key }) => key),
    ];

    const allExactFiltersStrings = [
      filtersToStringify.job_titles,
      filtersToStringify.languages,
      filtersToStringify.companies,
      filtersToStringify.skills,
      filtersToStringify.schools,
      filtersToStringify.education_fields,
      filtersToStringify.education_degrees,
      filtersToStringify.industries,
    ].reduce(extractKeyFromCompositeFilter, []);

    return [...new Set(allExactFiltersStrings)];
  }, [searchFiltersControlState.lastSavedState]);

  return useMemo(() => ({ searchesAsStringArray }), [searchesAsStringArray]);
};
