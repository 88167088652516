import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      cursor: 'pointer',
      '&:focus-visible': {
        outline: '0',
        border: 0,
      },
    }),
  default: ({ theme }) =>
    css({
      color: theme.colors.secondary.sky[60],
      '&:hover': {
        color: theme.colors.secondary.sky[60],
      },
      '&:focus-visible': {
        color: theme.colors.secondary.sky[70],
      },
      '&:active': {
        color: theme.colors.secondary.sky[70],
      },
    }),
  subtle: ({ theme }) =>
    css({
      color: theme.colors.primary.fuchsia[60],
      '&:hover': {
        color: theme.colors.primary.fuchsia[60],
      },
      '&:focus-visible': {
        color: theme.colors.primary.fuchsia[70],
      },
      '&:active': {
        color: theme.colors.primary.fuchsia[70],
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
