import { LatLon, Location } from 'shared/contexts/SearchContext/FiltersContext/types';
import { calculateGeographicDistance } from 'utils/shared';

export const getClosestLocation = (origin: LatLon | undefined, locations: Location[]): Location | undefined => {
  if (!origin) return undefined;

  return locations.reduce(
    (closestLocation, currLocation) => {
      const closestDistance =
        closestLocation && calculateGeographicDistance(origin, { lat: closestLocation.lat, lon: closestLocation.lon });
      const distance = calculateGeographicDistance(origin, { lat: currLocation.lat, lon: currLocation.lon });

      if (!closestDistance || (closestDistance && closestDistance > distance)) return currLocation;

      return closestLocation;
    },
    undefined as Location | undefined,
  );
};
