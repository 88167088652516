import { Modal } from 'components/ui/molecules';
import { useLocalStorage } from 'hooks/shared/useLocalStorage';
import {
  ConnectEmailModal,
  DISMISS_CONNECT_YOUR_EMAIL_MODAL,
} from 'pages/EmailSettingsPage/components/ConnectEmailModal';
import { useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';
import { AccountStatus, type CandidateEmailSettingsState } from './CandidateEmailSettingsContext.types';
import { useContainer } from './CandidateEmailSettingsContext.useContainer';
import { useStyles } from './styles';

const initialState: CandidateEmailSettingsState = {
  handleCloseConnectEmailModal() {
    throw Error('Not implemented');
  },

  handleOpenMailContactModal() {
    throw Error('Not implemented');
  },

  openOAuthPopup() {
    throw Error('Not implemented');
  },

  currentEmailStatus: AccountStatus.NonExistent,
  isLoadingEmailStatus: false,
};

export const CandidateEmailSettingsContext = createContext(initialState);

export const CandidateEmailSettingsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const { value: shouldDismissConnectYourEmailModal } = useLocalStorage(DISMISS_CONNECT_YOUR_EMAIL_MODAL);

  const setActiveOutreachModal = (_: string) => _;

  const [shouldShowConnectEmailModal, setShouldShowConnectEmailModal] = useState(false);
  const handleCloseConnectEmailModal = () => setShouldShowConnectEmailModal(false);

  const onCloseConnectMailPopup = () => {
    handleCloseConnectEmailModal();
    setActiveOutreachModal('email');
  };

  const { currentEmailStatus, openOAuthPopup, isLoadingEmailStatus } = useContainer({
    onClosePopup: onCloseConnectMailPopup,
  });

  const handleOpenMailContactModal = () => {
    if (
      !shouldDismissConnectYourEmailModal &&
      (currentEmailStatus === AccountStatus.NonExistent || currentEmailStatus === AccountStatus.Invalid)
    ) {
      return setShouldShowConnectEmailModal(true);
    }

    setActiveOutreachModal('email');
  };

  const value = useMemo<CandidateEmailSettingsState>(
    () => ({
      handleCloseConnectEmailModal,
      handleOpenMailContactModal,
      currentEmailStatus,
      openOAuthPopup,
      isLoadingEmailStatus,
    }),
    [currentEmailStatus, isLoadingEmailStatus, openOAuthPopup, shouldDismissConnectYourEmailModal],
  );

  return (
    <CandidateEmailSettingsContext.Provider value={value}>
      <>
        {children}
        <Modal
          visible={shouldShowConnectEmailModal && !shouldDismissConnectYourEmailModal}
          onRequestClose={handleCloseConnectEmailModal}
          hideCloseButton
          css={classes.connectEmailAccountModal}>
          <ConnectEmailModal emailStatus={currentEmailStatus} setVisibility={setShouldShowConnectEmailModal} />
        </Modal>
      </>
    </CandidateEmailSettingsContext.Provider>
  );
};
