import { styleUtils } from 'utils';
import { SingleSelectStyleStates } from './types';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { disabled, focused, autocomplete } }) =>
    css({
      cursor: autocomplete ? 'text' : 'pointer',
      outline: 0,
      borderRadius: 8,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[10],
      background: theme.colors.neutral.variant[10],
      transition: styleUtils.generateTransitionStyles(['background-color', 'border-color', 'color']),
      '&:hover': {
        background: theme.colors.neutral.variant[5],
        borderColor: theme.colors.neutral.variant[30],
      },
      '&, &:hover': {
        [`${focused && '&, '}&:focus, &:focus-within`]: {
          background: theme.colors.neutral.variant[0],
          borderColor: theme.colors.primary.fuchsia[30],
        },
      },
      ...(disabled && {
        '&, &:hover, &:focus, &:focus-within': {
          pointerEvents: 'none',
          color: theme.colors.neutral.variant[30],
          background: theme.colors.neutral.variant[10],
          borderColor: theme.colors.neutral.variant[10],
        },
      }),
    }),
  input: ({ theme, props: { contained, disabled, autocomplete } }) =>
    css({
      margin: 0,
      border: 0,
      outline: 0,
      padding: '9px 0 9px 16px',
      backgroundColor: 'transparent',
      '&::placeholder': {
        color: theme.colors.neutral.variant[40],
        ...(disabled && { color: theme.colors.neutral.variant[30] }),
      },
      ...(disabled && { color: theme.colors.neutral.variant[30] }),
      ...(contained && { width: '100%' }),
      ...(!autocomplete && { cursor: 'pointer' }),
    }),
} satisfies Styles<SingleSelectStyleStates>;

export const useStyles = withStyles(styles);
