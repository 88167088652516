import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { RowStyleStates } from './types';

const styles = {
  root: ({ props }) =>
    css({
      display: props.inline ? 'inline-flex' : 'flex',
      gap: Array.isArray(props.gap) ? `${props.gap[0]}px ${props.gap[1]}px` : props.gap,
      ...(props.center && { justifyContent: 'center' }),
      ...(props.alignCenter && { alignItems: 'center' }),
      ...(props.flexStart && { alignItems: 'flex-start' }),
      ...(props.flexEnd && { alignItems: 'flex-end' }),
      ...(props.right && { justifyContent: 'flex-end' }),
      ...(props.left && { justifyContent: 'flex-start' }),
      ...(props.contained && { width: '100%' }),
      ...(props.spaceBetween && { justifyContent: 'space-between' }),
      ...(props.displayInline && { display: 'inline' }),
      ...(props.wrap && { flexWrap: 'wrap' }),
      ...(props.alignSelfStart && { alignSelf: 'flex-start' }),
      ...(props.alignSelfCenter && { alignSelf: 'center' }),
      ...(props.alignSelfEnd && { alignSelf: 'flex-end' }),
    }),
} satisfies Styles<RowStyleStates>;

export const useStyles = withStyles(styles);
