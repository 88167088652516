import { generateTransitionStyles } from 'styles/theme';
import { FeatureToggleRowStyleStates } from './types';
import { withStyles, Styles, css } from '@recruitrobin/robin-theme/web-styles';
import { keyframes } from '@emotion/react';

const VARIABLE_PREFIX = 'rr-skeleton';

const skeletonAnimation = keyframes`
  0% { background: var(--${VARIABLE_PREFIX}-background); }
  50% { background: var(--${VARIABLE_PREFIX}-background); }
  67% { background: var(--${VARIABLE_PREFIX}-highlight); }
  81% { background: var(--${VARIABLE_PREFIX}-highlight); }
  100%{ background: var(--${VARIABLE_PREFIX}-background); }
`;

const styles = {
  root: () =>
    css({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 16,
    }),
  removeButtonBackground: ({ theme }) =>
    css({
      zIndex: 0,
      position: 'absolute',
      display: 'block',
      width: 4,
      height: '100%',
      backgroundColor: theme.colors.neutral.variant[30],
      left: 7,
    }),
  removeButton: ({ theme }) =>
    css({
      zIndex: 1,
      width: 18,
      height: 18,
      borderRadius: 9,
      borderWidth: 1,
      borderStyle: 'solid',
      color: theme.colors.secondary.sky[60],
      backgroundColor: theme.colors.secondary.sky[20],
      borderColor: theme.colors.secondary.sky[80],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: generateTransitionStyles(['border-color', 'background-color', 'color'], '120ms'),
      '&:hover': {
        color: theme.colors.tertiary.red[90],
        backgroundColor: theme.colors.tertiary.red[20],
        borderColor: theme.colors.tertiary.red[40],
      },
    }),
  field: () =>
    css({
      marginLeft: 8,
      display: 'flex',
      alignItems: 'center',
    }),
  checkbox: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > label': {
        marginLeft: 0,
      },
    }),

  skeleton: ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 16,
      '& .MuiSkeleton-text': { transform: 'scale(1)' },
      '& > .skeleton--remove-button': {
        width: 18,
        height: 60,
      },
      '& > .skeleton--input': {
        width: 180,
        '& > span:not(:first-of-type)': {
          marginTop: 8,
        },
      },
      '& > .skeleton--checkbox': {
        width: 46,
        '& > span:not(:first-of-type)': {
          marginTop: 8,
        },
      },
      '& .MuiSkeleton-root.MuiSkeleton-pulse': {
        [`--${VARIABLE_PREFIX}-background`]: theme.colors.neutral.variant[20],
        [`--${VARIABLE_PREFIX}-highlight`]: theme.colors.neutral.variant[10],
        display: 'inline-flex',
        background: `--${VARIABLE_PREFIX}-background`,
        animationName: skeletonAnimation,
        animationDuration: '1750ms',
        animationTimingFunction: 'ease-in-out',
        animationIterationCount: 'infinite',
      },
    }),
} satisfies Styles<FeatureToggleRowStyleStates>;

export const useStyles = withStyles(styles);
