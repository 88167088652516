import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css``,
  connectEmailAccountModal: css`
    max-width: 600px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
