import { cssBreakpoints } from 'styles/breakpoints';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 500,
      padding: 24,
      fontFamily: '"Outfit", sans-serif',
      [cssBreakpoints.mobile]: {
        minWidth: 'initial',
      },
    }),
  title: () =>
    css({
      fontSize: 22,
      marginBottom: 24,
      [cssBreakpoints.mobile]: {
        fontSize: 22,
      },
    }),
  description: () =>
    css({
      fontSize: 14,
      marginBottom: 24,
      width: '100%',
    }),
  ctas: () =>
    css({
      display: 'flex',
      width: '100%',
      alignItems: 'row',
      justifyContent: 'space-between',
      marginBottom: 0,
      marginTop: 8,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
