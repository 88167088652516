import { tenantClient } from 'clients/Admin';
import { BaseError } from 'clients/types';
import { extractErrorMessageFromAxiosResponse } from 'helpers/clientHelpers';
import { useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
import { useStyles } from './CreateOrEditTenantModal.styles';
import { CreateOrEditTenantModalFormFields, CreateOrEditTenantModalProps, SubscriptEditionOption } from './types';
import { useGTM } from 'hooks/gtm';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CheckboxWithUseForm } from 'shared/components/CheckboxGroup';
import { default as Modal, CloseModalHandler } from 'shared/components/Modal';
import { RadioGroupWithUseForm } from 'shared/components/RadioGroup';
import { SelectWithUseForm } from 'shared/components/Select';
import { TextFieldWithUseForm } from 'shared/components/TextField';

const TRIGGER_TO_SEND_TO_ATS_OPTIONS = [
  {
    value: 'AFTER_MATCH',
    title: 'After match',
  },
  {
    value: 'AFTER_APPROVAL',
    title: 'After approval',
  },
];

const PAYMENT_METHOD_OPTIONS = [
  {
    value: 'PAY_PER_CREDIT',
    title: 'Pay per credit',
  },
  {
    value: 'PAY_PER_MATCH',
    title: 'Pay per match',
  },
];

const CreateOrEditModal = ({ visible, onClose, tenant, focusCreditLimit = false }: CreateOrEditTenantModalProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const { setSnackbarState } = useContext(LayoutContext);
  const gtm = useGTM();
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);
  const classes = useStyles();
  const methods = useForm<CreateOrEditTenantModalFormFields>({
    mode: 'all',
    defaultValues: {
      active: tenant?.is_active ?? true,
      subscriptionEdition: tenant?.subscription_edition || 'STANDARD',
      companyName: tenant?.name,
      creditLimit: tenant?.credit_limit.toString(),
      companyCode: tenant?.code,
      privacyPolicyLink: tenant?.privacy_policy_link,
      triggerToSendCandidateToAts: tenant?.trigger_to_send_candidate_to_ats || 'AFTER_MATCH',
      paymentMethod: tenant?.payment_method || 'PAY_PER_MATCH',
    },
  });

  const subscriptEditionOptions: SubscriptEditionOption[] = [
    { label: dictionary.standard, value: 'STANDARD' },
    { label: dictionary.demo, value: 'DEMO' },
  ];

  useEffect(() => {
    methods.reset({
      active: tenant?.is_active,
      companyName: tenant?.name,
      creditLimit: tenant?.credit_limit.toString(),
      subscriptionEdition: tenant?.subscription_edition,
      companyCode: tenant?.code,
      privacyPolicyLink: tenant?.privacy_policy_link,
      triggerToSendCandidateToAts: tenant?.trigger_to_send_candidate_to_ats || 'AFTER_MATCH',
      paymentMethod: tenant?.payment_method || 'PAY_PER_MATCH',
    });
  }, [tenant]);

  const onSubmit = async (data: CreateOrEditTenantModalFormFields) => {
    setIsLoading(true);

    try {
      const tenantData = {
        code: data.companyCode,
        name: data.companyName,
        subscription_edition: data.subscriptionEdition,
        credit_limit: parseFloat(data.creditLimit),
        is_active: data.active,
        privacy_policy_link: data.privacyPolicyLink ? data.privacyPolicyLink : null,
        trigger_to_send_candidate_to_ats: data.triggerToSendCandidateToAts ? data.triggerToSendCandidateToAts : null,
        payment_method: data.paymentMethod ? data.paymentMethod : null,
      };

      if (tenant) {
        await tenantClient.patch({
          ...tenantData,
          id: tenant.id,
        });
        gtm.editTenant();
      } else {
        await tenantClient.create(tenantData);
        gtm.createTenant();
      }

      setSnackbarState({
        open: true,
        message: tenant ? dictionary.tenantHasBeenUpdatedSuccessfully : dictionary.tenantHasBeenCreatedSuccessfully,
        severity: 'success',
        autoHideDuration: 3000,
      });

      handleCloseModal('success');
    } catch (e) {
      setSnackbarState({
        open: true,
        message: extractErrorMessageFromAxiosResponse(e as BaseError),
        severity: 'error',
        autoHideDuration: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleCloseModal: CloseModalHandler = (reason) => {
    if (reason === 'confirm') {
      return formRef.current?.requestSubmit();
    }

    onClose(reason);
  };

  return (
    <Modal
      title={tenant ? dictionary.editCompany : dictionary.createNewCompany}
      visible={visible}
      onClose={handleCloseModal}
      isLoading={isLoading}>
      <FormProvider {...methods}>
        <form ref={formRef} css={classes.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <TextFieldWithUseForm
            label={dictionary.companyCode}
            name="companyCode"
            placeholder="Company_Name_Without_Spaces"
            rules={{
              required: dictionary.required,
              validate: (data: string) => {
                const hasSpaces = data.indexOf(' ') !== -1;

                return hasSpaces ? dictionary.removeSpaces : undefined;
              },
            }}
            disabled={!!tenant}
            contained
          />
          <TextFieldWithUseForm
            label={dictionary.companyName}
            name="companyName"
            placeholder="Pretty Company Name"
            rules={{ required: dictionary.required }}
            contained
          />
          <RadioGroupWithUseForm
            label={dictionary.subscriptionEdition}
            name="subscriptionEdition"
            radioList={subscriptEditionOptions}
            direction="horizontal"
            contained
          />
          <TextFieldWithUseForm
            label={dictionary.creditLimit}
            name="creditLimit"
            placeholder="50"
            type="number"
            rules={{ required: dictionary.required }}
            autoFocus={focusCreditLimit}
            contained
          />
          <TextFieldWithUseForm label={dictionary.website} name="privacyPolicyLink" contained />
          <SelectWithUseForm
            label={dictionary.triggerToSendCandidateToAts}
            options={TRIGGER_TO_SEND_TO_ATS_OPTIONS}
            name="triggerToSendCandidateToAts"
            contained
          />
          <SelectWithUseForm
            label={dictionary.paymentMethod}
            options={PAYMENT_METHOD_OPTIONS}
            name="paymentMethod"
            contained
          />
          <CheckboxWithUseForm label={dictionary.active} name="active" />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default CreateOrEditModal;
