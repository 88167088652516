import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 14px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
