import { ActionNames, CampaignListItemActionOptionProps } from './CampaignListItemActionOption.types';
import { ChangeCampaignOwner } from './ChangeCampaignOwner';
import { Typography } from 'components/ui/atoms';
import { useStyles } from './CampaignListItemActionOption.styles';

const CampaignListItemActionOption = ({
  onClick,
  renderIcon,
  label,
  isLoading,
  name,
  campaign,
  handleChangeOwner,
}: CampaignListItemActionOptionProps) => {
  const classes = useStyles();
  return (
    <div key={name} onClick={onClick} css={classes.root}>
      {name === 'changeOwner' ? (
        <ChangeCampaignOwner
          currentOwnerName={campaign.full_owner_name}
          onSelectNewOwner={handleChangeOwner}
          campaignId={campaign.id}
          dropdownTriggerChildren={
            <>
              <span>{renderIcon(isLoading)}</span>
              <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.neutral.variant[70]}>
                {label}
              </Typography>
            </>
          }
        />
      ) : (
        <>
          <span>{renderIcon(isLoading)}</span>
          <Typography
            variant={(v) => v.supporting.helper}
            colorv2={(c) => (name === ActionNames.delete ? c.tertiary.red[50] : c.neutral.variant[70])}>
            {label}
          </Typography>
        </>
      )}
    </div>
  );
};

export default CampaignListItemActionOption;
