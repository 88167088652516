import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { usePreventScroll } from 'shared/hooks/usePreventScroll';
import { ShouldInstallBrowserExtensionDialogProps } from './ShouldInstallBrowserExtensionDialog.types';
import { useStyles } from './ShouldInstallBrowserExtensionDialog.styles';
import { Checkbox, Dialog } from '@recruitrobin/robin-theme/web-components';
import { useLocalStorage } from 'hooks/shared/useLocalStorage';

export const IGNORE_SHOULD_INSTALL_BROWSER_EXTENSION_DIALOG = 'IGNORE_SHOULD_INSTALL_BROWSER_EXTENSION_DIALOG';

export const ShouldInstallBrowserExtensionDialog = ({
  isVisible,
  onConfirm,
}: ShouldInstallBrowserExtensionDialogProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const { value, setValue } = useLocalStorage<boolean>(IGNORE_SHOULD_INSTALL_BROWSER_EXTENSION_DIALOG, false);

  usePreventScroll(isVisible);

  return (
    <Dialog
      isVisible={isVisible}
      onConfirm={onConfirm}
      title={dictionary.browserExtension}
      body={
        <div css={classes.shouldInstallBrowserExtensionBody}>
          {dictionary.shouldInstallBrowserExtensionModal}
          <img src="/install-extension.gif"></img>
          <div>
            <Checkbox label={dictionary.dontPerformThisCheckNextTime} checked={value} onClick={setValue} />
          </div>
        </div>
      }
      confirmLabel={dictionary.done}
      maxWidth={500}
    />
  );
};
