import { useStyles } from './TooltipIcon.styles';
import { Info } from '@material-ui/icons';
import { TooltipIconProps } from './types';
import Tooltip from './Tooltip';
import { generateDtiAttribute } from 'helpers/helpers';

const TooltipIcon = ({ ...props }: TooltipIconProps) => {
  const classes = useStyles({ disabled: props.disabled });

  return (
    <Tooltip {...props} {...generateDtiAttribute('tooltip')}>
      <div css={classes.root}>
        <Info />
      </div>
    </Tooltip>
  );
};

export default TooltipIcon;
