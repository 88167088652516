import { MenuItemStyleStates, MenuItemVariantStyles } from './types';
import { Styles, withStyles, Theme, css } from '@recruitrobin/robin-theme/web-styles';

const createVariantStyles: MenuItemVariantStyles = (theme: Theme) => ({
  default: {
    '&:hover': {
      color: theme.colors.neutral.variant[80],
      backgroundColor: theme.colors.neutral.variant[5],
    },
    '&:focus': {
      color: theme.colors.neutral.variant[80],
      backgroundColor: theme.colors.neutral.variant[10],
    },
  },
  danger: {
    '&:hover': {
      color: theme.colors.tertiary.red[60],
      backgroundColor: theme.colors.neutral.variant[0],
    },
    '&:focus': {
      color: theme.colors.tertiary.red[60],
      backgroundColor: theme.colors.tertiary.red[10],
    },
  },
  warning: {
    '&:hover': {
      color: theme.colors.tertiary.lime[60],
      backgroundColor: theme.colors.neutral.variant[0],
    },
    '&:focus': {
      color: theme.colors.tertiary.lime[60],
      backgroundColor: theme.colors.tertiary.lime[10],
    },
  },
  success: {
    '&:hover': {
      color: theme.colors.secondary.emerald[60],
      backgroundColor: theme.colors.neutral.variant[0],
    },
    '&:focus': {
      color: theme.colors.secondary.emerald[60],
      backgroundColor: theme.colors.secondary.emerald[10],
    },
  },
});

const styles = {
  root: ({ theme, props: { variant } }) =>
    css({
      '&.MuiListItem-button': {
        color: theme.colors.neutral.variant[70],
        backgroundColor: theme.colors.neutral.variant[0],
        height: 30,
        padding: '0 14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        userSelect: 'none',
        cursor: 'pointer',
        '&:active': {
          cursor: 'default',
        },
        '& > span:first-letter': {
          textTransform: 'capitalize',
        },
        '& > .menu-item--icon': {
          marginRight: 10,
          display: 'flex',
          alignItems: 'center',
        },
        '& > .MuiTouchRipple-root': {
          display: 'none',
        },
        ...createVariantStyles(theme)[variant],
      },
    }),
  divisor: ({ theme }) =>
    css({
      height: 1,
      width: '100%',
      margin: '10px 0',
      border: 0,
      padding: '0px 10px',
      backgroundColor: theme.colors.neutral.variant[10],
    }),
} satisfies Styles<MenuItemStyleStates>;

export const useStyles = withStyles(styles);
