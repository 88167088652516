import { BaseClient } from 'clients/BaseClient/BaseClient';
import { hosts } from 'config';

import {
  CreateTenantCvSources,
  CreateTenantEmailSettingsHandler,
  CreateTenantHandler,
  DeleteTenantHandler,
  GetAtsCredentialsHandler,
  GetTeamsHandler,
  GetTenantCvSources,
  GetTenantEmailSettingsHandler,
  GetTenantHandler,
  GetTenantUsersHandler,
  ImpersonateUserHandler,
  IndexTenantHandler,
  PatchTenantHandler,
  Tenant,
  UpdateTenantCvSources,
  UpdateTenantEmailSettingsHandler,
} from './types';
import { tenantClientCacheKeys } from './cacheKeys';

class TenantClient extends BaseClient {
  index: IndexTenantHandler = ({ search = '', limit = 10, offset = 0, sort }) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/`,
      method: 'GET',
      params: { search, limit, offset, ...(sort && { ordering: `${sort.order === 'DESC' ? '-' : ''}${sort.by}` }) },
    });
  };

  get: GetTenantHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${id}/`,
      method: 'GET',
    });
  };

  getUsers: GetTenantUsersHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${id}/users/`,
      method: 'GET',
    });
  };

  create: CreateTenantHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/`,
      method: 'POST',
      data,
    });
  };

  patch: PatchTenantHandler = ({ id, ...data }) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${id}/`,
      method: 'PATCH',
      data,
    });
  };

  delete: DeleteTenantHandler = (id) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${id}/`,
      method: 'DELETE',
    });
  };

  impersonateUser: ImpersonateUserHandler = (data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/impersonate_user/`,
      method: 'POST',
      data,
    });
  };

  getTenantEmailSettings: GetTenantEmailSettingsHandler = (tenantId) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/email_settings/`,
      method: 'GET',
    });
  };

  createTenantEmailSettings: CreateTenantEmailSettingsHandler = (tenantId, data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/email_settings/`,
      method: 'POST',
      data,
    });
  };

  updateTenantEmailSettings: UpdateTenantEmailSettingsHandler = (tenantId, id, data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/email_settings/${id}/`,
      method: 'PATCH',
      data,
    });
  };

  getCvSources: GetTenantCvSources = (tenantId) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/cv_sources/`,
      method: 'GET',
    });
  };

  createCvSources: CreateTenantCvSources = (tenantId, data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/cv_sources/`,
      method: 'POST',
      data,
    });
  };

  updateCvSources: UpdateTenantCvSources = (tenantId, id, data) => {
    return this.request({
      url: `${hosts.userApi()}/tenants/${tenantId}/cv_sources/${id}/`,
      method: 'PATCH',
      data,
    });
  };

  getAtsCredentials: GetAtsCredentialsHandler = (tenantId) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/ats_credentials/`,
      method: 'GET',
      params: { is_active: true, tenant_id: tenantId },
    });
  };

  getTeams: GetTeamsHandler = (tenantId) => {
    return this.request({
      url: `${hosts.userApi()}/teams/`,
      method: 'GET',
      params: { tenant_id: tenantId },
    });
  };
}

const tenantClient = new TenantClient();

export type { Tenant };
export { tenantClient, tenantClientCacheKeys };
