import { useMemo } from 'react';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { useContextSelector } from 'use-context-selector';

const SKELETON_COUNT = 20;

export const useContainer = () => {
  const campaigns = useContextSelector(CampaignManagerContext, (state) => state.campaigns);

  const data = campaigns.data;
  const count =
    data.length === 0 && campaigns.isLoading
      ? SKELETON_COUNT
      : data.length + (campaigns.hasNextPage ? SKELETON_COUNT : 0);

  return useMemo(
    () => ({
      isItemLoaded: (index: number) => data[index] !== undefined,
      loadMoreItems: campaigns.fetchNextPage,
      count: count,
      campaigns: data,
    }),
    [count, data, campaigns.fetchNextPage],
  );
};
