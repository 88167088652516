import { Typography } from '@recruitrobin/robin-theme/web-components';
import { isAxiosError } from 'helpers/clientHelpers';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

export const CandidateCVErrorFallback = ({ error }: { error: unknown }) => {
  const { dictionary } = useContext(LocalizationContext);

  let errorMessage = dictionary.somethingWentWrong;

  if (isAxiosError(error) && error.response?.status === 404) {
    errorMessage = `${dictionary.cv} ${dictionary.notFound}`;
  }

  return (
    <Typography variant={(v) => v.supporting.label} color={(c) => c.neutral.variant[80]}>
      {errorMessage}
    </Typography>
  );
};
