import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { SelectOptionStyleStates } from './types';

const styles = {
  root: ({ props }) =>
    css({
      marginLeft: props.multiple ? '-9px' : '-2px',
      display: 'inline-flex',
      alignItems: 'center',
    }),
} satisfies Styles<SelectOptionStyleStates>;

export const useStyles = withStyles(styles);
