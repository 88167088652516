import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

const findTalentProjectBriefsGQLQuery = () => ({
  operationName: 'findTalentProjectBriefs',
  variables: {
    input: {},
  },
  query:
    'query findTalentProjectBriefs($input: FindTalentProjectBriefsInput!) {\n  findTalentProjectBriefs(input: $input) {\n    projects {\n      default\n      key\n      name\n      createdByAccountKey\n      __typename\n    }\n    __typename\n  }\n}\n',
});

export const getProjectsList: GetProjectListGQLQueryHandler = async ({ headers }) => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://apis.indeed.com/graphql',
    method: 'POST',
    body: JSON.stringify(findTalentProjectBriefsGQLQuery()),
    headers,
  });

  return JSON.parse(response.body).data;
};

export type IndeedProject = {
  default: boolean;
  key: string;
  name: string;
  createdByAccountKey: string;
  __typename: string;
};

type IndeedProjectListGQLMutationResponse = {
  findTalentProjectBriefs: {
    projects: IndeedProject[];
    __typename: string;
  };
};

type GetProjectListGQLQueryHandler = (params: {
  headers: Record<string, any>;
}) => Promise<IndeedProjectListGQLMutationResponse>;
