import type { InsightType } from 'clients/CampaignsClient/CandidateResponse';
import type { Insight } from 'clients/CampaignsClient/CandidateResponse';

export default class InsightChecker {
  private insights: Insight[];
  constructor(insights: Insight[]) {
    this.insights = insights;
  }

  check(type: InsightType, config?: { checkDiff: boolean }): boolean {
    const findedInsight = this.insights.find((insight: Insight) => {
      const insightCreatedAtPlusSevenDays = new Date(insight.createdAt);
      insightCreatedAtPlusSevenDays.setDate(insightCreatedAtPlusSevenDays.getDate() + 7);

      const today = new Date();

      return (
        (config?.checkDiff ? insight.type !== type : insight.type === type) && insightCreatedAtPlusSevenDays > today
      );
    });

    return !!findedInsight;
  }
}
