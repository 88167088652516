import { useStyles } from './styles';
import { default as Row } from 'components/ui/atoms/Row';

import * as Icons from 'components/ui/atoms/icons';

import type { TextButtonProps } from './types';
import { forwardRef } from 'react';

const TextButton = forwardRef(
  ({ icon, children, onClick, variant = 'default' }: TextButtonProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const classes = useStyles();

    const handleClick = () => {
      onClick?.();
    };

    return (
      <Row
        ref={ref}
        css={[classes.root, classes[variant]]}
        gap={4}
        inline
        alignCenter
        tabIndex={0}
        onClick={handleClick}>
        {icon && Icons[icon]({ color: 'currentColor', size: 16 })}
        {children}
      </Row>
    );
  },
);

export default TextButton;
