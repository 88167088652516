import { IMCandidate } from 'model/Candidate';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';
import { CandidatePreferences } from './types';

export const extractPreferences = (
  preferences: IMCandidate['preferences'],
  dictionary: Dictionary,
): CandidatePreferences[] => {
  return preferences?.map(({ title, description }, index) => {
    return {
      id: index,
      text: dictionary[title],
      meta: title === 'workingHours' ? `${description} ${dictionary.hours}` : description,
    };
  });
};
