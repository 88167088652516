import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      padding: '32px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  listRoot: () =>
    css({
      marginTop: 16,
      width: 680,
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
    }),
  listWrapper: () =>
    css({
      minHeight: 300,
      maxHeight: 400,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      paddingRight: 8,
    }),
  addFeatureToggleWrapper: () =>
    css({
      marginTop: 16,
    }),
  actions: () =>
    css({
      marginTop: 16,
      display: 'flex',
      justifyContent: 'flex-end',
      '& > :not(:first-of-type)': {
        marginLeft: 8,
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
