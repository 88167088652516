import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import {
  CampaignCandidateResponse,
  CampaignCandidateSaveNotesResponse,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateResponse, Source } from 'clients/CampaignsClient/CandidateResponse';
import { searchCandidateKey } from 'hooks/search';
import { SearchCandidateResponse } from 'hooks/search/types';
import { Candidate } from 'model';
import { useCallback, useMemo } from 'react';
import { SearchCandidateContext } from 'shared/contexts/SearchCandidateContext';
import { CAMPAIGN_STATS, ICampaign } from 'types/campaign';
import { useContextSelector } from 'use-context-selector';

export const useCandidateOrchestratorForSearch = () => {
  const queryClient = useQueryClient();
  const searchListContext = useContextSelector(SearchCandidateContext, (state) => state.searchListContext);
  const currentSearchListKey = useMemo(() => {
    return searchCandidateKey({
      labelsFilter: searchListContext.labelsFilter,
      sort: searchListContext.sort,
      status: searchListContext.status,
      search: searchListContext.search,
    });
  }, [searchListContext]);

  const invalidateCurrentQuery = useCallback(async () => {
    await queryClient.invalidateQueries(currentSearchListKey);
  }, [currentSearchListKey]);

  const resetList = useCallback(
    (statKey: keyof ICampaign.Stats) => {
      queryClient.removeQueries({
        queryKey: searchCandidateKey({ labelsFilter: [], status: statKey }),
        exact: false,
      });
    },
    [queryClient],
  );

  const updateCurrentCandidate = useCallback(
    (candidate: Candidate, cb: (candidate: Candidate) => Candidate) => {
      queryClient.setQueryData<InfiniteData<SearchCandidateResponse>>(currentSearchListKey, (data) => {
        if (!data) return data;

        const updatedPages = data.pages.map((page) => {
          return {
            ...page,
            results: page.results.map((c) => (c.es_person_id === candidate?.es_person_id ? Candidate.clone(cb(c)) : c)),
          };
        });

        return {
          pageParams: data.pageParams,
          pages: updatedPages,
        };
      });
    },
    [currentSearchListKey, queryClient],
  );

  /**
   * Implementations
   */

  const reset = useMemo(
    () => ({
      shortlistList: () => resetList(CAMPAIGN_STATS.SHORTLISTED),
      contactList: () => resetList(CAMPAIGN_STATS.CONTACTED),
      matchList: () => resetList(CAMPAIGN_STATS.MATCHED),
      hiddenList: () => resetList(CAMPAIGN_STATS.HIDDEN),
    }),
    [queryClient],
  );

  const update = useCallback(
    (candidate: Candidate) => ({
      source: (candidateSource: Partial<Source>) =>
        updateCurrentCandidate(candidate, (c) => c.mergeSource(candidateSource)),
      locally: (updatedCandidate: Partial<CampaignCandidateResponse>) =>
        updateCurrentCandidate(candidate, (c) => c.mergeLocally(updatedCandidate)),
      notes: (notes: CampaignCandidateSaveNotesResponse[]) =>
        updateCurrentCandidate(candidate, (c) => c.mergeNotesResponse(notes)),
      info: (candidateInfo: CandidateResponse) =>
        updateCurrentCandidate(candidate, (c) => c.setCandidateInfo(candidateInfo)),
      labels: {
        set: (labels: string | string[]) =>
          updateCurrentCandidate(candidate, (c) =>
            c.mergeLocally({ reasons: Array.isArray(labels) ? labels : [labels] }),
          ),
        add: (labels: string | string[]) =>
          updateCurrentCandidate(candidate, (c) =>
            c.mergeLocally({
              reasons: Array.from(new Set([...(Array.isArray(labels) ? labels : [labels]), ...c.reasons])),
            }),
          ),
        remove: (labels: string | string[]) =>
          updateCurrentCandidate(candidate, (c) =>
            c.mergeLocally({ reasons: c.reasons.filter((l) => !labels.includes(l)) }),
          ),
      },
    }),
    [updateCurrentCandidate],
  );

  const markAs = useCallback(
    (candidate: Candidate) => ({
      noLongerAvailable: () => {
        updateCurrentCandidate(candidate, (c) => {
          c.isAvailable = false;
          return c;
        });
      },
    }),
    [updateCurrentCandidate],
  );

  const unlock = useCallback(
    (candidate: Candidate) => {
      updateCurrentCandidate(candidate, (c) => c.mergeLocally({ is_unlocked: true }));
    },
    [updateCurrentCandidate],
  );

  return useCallback(
    (candidate: Candidate) => ({
      invalidateCurrentQuery,
      reset,
      update: update(candidate),
      markAs: markAs(candidate),
      unlock: unlock(candidate),
    }),
    [invalidateCurrentQuery, reset],
  );
};
