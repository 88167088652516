import { useContext } from 'react';

import { useStyles } from './SharedLayoutComponents.styles';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';

export const SharedLayoutComponents = () => {
  const { Snackbar } = useContext(LayoutContext);
  const classes = useStyles();

  return <div css={classes.root}>{Snackbar}</div>;
};
