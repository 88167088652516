import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { generateTransitionStyles } from 'utils/style';

const styles = {
  root: () =>
    css({
      maxHeight: '50vh',
      minWidth: '65vw',
      overflowY: 'auto',
    }),
  componentWrapper: () =>
    css({
      minWidth: '65vw',
      paddingBottom: 16,
      display: 'flex',
      '& > *:not(:first-of-type)': {
        marginLeft: 18,
      },
    }),
  sidebar: ({ theme }) =>
    css({
      position: 'relative',
      width: 4,
      height: 'inherif',
      backgroundColor: theme.colors.neutral.variant[30],
      display: 'flex',
      alignItems: 'center',
      margin: '0 7px',
      '& > button': {
        left: -7,
        position: 'absolute',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: 18,
        height: 18,
        padding: 0,
        color: theme.colors.secondary.sky[60],
        backgroundColor: theme.colors.secondary.sky[20],
        transition: generateTransitionStyles(['border-color', 'color']),
        '&:hover': {
          color: theme.colors.tertiary.red[90],
          backgroundColor: theme.colors.tertiary.red[20],
          borderColor: theme.colors.tertiary.red[40],
        },
      },
    }),
  add: () =>
    css({
      paddingTop: 7,
      paddingLeft: 20,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
