import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.neutral.variant[0],
    }),
  fetchMore: () =>
    css({
      display: 'block',
      textAlign: 'center',
      marginTop: 16,
      marginBottom: 16,
    }),
  searchWrapper: () =>
    css({
      display: 'flex',
      justifyContent: 'center',
      '& > div': {
        width: '100%',
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
