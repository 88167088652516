import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { useEffect } from 'react';

export const useBrowserExtensionSyncResize = ({ enabled = false }: { enabled: boolean }) => {
  useEffect(() => {
    if (enabled) {
      const observer = new ResizeObserver(() => {
        const width = window.innerWidth;
        const height = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight);

        browserExtensionClient.sendMessage('iframeSizeChanged', { width, height });
      });

      observer.observe(document.body);

      return () => {
        observer.disconnect();
      };
    }
  }, [enabled]);
};
