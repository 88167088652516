import { useContext } from 'react';
import { Typography } from '@recruitrobin/robin-theme/web-components';

import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Row, TextButton } from 'components/ui/atoms';

import { useStyles } from './SmallLanguageSwitch.styles';

export const SmallLanguageSwitch = () => {
  const { setLanguage, language } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <Row gap={10}>
      <TextButton variant={'subtle'} onClick={() => setLanguage('en')}>
        <Typography
          variant={(v) => v.supporting.label}
          color={(c) => (language === 'en' ? c.primary.fuchsia[50] : c.neutral.variant[40])}>
          EN
        </Typography>
      </TextButton>

      <div css={classes.divider} />

      <TextButton variant={'subtle'} onClick={() => setLanguage('nl')}>
        <Typography
          variant={(v) => v.supporting.label}
          color={(c) => (language === 'nl' ? c.primary.fuchsia[50] : c.neutral.variant[40])}>
          NL
        </Typography>
      </TextButton>
    </Row>
  );
};
