import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { VisualState } from './DropDownMenu.types';

const styles = {
  root: () =>
    css({
      position: 'sticky',
      top: 0,
      display: 'flex',
      zIndex: 50,
      alignItems: 'center',
      height: '100%',
    }),
  button: () =>
    css({
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 5,
    }),
  label: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      '& > div': {
        cursor: 'pointer',
      },
    }),
  popup: ({ props: { open } }) =>
    css({
      display: open ? 'block' : 'none',
      flexDirection: 'column',
      position: 'absolute',
      top: '100%',
      right: 0,
      transitionDuration: '300ms',
      zIndex: 9999,
      section: {
        maxHeight: '30vh',
        overflow: 'auto',
      },
    }),
  item: ({ theme, props: { size } }) =>
    css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 20,
      paddingRight: 8,
      color: theme.colors.neutral.variant[90],
      fontSize: 16,
      borderBottom: `1px solid ${theme.colors.neutral.variant[20]}`,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      svg: {
        marginRight: 8,
      },
      button: {
        cursor: 'pointer',
      },
      '&:hover': {
        backgroundColor: theme.colors.neutral.variant[20],
      },
      ...(size === 'medium' && {
        padding: 10,
      }),
      ...(size === 'small' && {
        padding: 8,
      }),
    }),
  title: ({ props: { size } }) =>
    css({
      padding: 20,
      cursor: 'default',
      fontWeight: 500,
      fontSize: 18,
      '&:hover': {
        background: 'none',
      },
      ...(size === 'medium' && {
        padding: 10,
      }),
      ...(size === 'small' && {
        padding: 8,
      }),
    }),
  actions: ({ props: { size } }) =>
    css({
      padding: 20,
      cursor: 'default',
      fontWeight: 500,
      textAlign: 'center',
      '&:hover': {
        background: 'none',
      },
      ...(size === 'medium' && {
        padding: 10,
      }),
      ...(size === 'small' && {
        padding: 8,
      }),
    }),
} satisfies Styles<VisualState>;

export const useStyles = withStyles(styles);
