import { PaddingProps } from './types';
import { useStyles } from './styles';

const Padding = ({
  children,
  padding: _padding,
  top: _top,
  right: _right,
  bottom: _bottom,
  left: _left,
  contained = false,
  className,
  onClick,
}: PaddingProps) => {
  const [top, right, bottom, left] = Array.isArray(_padding) ? _padding : [_padding, _padding, _padding, _padding];

  const classes = useStyles({
    padding: [_top ?? top, _right ?? right ?? top, _bottom ?? bottom ?? top, _left ?? left ?? right ?? top],
    contained,
  });

  return (
    <div css={classes.root} className={className} onClick={() => onClick?.()}>
      {children}
    </div>
  );
};

export default Padding;
