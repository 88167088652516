import type { PropertiesHyphen } from 'csstype';
import { StyleGuidePatterns } from 'styles/types';

type CSSPropName = keyof PropertiesHyphen;

type StyleDuration = number | string | ((d: StyleGuidePatterns['transition']['duration']) => string);

type StyleFunction = string | ((d: StyleGuidePatterns['transition']['function']) => string);

const getStyleDuration = (duration: StyleDuration = '80ms'): string => {
  if (typeof duration === 'function') {
    return duration({
      short: '80ms',
      long: '120ms',
    });
  }

  if (typeof duration === 'number') {
    return `${duration}ms`;
  }

  return duration;
};

const getStyleFunction = (duration: StyleFunction = 'cubic-bezier(.22,.9,.53,1)'): string => {
  if (typeof duration === 'function') {
    return duration({
      default: 'cubic-bezier(.22,.9,.53,1)',
    });
  }

  return duration;
};

export const generateTransitionStyles = (
  props: CSSPropName | CSSPropName[],
  _duration?: StyleDuration,
  _animFunction?: StyleFunction,
) => {
  const fProps: Array<CSSPropName> = Array.isArray(props) ? props : [props];
  const duration = getStyleDuration(_duration);
  const animFunction = getStyleFunction(_animFunction);

  return fProps.map((prop) => `${prop} ${duration} ${animFunction}`).join(', ');
};
