type generateLabelsCacheKeyType = (reasons: string[]) => string;

const generateLabelsCacheKey: generateLabelsCacheKeyType = (reasons) => {
  return reasons
    ?.map((reason: string) => reason)
    .sort()
    .join('-');
};

export default generateLabelsCacheKey;
