import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { useContext, useEffect } from 'react';
import { getTokenFromBrowserExtension } from 'services/browser-extension/getTokenFromBrowserExtension';
import { AuthContext } from 'shared/contexts/AuthContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useBrowserExtension } from 'shared/hooks';
import { BrowserExtensionInfoStatus } from 'shared/hooks/useBrowserExtension/types';

export const useBrowserExtensionSync = async () => {
  const { activeToken } = useContext(AuthContext);
  const { currentUser } = useContext(UserContext);
  const { info } = useBrowserExtension();

  const updateUserStateChange = async () => {
    browserExtensionClient.sendMessage('userStateChanged', {
      user: currentUser,
      isLoggedIn: true,
      token: activeToken,
    });
  };

  useEffect(() => {
    if (activeToken && currentUser && info?.status === BrowserExtensionInfoStatus.Active) {
      getTokenFromBrowserExtension().then((token) => {
        if (!token) {
          updateUserStateChange();
        }
      });
    }
  }, [activeToken, currentUser, info?.status]);

  return {
    updateUserStateChange,
  };
};
