import { routes } from 'config';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { PageContext } from './usePageContext.types';

export const usePageContext = (): PageContext => {
  const isSharePage = useMatch(`${routes.sharedCandidatePage()}`);
  const isCandidatePage = useMatch(`${routes.candidatePage()}`);
  const isSearchv3 = useMatch(`searchv3`);

  const pageContext = useMemo(() => {
    if (isSharePage) return PageContext.Shared;
    if (isCandidatePage) return PageContext.Candidate;

    /// TODO: verify how this logic will work for searchv3
    // eslint-disable-next-line no-constant-condition
    if (isSearchv3 || true) return PageContext.Campaign;
  }, [isSharePage, isCandidatePage, isSearchv3]);

  return pageContext;
};
