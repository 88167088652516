import { jobBoardIconSizes, useStyles } from './styles';
import { default as Skeleton } from 'components/ui/molecules/Skeleton';
import { Tooltip } from 'shared/components/Tooltip';

import {
  Database_01,
  Debanensite,
  Indeed,
  Jobbird,
  Linkedin,
  Monsterboard,
  Nvb,
  Resumeio,
  Techniekwerkt,
  Uitzendbureau,
  Werknl,
  Studentjob,
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // Werkzoeken,
} from '@recruitrobin/robin-theme/web-icons';

import type { JobBoardIconProps, JobBoardIconSize } from './types';
import {
  CampaignJobboard,
  CampaignJobboardLabel,
} from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { useState } from 'react';

const JobBoardIcon = ({
  onClick,
  variant = CampaignJobboard.Linkedin,
  disabled = false,
  isLoading,
  size = 'medium',
  isActivating = false,
  showJobBoardNameTooltip = false,
  backgroundColor,
  color,
}: JobBoardIconProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles({
    disabled,
    size,
    isActivating,
    pointer: Boolean(onClick),
    jobboardVariant: variant,
    backgroundColor,
    isLoading,
    color,
  });

  return (
    <Tooltip title={CampaignJobboardLabel[variant]} disabled={!showJobBoardNameTooltip} enablePortal>
      <div css={classes.wrapper} onClick={onClick} className={'job-board-icon'}>
        {isLoading ? (
          <Skeleton variant="circle" size={jobBoardIconSizes[size]} />
        ) : (
          <div css={classes.content} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            {jobBoardIcon[variant](size, isHovering && !disabled)}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default JobBoardIcon;

const jobBoardIcon: Record<
  CampaignJobboard,
  (size: JobBoardIconSize, enableSecondaryColor?: boolean) => React.ReactNode
> = {
  [CampaignJobboard.Linkedin]: (size) => <Linkedin size={size === 'big' ? 14 : 14} />,
  [CampaignJobboard.Werknl]: (size) => <Werknl size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.Indeed]: (size) => (
    <span style={{ marginLeft: -2 }}>
      <Indeed size={size === 'big' ? 16 : 12} />
    </span>
  ),
  [CampaignJobboard.Monsterboard]: (size) => <Monsterboard size={size === 'big' ? 13 : 9} />,
  [CampaignJobboard.Resumeio]: (size) => <Resumeio size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.Database]: (size) => <Database_01 size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.Debanensite]: (size) => <Debanensite size={size === 'big' ? 14 : 12} />,
  [CampaignJobboard.Jobbird]: (size) => <Jobbird size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.NationaleVacaturebank]: (size) => <Nvb size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.Techniekwerkt]: (size) => <Techniekwerkt size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.Uitzendbureau]: (size) => <Uitzendbureau size={size === 'big' ? 16 : 12} />,
  [CampaignJobboard.StudentJob]: (size, secondaryColor) => (
    <Studentjob
      size={size === 'big' ? 29 : 21.75}
      color={(c) => (secondaryColor ? c.extra.jobboard.studentjobSecondary : c.neutral.variant[0])}
    />
  ),
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // [CampaignJobboard.Werkzoeken]: (size) => <Werkzoeken size={size === 'big' ? 16 : 12} />,
};
