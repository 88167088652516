import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 6px 8px 6px 16px;
    gap: 18px;
    text-align: center;
  `,
  text: css`
    gap: 6;
    max-width: 318px;
  `,
  svg: css`
    max-width: 215px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
