import { useQuery } from '@tanstack/react-query';
import { autoCompleteClient } from 'clients/AutoCompleteClient/AutoCompleteClient';

export const generateAtsVacancyQueryKey = (search?: string) => [`atsVacancyQuery`, { search }];

export const useAtsVacancyQuery = (params?: { search?: string; enabled?: boolean }) => {
  return useQuery(
    generateAtsVacancyQueryKey(params?.search),
    async () => {
      const { data } = await autoCompleteClient.atsVacancy(params?.search);

      return data.result;
    },
    { keepPreviousData: true, cacheTime: 5 * 60 * 1000, staleTime: 5 * 60 * 1000, enabled: params?.enabled },
  );
};
