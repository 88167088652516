import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { logger } from 'config/logger';
import { isAxiosError } from 'helpers/clientHelpers';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { useIsCvAvailable } from 'shared/contexts/UserContext/CandidateContext.useIsCvAvailable';
import { CvNotAvailable } from 'shared/exceptions/CvNotAvailableException';
import { useFeatureToggles } from 'shared/hooks';
import { CandidateStatus } from 'types/candidate';
import { QueryKey } from 'types/query';
import { RequestCvErrorType, UseRequestCvHandler } from './CandidateCV.types';

export const useRequestCV: UseRequestCvHandler = (candidate) => {
  const { isFeatureToggleEnabled } = useFeatureToggles();
  const isRedactEnabled = isFeatureToggleEnabled('redact_cv');
  const isCvAvailable = useIsCvAvailable();
  const shouldUseRedact = isRedactEnabled && candidate.status !== CandidateStatus.Matched;

  const { data: { data, error } = { data: null } } = useQuery(
    [QueryKey.useRequestCv, { esPersonId: candidate?.es_person_id, shouldUseRedact }],
    async () => {
      const cvUrl = candidate.activeJobBoard === CampaignJobboard.Werknl ? candidate.es_person_id : candidate.cvFileId;

      if (cvUrl) {
        return fetchCVFileUrl(cvUrl, { shouldUseRedact });
      } else if (isCvAvailable(candidate)) {
        return fetchCVParsedFile(candidate.campaignId, candidate.es_person_id);
      }

      return { data: null };
    },
    { suspense: true, retry: 1 },
  );

  if (data instanceof Blob) {
    return { data, isPdf: true };
  }

  return { data, isPdf: false, error };
};

const isPdf = (filename: string) => !!filename.match(/\.pdf/);

const fetchCVFileUrl = async (cvFileId: string, { shouldUseRedact }: { shouldUseRedact: boolean }) => {
  try {
    const { data } = await campaignsClient.getCampaignCandidateCVURL({ cvFileId, shouldUseRedact });

    if (isPdf(data.url) || shouldUseRedact) {
      const { data: cv, headers } = await campaignsClient.getFile(data.url);

      if (headers['content-type'] === 'application/pdf') {
        return { data: cv ?? null };
      }
    }

    return { data: data.url };
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      logger.error(new CvNotAvailable('cv not available anymore'));

      return { data: null, error: RequestCvErrorType.notAvailable };
    }

    throw e;
  }
};

const fetchCVParsedFile = async (campaignId: number, candidateId: string) => {
  const { data } = await campaignsClient.getCampaignCandidateCV({
    campaignId: `${campaignId}`,
    candidateId: candidateId,
    options: { responseType: 'blob' },
  });

  return { data: data ?? null, error: undefined };
};
