import { Typography } from '@recruitrobin/robin-theme/web-components';
import { AlertTriangle, Check, InfoCircle, XClose } from '@recruitrobin/robin-theme/web-icons';
import { default as Column } from 'components/ui/atoms/Column';
import { LoaderSpinner1 } from 'components/ui/atoms/icons/animated';
import { default as Row } from 'components/ui/atoms/Row';
import { useContext, useMemo } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './Snackbar.styles';
import { AlertProps, SnackbarProps, SnackbarVariant } from './types';

const AlertBase = ({ title, label, icon, variant, onClose }: AlertProps) => {
  const classes = useStyles({ variant });

  return (
    <Row css={classes.root} gap={12} flexStart>
      <Column css={classes.iconWrapper} center alignCenter>
        <Row>{icon}</Row>
      </Column>
      <Row gap={12}>
        <Column gap={0}>
          {title && <Typography variant={(v) => v.heading[5]}>{title}</Typography>}
          <Typography variant={(v) => v.supporting.helper}>{label}</Typography>
        </Column>
        {onClose && (
          <div css={classes.closeButton} onClick={onClose}>
            <XClose size={24} />
          </div>
        )}
      </Row>
    </Row>
  );
};

const Snackbar = ({ title, icon, label, variant = 'success', onClose }: SnackbarProps) => {
  const alertBaseProps = useAlertBaseProps(variant);

  return (
    <AlertBase
      {...{
        ...alertBaseProps,
        label,
        variant,
        onClose,
        title: title ?? alertBaseProps.title,
        icon: icon ?? alertBaseProps.icon,
      }}
    />
  );
};

export default Snackbar;

const useAlertBaseProps = (variant: SnackbarVariant): { icon: React.ReactNode; title?: string } => {
  const { dictionary } = useContext(LocalizationContext);

  return useMemo(() => {
    const props = {
      danger: {
        icon: <InfoCircle size={14} />,
        title: dictionary.error,
      },
      loading: {
        icon: <LoaderSpinner1 size={14} />,
        title: dictionary.loading,
      },
      success: {
        icon: <Check size={14} />,
        title: dictionary.success,
      },
      warning: {
        icon: <AlertTriangle size={14} />,
      },
    };

    return props[variant];
  }, [variant, dictionary]);
};
