import { routes } from 'config';
import { useContext, useMemo } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useShare = (params: WrappedHookParams) => {
  const currentUserId = useCurrentUserIdMemoized();

  return useCandidateAction<void, ShareCallbackParams>(async ({ candidate }) => {
    return Promise.resolve(
      `${window.location.origin}` +
        routes.sharedCandidatePage({
          campaignId: candidate.campaignId,
          candidateId: candidate.es_person_id,
          userId: currentUserId,
        }),
    );
  })(params);
};

const useCurrentUserIdMemoized = () => {
  const { currentUser: { id: currentUserId = undefined } = {} } = useContext(UserContext);

  return useMemo(() => currentUserId, [currentUserId]);
};

export type ShareCallbackParams = string;
