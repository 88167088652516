import { CSSObject } from '@emotion/core';
import { generateTransitionStyles } from 'styles/theme';

import { SelectChipStyleStates, SelectChipVariants } from './types';
import { Styles, withStyles, css, Theme } from '@recruitrobin/robin-theme/web-styles';

const chipVariantsCss: ({ theme }: { theme: Theme }) => { [key in SelectChipVariants]: CSSObject } = ({ theme }) => ({
  default: {
    backgroundColor: theme.colors.secondary.sky[20],
  },
  'must-have': {
    color: theme.colors.secondary.emerald[60],
    backgroundColor: theme.colors.secondary.emerald[20],
  },
  exclude: {
    color: theme.colors.tertiary.lime[60],
    backgroundColor: theme.colors.tertiary.lime[10],
  },
  remove: {
    backgroundColor: theme.colors.secondary.sky[20],
  },
  subtle: {
    backgroundColor: theme.colors.neutral.variant[20],
  },
  highlight: {
    backgroundColor: theme.colors.primary.fuchsia[20],
  },
});

const chipHoverVariantsCss: ({ theme }: { theme: Theme }) => { [key in SelectChipVariants]: CSSObject } = ({
  theme,
}) => ({
  default: {
    backgroundColor: theme.colors.secondary.sky[30],
  },
  'must-have': {
    backgroundColor: theme.colors.secondary.emerald[30],
  },
  exclude: {
    backgroundColor: theme.colors.tertiary.lime[20],
  },
  remove: {
    backgroundColor: theme.colors.secondary.sky[30],
  },
  subtle: {
    backgroundColor: theme.colors.neutral.variant[30],
  },
  highlight: {
    backgroundColor: theme.colors.primary.fuchsia[30],
  },
});

const iconWrapperHoverCss: ({ theme }: { theme: Theme }) => { [key in SelectChipVariants]: CSSObject } = ({
  theme,
}) => ({
  default: {
    backgroundColor: theme.colors.secondary.sky[20],
  },
  'must-have': {
    backgroundColor: theme.colors.secondary.emerald[20],
  },
  exclude: {
    backgroundColor: theme.colors.tertiary.lime[10],
  },
  remove: {
    backgroundColor: theme.colors.secondary.sky[20],
  },
  subtle: {
    backgroundColor: theme.colors.neutral.variant[20],
  },
  highlight: {
    backgroundColor: theme.colors.primary.fuchsia[30],
  },
});

const styles = {
  root: ({ theme, props }) =>
    css({
      '&.MuiChip-root': {
        height: 22,
        maxWidth: 'calc(100% - 3px)',
        minWidth: 22,
        // boxShadow: `inset 0px 0px 0px 1px ${theme.styleGuide.color.navy[30]}`,
        transition: generateTransitionStyles(['color', 'background-color', 'box-shadow']),
        '&, &:focus, &.MuiChip-clickable, &.MuiChip-clickable:focus, &.MuiChip-deletable, &.MuiChip-deletable:focus, & > .select-chip--icon-wrapper':
          {
            ...(props.type === 'default' && {
              ...chipVariantsCss({ theme })[props.variant],
            }),
            ...(props.type === 'menu' && {
              ...chipVariantsCss({ theme })[props.variant],
            }),
            ...(props.iconWrapperHover && {
              ...(props.type === 'default' && {
                ...chipHoverVariantsCss({ theme })[props.variant],
              }),
              ...(props.type === 'menu' && {
                ...chipHoverVariantsCss({ theme })[props.variant],
              }),
            }),
            ...(props.customChip && {
              cursor: 'pointer',
              color: theme.colors.neutral.variant[0],
              backgroundColor: theme.colors.primary.fuchsia[50],
              ...(!props.iconWrapperHover && {
                '&:hover': {
                  backgroundColor: theme.colors.primary.fuchsia[60],
                },
              }),
            }),
          },
        '& > .MuiChip-label': {
          padding: '0 4px 0 8px',
        },
        '& > .MuiChip-icon': {
          color: theme.colors.neutral.variant[0],
        },
      },
    }),
  iconWrapper: ({ theme, props }) =>
    css({
      '&.MuiChip-deleteIcon': {
        // color: theme.styleGuide.color.monochrome[90],
        borderRadius: '50%',
        margin: '0',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: generateTransitionStyles(['color', 'background-color']),
        '&:hover': {
          // color: theme.styleGuide.color.navy[80],
        },
        ...(props.customChip && {
          color: theme.colors.neutral.variant[0],
          backgroundColor: theme.colors.primary.fuchsia[50],
          '&:hover': {
            backgroundColor: theme.colors.primary.fuchsia[60],
          },
        }),
        ...(props.iconWrapperHover && {
          '&, &:hover': {
            ...(props.type === 'default' && {
              ...iconWrapperHoverCss({ theme }).remove,
            }),
            ...(props.type === 'menu' && {
              ...iconWrapperHoverCss({ theme })[props.variant],
            }),
            ...(props.customChip && {
              color: theme.colors.neutral.variant[0],
              backgroundColor: theme.colors.primary.fuchsia[50],
            }),
          },
        }),
      },
    }),
  popper: css({
    display: 'flex',
    justifyContent: 'center',
  }),
  popperTransition: css({
    left: 'unset',
  }),
  menuWrapper: () =>
    css({
      '&.MuiPaper-root': {
        marginTop: '6px',
        '& > .MuiList-root': {
          outline: '0',
        },
      },
      '.MuiDivider-root': {
        margin: '6px 0',
      },
    }),
  menuItem: ({ theme }) =>
    css({
      '&.MuiListItem-button': {
        transition: generateTransitionStyles(['color', 'background-color']),
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.colors.neutral.variant[5],
        },
        '&.menu-item--must-have': {
          '&:hover, &.menu-item--selected': {
            color: theme.colors.secondary.emerald[60],
            backgroundColor: theme.colors.secondary.emerald[10],
          },
        },
        '&.menu-item--exclude': {
          '&:hover, &.menu-item--selected': {
            color: theme.colors.tertiary.lime[60],
            backgroundColor: theme.colors.tertiary.lime[10],
          },
        },
        '&.menu-item--danger': {
          '&:hover, &.Mui-focusVisible': {
            color: theme.colors.tertiary.red[60],
            backgroundColor: theme.colors.tertiary.red[10],
          },
        },
        '& > span': {
          marginLeft: 6,
        },
      },
    }),
} satisfies Styles<SelectChipStyleStates>;

export const useStyles = withStyles(styles);
