import { ChangeEvent, useContext, useRef, useState } from 'react';
import { FeatureToggleRowProps } from './types';
import { useStyles } from './FeatureToggleRow.styles';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as TextField } from 'shared/components/TextField';
import { default as InputLabelWrapper } from 'shared/components/InputLabelWrapper';
import { Checkbox } from 'shared/components/CheckboxGroup';
import { XClose } from '@recruitrobin/robin-theme/web-icons';

const FeatureToggleRow = ({ featureToggle, onChange, onRemove }: FeatureToggleRowProps) => {
  const { dictionary } = useContext(LocalizationContext);

  const classes = useStyles();
  const checkboxId = useRef<string>(Math.floor(Math.random() * 10000 * Date.now()).toString(16));
  const [name, setName] = useState(featureToggle.name);
  const [value, setValue] = useState(featureToggle.value);
  const [enabled, setEnabled] = useState(featureToggle.enabled);

  const handleRemove = () => {
    onRemove?.(featureToggle);
  };

  const handleChangeTitle = (n: string) => {
    const name = n.replace(/ /g, '_');
    setName(name);

    onChange?.({ ...featureToggle, name });
  };

  const handleChangeValue = (value: string) => {
    setValue(value);

    onChange?.({ ...featureToggle, value });
  };

  const handleChangeEnabled = (e: ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;
    setEnabled(enabled);

    onChange?.({ ...featureToggle, enabled });
  };

  return (
    <div css={classes.root}>
      <div css={classes.removeButtonBackground} />
      <div css={classes.removeButton} onClick={handleRemove}>
        <XClose size={12} />
      </div>
      <div css={classes.field}>
        <TextField label={dictionary.title} onChange={handleChangeTitle} value={name} />
      </div>
      <div css={classes.field}>
        <TextField label={dictionary.value} onChange={handleChangeValue} value={value} placeholder="[empty]" />
      </div>
      <div css={classes.field}>
        <div css={classes.checkbox}>
          <InputLabelWrapper label={dictionary.enabled} htmlFor={checkboxId.current} />
          <Checkbox id={checkboxId.current} onChange={handleChangeEnabled} checked={enabled} />
        </div>
      </div>
    </div>
  );
};

export default FeatureToggleRow;
