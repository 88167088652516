import { styleUtils } from 'utils';
import { RawInputStyleStates } from './types';
import { Styles, css, withStyles, extractFont, extractColor } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { placeholder, placeholderWidth, variant, color } }) =>
    css({
      ...extractFont(variant, 'web'),
      color: extractColor(color),
      position: 'relative',
      margin: 0,
      background: 0,
      outline: 0,
      border: 0,
      flexShrink: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent',
      borderRadius: 3,
      padding: '4px 8px',
      transition: styleUtils.generateTransitionStyles(['border-color']),
      '&:hover': {
        borderColor: theme.colors.neutral.variant[30],
      },
      '&:focus': {
        borderColor: theme.colors.primary.fuchsia[40],
        textOverflow: 'unset',
      },
      ...(placeholderWidth &&
        placeholder && {
          minWidth: placeholderWidth,
          '&:empty:after': {
            content: `"${placeholder}"`,
            cursor: 'text',
            color: 'unset',
            opacity: 0.75,
            top: 0,
            left: 0,
          },
        }),
    }),
  tooltip: css({
    width: '100%',
  }),
} satisfies Styles<RawInputStyleStates>;

export const useStyles = withStyles(styles);
