import { default as Column } from 'components/ui/atoms/Column';
import { default as Row } from 'components/ui/atoms/Row';
import { useStyles } from './styles';
import { SkeletonProps } from './types';

const FragComponent = ({ children }: { children: React.ReactNode }) => <>{children}</>;

const ColumnComponent = ({ children }: { children: React.ReactNode }) => <Column gap={6}>{children}</Column>;

const Skeleton = ({ variant, rows = 1, ...styleProps }: SkeletonProps) => {
  const classes = useStyles({ ...styleProps });
  const isParagraph = variant === 'paragraph';

  const variantClass = classes[isParagraph ? 'text' : variant];

  const Wrapper = isParagraph ? ColumnComponent : FragComponent;

  const Logo = () => (
    <Column gap={0} css={classes.logo}>
      <Row gap={0}>
        <div css={classes.root} className={'bottom-rounded'} />
        <div css={classes.root} className={'top-rounded'} />
      </Row>
      <div css={classes.root} className={'top-rounded'} />
    </Column>
  );

  return (
    <Wrapper>
      {variant === 'logo' ? (
        <Logo />
      ) : (
        Array.from({ length: rows }).map((_, i) => <div key={i} css={[classes.root, variantClass]} />)
      )}
    </Wrapper>
  );
};

export default Skeleton;
