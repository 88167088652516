export enum SidebarNavPages {
  Campaign = 'campaign',
  Users = 'users',
  Teams = 'teams',
  Dashboard = 'dashboard',
  AtsConnections = 'atsConnections',
  EmailSettings = 'email-settings',
  BrowserExtension = 'browserExtension',
  Admin = 'admin',
}
