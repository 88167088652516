import { Typography } from '@recruitrobin/robin-theme/web-components';
import { MailArrowDown, UserPlus_01 } from '@recruitrobin/robin-theme/web-icons';
import { useStyles } from './CampaignNotificationPill.styles';
import { CampaignNotificationPillProps } from './CampaignNotificationPill.types';

export const CampaignNotificationPill = ({
  newCandidates,
  newMessages,
  onClickNewCandidates,
  onClickNewMessages,
}: CampaignNotificationPillProps) => {
  const classes = useStyles();

  if (!newCandidates && !newMessages) {
    return;
  }

  return (
    <div css={classes.root}>
      {!!newCandidates && (
        <div
          css={classes.icon}
          onClick={(e) => {
            e.stopPropagation();
            onClickNewCandidates();
          }}>
          <UserPlus_01 size={14} />
          <Typography variant={(v) => v.supporting['label-tiny']}>{newCandidates}</Typography>
        </div>
      )}
      {!!newMessages && (
        <div
          css={classes.icon}
          onClick={(e) => {
            e.stopPropagation();
            onClickNewMessages();
          }}>
          <MailArrowDown size={14} />
          <Typography variant={(v) => v.supporting['label-tiny']}>{newMessages}</Typography>
        </div>
      )}
    </div>
  );
};
