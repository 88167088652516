export const formatPhoneNumberNL = (phoneNumber: string, countryCode = '31') => {
  if (phoneNumber.match(/^\+(?!31).+/)) {
    return phoneNumber;
  }

  const phone = phoneNumber
    .replace(/^0031/, '')
    .replace(/[^0-9+]+/g, '')
    .replace(/^(\+31)?0?6?/g, '');

  return `+${countryCode}6${phone}`;
};
