export const replaceUrlToLocal = (url: string, config?: { debug: boolean }): string => {
  if (window.location.hostname !== 'localhost') {
    return url;
  }

  const newUrl = url
    .replace(/^https/, 'http')
    .replace(new RegExp(`app.${process.env.REACT_APP_API_DOMAIN}`), window.location.host);

  if (config?.debug) {
    console.debug('[replaceUrlToLocal]', `${url} => ${newUrl}`);
  }

  return newUrl;
};
