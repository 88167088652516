import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      display: 'flex',
      width: '100%',
      height: '100vh',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.colors.neutral.variant[10],
      padding: 48,
      zIndex: 10,
    }),
  title: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      gap: 24,
    }),
  body: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      maxWidth: '454px',
      width: '100%',
      fontSize: 18,
      marginTop: 32,
    }),
  cardContent: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      padding: 24,
      gap: 16,
    }),
  section: () =>
    css({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  sectionHeader: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 12,
    }),
  contentWrapper: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      marginTop: 16,
    }),
  buttonWrapper: () =>
    css({
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 16,
    }),
  button: () =>
    css({
      display: 'flex',
      alignItem: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-end',
      gap: 9,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
