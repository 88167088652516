import { useCallback, useContext } from 'react';
import { UserContext } from './UserContext';
import { default as Candidate } from 'model/Candidate/Candidate';
import { CampaignJobboard } from '../SelectedCampaignContext/SelectedCampaignContext.types';
import { WerknlLayout } from 'clients/UserClient/types';

export const useIsCvAvailable = () => {
  const { currentUser: { werknl_layout = WerknlLayout.New } = {} } = useContext(UserContext);

  return useCallback(
    (candidate: Candidate | undefined): boolean => {
      if (candidate === undefined) return false;

      if (candidate.activeJobBoard !== CampaignJobboard.Werknl) return candidate.__cvAvailable;

      return werknl_layout === WerknlLayout.Legacy;
    },
    [werknl_layout],
  );
};
