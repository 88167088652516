import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      padding: '32px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  search: () =>
    css({
      width: '100%',
      marginBottom: 16,
    }),
  usersWrapper: () =>
    css({
      overflowY: 'auto',
      marginBottom: 16,
      minWidth: 400,
    }),
  row: () =>
    css({
      minWidth: 400,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
