import { createContext, Fragment, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { SnackbarWrapper } from './components';
import {
  AssignCloseSnackbarTimeoutHandler,
  CloseSnackbarHandler,
  CreateSnackbarHandler,
  GenericSnackbarWhenCatchAsyncErrorHandler,
  SnackbarContextProps,
  SnackbarContextProviderProps,
  SnackbarRecord,
} from './types';
import Snackbar, { SnackbarId } from 'shared/components/Snackbar';

export const DEFAULT_TIMEOUT_DURATION = 5000;

const initialState: SnackbarContextProps = {
  createSnackbar: () => '',
  closeSnackbar: () => undefined,
  genericSnackbarWhenCatchAsyncError: (_, { errorData }) => {
    return () => errorData;
  },
};

export const SnackbarContext = createContext<SnackbarContextProps>(initialState);

export const SnackbarContextProvider = ({ children }: SnackbarContextProviderProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const [snackbars, setSnackbars] = useState<SnackbarRecord[]>([]);
  const snackbarTimeoutHandler = useRef<Record<SnackbarId, NodeJS.Timeout>>({});

  const closeSnackbar = useCallback<CloseSnackbarHandler>((id) => {
    setSnackbars((snackbars) => snackbars.filter(([snackbarId]) => snackbarId !== id));
    clearTimeout(snackbarTimeoutHandler.current[id]);
  }, []);

  const assignCloseSnackbarTimeout = useCallback<AssignCloseSnackbarTimeoutHandler>(
    ({ id, duration = DEFAULT_TIMEOUT_DURATION }) => {
      snackbarTimeoutHandler.current[id] = setTimeout(() => {
        closeSnackbar(id);
      }, duration);
    },
    [closeSnackbar],
  );

  const createSnackbar = useCallback<CreateSnackbarHandler>(
    (label, config) => {
      const id = config?.id ?? `snackbar-${Date.now()}`;

      setSnackbars((snackbars) => [
        ...snackbars,
        [
          id,
          {
            component: (
              <Snackbar
                key={`snackbar-${id}`}
                label={label}
                variant={config?.variant}
                onClose={
                  config?.persist
                    ? () => {
                        closeSnackbar(id);
                      }
                    : undefined
                }
              />
            ),
            config,
          },
        ],
      ]);
      if (!config?.persist) {
        assignCloseSnackbarTimeout({ id, duration: config?.duration });
      }

      return id;
    },
    [assignCloseSnackbarTimeout, closeSnackbar],
  );

  const genericSnackbarWhenCatchAsyncError = useCallback<GenericSnackbarWhenCatchAsyncErrorHandler>(
    (cb, { errorData, customText }) =>
      async (...args) => {
        return cb(...args).catch((error) => {
          const text = customText?.(error) ?? dictionary.pleaseTryAgainOrContactUs;

          createSnackbar(text, { variant: 'danger' });
          return Promise.resolve(errorData);
        });
      },
    [createSnackbar],
  );

  const valueMemoized = useMemo(() => {
    return { createSnackbar, closeSnackbar, genericSnackbarWhenCatchAsyncError };
  }, [createSnackbar, closeSnackbar, genericSnackbarWhenCatchAsyncError]);

  return (
    <Fragment>
      <SnackbarContext.Provider value={valueMemoized}>{children}</SnackbarContext.Provider>
      <SnackbarWrapper snackbars={snackbars} />
    </Fragment>
  );
};
