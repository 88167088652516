import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import { IndeedCandidateAlreadyContactedException } from 'shared/exceptions/IndeedCandidateAlreadyContactedException';

const createTalentEngagementsGQLMutation = ({
  candidateId,
  senderName,
  jobDescription,
  jobTitle,
  company,
  message,
  projectKey,
}: {
  candidateId: string;
  senderName: string;
  jobDescription: string;
  jobTitle: string;
  company: string;
  message: string;
  projectKey: string;
}) => ({
  operationName: 'CreateTalentEngagements',
  variables: {
    input: {
      candidateIds: [candidateId],
      detailsByType: {
        email: {
          message,
          company,
          senderName,
          jobInfo: { jobKey: '', jobDescription, jobTitle },
          includeProfileSignature: true,
          reminders: [],
          subject: '',
        },
      },
      metadata: {
        editedAfterPreview: null,
        previewed: null,
        skipProactiveModerationCheck: false,
        source: 'RESUME',
        templateId: '',
      },
      projectKey,
      // captcha: {
      //   token: captchaToken,
      //   type: 'PASSIVE',
      //   sitekey: indeedSiteKey,
      //   from: 'SERP-contactForm',
      // },
    },
  },
  query:
    'mutation CreateTalentEngagements($input: CreateTalentEngagementsInput!) {\n  createTalentEngagements(input: $input) {\n    results {\n      ... on CreateTalentEngagementError {\n        candidateId\n        code\n        message\n        __typename\n      }\n      ... on TalentEngagementCreatedResult {\n        additionalInfo {\n          ... on CreateTalentEngagementForPhoneDetails {\n            proxyPhoneNumber\n            __typename\n          }\n          __typename\n        }\n        candidateId\n        __typename\n      }\n      __typename\n    }\n    successCount\n    __typename\n  }\n}\n',
});

export const sendMessage: SendMessageHandler = async ({ data, headers }) => {
  // const validCaptcha = await window.hbc();

  const mutation = createTalentEngagementsGQLMutation({
    candidateId: data.candidateId,
    company: data.senderCompany,
    jobDescription: data.description,
    jobTitle: data.jobTitle,
    message: data.message,
    senderName: data.senderName,
    projectKey: data.projectKey,
  });

  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://apis.indeed.com/graphql',
    method: 'POST',
    body: JSON.stringify(mutation),
    headers,
  });

  const parsedResponse: SendMessageResponse = JSON.parse(response.body).data;
  parsedResponse.createTalentEngagements.results.forEach((el) => {
    if (el.__typename === 'CreateTalentEngagementError' && el.code === 'ALREADY_CONTACTED') {
      throw new IndeedCandidateAlreadyContactedException(el.message);
    }
  });

  return parsedResponse;
};

type TalentEngagementCreatedResult = {
  additionalInfo: null;
  candidateId: string;
  __typename: 'TalentEngagementCreatedResult';
};

type CreateTalentEngagementError = {
  candidateId: string;
  code: string;
  message: string;
  __typename: 'CreateTalentEngagementError';
};

export type SendMessageResponse = {
  createTalentEngagements: {
    results: Array<TalentEngagementCreatedResult | CreateTalentEngagementError>;
    successCount: number;
    __typename: 'CreateTalentEngagementsPayload';
  };
};

type SendMessageHandler = (params: {
  headers: Record<string, any>;
  data: {
    candidateId: string;
    description: string;
    jobTitle: string;
    message: string;
    senderName: string;
    senderCompany: string;
    projectKey: string;
  };
}) => Promise<SendMessageResponse>;
