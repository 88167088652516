export const htmlToPlainText = (
  text: string,
  config?: {
    preserveLineBreak?: boolean;
  },
) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  const plainText = div.innerText;

  if (config?.preserveLineBreak) {
    return plainText.replace(/\n/g, '<br>');
  }

  return plainText;
};
