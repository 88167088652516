import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { Candidate } from 'model';
import { useCallback } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';

export const useSaveOnDb = (candidate: Candidate | undefined) => {
  return useCallback(async () => {
    if (!candidate) {
      throw new Error('Candidate cannot be empty');
    }

    if (candidate.status !== CANDIDATE_STATUS.NEW || candidate.isUnlocked) return;

    await campaignsClient.createOrUpdateCampaignCandidate({
      campaignId: candidate.campaignId,
      esPersonId: candidate.es_person_id,
      status: candidate.status,
      is_unlocked: false,
    });
  }, [candidate]);
};
