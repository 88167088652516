import { Fragment, useContext } from 'react';
import { CloseModalHandler, ModalProps } from './types';
import { Fade as MuiFade, Modal as MuiModal } from '@material-ui/core';
import { useStyles } from './Modal.styles';
import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { X } from 'shared/icons';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { generateDtiAttribute } from 'helpers/helpers';

const Modal = ({
  visible,
  title,
  onClose,
  children,
  subtitle,
  className,
  actions,
  isLoading = false,
  hideSave = false,
  hideActions = false,
  'data-tutorial-id': dataTutorialId,
  onSaveChanges,
}: ModalProps) => {
  const { dictionary } = useContext(LocalizationContext);

  const handleClose: CloseModalHandler = (reason) => {
    if (isLoading) return;

    onClose(reason);
  };

  const classes = useStyles();
  return (
    <MuiModal css={classes.root} className={className} open={visible} onClose={() => handleClose('click-away')}>
      <MuiFade in={visible}>
        <div css={classes.content} className="modal--content" {...generateDtiAttribute(dataTutorialId, 'modal')}>
          <div css={classes.closeButton}>
            <Button
              variant="subtle"
              icon={<X color={(color) => color.monochrome[70]} />}
              onClick={() => handleClose('close-button')}
              disabled={isLoading}
              tooltipLabel={dictionary.close}
              data-tutorial-id={`${dataTutorialId}-close`}
            />
          </div>
          <div css={classes.header}>
            <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.neutral.variant[80]}>
              {title}
            </Typography>
          </div>
          {subtitle && (
            <div css={classes.subtitle}>
              <Typography variant={(v) => v.heading[6]} colorv2={(c) => c.neutral.variant[70]}>
                {subtitle}
              </Typography>
            </div>
          )}
          <div css={classes.body}>{children}</div>
          {!hideActions && (
            <div css={classes.actions}>
              {actions ?? (
                <Fragment>
                  <Button
                    variant="subtle"
                    label={dictionary.cancel}
                    onClick={() => handleClose('cancel')}
                    disabled={isLoading}
                  />
                  {!hideSave && (
                    <Button
                      variant="highlight"
                      label={dictionary.saveChanges}
                      onClick={onSaveChanges ? onSaveChanges : () => handleClose('confirm')}
                      loading={isLoading}
                    />
                  )}
                </Fragment>
              )}
            </div>
          )}
        </div>
      </MuiFade>
    </MuiModal>
  );
};

export default Modal;
