import { FilterOrder } from 'clients/types';
import { useState } from 'react';
import { SelectedCampaignFilterState } from './SelectedCampaignContext.types';

export const useFilters = () => {
  const [order, setOrder] = useState(initialFiltersState.order);
  const [labels, setLabels] = useState<string[]>(initialFiltersState.labels);

  return { order, setOrder, labels, setLabels };
};

export const initialFiltersState: SelectedCampaignFilterState = {
  order: FilterOrder.Desc,
  setOrder: () => {
    throw Error('not implemented');
  },
  labels: [],
  setLabels: () => {
    throw Error('not implemented');
  },
};
