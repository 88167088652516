import { RefCallback, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { PortalWrapperProps } from './types';

export const useContainer = ({
  animationDuration,
  visible,
  anchorEl,
  onRequestClose,
  alignment = 'left',
  contained = false,
}: Omit<PortalWrapperProps, 'children'> & { animationDuration: number }) => {
  const lastScheduledRef = useRef<NodeJS.Timeout | null>(null);
  const [portal, setPortal] = useState<HTMLDivElement | null>(null);
  const [transitionWrapper, setTransitionWrapper] = useState<HTMLSpanElement | null>(null);
  const [animationVisible, setAnimationVisible] = useState(false);
  const [allowedToMount, setAllowedToMount] = useState(false);
  const anchorElement = anchorEl.current;
  const anchorElementRects = anchorElement?.getBoundingClientRect();
  const childElementRects = transitionWrapper?.getBoundingClientRect();
  const childPosition = useMemo(() => {
    if (portal && anchorElementRects && childElementRects) {
      const offset = window.scrollY;

      if (alignment === 'left') {
        return {
          top: offset + anchorElementRects.y + anchorElementRects.height + 8,
          left: anchorElementRects.x,
        };
      }

      return {
        top: offset + anchorElementRects.y + anchorElementRects.height + 8,
        left: anchorElementRects.x + anchorElementRects.width - childElementRects.width,
      };
    }

    return null;
  }, [
    portal,
    anchorElementRects?.y,
    anchorElementRects?.x,
    anchorElementRects?.height,
    anchorElementRects?.width,
    childElementRects?.width,
  ]);
  const childPositionRecalculated = useMemo(() => {
    if (!childPosition) return null;

    const position = { top: childPosition.top, left: childPosition.left };
    const anchorElementRects = anchorElement?.getBoundingClientRect();
    const childElementRects = transitionWrapper?.getBoundingClientRect();

    if (portal && anchorElementRects && childElementRects) {
      const currentChildBottomPosition = childPosition.top + childElementRects.height;
      const currentScreenBottomPosition = window.innerHeight + window.scrollY;
      const currentChildRightPosition = childPosition.left + childElementRects.width;
      const currentScreenRightPosition = window.innerWidth + window.scrollX;

      if (currentChildBottomPosition > currentScreenBottomPosition) {
        const diffOffset = currentChildBottomPosition - currentScreenBottomPosition + 16;

        position.top -= diffOffset;
      }

      if (currentChildRightPosition > currentScreenRightPosition) {
        const diffOffset = currentChildRightPosition - currentScreenRightPosition + 16;

        position.left -= diffOffset;
      }
    }

    return position;
  }, [childPosition]);

  const portalRef: RefCallback<HTMLDivElement | null> = useCallback(
    (node) => {
      const anchorElementRects = anchorEl.current?.getBoundingClientRect();

      if (node && anchorElementRects) {
        node.style.top = `${window.scrollY + anchorElementRects.top}px`;
        node.style.left = `${anchorElementRects.left}px`;

        if (contained) {
          node.style.width = `${anchorElementRects.width}px`;
        }
      }

      setPortal(node);
      setAllowedToMount(!!node);
    },
    [contained],
  );

  const childWrapperRef: RefCallback<HTMLSpanElement | null> = useCallback(
    (node) => {
      if (node?.parentElement && contained) {
        node.parentElement.style.width = `100%`;
      }

      setTransitionWrapper(node);
    },
    [contained],
  );

  const closePortalIfNecessary = useCallback(
    (node: Node) => {
      if (portal) {
        const clickedInsidePortal = portal.contains(node);
        const clickedInsideAnchorElement = anchorElement?.contains(node) ?? false;
        const shouldClosePortal = !clickedInsidePortal && !clickedInsideAnchorElement;

        if (shouldClosePortal && onRequestClose) {
          onRequestClose();
        }
      }
    },
    [onRequestClose, portal],
  );

  useLayoutEffect(() => {
    if (portal && childPositionRecalculated) {
      portal.style.top = `${childPositionRecalculated.top}px`;
      portal.style.left = `${childPositionRecalculated.left}px`;
    }
  }, [childPositionRecalculated]);

  useEffect(() => {
    if (visible) {
      clearTimeout(lastScheduledRef.current ?? undefined);

      setAnimationVisible(true);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible && animationVisible) {
      setAnimationVisible(false);
    }
  }, [visible, animationVisible, animationDuration]);

  useEffect(() => {
    if (visible) {
      const onClick = (e: MouseEvent) => closePortalIfNecessary(e.target as Node);

      document.addEventListener('click', onClick);
      return () => {
        document.removeEventListener('click', onClick);
      };
    }
  }, [visible, closePortalIfNecessary]);

  return useMemo(
    () => ({
      refs: { portalRef, childWrapperRef },
      states: { animationVisible, allowedToMount, alignment },
    }),
    [animationVisible, allowedToMount, alignment],
  );
};
