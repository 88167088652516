import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { DEFAULT_CELL_STYLE } from '../../Table.styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      '& > div': {
        ...DEFAULT_CELL_STYLE,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        height: 44,
        textAlign: 'left',
        userSelect: 'none',
        '&.table-header--sortable': {
          cursor: 'pointer',
          '&:active': {
            cursor: 'default',
          },
        },
        '& > .table-header--wrapper': {
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          '& > .table-header--icon': {
            position: 'absolute',
            right: -24,
          },
        },
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
