import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { DashboardStateStyles } from './types';

const styles = {
  root: ({ theme }) =>
    css({
      padding: 32,
      height: '100%',
      backgroundColor: theme.colors.neutral.variant[0],
      '& > div:nth-of-type(1)': {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      },
      '& > div:nth-of-type(2)': {
        marginTop: 40,
      },
    }),
} satisfies Styles<DashboardStateStyles>;

export const useStyles = withStyles(styles);
