import { keyframes } from '@emotion/core';
import { css, Styles, Theme, withStyles, extractColor } from '@recruitrobin/robin-theme/web-styles';
import { JobBoardIconSize, JobBoardIconStyleStates } from './types';
import { generateTransitionStyles } from 'utils/style';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';

export const jobBoardIconSizes: Record<JobBoardIconSize, number> = {
  small: 24,
  medium: 30,
  big: 40,
};

const isActivatingAnimation = keyframes`
  from {
    transform: rotateZ(0deg) scale(1);
  }
  50% {
    transform: rotateZ(180deg) scale(0.8);
  }
  to {
    transform: rotateZ(360deg) scale(1);
  }
`;

const styles = {
  wrapper: ({ theme, props: { size, pointer } }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: jobBoardIconSizes[size],
      height: jobBoardIconSizes[size],
      backgroundColor: theme.colors.neutral.variant[0],
      borderRadius: '50%',
      overflow: 'hidden',
      boxSizing: 'content-box',
      ...(pointer && { cursor: 'pointer' }),
    }),
  content: ({ theme, props: { size, disabled, isActivating, jobboardVariant, backgroundColor } }) =>
    css({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: jobBoardIconSizes[size],
      height: jobBoardIconSizes[size],
      color: theme.colors.neutral.variant[0],
      borderRadius: '50%',
      backgroundColor: theme.colors.extra.jobboard[jobboardMap[jobboardVariant]],
      transition: generateTransitionStyles('opacity', (d) => d.long),
      '&:hover': {
        backgroundColor: disabled
          ? theme.colors.neutral.variant[30]
          : theme.colors.extra.jobboard[jobboardMap[jobboardVariant]],
      },
      ...(backgroundColor && {
        backgroundColor: extractColor(backgroundColor),
      }),
      ...(disabled && {
        backgroundColor: theme.colors.neutral.variant[30],
      }),
      ...(isActivating && {
        animationName: isActivatingAnimation,
        animationDuration: '1000ms',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
      }),
    }),
} satisfies Styles<JobBoardIconStyleStates>;

const jobboardMap: Record<CampaignJobboard, keyof Theme['colors']['extra']['jobboard']> = {
  [CampaignJobboard.Debanensite]: 'debanensite',
  [CampaignJobboard.NationaleVacaturebank]: 'nvb',
  [CampaignJobboard.Uitzendbureau]: 'uitzendbureau',
  [CampaignJobboard.Indeed]: 'indeed',
  [CampaignJobboard.Resumeio]: 'resumeio',
  [CampaignJobboard.Werknl]: 'werknl',
  [CampaignJobboard.Jobbird]: 'jobbird',
  [CampaignJobboard.Techniekwerkt]: 'techniekwerkt',
  [CampaignJobboard.Database]: 'ats',
  [CampaignJobboard.Linkedin]: 'linkedin',
  [CampaignJobboard.Monsterboard]: 'monsterboard',
  [CampaignJobboard.StudentJob]: 'studentjob',
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // [CampaignJobboard.Werkzoeken]: 'werkzoeken',
};

export const useStyles = withStyles(styles);
