import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      'div[data-nth-column="2"], div[data-nth-column="3"]': {
        width: '200%',
      },
      'div[data-nth-column="3"]': {
        textTransform: 'unset',
        '&:first-letter': {
          textTransform: 'unset',
        },
      },
    }),
  actions: () =>
    css({
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
