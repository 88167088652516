export type Breakpoints = {
  mobile: string;
  tablet: string;
  desktop: string;
};

export const breakpoints: Breakpoints = {
  mobile: 'only screen and (max-width: 600px)',
  tablet: 'only screen and (max-width: 1011px)',
  desktop: 'only screen and (min-width: 1012px)',
};

export const cssBreakpoints = (Object.keys(breakpoints) as (keyof Breakpoints)[]).reduce<Partial<Breakpoints>>(
  (css, key) => {
    css[key] = `@media ${breakpoints[key]}`;
    return css;
  },
  {},
) as Breakpoints;
