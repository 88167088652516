import { ClassNames } from '@emotion/react';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  InputAdornment as MuiInputAdornment,
  OutlinedInput as MuiOutlinedInput,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
} from '@material-ui/core';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { useStyles } from './Select.styles';
import { ChevronDownV2 } from 'shared/icons';
import SelectChip from './SelectChip';

import {
  ChangeCallback,
  ChangeSelectCallback,
  CloseMenuCallback,
  CustomOptionType,
  GetTagPropsType,
  OptionType,
  SelectChipRef,
  SelectProps,
  SelectPropsMultiple,
} from './types';
import SelectOption from './SelectOption';
import KeypressClassToggler, { KeypressClassTogglerCallback } from 'helpers/keypressClassToggler';
import { generateDtiAttribute } from 'helpers/helpers';
import { checkboxHoveringParent } from 'shared/components/CheckboxGroup';
import { default as Transition } from 'shared/components/Transition';
import { default as InputLabelWrapper } from 'shared/components/InputLabelWrapper';
import { default as FormHelperError } from 'shared/components/FormHelperError';

const CustomPopper = ({ children, ...props }: MuiPopperProps) => {
  return (
    <MuiPopper {...props}>
      <Transition pattern="fadeGrow" visible>
        {children as ReactElement<any, any>}
      </Transition>
    </MuiPopper>
  );
};

const Select = <T extends OptionType>({
  options,
  label,
  tooltipLabel,
  placeholder,
  onChange,
  onInputChange,
  onTyping,
  value: parentValue,
  textValue,
  customOptions,
  chipMenuOptions,
  chipType = 'default',
  freeSolo = false,
  typeable = false,
  multiple = false,
  disabled = false,
  disabledOptions = false,
  contained = false,
  showArrowIcon = true,
  defaultChipValue = undefined,
  allowParentTextValue = false,
  onFocus,
  onBlur,
  onBeforeChange,
  error,
  className,
  allowUndefined = false,
  ...props
}: SelectProps<T> | SelectPropsMultiple<T>): ReactElement<any, any> => {
  const selectRef = useRef<HTMLElement | null>(null);
  const selectChipsRef = useRef<Record<string, SelectChipRef>>({});
  const [selectId, setSelectId] = useState('');
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectWasOpen, setSelectWasOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const groupEnabled = useMemo(() => options.every(({ group }) => !!group) || undefined, [options]);
  const [currentValueWhenEditingTag, setCurrentValueWhenEditingTag] = useState<OptionType[] | null>(null);

  const [_localValue, _setLocalValue] = useState<OptionType[]>([]);

  const selectVisible = options && options.length > 0 && selectOpen;
  const currentValue = (currentValueWhenEditingTag || parentValue || _localValue) as OptionType | OptionType[];
  const setValue = (((parentValue || allowParentTextValue || allowUndefined) && onChange) ||
    _setLocalValue) as ChangeCallback<OptionType | OptionType[]>;
  const formattedCustomOptions = useMemo(
    () => customOptions?.map<CustomOptionType>((opt) => ({ ...opt, isCustomOption: true })) || [],
    [customOptions],
  );

  const classes = useStyles({
    typeable,
    disabled,
    multiple,
    contained,
    showArrowIcon,
    empty: !currentValue || (Array.isArray(currentValue) && currentValue.length === 0),
    disabledOptions,
    hasError: !!error,
  });

  const selectChipCloseOthers = useCallback((chipId?: string) => {
    const chips = Object.entries(selectChipsRef.current);

    chips.forEach(([key, chip]) => key !== chipId && chip.close());
  }, []);

  const selectChipsRefItem = useCallback((e: SelectChipRef) => {
    if (e.selectChipId) {
      e.assinOnCloseCallback(selectChipCloseOthers);
      selectChipsRef.current[e.selectChipId] = e;
    }
  }, []);

  useEffect(() => {
    const onClick = () => selectChipCloseOthers();

    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    };
  }, []);

  useEffect(() => {
    const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
    setSelectId(`select-${id}`);
  }, []);

  useEffect(() => {
    const inputValueTrim = `${inputValue}`.replace(/\s+/g, '');

    if (inputValueTrim.length > 0 && !isTyping) {
      setIsTyping(true);
      onTyping?.(true);
    } else if (inputValueTrim.length === 0 && isTyping) {
      setIsTyping(false);
      onTyping?.(false);
    }
  }, [isTyping, inputValue, onTyping]);

  useEffect(() => {
    if (options && options.length > 0) {
      const handleTab = () => {
        setSelectOpen(true);
      };

      const cancelTagEditKeypressHandler = new KeypressClassToggler(
        selectRef.current as HTMLElement,
        { Tab: { onKeyUp: handleTab } },
        { disablePreventDefault: true },
      ).init();

      return () => {
        cancelTagEditKeypressHandler.dispose();
      };
    }
  }, [options]);

  useEffect(() => {
    if (!isTyping) {
      const checkIfSelectIsOpen: KeypressClassTogglerCallback = () => selectOpen && setSelectWasOpen(true);
      const openSelect: KeypressClassTogglerCallback = () => {
        const input = document.getElementById(selectId) as HTMLElement;
        if (document.activeElement !== input) return;

        if (!selectWasOpen) {
          setSelectOpen(true);
        }

        setSelectWasOpen(false);
      };

      const toggleOpenedStateKeypressHandler = new KeypressClassToggler(selectRef.current as HTMLElement, {
        Enter: { onKeyDown: checkIfSelectIsOpen, onKeyUp: openSelect },
        Space: { onKeyUp: openSelect },
      }).init();

      return () => {
        toggleOpenedStateKeypressHandler.dispose();
      };
    }
  }, [selectOpen, selectWasOpen, isTyping, selectId]);

  useEffect(() => {
    if (currentValueWhenEditingTag) {
      const handleEsc = () => {
        setCurrentValueWhenEditingTag(null);
        setInputValue('');
      };

      const cancelTagEditKeypressHandler = new KeypressClassToggler(document.body, {
        Escape: { onKeyUp: handleEsc },
      }).init();

      return () => {
        cancelTagEditKeypressHandler.dispose();
      };
    }
  }, [currentValueWhenEditingTag]);

  const handleOpen = useCallback(
    (e: React.ChangeEvent<{}> | HTMLElement | null) => {
      const { target } = (e as React.ChangeEvent<HTMLElement>) || { target: e };
      const isChipActionButton = !!target && target.closest('.select-chip--icon-wrapper');
      const isChipPopper = !!target && target.closest('.select-chip--popper');

      if (disabled || isChipActionButton || isChipPopper) return;

      setSelectOpen(true);
    },
    [disabled],
  );

  const handleClose = () => {
    setSelectOpen(false);
  };

  const handleChange: ChangeCallback<OptionType[]> = (value, reason = 'update-value') => {
    if (reason === 'edit-tag') {
      setCurrentValueWhenEditingTag(value);
    } else if (currentValueWhenEditingTag) {
      setCurrentValueWhenEditingTag(null);
    }

    if (reason === 'update-tag') {
      if (multiple) {
        const [newOption] = value;
        const currentValueClone = (currentValue as OptionType[]).map<OptionType>((props) => ({ ...props }));
        const updatedOptionIndex = currentValueClone.findIndex(({ value: v }) => v === newOption.value);

        currentValueClone[updatedOptionIndex] = newOption;

        setValue(currentValueClone);
      }
    } else if (reason === 'update-value' || reason === 'remove-value') {
      if (multiple) {
        setValue(value, reason === 'remove-value' ? 'remove-value' : undefined);
      } else {
        const [newOption] = value;

        setValue(newOption);
      }
    }
  };

  const handleChangeSelect: ChangeSelectCallback = (chipReason, _value, reason) => {
    const value =
      !!_value &&
      (Array.isArray(_value) ? [..._value] : [_value]).filter((opt: CustomOptionType) => !opt.isCustomOption);
    if (!value) return;

    if (onBeforeChange?.({ reason, value, inputValue }) === false) return;
    if (inputValue) setInputValue('');

    if (chipReason === 'edit') {
      handleChange(value, 'edit-tag');
    } else if (reason === 'create-option') {
      const newOptionTitle = (value.pop() as unknown as string).toLowerCase();
      const newOption: OptionType = {
        title: newOptionTitle,
        value: newOptionTitle,
      };

      handleChange([...value, newOption]);
    } else if (reason === 'select-option') {
      handleChange(value);
    } else if (reason === 'remove-option') {
      handleChange(value, 'remove-value');
    }
  };

  const handleChangeInputText = (onChange: ChangeEventHandler): ChangeEventHandler<HTMLInputElement> => {
    return (e) => {
      onChange(e);
      setInputValue(e.target.value);

      if (onInputChange) {
        onInputChange(e.target.value);
      }

      if (currentValueWhenEditingTag && e.target.value === '') {
        handleChange(currentValueWhenEditingTag, 'remove-value');
      }
    };
  };

  const setFocusAtInput = () => {
    const input = document.getElementById(selectId) as HTMLElement | undefined;

    input?.focus();
  };

  const handleChangeChipVariant = (onDelete: CloseMenuCallback, option: OptionType): CloseMenuCallback => {
    return (reason) => {
      if (reason === 'remove') {
        onDelete(reason);
      } else if (reason === 'edit' && typeable) {
        onDelete(reason);
        setInputValue(option.title);
        setFocusAtInput();
      } else {
        const newTagVariant =
          option.tagVariant !== reason
            ? reason === 'must-have' || reason === 'exclude'
              ? reason
              : option.tagVariant
            : defaultChipValue;

        const newOption: OptionType = {
          ...option,
          tagVariant: newTagVariant,
        };

        handleChange([newOption], 'update-tag');
      }
    };
  };

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'ArrowRight') {
      const input = document.getElementById(selectId) as HTMLElement;
      const inputWrapperChildren = Array.from(input.parentElement?.children || []);
      const tag = (e.target as HTMLElement).parentElement as HTMLElement;

      const inputIndex = inputWrapperChildren.indexOf(input);
      const tagIndex = inputWrapperChildren.indexOf(tag);

      if (inputIndex - 1 === tagIndex) {
        input.focus();
      }
    }
  };

  const handleFocus = () => {
    if (typeable) {
      onFocus?.();
    }
  };

  const handleBlur = () => {
    if (typeable) {
      onBlur?.();
    }
  };

  return (
    <div css={classes.root} className={className} {...generateDtiAttribute(props['data-tutorial-id'], 'select')}>
      <InputLabelWrapper label={label} tooltipLabel={tooltipLabel} htmlFor={selectId} disabled={disabled} />
      <span css={classes.selectWrapper}>
        <ClassNames>
          {({ css }) => (
            <MuiAutocomplete
              css={classes.inputRoot}
              classes={{
                paper: [css(classes.paper), css({ width: selectRef.current?.getBoundingClientRect().width })].join(' '),
                option: checkboxHoveringParent,
              }}
              id={selectId}
              ref={selectRef}
              onOpen={handleOpen}
              onClose={handleClose}
              onBlur={handleClose}
              open={selectVisible}
              onChange={handleChangeSelect}
              value={
                Array.isArray(currentValue)
                  ? [...currentValue, ...formattedCustomOptions]
                  : (currentValue as unknown as OptionType[])
              }
              options={options}
              groupBy={groupEnabled && ((opt) => opt.group as string)}
              getOptionLabel={(opt) => {
                return opt?.title || '';
              }}
              getOptionSelected={(opt, value) => {
                return `${opt.value}`.toLowerCase() === `${value.value}`.toLowerCase();
              }}
              inputValue={typeable ? inputValue : undefined}
              renderInput={({ InputProps: { ref, startAdornment }, ...params }) => {
                const inputPropsAsAny = params.inputProps as any;

                return (
                  <div ref={ref}>
                    <MuiOutlinedInput
                      {...{ ...params.inputProps, startAdornment }}
                      type="text"
                      endAdornment={
                        showArrowIcon && (
                          <MuiInputAdornment
                            onClick={setFocusAtInput}
                            position="end"
                            component="div"
                            className="MuiAutocomplete-endAdornment">
                            <ChevronDownV2 size={18} />
                          </MuiInputAdornment>
                        )
                      }
                      onChange={handleChangeInputText(inputPropsAsAny.onChange)}
                      placeholder={Array.isArray(currentValue) && currentValue.length > 0 ? '' : placeholder}
                      readOnly={!typeable}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      {...{ ...(allowParentTextValue && { value: textValue }), ...generateDtiAttribute('input') }}
                    />
                  </div>
                );
              }}
              renderOption={(option, state) => <SelectOption option={option} state={state} multiple={multiple} />}
              renderTags={(opts, getTagProps) => {
                return (opts as CustomOptionType[]).map((option, index) => {
                  const { onDelete, ...props } = { ...(getTagProps({ index }) as GetTagPropsType) };
                  const { isCustomOption } = option;

                  return (
                    <SelectChip
                      {...props}
                      ref={selectChipsRefItem}
                      key={`select-key-${option.title}-${index}`}
                      label={option.title}
                      variant={option.tagVariant}
                      onChange={
                        isCustomOption ? () => option.onDelete?.(option) : handleChangeChipVariant(onDelete, option)
                      }
                      onClick={isCustomOption ? () => option.onClick?.(option) : undefined}
                      type={chipType}
                      chipMenuOptions={
                        chipMenuOptions === 'all' ? ['edit', 'must-have', 'exclude', 'remove'] : chipMenuOptions
                      }
                      customChip={option.isCustomOption}
                    />
                  );
                });
              }}
              freeSolo={freeSolo}
              multiple={multiple as true}
              disableCloseOnSelect={multiple}
              disabled={disabled}
              filterSelectedOptions={false}
              forcePopupIcon={showArrowIcon}
              PopperComponent={CustomPopper}
              onKeyDown={handleKeyDown}
            />
          )}
        </ClassNames>
      </span>
      {error && <FormHelperError id={`error-${selectId}`} message={error} />}
    </div>
  );
};

export default Select;
