import { FormEvent, useEffect, useState } from 'react';
import {
  FormControl as MuiFormControl,
  FormLabel as MuiFormLabel,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core';
import { useStyles } from './RadioGroup.styles';
import { RadioGroupProps, RadioValue } from './types';
import { default as FormHelperError } from 'shared/components/FormHelperError';
import Radio from './Radio';

const convertBooleanToNumber = (value?: RadioValue) => {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }

  return value;
};

const RadioGroup = ({
  label,
  name,
  value,
  hasError,
  errorMessage,
  radioList,
  onChange,
  onBlur,
  defaultValue,
  direction = 'vertical',
  itemsPerRow = 3,
  showLabel = true,
  contained = false,
}: RadioGroupProps) => {
  const classes = useStyles({ contained, itemsPerRow, direction });
  const [radioGroupId, setRadioGroup] = useState('');

  useEffect(() => {
    const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
    setRadioGroup(`radio-group-${id}`);
  }, []);

  const handleChange = (event: FormEvent<HTMLFieldSetElement>) => {
    const cb = event.target as HTMLInputElement;
    const value: RadioValue = cb.value;

    onChange?.(value);
  };

  const handleBlur = (event: FormEvent<HTMLFieldSetElement>) => {
    const cb = event.target as HTMLInputElement;
    const value: RadioValue = cb.value;

    onBlur?.(value);
  };

  return (
    <div css={classes.root}>
      <MuiFormControl
        onChange={handleChange}
        onBlur={handleBlur}
        component="fieldset"
        error={hasError}
        aria-label={label}
        aria-invalid={hasError}
        aria-describedby={`error-${radioGroupId}`}>
        {showLabel && <MuiFormLabel component="legend">{label}</MuiFormLabel>}
        <MuiRadioGroup id={radioGroupId} name={name} defaultValue={convertBooleanToNumber(defaultValue)}>
          {radioList.map(({ value: rValue, label, disabled }) => (
            <Radio
              key={convertBooleanToNumber(rValue)}
              value={rValue}
              label={label}
              checked={value === undefined ? undefined : rValue === value}
              disabled={disabled}
              error={hasError}
            />
          ))}
        </MuiRadioGroup>
        {hasError && <FormHelperError id={`error-${radioGroupId}`} message={errorMessage} />}
      </MuiFormControl>
    </div>
  );
};

export default RadioGroup;
