import Checkbox from './Checkbox';
// @ts-ignore
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxWithUseFormProps, FieldValues } from './types';

type ControlError = { message?: string };

const CheckboxWithUseForm = <T extends FieldValues>({
  errors: parentErrors,
  control: parentControl,
  ...props
}: CheckboxWithUseFormProps<T>) => {
  const { errors = parentErrors, control = parentControl } = useFormContext() || {};
  const instanceErrors = errors?.[props.name] as ControlError;

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ onChange, value }: any) => (
        <Checkbox
          {...props}
          onChange={(e) => {
            onChange(e.target.checked);
            props.onChange?.(e);
          }}
          checked={value}
          error={!!instanceErrors}
          errorMessage={instanceErrors?.message}
        />
      )}
    />
  );
};

export default CheckboxWithUseForm;
