import { cx } from '@emotion/css';
import { SerializedStyles } from '@emotion/react';
import { forwardRef, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ContentEditableEvent, default as RContentEditable } from 'react-contenteditable';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { ActionNotPermittedException } from 'shared/exceptions/ActionNotPermittedException';
import { blobToHtmlText } from 'utils/shared/blobToHtmlText';
import { htmlToPlainText } from 'utils/shared/htmlToPlainText';
import { useStyles } from './styles';
import { ContentEditableProps } from './types';

export const CONTENT_EDITABLE_CLASS_NAME = 'rcontent-editable--input';
const ALLOWED_PASTE_IMAGE_TYPES = ['image/png', 'image/jpeg'];
const MAX_TEXT_HISTORY = 10;

type ContentEditableMemoizedProps = {
  id?: string;
  innerRef: (node: HTMLDivElement) => void;
  rootCss: SerializedStyles;
  refClassName?: string;
  refText: string;
  isEmpty: boolean;
  showPlaceholder: boolean;
  placeholderText?: string;
  handleChange: (e: ContentEditableEvent) => void;
  handleKeyDown: React.KeyboardEventHandler<HTMLDivElement>;
  handlePaste: React.ClipboardEventHandler<HTMLDivElement>;
  handleFocus: React.FocusEventHandler<HTMLDivElement>;
  handleBlur: React.FocusEventHandler<HTMLDivElement>;
  disabled?: boolean;
  maxLength?: number;
};

/**
 * This component prevents unnecessary re-renders that are an issue when using content editable in React.
 * It also fixes a bug where, when typing too fast and the "react-editablecomponent" gets out of sync with
 * the "refText", the cursor jumps to the end of the input.
 */
const ContentEditableMemoized = memo(
  ({
    id,
    innerRef,
    rootCss,
    refClassName,
    refText,
    isEmpty,
    showPlaceholder,
    placeholderText,
    handleChange,
    handleKeyDown,
    handlePaste,
    handleFocus,
    handleBlur,
    disabled,
    maxLength,
  }: ContentEditableMemoizedProps) => {
    const [, forceUpdate] = useState({});
    const lastTexts = useRef<Array<{ value: string; timestamp: number }>>([]);
    const text = useRef('');

    useEffect(() => {
      const listCleaner = () => {
        lastTexts.current = lastTexts.current.filter(({ timestamp }) => {
          const threeSeconds = 3 * 1000;
          if (Date.now() > timestamp + threeSeconds) {
            return false;
          }

          return true;
        });
      };

      const interval = setInterval(listCleaner, 500);

      return () => {
        clearInterval(interval);
      };
    }, []);

    useEffect(() => {
      if (lastTexts.current.every(({ value }) => value !== refText)) {
        addText(refText);
      }
    }, [refText]);

    const addText = (currentText: string) => {
      if (currentText.includes('content-editable--placeholder')) return;
      const rest = lastTexts.current.slice(lastTexts.current.length >= MAX_TEXT_HISTORY ? 1 : 0);

      lastTexts.current = [...rest, { value: currentText, timestamp: Date.now() }];

      text.current = currentText;
      forceUpdate({});
    };

    const handleChangeMiddleware = useCallback(
      (e: ContentEditableEvent) => {
        const text = typeof maxLength === 'number' ? e.target.value.slice(0, maxLength) : e.target.value;

        e.target.value = text;

        addText(e.target.value);

        handleChange(e);
      },
      [handleChange, maxLength],
    );

    return (
      <RContentEditable
        id={id}
        innerRef={innerRef}
        css={rootCss}
        className={cx(CONTENT_EDITABLE_CLASS_NAME, refClassName)}
        html={(isEmpty && showPlaceholder && placeholderText) || text.current}
        onChange={handleChangeMiddleware}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.id === nextProps.id &&
      prevProps.innerRef === nextProps.innerRef &&
      prevProps.rootCss.name === nextProps.rootCss.name &&
      prevProps.refClassName === nextProps.refClassName &&
      prevProps.refText === nextProps.refText &&
      prevProps.isEmpty === nextProps.isEmpty &&
      prevProps.showPlaceholder === nextProps.showPlaceholder &&
      prevProps.placeholderText === nextProps.placeholderText &&
      prevProps.handleChange === nextProps.handleChange &&
      prevProps.handleKeyDown === nextProps.handleKeyDown &&
      prevProps.handlePaste === nextProps.handlePaste &&
      prevProps.handleFocus === nextProps.handleFocus &&
      prevProps.handleBlur === nextProps.handleBlur &&
      prevProps.disabled === nextProps.disabled &&
      prevProps.maxLength === nextProps.maxLength
    );
  },
);

const ContentEditable = forwardRef<HTMLDivElement, ContentEditableProps>((props, ref) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const { createSnackbar } = useContext(SnackbarContext);
  const divRef = useRef<HTMLDivElement>();
  const propsRef = useRef<ContentEditableProps>(props);
  const isEmpty =
    !props.text.includes('<img') && htmlToPlainText(propsRef.current.text).replace(/\s+/, '').length === 0;

  const classes = useStyles({ isEmpty, isDisabled: !!props.disabled });
  const placeholderText =
    propsRef.current.placeholder && `<div class="content-editable--placeholder">${propsRef.current.placeholder}</div>`;
  propsRef.current = props;

  const handleChange = useCallback((e: ContentEditableEvent) => {
    const value =
      typeof propsRef.current.maxLength === 'number'
        ? e.target.value.substring(0, propsRef.current.maxLength)
        : e.target.value;

    if (propsRef.current.allowHtml) {
      return propsRef.current.onChange(value);
    }

    propsRef.current.onChange(htmlToPlainText(value));
  }, []);

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = useCallback((e) => {
    if (e.key === 'Enter' && !propsRef.current.allowHtml) {
      e.preventDefault();
    }
  }, []);

  const handlePaste: React.ClipboardEventHandler<HTMLDivElement> = useCallback((e) => {
    const data = e.clipboardData;
    const htmlText = data.types.includes('text/html') && data.getData('text/html');
    const plainText = data.types.includes('text/plain') && data.getData('text/plain');
    const file =
      data.types.includes('Files') &&
      [...data.items].find((item: DataTransferItem) => ALLOWED_PASTE_IMAGE_TYPES.includes(item.type));

    if (propsRef.current.allowHtml && file) {
      e.preventDefault();

      const blob = file.getAsFile();

      return (
        blob &&
        blobToHtmlText(blob, { alt: blob.name, width: '100%' }).then((htmlText) => propsRef.current.onChange(htmlText))
      );
    }

    if (!htmlText && !plainText) {
      e.preventDefault();
      return createSnackbar(new ActionNotPermittedException().message, { variant: 'danger' });
    }
  }, []);

  const handleFocus: React.FocusEventHandler<HTMLDivElement> = useCallback(() => {
    setShowPlaceholder(false);
  }, []);

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = useCallback(() => {
    setShowPlaceholder(true);
  }, []);

  const handleRef = useCallback((node: HTMLDivElement) => {
    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }

    divRef.current = node;
  }, []);

  return (
    <ContentEditableMemoized
      id={props.id}
      innerRef={handleRef}
      rootCss={classes.root}
      refClassName={propsRef.current.className}
      refText={propsRef.current.text}
      isEmpty={isEmpty}
      showPlaceholder={showPlaceholder}
      placeholderText={placeholderText}
      handleChange={handleChange}
      handleKeyDown={handleKeyDown}
      handlePaste={handlePaste}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
      disabled={props.disabled}
      maxLength={propsRef.current.maxLength}
    />
  );
});

export default ContentEditable;
