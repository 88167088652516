import { useQueryClient } from '@tanstack/react-query';
import {
  CampaignCandidateResponse,
  CampaignCandidateSaveNotesResponse,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { CandidateResponse, Source } from 'clients/CampaignsClient/CandidateResponse';
import { Candidate } from 'model';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { usePath } from 'shared/contexts/SharedCandidateContext/SharedCandidateContext.usePath';
import { ICampaign } from 'types/campaign';
import { QueryKey } from 'types/query';

export const useCandidateOrchestratorForShare = () => {
  const queryClient = useQueryClient();
  const { campaignId, candidateEsPersonId } = usePath();
  const queryKeyRef = useRef<{ campaignId: number | undefined; candidateEsPersonId: string | undefined }>({
    campaignId,
    candidateEsPersonId,
  });

  useEffect(() => {
    queryKeyRef.current = { campaignId, candidateEsPersonId };
  }, [campaignId, candidateEsPersonId]);

  /**
   * Base
   */

  const updateCurrentCandidate = useCallback((cb: (candidate: Candidate) => Candidate) => {
    queryClient.setQueryData<Candidate>(
      [QueryKey.sharedCandidateContextUseCandidate, queryKeyRef.current],
      (candidate) => {
        if (!candidate) return candidate;

        return Candidate.clone(cb(candidate));
      },
    );
  }, []);

  const changeCandidateStatus = useCallback((statKey: keyof ICampaign.Stats) => {
    updateCurrentCandidate((c) => c.mergeLocally({ status: statKey }));
  }, []);

  /**
   * Implementation
   */

  const moveCandidateTo = useMemo(
    () => ({
      shortlistList: () => changeCandidateStatus(CANDIDATE_STATUS.SHORTLISTED),
      contactList: () => changeCandidateStatus(CANDIDATE_STATUS.CONTACTED),
      matchList: () => changeCandidateStatus(CANDIDATE_STATUS.MATCHED),
      hiddenList: () => changeCandidateStatus(CANDIDATE_STATUS.HIDDEN),
    }),
    [changeCandidateStatus],
  );

  const updateLabels = useCallback(
    (cb: (labels: string[]) => string[]): string[] => {
      const labels = [] as string[];

      updateCurrentCandidate((c) => {
        labels.push(...cb(c.reasons));
        return c.mergeLocally({ reasons: labels });
      });

      return labels;
    },
    [updateCurrentCandidate],
  );

  const update = useMemo(
    () => ({
      source: (candidateSource: Partial<Source>) => updateCurrentCandidate((c) => c.mergeSource(candidateSource)),
      locally: (updatedCandidate: Partial<CampaignCandidateResponse>) =>
        updateCurrentCandidate((c) => c.mergeLocally(updatedCandidate)),
      notes: (notes: CampaignCandidateSaveNotesResponse[]) =>
        updateCurrentCandidate((c) => c.mergeNotesResponse(notes)),
      info: (candidateInfo: CandidateResponse) => updateCurrentCandidate((c) => c.setCandidateInfo(candidateInfo)),
      labels: {
        set: (labels: string | string[]) => updateLabels(() => (Array.isArray(labels) ? labels : [labels])),
        add: (labels: string | string[]) =>
          updateLabels((cl) => Array.from(new Set([...(Array.isArray(labels) ? labels : [labels]), ...cl]))),
        remove: (labels: string | string[]) => updateLabels((cl) => cl.filter((l) => !labels.includes(l))),
      },
    }),
    [updateCurrentCandidate],
  );

  const markAs = useMemo(
    () => ({
      noLongerAvailable: () => {
        updateCurrentCandidate((c) => {
          c.isAvailable = false;
          return c;
        });
      },
    }),
    [updateCurrentCandidate],
  );

  return useCallback(
    () => ({
      moveCandidateTo,
      update,
      markAs,
    }),
    [],
  );
};
