import { DialogProps } from './types';
import { useStyles } from './styles';
import { Column, Expand, Row, Typography } from 'components/ui/atoms';
import { default as Button } from 'components/ui/molecules/Button';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Check, X } from 'components/ui/atoms/icons';
import { ClipLoader } from 'react-spinners';

const Dialog = ({ labels, onCancel, onConfirm, persist = false }: DialogProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <Column css={classes.root} gap={60}>
      <Column gap={24}>
        <Row>
          <Typography variant="header.h2">{labels.title}</Typography>
        </Row>
        <Row>
          <Typography variant="body.long">{labels.description}</Typography>
        </Row>
      </Column>

      {persist ? (
        <Column alignCenter gap={0}>
          <Typography variant="body.short" color={(c) => c.byzantine[60]}>
            <ClipLoader color="currentColor" size={40} />
          </Typography>
          <Expand height={24} />
        </Column>
      ) : (
        <Row contained spaceBetween>
          {onCancel && <Button label={dictionary.cancel} variant="subtle" icon={<X />} onClick={onCancel} />}
          {onConfirm && <Button label={dictionary.yes} variant="highlight" icon={<Check />} onClick={onConfirm} />}
        </Row>
      )}
    </Column>
  );
};

export default Dialog;
