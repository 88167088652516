import { Candidate } from 'model';
import { Dispatch, SetStateAction, createContext, useMemo, useState } from 'react';

type SelectedCandidateContextProps = {
  children: React.ReactNode;
};

type SelectedCandidateState = {
  selectedCandidate: Candidate | undefined;
  setSelectedCandidate: Dispatch<SetStateAction<Candidate | undefined>>;
};

const initalState: SelectedCandidateState = {
  selectedCandidate: undefined,
  setSelectedCandidate: () => undefined,
};

export const SelectedCandidateContext = createContext(initalState);

export const SelectedCandidateContextProvider = ({ children }: SelectedCandidateContextProps) => {
  const [selectedCandidate, setSelectedCandidate] = useState<Candidate>();

  const state = useMemo<SelectedCandidateState>(
    () => ({ selectedCandidate, setSelectedCandidate }),
    [selectedCandidate],
  );

  return <SelectedCandidateContext.Provider value={state}>{children}</SelectedCandidateContext.Provider>;
};
