import { SearchCandidateParams } from './types';

/**
 * useSearchCandidateInfiniteQuery
 */

export const searchCandidateKey = ({ search = '', ...rest }: SearchCandidateParams) => [
  `useCampaignQuery-${search}`,
  rest,
];
