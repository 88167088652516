import { LOG_INFO, LoggerInfoKeys, LoggerLevels, LoggerProps } from './types';
import { withLogger } from './utils';

export class LoggerBase {
  protected debug;
  private initialized = false;
  private callLater = [] as Array<() => void>;

  constructor({ debug = false }: LoggerProps = {}) {
    this.debug = debug;
  }

  init = () => {
    this.callLater.map((callback) => callback());
    this.initialized = true;
  };

  safeCall = (callback: () => void) => {
    if (!this.initialized) {
      return this.callLater.push(callback);
    }

    callback();
  };

  log = (level: LoggerLevels, arg: string | Record<string, any>, key: LoggerInfoKeys = LOG_INFO.MANUAL_LOG) => {
    const app_version = __COMMIT_HASH__;
    const message = typeof arg === 'string' ? arg : JSON.stringify(arg);

    this.safeCall(() => {
      withLogger({ debug: this.debug, level }, newrelic.addPageAction, key, {
        message,
        app_version,
      });
    });
  };
}
