import { UserAvatarStyleStates } from './types';
import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { pictureUrl, variant } }) =>
    css({
      width: 28,
      height: 28,
      backgroundColor: variant === 'neutral' ? theme.colors.neutral.variant[20] : theme.colors.secondary.sky[10],
      color: theme.colors.neutral.variant[60],
      textTransform: 'uppercase',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      userSelect: 'none',
      cursor: 'default',
      ...(pictureUrl && {
        backgroundImage: `url('${pictureUrl}')`,
        backgroundSize: 'cover',
      }),
    }),
} satisfies Styles<UserAvatarStyleStates>;

export const useStyles = withStyles(styles);
