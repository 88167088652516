import { AnimationProps, motion } from 'framer-motion';

export const FadeInOut = ({
  children,
  className,
  duration = 500,
  ...animationProps
}: {
  children: React.ReactNode;
  className?: string;
  duration?: number;
} & AnimationProps) => {
  return (
    <motion.div
      className={className}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: duration / 1000, ease: [0.22, 0.9, 0.53, 1] }}
      {...animationProps}>
      {children}
    </motion.div>
  );
};
