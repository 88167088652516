import { QueryFunctionContext } from '@tanstack/react-query';

type GenericFunction<TParam = any, TReturn = any> = (params: TParam) => TReturn;

export const paramBind = <T extends GenericFunction>(cb: T) => {
  return ({
    queryKey: [, params],
    pageParam,
  }: QueryFunctionContext<[string, ...Parameters<T>], any>): ReturnType<T> => {
    return cb(pageParam || params);
  };
};
