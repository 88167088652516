import { Styles, css, extractTransition, withStyles } from '@recruitrobin/robin-theme/web-styles';

export const SEARCH_CHANGED_ANIMATION_DURATION = 320;

const styles = {
  root: css`
    display: flex;
    justify-content: center;

    position: absolute;
    transform: translateX(12px);
    backdrop-filter: blur(0px);
    opacity: 0;

    cursor: pointer;

    ${extractTransition((f) => f.cubic, {
      property: ['backdrop-filter', 'opacity'],
      duration: SEARCH_CHANGED_ANIMATION_DURATION,
    })}
  `,
  rootAnimated: css`
    backdrop-filter: blur(5px);
    opacity: 1;
  `,
  container: ({ theme }) => css`
    align-self: flex-start;
    max-width: 428px;
    margin-top: 30px;
    background: ${theme.colors.neutral.variant[0]};
    box-shadow:
      0px 9px 46px 8px rgba(0, 0, 0, 0.12),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 11px 15px -7px rgba(0, 0, 0, 0.2);
    padding: 48px 64px;
    border-radius: 12px;

    & > span {
      display: inline-block;
      margin-top: 10px;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
