import { NoCreditsAvailableForMonsterboardModalContentProps } from './types';
import { useStyles } from './styles';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Column, Row, Typography } from 'components/ui/atoms';
import { default as Button } from 'components/ui/molecules/Button';

const NoCreditsAvailableForMonsterboardModalContent = ({
  onCancel,
}: NoCreditsAvailableForMonsterboardModalContentProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <Column css={classes.root} gap={24}>
      <Column>
        <Typography variant="header.h3">{dictionary.noCreditsAvailableForMonsterboardTitle}</Typography>

        <Typography variant="body.long" color={(c) => c.monochrome[90]} inline>
          {dictionary.noCreditsAvailableForMonsterboardDescription()}
        </Typography>
      </Column>

      <Row spaceBetween>
        <Button label={dictionary.cancel} variant="subtle" onClick={onCancel} />
      </Row>
    </Column>
  );
};

export default NoCreditsAvailableForMonsterboardModalContent;
