export type Routes = {
  login: string;
  recoverPassword: string;
  resetPassword: string;
  multiFactorAuth: string;
  impersonate: string;
  loginTenant: string;
  resetPasswordWithCode: (userId: number, resetCode: string, tenantId: number) => string;
  activateEmail: string;
  register: string;
  jobBoards: string;
  newJobBoard: string;
  jobBoard: (id?: string | number) => string;
  atsConnections: string;
  newAtsConnection: string;
  atsConnection: (id?: string | number) => string;
  users: string;
  newUser: string;
  userMe: string;
  user: (id?: string | number) => string;
  search: (params?: { campaignId?: number | string; tab?: string; justCreatedNewCampaign?: boolean }) => string;
  sharedCandidatePage: (params?: {
    candidateId?: number | string;
    campaignId?: number | string;
    userId?: number | string;
  }) => string;
  candidatePage: (params?: {
    candidateId?: number | string;
    campaignId?: number | string;
    userId?: number | string;
  }) => string;
  browserExtension: string;
  notifications: string;
  teams: string;
  newTeam: string;
  team: (id?: string | number) => string;
  detailsTeam: (id?: string | number) => string;
  tenantEmailSettings: string;
  gtNotAvailable: string;
  robinWrapped: string;
  dashboard: string;
  emailSettings: string;
  nylasSuccess: string;
  nylasFailed: string;
  /////////////////////////////
  /// Admin routes
  /////////////////////////////
  admin: {
    root: string;
    dashboard: string;
  };
  /////////////////////////////
  /// External routes
  /////////////////////////////
  helpCenter: string;
};

export const routes: Routes = {
  login: '/',
  recoverPassword: '/Account/ForgotPassword',
  resetPassword: '/Account/ResetPassword',
  multiFactorAuth: '/Account/MultiFactorAuth',
  impersonate: '/Account/ImpersonateSignIn',
  loginTenant: '/Account/LoginTenant',
  resetPasswordWithCode: (userId, resetCode, tenantId) =>
    `/Account/ResetPassword?userId=${userId}&resetCode=${resetCode}&tenantId=${tenantId}`,
  userMe: '/Users/User/Me/',
  user: (id = ':id') => `/Users/User/${id}`,
  activateEmail: '/Account/EmailActivation',
  register: '/TenantRegistration/Register',
  jobBoards: '/Customer/JobBoards',
  newJobBoard: '/Customer/JobBoard',
  jobBoard: (id = ':id') => `/Customer/JobBoard/${id}`,
  atsConnections: '/Customer/ATS',
  newAtsConnection: '/Customer/ATS/ATS',
  atsConnection: (id = ':id') => `/Customer/ATS/ATS/${id}`,
  users: '/Customer/Users',
  newUser: '/Customer/Users/User/',
  search: (props) => {
    const { campaignId, justCreatedNewCampaign, tab } = props || {};
    const baseUrl = '/search';
    const pathUrl = [];
    const queryUrl = [];

    if (campaignId !== undefined) pathUrl.push(campaignId.toString());
    if (tab !== undefined) pathUrl.push(tab);

    if (justCreatedNewCampaign !== undefined)
      queryUrl.push(['justCreatedNewCampaign', justCreatedNewCampaign ? 'true' : 'false']);

    return (
      baseUrl +
      pathUrl.reduce((str, value) => str + `/${value}`, '') +
      queryUrl.reduce((str, [key, value], index) => (index === 0 ? `?${key}=${value}` : str + `&${key}=${value}`), '')
    );
  },
  sharedCandidatePage: ({ campaignId = ':campaignId', candidateId = ':candidateId', userId = ':userId' } = {}) =>
    `/shared/u/${userId}/s/${campaignId}/c/${candidateId}`,
  candidatePage: ({ campaignId = ':campaignId', candidateId = ':candidateId', userId = ':userId' } = {}) =>
    `/candidate/u/${userId}/s/${campaignId}/c/${candidateId}`,
  browserExtension: '/BrowserExtension',
  notifications: '/notifications',
  teams: '/teams',
  newTeam: '/teams/team',
  team: (id = ':id') => `/teams/team/${id}`,
  detailsTeam: (id = ':id') => `/teams/team/details/${id}`,
  tenantEmailSettings: '/company_email_settings',
  robinWrapped: '/wrapped',
  dashboard: '/dashboard',
  emailSettings: '/email-settings',
  nylasSuccess: '/nylas/success',
  nylasFailed: '/nylas/failed',
  /////////////////////////////
  /// Admin routes
  /////////////////////////////
  admin: {
    root: '/admin',
    dashboard: '/admin/dashboard',
  },
  /////////////////////////////
  /// External routes
  /////////////////////////////
  helpCenter: 'https://intercom.help/recruit-robin',
  gtNotAvailable: '/google-translate-not-available',
};
