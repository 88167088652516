import { Card } from 'shared/components/layouts/Card/Card';
import { useStyles } from './OAuthNylasSuccessPopup.styles';
import { Typography } from 'components/ui/atoms';
import { CheckCircle } from '@recruitrobin/robin-theme/web-icons';
import { Button } from '@recruitrobin/robin-theme/web-components';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

const OAuthNylasSuccessPopup = () => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <div css={classes.body}>
        <Card>
          <div css={classes.cardContent}>
            <div css={classes.section}>
              <div css={classes.sectionHeader}>
                <CheckCircle color={(c) => c.secondary.emerald[40]} width={38} />
                <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.neutral.variant[100]}>
                  {dictionary.theConnectionWasASuccess}
                </Typography>
              </div>

              <div css={classes.contentWrapper}>
                <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[70]}>
                  {dictionary.theConnectionWasASuccessDescription}
                </Typography>
                <div css={classes.buttonWrapper}>
                  <Button
                    onClick={() => window.close()}
                    type="highlight"
                    style="filled"
                    label={dictionary.backToRobin}
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default OAuthNylasSuccessPopup;
