import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignCandidateSaveNotesResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useSaveNotes = (params: WrappedHookParams) => {
  return useCandidateAction<SaveNotesHookCallbackParams, SaveNotesCallbackParams>(
    async ({ candidate, notes }) => {
      const { data: note } = await campaignsClient.postCampaignCandidateNotes({
        es_person_id: candidate.es_person_id,
        campaign_id: candidate.campaignId,
        notes,
      });

      return { notes: [note], isNew: true };
    },
    {
      onSuccess: ({ fcParams: { candidate }, gtm }) => {
        return async ({ candidateOrchestrator: co, pageContext, data: { notes, isNew } }) => {
          if (isNew) {
            gtm.updatedNotesTabOnCandidateCard(candidate.campaignId, candidate.es_person_id);
          }

          if (pageContext === PageContext.Campaign) {
            co.campaign.update.notes(notes);
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.update.notes(notes);
          }
        };
      },
    },
  )(params);
};

type SaveNotesCallbackParams = {
  notes: CampaignCandidateSaveNotesResponse[];
  isNew: boolean;
};

type SaveNotesHookCallbackParams = {
  notes: string;
};
