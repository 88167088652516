import { getDurationInYearsAbbr } from './getDurationInYearsAbbr';

import type { EducationDates } from 'clients/CampaignsClient/CandidateResponse';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';

export const getDuration = (dates: EducationDates) => (dictionary: Dictionary) => {
  if (!dates.end_recalculated) {
    return dictionary.current;
  }

  return getDurationInYearsAbbr(dates, dictionary);
};

export const getFullDuration = (dates: EducationDates) => (dictionary: Dictionary) => {
  const today = new Date().getFullYear() * 12 + (new Date().getMonth() + 1);
  const months = Math.max((dates?.end_recalculated || today) - (dates.start_recalculated || 1), 1);

  const years = Math.floor(months / 12);
  const leftOverMonths = months % 12;
  if (months < 12) {
    return `${months} ${months > 1 ? dictionary.months : dictionary.month}`;
  }

  if (leftOverMonths > 0) {
    return `${years} ${years > 1 ? dictionary.years : dictionary.year} ${dictionary.and} ${leftOverMonths} ${
      leftOverMonths > 1 ? dictionary.months : dictionary.month
    }`;
  }

  return `${years} ${years > 1 ? dictionary.years : dictionary.year}`;
};

export const getTotalDuration = (first: number, last: number | undefined, gap = 0) => {
  return getFullDuration({ start_recalculated: (first || 1) + gap, end_recalculated: last } as EducationDates);
};
