import { css, withStyles, Styles } from '@recruitrobin/robin-theme/web-styles';

type MultiFloatingButtonStyles = {
  menuOpen: boolean;
};

const styles = {
  filterButton: ({ theme, props: { menuOpen } }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 4,
      height: 36,
      width: 36,
      justifyContent: 'center',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      transitionProperty: 'color, border-color, background-color',
      transitionTimingFunction: 'cubic-bezier(0.22, 0.9, 0.53, 1)',
      transitionDuration: '80ms',
      color: theme.colors.neutral.variant[80],
      '&:hover': {
        borderRadius: 4,
      },
      ...(menuOpen && {
        backgroundColor: theme.colors.neutral.variant[20],
        borderRadius: 4,
      }),
    }),
  tooltip: ({ theme }) =>
    css({
      borderRadius: 4,
      backgroundColor: theme.colors.neutral.variant[80],
      color: theme.colors.neutral.variant[0],
      padding: '3px 9px 3px 9px',
      zIndex: 100,
    }),
  tooltipArrow: ({ theme }) =>
    css({
      fill: theme.colors.neutral.variant[80],
    }),
  statusFilterMenu: ({ theme }) =>
    css({
      borderRadius: 8,
      backgroundColor: theme.colors.neutral.variant[0],
      padding: 4,
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03)',
      border: `1px solid ${theme.colors.neutral.variant[10]}`,
      zIndex: 100,
      alignItems: 'center',
      display: 'grid',
      width: 'max-content',
    }),
} satisfies Styles<MultiFloatingButtonStyles>;

export const useStyles = withStyles(styles);
