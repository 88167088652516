import { useStyles } from './CampaignList.styles';
import { useContainer } from './CampaignList.useContainer';
import { CampaignListEmptyState } from './CampaignListEmptyState';
import { CampaignListInfiniteLoader } from './CampaignListInfiniteLoader';

const CampaignList = () => {
  const classes = useStyles();
  const { isEmptyState } = useContainer();

  return <div css={classes.root}>{isEmptyState ? <CampaignListEmptyState /> : <CampaignListInfiniteLoader />}</div>;
};

export default CampaignList;
