import { useStyles } from './styles';
import { forwardRef } from 'react';

import type { RowProps } from './types';

const Row = forwardRef((props: RowProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    gap = 16,
    children,
    className,
    onClick,
    onContextMenu,
    onDoubleClick,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    tabIndex,
    inline = false,
    center = false,
    alignCenter = false,
    right = false,
    left = false,
    contained = false,
    spaceBetween = false,
    displayInline = false,
    wrap = false,
    flexStart = false,
    flexEnd = false,
    alignSelfStart = false,
    alignSelfCenter = false,
    alignSelfEnd = false,
    'data-tutorial-id': dataTutorialId,
    ...dataProps
  } = props;
  const classes = useStyles({
    gap,
    inline,
    center,
    alignCenter,
    right,
    left,
    contained,
    spaceBetween,
    displayInline,
    wrap,
    flexStart,
    flexEnd,
    alignSelfStart,
    alignSelfCenter,
    alignSelfEnd,
  });

  return (
    <div
      ref={ref}
      css={classes.root}
      className={className}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDoubleClick={onDoubleClick}
      tabIndex={tabIndex}
      data-tutorial-id={dataTutorialId}
      {...dataProps}>
      {children}
    </div>
  );
});

export default Row;
