import { routes } from 'config';
import { useAuth, useFeatureToggles } from 'shared/hooks';
import { Navigate, useLocation } from 'react-router-dom';
import { RequireAuthProps } from './types';
import { Loader } from '@recruitrobin/robin-theme/web-components';

const RequireAuth = ({ children, shouldAllow: shouldAllowHandler }: RequireAuthProps) => {
  const auth = useAuth();
  const featureToggles = useFeatureToggles();
  const location = useLocation();
  const shouldAllow = shouldAllowHandler?.({ auth, featureToggles }) ?? auth.isLoggedIn;
  const { isAllowed, redirectTo = routes.search() } =
    typeof shouldAllow === 'boolean' ? { isAllowed: shouldAllow } : shouldAllow;

  if (auth.isLoading || (auth.isLoggedIn && featureToggles.isLoading)) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}>
        <Loader size={48} />
      </div>
    );
  }

  if (!auth.isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectTo} replace />;
  }

  return children;
};

export default RequireAuth;
