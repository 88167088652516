import { userClient } from 'clients/UserClient/UserClient';
import { FeatureToggle } from 'clients/UserClient/types';
import { useContext, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Button } from 'components/ui/molecules';
import { Plus } from 'shared/icons';
import { FeatureToggleRow, FeatureToggleRowSkeleton } from './components';
import { useStyles } from './FeatureTogglesModal.styles';
import { FeatureTogglesModalListProps, FeatureTogglesModalProps } from './types';
import { paramBind } from 'helpers/useQueryHelpers';
import { logger } from 'config/logger';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { default as Modal, CloseModalHandler } from 'shared/components/Modal';

const EMPTY_ARRAY: FeatureToggle[] = [];

const FeatureTogglesModalList = ({ tenantId, onDiscart, onSave }: FeatureTogglesModalListProps) => {
  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { createSnackbar } = useContext(SnackbarContext);

  const classes = useStyles();
  const [featureTogglesCreated, setFeatureTogglesCreated] = useState<FeatureToggle[]>([]);
  const [featureTogglesChanged, setFeatureTogglesChanged] = useState<FeatureToggle[]>([]);
  const [featureTogglesRemoved, setFeatureTogglesRemoved] = useState<FeatureToggle[]>([]);
  const [saveLoading, setSaveLoading] = useState(false);
  const { data: { data: featureToggles = EMPTY_ARRAY } = {}, isFetching } = useQuery(
    ['featureToggles-admin', { tenant_id: tenantId }],
    paramBind(userClient.getFeatureToggles),
  );

  const featureTogglesResponseWithLocalId = useMemo(() => {
    const ftSaved: FeatureToggle[] = [];
    const ftUnsaved: FeatureToggle[] = [];

    featureToggles.forEach((ft) => {
      if (ft.id === null) {
        ftUnsaved.push({ ...ft, id: -(Date.now() + Math.random()) });
      } else {
        ftSaved.push(ft);
      }
    });

    setFeatureTogglesCreated(ftUnsaved);
    return ftSaved;
  }, [featureToggles]);

  const allVisibleFeatureToggles = useMemo(() => {
    const oldFtVisible = featureTogglesResponseWithLocalId.reduce((fts, ftResponseWithLocalId) => {
      if (featureTogglesRemoved.some((ft) => ft.id === ftResponseWithLocalId.id)) {
        return fts;
      }

      const ftChanged = featureTogglesChanged.find((ft) => ft.id === ftResponseWithLocalId.id);
      if (ftChanged) {
        return [...fts, ftChanged];
      }

      return [...fts, ftResponseWithLocalId];
    }, [] as FeatureToggle[]);

    return [...oldFtVisible, ...featureTogglesCreated];
  }, [featureTogglesChanged, featureTogglesCreated, featureTogglesRemoved, featureTogglesResponseWithLocalId]);

  const handleAddFeatureToggle = () => {
    const featureToggle: FeatureToggle = {
      id: -(Date.now() + Math.random()),
      name: 'new_feature_toggle',
      value: '',
      enabled: true,
    };

    setFeatureTogglesCreated([...featureTogglesCreated, featureToggle]);
  };

  const handleChangeFeatureToggle = (featureToggle: FeatureToggle) => {
    if (isFeatureToggleLocal(featureToggle)) {
      return setFeatureTogglesCreated([
        ...featureTogglesCreated.map((ft) => (ft.id === featureToggle.id ? featureToggle : ft)),
      ]);
    }

    setFeatureTogglesChanged([...featureTogglesChanged.filter((ft) => ft.id !== featureToggle.id), featureToggle]);
  };

  const handleRemoveFeatureToggle = (featureToggle: FeatureToggle) => {
    if (isFeatureToggleLocal(featureToggle)) {
      return setFeatureTogglesCreated([...featureTogglesCreated.filter((ft) => ft.id !== featureToggle.id)]);
    }

    setFeatureTogglesRemoved([...featureTogglesRemoved, featureToggle]);
  };

  const handleSave = () => {
    if (featureTogglesCreated.length + featureTogglesChanged.length + featureTogglesRemoved.length === 0) return;

    setSaveLoading(true);
    const createFeatureToggleRequests = featureTogglesCreated.map((ft) =>
      userClient.createFeatureToggles({ ...ft, id: null, tenant_id: tenantId }),
    );
    const updateFeatureToggleRequests = featureTogglesChanged.map((ft) =>
      userClient.updateFeatureToggles({ ...ft, id: ft.id as number }),
    );
    const removeFeatureToggleRequests = featureTogglesRemoved.map((ft) => userClient.deleteFeatureToggles(ft.id!));

    Promise.all([...createFeatureToggleRequests, ...updateFeatureToggleRequests, ...removeFeatureToggleRequests])
      .then(() => {
        createSnackbar(dictionary.changesSaved);
      })
      .catch((e) => {
        logger.error(e);
        createSnackbar(dictionary.somethingWentWrong, { variant: 'danger' });
      })
      .finally(() => {
        queryClient.invalidateQueries(['featureToggles-admin', { tenant_id: tenantId }]).then(() => {
          setFeatureTogglesCreated([]);
          setFeatureTogglesChanged([]);
          setFeatureTogglesRemoved([]);
          setSaveLoading(false);
          onSave?.();
        });
      });
  };

  return (
    <div css={classes.listRoot}>
      <div css={classes.listWrapper}>
        {isFetching
          ? Array.from({ length: 4 }).map((_, i) => <FeatureToggleRowSkeleton key={i} />)
          : allVisibleFeatureToggles.map((featureToggle) => (
              <FeatureToggleRow
                key={featureToggle.id}
                featureToggle={featureToggle}
                onChange={handleChangeFeatureToggle}
                onRemove={handleRemoveFeatureToggle}
              />
            ))}
      </div>
      <div css={classes.addFeatureToggleWrapper}>
        <Button icon={<Plus />} variant="subtle" label={dictionary.addFeatureToggle} onClick={handleAddFeatureToggle} />
      </div>
      <div css={classes.actions}>
        <Button label={dictionary.cancel} variant="subtle" onClick={onDiscart} />
        <Button label={dictionary.saveChanges} variant="highlight" onClick={handleSave} loading={saveLoading} />
      </div>
    </div>
  );
};

const FeatureTogglesModal = ({ visible, onClose, tenantId }: FeatureTogglesModalProps) => {
  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  const handleClose: CloseModalHandler = (opt) => {
    if (opt !== 'success') {
      queryClient.invalidateQueries(['featureToggles-admin']);
      onClose(opt);
    }
  };

  return (
    <Modal css={classes.root} title={dictionary.featureToggles} visible={visible} onClose={handleClose} hideActions>
      <FeatureTogglesModalList
        tenantId={tenantId}
        onDiscart={() => handleClose('cancel')}
        onSave={() => handleClose('success')}
      />
    </Modal>
  );
};

const isFeatureToggleLocal = (featureToggle: FeatureToggle) => {
  return typeof featureToggle.id === 'number' && featureToggle.id < 0;
};

export default FeatureTogglesModal;
