import { ConfirmJobboardCreditUsage, Modal } from 'components/ui/molecules';
import NoCreditsAvailableForMonsterboardModalContent from 'components/ui/molecules/NoCreditsAvailableForMonsterboardModalContent';
import { ShouldInstallBrowserExtensionDialog } from 'components/ui/molecules/ShouldInstallBrowserExtensionDialog/ShouldInstallBrowserExtensionDialog';
import { capitalize } from 'lodash';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { NoCreditsAvailableForMonsterboard } from 'shared/exceptions';
import { createContext, useContextSelector } from 'use-context-selector';
import { CandidateOutreachModalContext } from '../CandidateOutreachModalContext';
import { useActions } from './actions';
import { isLoadingInitialState as actionsIsLoadingInitialState } from './actions/useActions';
import { useShouldUnlockModal } from './actions/useShouldUnlockModal';
import { CandidateEmailSettingsContextProvider } from './CandidateEmailSettingsContext';
import { useCandidateEffects } from './effects';
import { useOutreach } from './outreach';
import { useProps } from './props';
import { initialState as searchv3InitialState, useSearchV3Candidate } from './searchv3/useSearchV3Candidate';
import { CandidateContextActions, CandidateContextProps, CandidateContextProviderProps } from './types';

const initialState: CandidateContextProps = {
  candidate: null as any,
  candidateEsPersonId: '',
  candidateName: '',
  campaignId: undefined,
  notObfuscatedPhone: undefined,
  notObfuscatedEmail: undefined,
  isNoLongerAvailable: false,
  isAlreadyInteracted: false,
  activeJobboard: undefined,
  labels: [],
  availableActions: {
    actions: [],
    extraOptions: {},
  },
  onUnlock: () => {
    throw '[CandidateContext - onUnlock] Provide CandidateContextProvider';
  },
  onSaveLabels: () => {
    throw '[CandidateContext - onSaveLabels] Provide CandidateContextProvider';
  },
  onApprove: () => {
    throw '[CandidateContext - onApprove] Provide CandidateContextProvider';
  },
  onContact: () => {
    throw '[CandidateContext - onContact] Provide CandidateContextProvider';
  },
  onCallMobileByPushNotification: () => {
    throw '[CandidateContext - onCallMobileByPushNotification] Provide CandidateContextProvider';
  },
  onShortlist: () => {
    throw '[CandidateContext - onShortlist] Provide CandidateContextProvider';
  },
  onSaveNotes: () => {
    throw '[CandidateContext - onSaveNotes] Provide CandidateContextProvider';
  },
  onShare: () => {
    throw '[CandidateContext - onShare] Provide CandidateContextProvider';
  },
  onResendAts: () => {
    throw '[CandidateContext - onResendAts] Provide CandidateContextProvider';
  },
  onReject: () => {
    throw '[CandidateContext - onReject] Provide CandidateContextProvider';
  },
  onMakeInvisible: () => {
    throw '[CandidateContext - onMakeInvisible] Provide CandidateContextProvider';
  },
  onRefresh: () => {
    throw '[CandidateContext - onRefresh] Provide CandidateContextProvider';
  },
  onLoadNotes: () => {
    throw '[CandidateContext - onLoadNotes] Provide CandidateContextProvider';
  },
  onSetActiveJobBoard: () => {
    throw '[CandidateContext - onSetActiveJobBoard] Provide CandidateContextProvider';
  },
  onRequestCV: () => {
    throw '[CandidateContext - onRequestCV] Provide CandidateContextProvider';
  },
  outreachMethods: {
    shouldEnableCall: false,
    shouldEnableEmail: false,
    shouldEnableWhatsapp: false,
    shouldEnableLinkedin: false,
    shouldEnableLinkedinPro: false,
    shouldEnableIndeed: false,
    shouldEnableWerknl: false,
  },
  availableOutreachMethods: [],
  outreachInformationWhyNotEnabled: {
    whyLinkedinNotEnabled: undefined,
    whyLinkedinProNotEnabled: undefined,
    whyIndeedNotEnabled: undefined,
    whyEmailNotEnabled: undefined,
    whyWhatsappNotEnabled: undefined,
    whyCallNotEnabled: undefined,
    whyWerknlNotEnabled: undefined,
    disabledError: {
      linkedin: undefined,
      indeed: undefined,
      email: undefined,
      whatsapp: undefined,
      call: undefined,
      werknl: undefined,
    },
  },
  personalizationTokens: {
    '{firstName}': '',
    '{fullName}': '',
    '{lastCompany}': '',
    '{lastName}': '',
    '{lastPosition}': '',
    '{recruiterFullName}': '',
    '{nameJobboard}': '',
    '{nameVacancy}': '',
    '{location}': '',
  },
  isLoading: actionsIsLoadingInitialState,
  shouldShowRequestContactInfo: false,
  lastRefresh: '',
  cvFile: undefined,
  searchv3: searchv3InitialState,
  setShouldShowInstallBrowserExtensionModal: () => {
    throw '[CandidateContext - setShouldShowInstallBrowserExtensionModal] Provide CandidateContextProvider';
  },
  setShouldShowOutreachModal: () => {
    throw '[CandidateContext - setShouldShowOutreachModal] Provide CandidateContextProvider';
  },
};

export const CandidateContext = createContext<CandidateContextProps>(initialState);

export const CandidateContextProvider = memo(
  ({ candidate, children, config }: CandidateContextProviderProps) => {
    const updateCandidateOutreachModal = useContextSelector(CandidateOutreachModalContext, (s) => s.updateCandidate);
    const setCandidateOutreachModal = useContextSelector(
      CandidateOutreachModalContext,
      (s) => s.setCandidateOutreachModal,
    );
    /// TODO WERKZOEKEN: Remove this after release new jobboard
    // const { hasMonsterboardV2License, hasWerkzoekenLicense } = useCandidateContextMemoized();
    const { hasMonsterboardV2License, currentUser } = useCandidateContextMemoized();
    const [shouldShowModalOfNoCreditsAvailableForMonsterboard, setShouldShowModalOfNoCreditsAvailableForMonsterboard] =
      useState(false);

    const [shouldShowInstallBrowserExtensionModal, setShouldShowInstallBrowserExtensionModal] = useState(false);

    /// TODO WERKZOEKEN: Remove this after release new jobboard
    const shouldShowRequestContactInfo =
      (hasMonsterboardV2License && candidate?.activeJobBoard === 'monsterboard_v2' && !candidate.isUnlocked) || false;
    // const shouldShowRequestContactInfo =
    //   ((hasMonsterboardV2License && candidate?.activeJobBoard === 'monsterboard_v2') ||
    //     (hasWerkzoekenLicense && candidate?.activeJobBoard === 'werkzoeken' && !candidate.isUnlocked)) ??
    //   false;

    const props = useProps(candidate);
    const outreach = useOutreach(candidate);
    const actions = useActions(candidate, {
      shouldConfirmUnlockIntention: shouldShowRequestContactInfo,
    });
    const shouldUnlockModal = useShouldUnlockModal(actions);
    const searchv3Candidate = useSearchV3Candidate(candidate, { actions, config });

    useCandidateEffects(candidate, { actions });

    const onUnlockWithCheckIfUserHasCredits: CandidateContextActions['onUnlock'] = useCallback(
      async (params) => {
        return actions.onUnlock(params).catch((error) => {
          if (error instanceof NoCreditsAvailableForMonsterboard) {
            setShouldShowModalOfNoCreditsAvailableForMonsterboard(true);
          }

          return Promise.resolve(false);
        });
      },
      [actions.onUnlock, props.activeJobboard],
    );

    const setShouldShowOutreachModal: CandidateContextProps['setShouldShowOutreachModal'] = useCallback(
      (type) => {
        if (typeof type === 'function') {
          return setCandidateOutreachModal((old) => {
            const newType = type(old?.type ?? null);

            if (newType && candidate) {
              return { actions, candidate, type: newType, currentUser, tokens: outreach.personalizationTokens };
            }

            return null;
          });
        }

        if (type && candidate) {
          setCandidateOutreachModal({ type, candidate, actions, currentUser, tokens: outreach.personalizationTokens });
        }
      },
      [currentUser, actions, candidate, outreach.personalizationTokens],
    );

    useEffect(() => {
      updateCandidateOutreachModal(candidate);
    }, [updateCandidateOutreachModal, candidate]);

    const valueMemoized = useMemo<CandidateContextProps>(() => {
      return {
        candidate,
        searchv3: searchv3Candidate,
        config,

        shouldShowRequestContactInfo,
        candidateEsPersonId: props.candidateEsPersonId,
        candidateName: props.candidateName,
        campaignId: props.campaignId,
        notObfuscatedPhone: props.notObfuscatedPhone,
        notObfuscatedEmail: props.notObfuscatedEmail,
        labels: props.labels,
        email: props.email,
        phone: props.phone,
        activeJobboard: props.activeJobboard,
        lastRefresh: props.lastRefresh,
        isNoLongerAvailable: props.isNoLongerAvailable,
        isAlreadyInteracted: props.isAlreadyInteracted,

        outreachMethods: outreach.outreachMethods,
        availableOutreachMethods: outreach.availableOutreachMethods,
        personalizationTokens: outreach.personalizationTokens,
        outreachInformationWhyNotEnabled: outreach.outreachInformationWhyNotEnabled,

        availableActions: actions.availableActions,
        isLoading: actions.isLoading,
        onContact: actions.onContact,
        onCallMobileByPushNotification: actions.onCallMobileByPushNotification,
        onApprove: actions.onApprove,
        onShortlist: actions.onShortlist,
        onUnlock: onUnlockWithCheckIfUserHasCredits,
        onSaveLabels: actions.onSaveLabels,
        onSaveNotes: actions.onSaveNotes,
        onShare: actions.onShare,
        onResendAts: actions.onResendAts,
        onReject: actions.onReject,
        onMakeInvisible: actions.onMakeInvisible,
        onRefresh: actions.onRefresh,
        onLoadNotes: actions.onLoadNotes,
        onSetActiveJobBoard: actions.onSetActiveJobBoard,
        onRequestCV: actions.onRequestCV,
        cvFile: actions.cvFile,

        setShouldShowInstallBrowserExtensionModal,
        setShouldShowOutreachModal,
      };
    }, [
      candidate,
      searchv3Candidate,
      shouldShowRequestContactInfo,
      props,
      outreach,
      actions,
      onUnlockWithCheckIfUserHasCredits,
      config,
    ]);

    return (
      <>
        <CandidateContext.Provider value={valueMemoized}>
          <CandidateEmailSettingsContextProvider>{children}</CandidateEmailSettingsContextProvider>
        </CandidateContext.Provider>

        <Modal visible={shouldUnlockModal.showJobboardCreditUsageModal} hideCloseButton>
          <ConfirmJobboardCreditUsage
            onCancel={() => shouldUnlockModal.onRequestCloseConfirmUnlockModal(false)}
            onConfirm={() => shouldUnlockModal.onRequestCloseConfirmUnlockModal(true)}
            onChangeShouldSkipNextTime={shouldUnlockModal.setShouldSkipJobboardModalVerificationNextTime}
            platform={capitalize(candidate?.activeJobBoard) ?? ''}
          />
        </Modal>

        <Modal visible={shouldShowModalOfNoCreditsAvailableForMonsterboard} hideCloseButton>
          <NoCreditsAvailableForMonsterboardModalContent
            onCancel={() => setShouldShowModalOfNoCreditsAvailableForMonsterboard(false)}
          />
        </Modal>

        <ShouldInstallBrowserExtensionDialog
          isVisible={shouldShowInstallBrowserExtensionModal}
          onConfirm={() => setShouldShowInstallBrowserExtensionModal(false)}
        />
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.candidate === nextProps.candidate &&
    prevProps.config?.candidateDetails.initialTab === nextProps.config?.candidateDetails.initialTab,
);

const useCandidateContextMemoized = () => {
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  const { hasMonsterboardV2License, currentUser } = useContext(UserContext);
  const { tenant, email, phone_number } = currentUser || {};
  // const { hasMonsterboardV2License, hasWerkzoekenLicense } = useContext(UserContext);

  const currentUserInfo = useMemo(
    () => ({
      email: email ?? '',
      phone: phone_number ?? '',
      website: tenant?.privacy_policy_link ?? '',
    }),
    [tenant?.code, email, phone_number],
  );

  /// TODO WERKZOEKEN: Remove this after release new jobboard
  return useMemo(
    () => ({
      hasMonsterboardV2License,
      currentUser: currentUserInfo,
    }),
    [hasMonsterboardV2License, currentUserInfo],
  );
  // return useMemo(
  //   () => ({ hasMonsterboardV2License, hasWerkzoekenLicense }),
  //   [hasMonsterboardV2License, hasWerkzoekenLicense],
  // );
};
