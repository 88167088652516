import { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ClipLoader } from 'react-spinners';
import { routes } from 'config';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Button } from 'components/ui/molecules';

import { useStyles } from './LoginTenant.styles';
import { paramBind } from 'helpers/useQueryHelpers';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContainer } from './useContainer';
import { userClient } from 'clients/UserClient/UserClient';
import { SmallLanguageSwitch } from 'shared/components/localization/SmallLanguageSwitch/SmallLanguageSwitch';
import { RobinLogo } from '@recruitrobin/robin-theme/web-icons';
import { Column, Expand, Typography } from 'components/ui/atoms';

export const LoginTenant = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const tokenId = params.get('tokenId') || '';
  const legacyCampaignId = params.get('assignmentId');
  const campaignId = params.get('campaignId') || 0;
  const {
    functions: { onSuccess },
  } = useContainer();

  const { dictionary } = useContext(LocalizationContext);

  const { data: { data: auth = undefined } = {}, isLoading } = useQuery(
    ['loginTenant', { login_secret: tokenId }],
    paramBind(userClient.loginTenant),
    { enabled: Boolean(tokenId), retry: 1 },
  );

  const isValid = auth?.access_token;

  const classes = useStyles();

  useEffect(() => {
    if (auth?.access_token) {
      onSuccess(auth.access_token, legacyCampaignId, campaignId);
    }
  }, [auth, legacyCampaignId, campaignId]);

  return (
    <div css={classes.root}>
      <Column alignCenter center>
        <RobinLogo width={220} />
        <Expand height={50} />
        <Typography variant={(v) => v.heading[2]} colorv2={(c) => c.neutral.variant[80]}>
          {isLoading
            ? dictionary.loading
            : isValid
              ? dictionary.loggingYouInAutomatically
              : dictionary.couldNotLogYouInAutomatically}
        </Typography>
        {isLoading && (
          <div>
            <ClipLoader color="currentColor" size={18} />
          </div>
        )}
        {isLoading && !isValid && (
          <>
            <Expand height={50} />
            <Button onClick={() => navigate(routes.login)} variant="highlight" label={dictionary.goToLogin} />
          </>
        )}
        <SmallLanguageSwitch />
      </Column>
    </div>
  );
};
