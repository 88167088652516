import { TextAreaProps } from './types';
import { useStyles } from './styles';
import { useCallback, useEffect, useRef } from 'react';

const TextArea = ({
  resize,
  className,
  maxHeight,
  onChange,
  onEnterKeyDown,
  maxLength,
  value,
  ...textAreaProps
}: TextAreaProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const classes = useStyles();

  const resizeTextArea = useCallback(
    (textArea: HTMLTextAreaElement) => {
      if (resize !== 'auto') return;
      textArea.style.height = 'auto';

      const scrollHeight = textArea.scrollHeight;
      const height = typeof maxHeight === 'number' && scrollHeight > maxHeight ? maxHeight : scrollHeight;

      textArea.style.height = `${height}px`;
    },
    [resize, maxHeight],
  );

  const handleInput = useCallback<React.FormEventHandler<HTMLTextAreaElement>>(
    (e) => {
      const textArea = e.currentTarget;
      const textAreaValue = textArea.value.substring(0, maxLength);

      onChange(textAreaValue);
    },
    [resizeTextArea, onChange],
  );

  const handleKeyDown = useCallback<React.KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (e.key === 'Enter') onEnterKeyDown?.(e);
    },
    [onEnterKeyDown],
  );

  useEffect(() => {
    const textArea = textAreaRef.current;

    if (textArea) {
      resizeTextArea(textArea);
    }
  }, [value]);

  return (
    <textarea
      {...textAreaProps}
      ref={textAreaRef}
      css={classes.root}
      className={className}
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      value={value}
    />
  );
};

export default TextArea;
