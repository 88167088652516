import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  divider: ({ theme }) =>
    css({
      width: 1,
      backgroundColor: theme.colors.neutral.variant[30],
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
