import { cssBreakpoints } from 'styles/breakpoints';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      padding: 24,
      fontFamily: '"Outfit", sans-serif',
      minWidth: 500,
      backgroundColor: theme.colors.neutral.variant[0],
      [cssBreakpoints.mobile]: {
        width: '100%',
        height: '100vh',
        minWidth: 320,
        padding: 16,
      },
    }),
  title: () =>
    css({
      marginBottom: 24,
    }),
  form: () =>
    css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }),
  row: () =>
    css({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      marginBottom: 8,
    }),
  ctas: () =>
    css({
      display: 'flex',
      marginTop: 16,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    }),
  close: ({ theme }) =>
    css({
      position: 'absolute',
      top: 24,
      right: 24,
      cursor: 'pointer',
      color: theme.colors.neutral.variant[80],
      [cssBreakpoints.mobile]: {
        top: 16,
        right: 16,
      },
    }),
  error: ({ theme }) =>
    css({
      color: theme.colors.tertiary.red[80],
      fontSize: 12,
      margin: 8,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
