import { tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { UserResponse } from 'clients/UserClient/types';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { TablePagination } from 'shared/components/Table/components';
import { LogIn } from 'shared/icons';
import { useStyles } from './ImpersonateModal.styles';
import { ImpersonateModalProps } from './types';
import { paramBind } from 'helpers/useQueryHelpers';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as Modal } from 'shared/components/Modal';
import { default as TextField } from 'shared/components/TextField';

const USERS_PER_PAGE = 12;

const ImpersonateModal = ({ visible, onClose, tenantId }: ImpersonateModalProps) => {
  const classes = useStyles();
  const { impersonate } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [userToImpersonate, setUserToImpersonate] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [allUsers, setAllUsers] = useState<UserResponse[]>([]);
  const [users, setUsers] = useState<UserResponse[]>([]);
  const { data: response = null, isLoading } = useQuery(
    [tenantClientCacheKeys.getUsers, tenantId],
    paramBind(tenantClient.getUsers),
    { enabled: visible },
  );

  useEffect(() => {
    if (response?.data) {
      setAllUsers(response.data.filter(({ is_active }) => is_active));
    }
  }, [response]);

  useEffect(() => {
    setUsers(Array.from(allUsers).splice((page - 1) * USERS_PER_PAGE, USERS_PER_PAGE));
  }, [allUsers, page]);

  useEffect(() => {
    if (userToImpersonate) {
      tenantClient.impersonateUser({ user_id: userToImpersonate }).then(({ data: response }) => {
        impersonate(response.access_token).then(() => {
          setUserToImpersonate(null);
        });
      });
    }
  }, [impersonate, userToImpersonate]);

  const handleSearch = (text: string) => {
    if (response?.data) {
      setAllUsers(
        response.data
          .filter(({ is_active }) => is_active)
          .filter(({ email }) => email.toLowerCase().includes(text.toLowerCase())),
      );
    }
  };

  return (
    <Modal title={dictionary.impersonateUser} visible={visible} onClose={onClose}>
      <div css={classes.root}>
        <div css={classes.search}>
          <TextField label={dictionary.search} onChange={handleSearch} contained />
        </div>
        <div css={classes.usersWrapper}>
          {users.map(({ id, email }) => (
            <div key={id} css={classes.row}>
              <div>
                <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
                  {email}
                </Typography>
              </div>
              <div>
                <Button
                  variant="subtle"
                  label={dictionary.impersonate}
                  onClick={() => setUserToImpersonate(id)}
                  loading={userToImpersonate === id}
                  icon={<LogIn size={18} />}
                  disabled={isLoading || userToImpersonate !== null}
                />
              </div>
            </div>
          ))}
        </div>
        <TablePagination
          perPage={USERS_PER_PAGE}
          visibleButtons={3}
          total={allUsers.length}
          currentPage={page}
          onChange={setPage}
          loading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default ImpersonateModal;
