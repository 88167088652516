import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  actions: () =>
    css({
      marginTop: '5px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    }),

  connectEmailAccountModal: () =>
    css({
      maxWidth: '600px',
    }),
  emailAccountModalTitle: () =>
    css({
      display: 'flex',
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
