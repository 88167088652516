import { CheckboxProps } from './types';
import { useStyles } from './styles';
import { Check } from '@recruitrobin/robin-theme/web-icons';
import { Typography } from '@recruitrobin/robin-theme/web-components';

const Checkbox = ({ checked, onChange, label }: CheckboxProps) => {
  const classes = useStyles({ checked });

  return (
    <span css={classes.root} onClick={() => onChange?.(!checked)}>
      <label>
        <span css={classes.label} className="cb-label">
          <span css={classes.iconWrapper}>
            <Check size={10} />
          </span>
        </span>
        {label && (
          <Typography variant={(v) => v.body.short} color={(c) => c.neutral.variant[80]}>
            {label}
          </Typography>
        )}
      </label>
    </span>
  );
};

export default Checkbox;
