import { injectScripts } from 'config/injectScripts';
import 'index.css';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import nl from 'javascript-time-ago/locale/nl';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

TimeAgo.addLocale(en);
TimeAgo.addLocale(nl);

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  injectScripts();

  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
