import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import { initialSearchListContext, useProps } from './props/useProps';
import { SearchCandidateContextProps, SearchCandidateContextProviderProps } from './types';

const initialState: SearchCandidateContextProps = {
  searchListContext: initialSearchListContext,
  setSearchListContext: () => {
    throw '[SearchCandidateContext - setSearchListContext] Provide SearchCandidateContextProvider';
  },
};

export const SearchCandidateContext = createContext<SearchCandidateContextProps>(initialState);

export const SearchCandidateContextProvider = ({ children }: SearchCandidateContextProviderProps) => {
  const props = useProps();

  const valueMemoized = useMemo<SearchCandidateContextProps>(() => {
    return {
      searchListContext: props.searchListContext,
      setSearchListContext: props.setSearchListContext,
    };
  }, [props]);

  return <SearchCandidateContext.Provider value={valueMemoized}>{children}</SearchCandidateContext.Provider>;
};
