import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${theme.colors.neutral.variant[0]};
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
