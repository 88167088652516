import { InformationTagProps } from './types';
import { useStyles } from './styles';
import { Row, Typography } from 'components/ui/atoms';
import { forwardRef, useCallback, useMemo } from 'react';

import * as Icons from 'components/ui/atoms/icons';
import { Theme } from '@recruitrobin/robin-theme/web-styles';
import { XClose } from '@recruitrobin/robin-theme/web-icons';

const InformationTag = forwardRef(
  (
    {
      icon,
      label,
      onClick,
      onDelete,
      size = 'default',
      type = 'default',
      uppercase,
      shouldHover = false,
      shouldWrap = false,
    }: InformationTagProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const hasLabel = label !== null && label !== undefined;
    const hasIcon = Boolean(icon);

    const classes = useStyles({
      size,
      type,
      hasLabel,
      hasIcon,
      uppercase,
      clickable: !!onClick,
      removeable: !!onDelete,
      isDigit: (typeof label === 'string' && label.length < 2) || (typeof label === 'number' && label < 10),
      shouldHover,
      shouldWrap,
    });
    const getTypographyVariant = useCallback(
      (font: Theme['fonts']) => {
        if (hasLabel && size === 'small') return font.supporting['label-tiny'];

        return font.supporting.label;
      },
      [hasLabel, hasIcon, size],
    );

    const iconSize = useMemo<number>(() => {
      if (hasLabel && hasIcon) {
        if (size === 'small') {
          return 14;
        }

        return 16;
      }

      if (hasIcon) {
        if (size === 'small') {
          return 12;
        }

        return 14;
      }

      return 16;
    }, [hasLabel, hasIcon, size]);

    const rowGap = useMemo<number>(() => {
      if (hasLabel && hasIcon && size === 'small') {
        return 3;
      }

      return 6;
    }, [hasLabel, hasIcon, size]);

    return (
      <Row ref={ref} css={[classes.root, classes[size]]} gap={rowGap} onClick={onClick} inline center alignCenter>
        {icon &&
          (typeof icon === 'string' ? Icons[icon as Icons.IconKey]({ color: 'currentColor', size: iconSize }) : icon)}
        {hasLabel && (
          <span className="ellipsis">
            <Typography css={classes.label} variant={getTypographyVariant}>
              {label}
            </Typography>
          </span>
        )}
        {onDelete && (
          <div css={classes.deleteIcon} onClick={(e) => [e.stopPropagation(), onDelete()]}>
            <XClose size={16} />
          </div>
        )}
      </Row>
    );
  },
);

export default InformationTag;
