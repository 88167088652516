import { memo, ReactElement } from 'react';
import { DefaultTableDataObject, TableProps } from './types';
import { useStyles } from './Table.styles';
import { TableHeader, TablePagination } from './components';
import { Typography } from 'components/ui/atoms';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { cx } from '@emotion/css';
import { nthColumn } from './helpers/attachAttributes';

const Table = <T extends DefaultTableDataObject>({
  data,
  headerConfig,
  paginationConfig,
  columnsToShow = undefined,
  extraColumns = [],
  mapRowProp = {},
  loading = false,
  bodyHeight,
}: TableProps<T>): ReactElement<any, any> => {
  const paginationConfigPerPage = paginationConfig.perPage || 10;
  const classes = useStyles({ loading, bodyHeight });

  const TableRowSkeleton = memo<ListChildComponentProps<unknown[]>>(({ index, style }) => {
    return (
      <div key={`t-l-tr-${index}`} css={classes.row} style={style}>
        {Array.from({ length: headerConfig.headers.length }).map((_, i) => (
          <div key={`t-l-td-${i}`} {...nthColumn(i)}>
            <MuiSkeleton width="80%" height={32} />
          </div>
        ))}
        {Array.from({ length: extraColumns.length }).map((_, i) => (
          <div key={`t-l-td-ec-${i}`} {...nthColumn(headerConfig.headers.length + i)}>
            <MuiSkeleton width={64} height={32} />
          </div>
        ))}
      </div>
    );
  });

  const TableRow = memo<ListChildComponentProps<T[]>>(({ data, index, style }) => {
    const row = data[index];

    return (
      <div key={row.id} css={classes.row} style={style} className={cx(index % 2 !== 0 && 'even')}>
        {Object.entries(row).map(
          ([columnKey, columnValue], i) =>
            (!columnsToShow || columnsToShow.includes(columnKey as any)) && (
              <div key={`${row.id}.${columnKey}`} {...nthColumn(i)}>
                <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
                  {Object.keys(mapRowProp).includes(columnKey)
                    ? (mapRowProp as any)[columnKey](columnValue)
                    : columnValue}
                </Typography>
              </div>
            ),
        )}
        {extraColumns.map((column, i) => (
          <div key={`${row.id}.extraColumn.${i}`} {...nthColumn(Object.entries(row).length + i)}>
            {column(row)}
          </div>
        ))}
      </div>
    );
  });

  return (
    <div css={classes.root}>
      <div>
        <div css={classes.table}>
          <div css={classes.thead}>
            <TableHeader {...headerConfig} emptyHeaders={extraColumns.length} loading={loading} />
          </div>
          <div css={classes.tbody}>
            <AutoSizer>
              {({ width, height }) =>
                loading ? (
                  <FixedSizeList
                    itemSize={44}
                    height={height}
                    width={width}
                    itemCount={paginationConfigPerPage}
                    itemData={Array.from({ length: paginationConfigPerPage })}>
                    {TableRowSkeleton}
                  </FixedSizeList>
                ) : (
                  <FixedSizeList itemSize={44} height={height} width={width} itemCount={data.length} itemData={data}>
                    {TableRow}
                  </FixedSizeList>
                )
              }
            </AutoSizer>
          </div>
        </div>
      </div>
      <div css={classes.pagination}>
        <TablePagination {...paginationConfig} loading={loading} />
      </div>
    </div>
  );
};

export default Table;
