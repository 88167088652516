import { BaseClient } from 'clients/BaseClient/BaseClient';

import { hosts } from 'config';
import { AtsVacancyHandler, GetCampaignNameHandler } from './types';

export class AutoCompleteClient extends BaseClient {
  constructor() {
    super();
    this.jobTitle = this.jobTitle.bind(this);
    this.country = this.country.bind(this);
    this.getSimilarJobTitles = this.getSimilarJobTitles.bind(this);
  }

  getCampaignName: GetCampaignNameHandler = (query) => {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Assignment/GetCampaignSuggestions`,
      method: 'GET',
      params: { query },
    });
  };

  atsVacancy: AtsVacancyHandler = (searchTerm) => {
    return this.request({
      url: `${hosts.atsGatewayApi()}/jobs/`,
      method: 'GET',
      ...(searchTerm && {
        params: {
          starts_with: searchTerm,
        },
      }),
    });
  };

  async criteria(query: string) {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Assignment/GetAutocomplete`,
      method: 'GET',
      params: {
        search: query,
      },
    });
  }

  async jobTitle(query: string) {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Assignment/GetJobTitleSuggestionDropdown`,
      method: 'GET',
      params: {
        searchTerm: query,
        pageSize: 10,
      },
    });
  }

  async getSimilarJobTitles(SearchTerm: string, TakenJobTitles: string[]) {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Assignment/GetJobTitleSuggestions`,
      method: 'GET',
      params: {
        TakenJobTitles,
        SearchTerm,
      },
    });
  }

  async country(key: string, { query = '', limit = 10 }) {
    return this.request({
      url: `${hosts.rrLegacyApi()}/api/services/app/Country/GetCountryDropdown`,
      method: 'GET',
      params: {
        searchTerm: query,
        pageSize: limit,
      },
    });
  }
}

export const autoCompleteClient = new AutoCompleteClient();
