import { ExpandProps } from './types';
import { useStyles } from './Expand.styles';

const Expand = ({ className, width = 0, height = 0, children }: ExpandProps) => {
  const classes = useStyles();

  return (
    <div css={classes.root} className={className} style={{ width, height }}>
      {children}
    </div>
  );
};

export default Expand;
