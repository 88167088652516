import { searchClient } from 'clients/SearchClient/SearchClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useRemoveCandidateMatch = (params: WrappedHookParams) => {
  return useCandidateAction<RemoveCandidateMatchHookCallbackParams>(
    async ({ candidate }) => {
      if (candidate.activeJobBoard === candidate.mainJobboard) {
        throw new Error('Mainjobboard cannot be removed');
      }

      await searchClient.candidateRemoveMatch({
        es_person_id: candidate.es_person_id,
      });
    },
    {
      onSuccess: () => {
        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (pageContext === PageContext.Campaign) {
            co.campaign.remove.currentJobBoard();
            co.campaign.markAs.active();
            co.campaign.load.mainJobBoardInfo();
          }
        };
      },
    },
  )(params);
};

type RemoveCandidateMatchHookCallbackParams = {
  campaignId?: number;
};
