import { Course } from 'clients/CampaignsClient/CandidateResponse';

export const getLastCourses = (_courses: Course[], length?: number) => {
  const courses = _courses.slice(0, length);

  return courses.map((course) => {
    return {
      name: course.name,
    };
  });
};
