import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { InsightBadgeStyleStates } from './types';

const styles = {
  root: css``,
  insightBadge: ({ props: { isTooltip } }) =>
    css({
      ...(isTooltip && { width: 18, height: 18 }),
      ...(!isTooltip && { fontSize: '13px' }),
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      background: 'red',
      padding: isTooltip ? '3px' : '0px 8px 0px 3px',
    }),
  new: ({ theme }) =>
    css({
      background: theme.colors.secondary.emerald[10],
      color: theme.colors.secondary.emerald[60],
    }),
  updated: ({ theme }) =>
    css({
      background: theme.colors.secondary.sky[10],
      color: theme.colors.secondary.sky[50],
    }),
  icon: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    width: 22px;
  `,
} satisfies Styles<InsightBadgeStyleStates>;

export const useStyles = withStyles(styles);
