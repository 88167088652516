/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */

import request from 'axios';

const THROTTLE_TIME = 30 * 1000;

const getVersion = async () => {
  const { data } = await request({
    url: `${window.location.origin}/static/version.txt?st=${Date.now()}`,
    method: 'GET',
  });

  return data;
};

export const versionCheckerInterval = (
  callback: (params: { version: { current: string; server: string } }) => void,
) => {
  const interval = setInterval(async () => {
    const serverVersion = await getVersion();
    const serverVersionHash = serverVersion.match(/\[(.+)\]/)?.[1];

    if (serverVersionHash !== __COMMIT_HASH__) {
      callback({ version: { current: __COMMIT_HASH__, server: serverVersionHash } });
      clearInterval(interval);
    }
  }, THROTTLE_TIME);

  return interval;
};
