import { useQuery } from '@tanstack/react-query';
import { searchClient } from 'clients/SearchClient/SearchClient';
import { Candidate } from 'model';
import { useMemo, useRef } from 'react';
import { QueryKey } from 'types/query';
import { CampaignTab } from './SelectedCampaignContext.types';

export const useCandidateMatches = ({ candidates, tab }: { candidates: Candidate[]; tab: CampaignTab }) => {
  const previousHashRef = useRef<string>(generateHash(candidates));
  const previousCandidatesRef = useRef<Candidate[]>(candidates);
  const ids = candidates.map((c) => ({ key: c.key, id: c.es_person_id, contact_info: c.originalContactInfo }));

  const hash = getHash(
    { previousHash: previousHashRef.current, previousCandidates: previousCandidatesRef.current },
    candidates,
  );

  const { data, isFetching } = useQuery(
    [QueryKey.selectedCampaignContextUseCandidateMatches, { hash, tab }],
    async () => {
      const response = await searchClient.getMatchCandidates(ids);

      previousHashRef.current = hash;
      previousCandidatesRef.current = candidates;

      return response.data as Record<string, string[] | undefined>;
    },
    { enabled: candidates.length > 0, keepPreviousData: true, cacheTime: 0 },
  );

  return useMemo(() => ({ matches: data || {}, isLoading: isFetching }), [data, isFetching]);
};

const generateHash = (candidates: Candidate[]) =>
  candidates
    .map((c) => c.es_person_id)
    .sort()
    .join(';;');

const getHash = (
  { previousHash, previousCandidates }: { previousHash: string; previousCandidates: Candidate[] },
  candidates: Candidate[],
) => {
  if (previousCandidates === candidates) {
    return previousHash;
  }

  const previousCandidatesIds = previousCandidates.reduce(
    (acc, c) => ({ ...acc, [c.es_person_id]: true }),
    {} as Record<string, boolean>,
  );

  if (candidates.every((c) => previousCandidatesIds[c.es_person_id])) {
    return generateHash(previousCandidates);
  }

  return generateHash(candidates);
};
