import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

export const createProject: CreateProjectHandler = async ({ name, csrfToken }) => {
  const data = {
    projectName: name,
  };

  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: `https://resumes.indeed.com/api/v1/hiring/projects/?source=projectDashboardPage&indeedcsrftoken=${csrfToken}`,
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'content-type': 'application/json;charset=UTF-8' },
  });

  return JSON.parse(response.body);
};

export type CreateProjectResponse = {
  projectKey: string;
  projectName: string;
  statistics: {
    totals: {
      precise: boolean;
      total: 0;
      saved: 0;
      contacted: 0;
      replied: 0;
      interested: 0;
      declined: 0;
      rejected: 0;
      offerExtended: 0;
      offerDeclined: 0;
      hired: 0;
      interviewRequested: 0;
      interviewRsvp: 0;
      interviewDeclined: 0;
      interviewRescheduleRequested: 0;
      interviewPast: 0;
      recommendations: 0;
    };
    updates: {
      precise: boolean;
      total: 0;
      saved: 0;
      contacted: 0;
      replied: 0;
      interested: 0;
      declined: 0;
      rejected: 0;
      offerExtended: 0;
      offerDeclined: 0;
      hired: 0;
      interviewRequested: 0;
      interviewRsvp: 0;
      interviewDeclined: 0;
      interviewRescheduleRequested: 0;
      interviewPast: 0;
      recommendations: 0;
    };
  };
  sharing: null;
  created: {
    modifier: {
      accountKey: string;
      email: string;
      modifierType: string;
    };
    timestamp: string;
  };
  lastModified: {
    modifier: {
      accountKey: string;
      email: string;
      modifierType: string;
    };
    timestamp: string;
  };
  labels: any[];
  customLabels: any[];
  specialModes: null;
  archivalState: {
    archived: boolean;
    reason: {
      id: string;
      name: string;
    };
  };
  jobs: null;
  recommendationSetting: {
    activities: any[];
    enabled: boolean;
    emailNotificationEnabled: boolean;
  };
  description: '';
  permissions: any[];
  links: any[];
  snapshotTimestamp: null;
  default: boolean;
};

type CreateProjectHandler = (params: { name: string; csrfToken: string }) => Promise<CreateProjectResponse>;
