import { InfiniteData, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignOverviewResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { PaginatedResponse } from 'clients/types';
import { useContext, useMemo } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { QueryKey } from 'types/query';
import { CampaignManagerCampaigns, CampaignManagerState } from './CampaignManagerContext.types';

const PAGINATION_LENGTH = 20;

export const useCampaigns = ({
  status,
  teamview,
  name,
}: Pick<CampaignManagerState['filters'], 'status' | 'teamview' | 'name'>) => {
  const { currentUser } = useContext(UserContext);
  const removeCampaignFromOverview = useRemoveCampaignFromOverview({ status, teamview, name });
  const markCampaignAsRead = useMarkCampaignAsRead({ status, teamview, name });
  const markCampaignMessagesAsRead = useMarkCampaignAsRead({ status, teamview, name, markMessagesAsRead: true });

  const {
    data: campaigns,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery<PaginatedResponse<CampaignOverviewResponse>>(
    [QueryKey.campaignManagerContextUseCampaigns, { status, teamview, name }],
    async ({ pageParam }) => {
      const { offset, limit } = pageParam ?? getNextPageParam();

      const { data } = await campaignsClient.getCampaignsOverview({
        status,
        limit,
        offset,
        name: name,
        ...(teamview ? {} : { user_id: currentUser?.id }),
      });

      return data;
    },
    { getNextPageParam, enabled: teamview ? true : currentUser !== undefined },
  );

  const { campaignsFlatten, isLoading, count } = useMemoizedCampaigns({
    campaigns,
    isFetching,
    isFetchingNextPage,
  });

  return useMemo<CampaignManagerCampaigns>(
    () => ({
      data: campaignsFlatten,
      fetchNextPage: async () => {
        if (isFetchingNextPage || !hasNextPage) return undefined;

        await fetchNextPage();
      },
      hasNextPage,
      isLoading,
      isFetchingNextPage,
      count,
      removeCampaignFromOverview,
      markCampaignAsRead,
      markCampaignMessagesAsRead,
    }),
    [campaignsFlatten, hasNextPage, isLoading, count, isFetchingNextPage],
  );
};

const getNextPageParam = (
  lastPage?: PaginatedResponse<CampaignOverviewResponse>,
  allPages?: PaginatedResponse<CampaignOverviewResponse>[],
) => {
  if (!lastPage) {
    return { limit: PAGINATION_LENGTH, offset: 0 };
  }

  if (!lastPage.next) return false;

  const loadedPagesCount = allPages?.length ?? 1;

  return { limit: PAGINATION_LENGTH, offset: loadedPagesCount * PAGINATION_LENGTH };
};

const useMemoizedCampaigns = ({
  isFetching,
  isFetchingNextPage,
  campaigns,
}: {
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  campaigns?: InfiniteData<PaginatedResponse<CampaignOverviewResponse>>;
}) => {
  const isLoading = (isFetching || isFetchingNextPage) ?? true;
  const count = campaigns?.pages?.[0].count;

  const campaignsFlatten = useMemo(() => campaigns?.pages.flatMap(({ results }) => results) || [], [campaigns]);

  return { campaignsFlatten, isLoading, count };
};

const useRemoveCampaignFromOverview = ({
  status,
  teamview,
  name,
}: Pick<CampaignManagerState['filters'], 'status' | 'teamview' | 'name'>) => {
  const queryClient = useQueryClient();

  return async (id: number) => {
    queryClient.setQueryData([QueryKey.campaignManagerContextUseCampaigns, { status, teamview, name }], (data: any) => {
      return {
        pageParams: data.pageParams,
        pages: data.pages.map((page: any) => {
          return {
            ...page,
            results: page.results.filter((campaignResult: any) => campaignResult.id !== id),
            count: page.count - 1,
          };
        }),
      };
    });

    await queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns], { exact: false });

    return queryClient.getQueryData<InfiniteData<PaginatedResponse<CampaignOverviewResponse>>>([
      QueryKey.campaignManagerContextUseCampaigns,
      { status, teamview, name },
    ]);
  };
};

const useMarkCampaignAsRead = ({
  status,
  teamview,
  name,
  markMessagesAsRead,
}: Pick<CampaignManagerState['filters'], 'status' | 'teamview' | 'name'> & { markMessagesAsRead?: boolean }) => {
  const queryClient = useQueryClient();

  return async (id: number, numberNonReadMessages?: number) => {
    queryClient.setQueryData<InfiniteData<PaginatedResponse<CampaignOverviewResponse>>>(
      [QueryKey.campaignManagerContextUseCampaigns, { status, teamview, name }],
      (data) => {
        if (!data) return data;

        const body = markMessagesAsRead ? { new_replies_count: numberNonReadMessages } : { new_candidates_count: 0 };
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page: any) => {
            return {
              ...page,
              results: page.results.map((campaignResult: any) =>
                campaignResult.id !== id ? campaignResult : { ...campaignResult, ...body },
              ),
            };
          }),
        };
      },
    );
  };
};
