import { Suspense, useState } from 'react';
import { AnimateSuspenseProps } from './AnimateSuspense.types';

export const AnimateSuspense = ({ children, fallback }: AnimateSuspenseProps) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {fallback({ isVisible: isLoading })}
      <Suspense fallback={<div ref={(ref) => setIsLoading(!!ref)} />}>
        {!isLoading && <div ref={(ref) => setIsLoading(!ref)} />}
        {children}
      </Suspense>
    </>
  );
};
