/* eslint-disable @typescript-eslint/no-unused-vars*/
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { ConfirmUnlockIntentionResolver } from '../types';

export const useShouldUnlockModal: UseShouldUnlockModal = ({
  confirmUnlockModalShouldBeVisible,
  confirmUnlockIntentionResolverRef,
}) => {
  const [showJobboardCreditUsageModal, setShowJobboardCreditUsageModal] = useState(false);
  const [shouldSkipMonsterboardModalVerificationNextTime, setShouldSkipJobboardModalVerificationNextTime] =
    useState(false);
  const {
    askBeforeSpendingMonsterInventoryCredit,
    // askBeforeSpendingWerkzoekenCredit,
    updateUserJobBoardSettingsProfile,
  } = useContext(UserContext);

  useEffect(() => {
    if (confirmUnlockModalShouldBeVisible) {
      /// TODO WERKZOEKEN: Remove this after release new jobboard
      if (askBeforeSpendingMonsterInventoryCredit) {
        // if (askBeforeSpendingMonsterInventoryCredit || askBeforeSpendingWerkzoekenCredit) {
        setShowJobboardCreditUsageModal(confirmUnlockModalShouldBeVisible);
      } else {
        onRequestCloseConfirmUnlockModal(true);
      }

      return () => setShowJobboardCreditUsageModal(false);
    }
  }, [confirmUnlockModalShouldBeVisible]);

  const onRequestCloseConfirmUnlockModal = useCallback(
    async (confirm: boolean) => {
      if (confirm && shouldSkipMonsterboardModalVerificationNextTime) {
        try {
          await updateUserJobBoardSettingsProfile?.({
            never_ask_before_spending_monster_inventory_credit: false,
            /// TODO WERKZOEKEN: Remove this after release new jobboard
            // never_ask_before_spending_werkzoeken_credit: false,
          });
        } catch (e) {
          console.error(e);
        }
      }

      confirmUnlockIntentionResolverRef.current.resolve(confirm);
    },
    [shouldSkipMonsterboardModalVerificationNextTime],
  );

  return useMemo(
    () => ({
      showJobboardCreditUsageModal,
      onRequestCloseConfirmUnlockModal,
      setShouldSkipJobboardModalVerificationNextTime,
    }),
    [showJobboardCreditUsageModal, onRequestCloseConfirmUnlockModal],
  );
};

type UseShouldUnlockModal = (params: {
  confirmUnlockModalShouldBeVisible: boolean;
  confirmUnlockIntentionResolverRef: React.MutableRefObject<ConfirmUnlockIntentionResolver>;
}) => {
  showJobboardCreditUsageModal: boolean;
  onRequestCloseConfirmUnlockModal: (confirm: boolean) => Promise<void>;
  setShouldSkipJobboardModalVerificationNextTime: React.Dispatch<React.SetStateAction<boolean>>;
};
