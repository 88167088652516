import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  form: () =>
    css({
      padding: '32px 0',
      '& > *:not(:last-child)': {
        paddingBottom: 16,
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
