import { Row } from 'components/ui/atoms';
import { Zap } from 'components/ui/atoms/icons';
import { Button } from 'components/ui/molecules';
import { routes, shouldShowRobinWrapped } from 'config';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { default as Logo } from 'shared/components/Logo';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

import SearchInput from '../SearchInput';
import KnowledgeBaseButton from './components/KnowledgeBaseButton';
import { NotificationsBell } from './components/NotificationsBell/NotificationsBell';
import { UserMenu } from './components/UserMenu/UserMenu';
import { useStyles } from './styles';
import { NavBarSidebarSpacer } from './NavBarSidebarSpacer';
import { NewMessageReplies } from './components/NewMessageReplies/NewMessageReplies';

export const NavBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Row css={classes.root} alignCenter left spaceBetween>
      <div css={classes.logoContainer}>
        <div css={classes.buttons}>
          <Logo variant={'text'} />
          {shouldShowRobinWrapped() && (
            <Button
              variant="highlight"
              icon={<Zap />}
              label={<u>{dictionary.robinYearWrapped({ year: new Date().getFullYear() })}</u>}
              onClick={() => [navigate(routes.robinWrapped)]}
            />
          )}
        </div>
        <NavBarSidebarSpacer />
      </div>

      <SearchInput />

      <Row css={classes.controlsContainer} alignCenter>
        <NewMessageReplies />
        <NotificationsBell />
        <KnowledgeBaseButton />
        <div css={classes.divider} />
        <UserMenu />
      </Row>
    </Row>
  );
};
