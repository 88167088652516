import { useContext, useState } from 'react';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@material-ui/core';

import { routes } from 'config';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Column, Expand, Typography } from 'components/ui/atoms';
import { Button } from 'components/ui/molecules';
import { SmallLanguageSwitch } from 'shared/components/localization/SmallLanguageSwitch/SmallLanguageSwitch';
import { CheckCircle } from 'shared/icons/index';

import { useStyles } from './PasswordRecovery.styles';
import { FormFields } from './PasswordRecovery.types';
import { userClient } from 'clients/UserClient/UserClient';
import { TextFieldWithUseForm } from 'shared/components/TextField';
import { RobinLogo } from '@recruitrobin/robin-theme/web-icons';

export const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const { dictionary } = useContext(LocalizationContext);

  const methods = useForm<FormFields>({
    defaultValues: {
      email: '',
    },
  });

  const { handleSubmit, reset, setError } = methods;

  const onSubmit = (values: FormFields) => {
    try {
      userClient.sendPasswordResetCode(values).then(() => {
        setShowDialog(true);
        reset();
      });
    } catch (error) {
      setError('email', { type: 'error', message: dictionary.invalidEmailAddress });
    }
  };

  const classes = useStyles();

  return (
    <div css={classes.root}>
      <Column alignCenter center>
        <RobinLogo width={220} />
        <Expand height={50} />
        <div css={classes.title}>
          <Typography variant={(v) => v.heading[1]} colorv2={(c) => c.neutral.variant[80]}>
            {dictionary.forgotPassword}
          </Typography>
        </div>
        <div css={classes.description}>
          <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[60]}>
            {dictionary.forgotPasswordDescription}
          </Typography>
        </div>
        <FormProvider {...methods}>
          <form css={classes.form} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <TextFieldWithUseForm
              label={dictionary.emailAddress}
              name="email"
              type="email"
              rules={{ required: dictionary.required }}
              contained
            />
            <hr />
            <div css={classes.ctas}>
              <Button onClick={() => navigate(routes.login)} variant="subtle" label={dictionary.back} />
              <Button variant="highlight" label={dictionary.resetPassword} submit />
            </div>
          </form>
        </FormProvider>
        <SmallLanguageSwitch />
        <Dialog open={showDialog} onClose={() => [setShowDialog(false), navigate(routes.login)]}>
          <div css={classes.popup}>
            <div css={classes.popupIcon}>
              <CheckCircle variant="tertiary" />
            </div>
            <div css={classes.popupTitle}>{dictionary.mailSent}</div>
            <div css={classes.popupDescription}>{dictionary.resetPasswordMailSentDescription}</div>
          </div>
        </Dialog>
      </Column>
    </div>
  );
};
