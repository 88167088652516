import { useQuery } from '@tanstack/react-query';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { queryClient } from 'config/queryClient';
import { isAxiosError } from 'helpers/clientHelpers';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { AccountStatus, type UseContainerProps } from './CandidateEmailSettingsContext.types';

export const useContainer = ({ onClosePopup }: UseContainerProps = {}) => {
  const { currentUser } = useContext(UserContext);
  const [currentEmailStatus, setCurrentEmailStatus] = useState(AccountStatus.NonExistent);

  const { data: url } = useQuery([`${currentUser?.email}-oauth-nylas-url`], notificationsClient.connectEmailAccount);

  const oauthUrl = url?.data;

  const openOAuthPopup = useCallback(() => {
    const oauthPopup = window.open(oauthUrl, 'popup', 'popup=true,resizable=1,width=600,height=600');

    const timer = setInterval(async function () {
      if (oauthPopup?.closed) {
        clearInterval(timer);
        await queryClient.resetQueries([`${currentUser?.email}-oauth-nylas-status`]);

        if (onClosePopup) {
          onClosePopup();
        }
      }
    }, 1000);
  }, [oauthUrl]);

  const {
    data: emailStatus,
    error: errorEmailStatus,
    isLoading: isLoadingEmailStatus,
  } = useQuery([`${currentUser?.email}-oauth-nylas-status`], notificationsClient.connectedEmailAccountStatus, {
    retry: false,
  });

  useEffect(() => {
    if (errorEmailStatus && isAxiosError(errorEmailStatus) && errorEmailStatus.response?.status === 404) {
      setCurrentEmailStatus(AccountStatus.NonExistent);
    }

    if (emailStatus?.status === 200) {
      if (emailStatus?.data.status === 'valid') {
        setCurrentEmailStatus(AccountStatus.Valid);
      }

      if (emailStatus?.data.status === 'invalid') {
        setCurrentEmailStatus(AccountStatus.Invalid);
      }
    }
  }, [emailStatus, errorEmailStatus]);

  return useMemo(() => {
    return {
      currentEmailStatus,
      isLoadingEmailStatus,
      openOAuthPopup,
    };
  }, [currentEmailStatus, isLoadingEmailStatus, openOAuthPopup]);
};
