import { ContentEditableStyleStates } from './types';
import { withStyles, Styles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { isDisabled } }) =>
    css({
      '& .content-editable--placeholder': {
        color: theme.colors.neutral.variant[40],

        ...(isDisabled && {
          color: theme.colors.neutral.variant[30],
        }),
      },
    }),
} satisfies Styles<ContentEditableStyleStates>;

export const useStyles = withStyles(styles);
