import { useStyles } from './FeatureToggleRow.styles';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';

const FeatureToggleRowSkeleton = () => {
  const classes = useStyles();

  return (
    <div css={classes.skeleton}>
      <div className="skeleton--remove-button">
        <MuiSkeleton variant="text" height="100%" width="100%" />
      </div>
      <div className="skeleton--input">
        <MuiSkeleton variant="text" height={19.3} width="75%" />
        <MuiSkeleton variant="text" height={24.3} width="100%" />
      </div>
      <div className="skeleton--input">
        <MuiSkeleton variant="text" height={19.3} width="75%" />
        <MuiSkeleton variant="text" height={24.3} width="100%" />
      </div>
      <div className="skeleton--checkbox">
        <MuiSkeleton variant="text" height={19.3} width={25} />
        <MuiSkeleton variant="text" height={24.3} width={38} />
      </div>
    </div>
  );
};

export default FeatureToggleRowSkeleton;
