import { forwardRef } from 'react';

import { useStyles } from './Card.styles';
import { Props } from './Card.types';

export const Card = forwardRef(({ rounded = true, ...props }: Props, ref) => {
  const classes = useStyles({ rounded });

  return (
    <div ref={ref} css={classes.root} {...props}>
      {props.children}
    </div>
  );
});
