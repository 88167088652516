import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_STATUS } from 'services/candidates';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useShortlist = (params: WrappedHookParams) => {
  return useCandidateAction<ShortlistHookCallbackParams>(
    async ({ candidate, campaignId }) => {
      await campaignsClient.createOrUpdateCampaignCandidate({
        campaignId: campaignId ?? candidate.campaignId,
        esPersonId: candidate.es_person_id,
        status: CANDIDATE_STATUS.SHORTLISTED,
        is_unlocked: candidate.isUnlocked,
      });
    },
    {
      onSuccess: ({ fcParams: { candidate, campaignId }, gtm }) => {
        gtm.shortlistCandidate(campaignId ?? candidate.campaignId, candidate.es_person_id);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (!campaignId) {
            if (pageContext === PageContext.Campaign) {
              if (candidate.status === CANDIDATE_STATUS.NEW) {
                co.campaign.decreaseStatOf.currentJobBoard();
              }

              co.campaign.remove.fromCurrentList();
              co.campaign.reset.shortlistList();
              co.campaign.decreaseStatOf.currentList();
              co.campaign.increaseStatOf.shortlistList();
            } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
              co.share.moveCandidateTo.shortlistList();
            }
          }
        };
      },
    },
  )(params);
};

type ShortlistHookCallbackParams = {
  campaignId?: number;
};
