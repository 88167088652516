import { ContextMenuStyleStates } from './types';
import { withStyles, css, Styles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props: { contained } }) =>
    css({
      padding: '6px 0',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[10],
      borderRadius: 8,
      background: theme.colors.neutral.variant[0],
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
      ...(contained && { width: '100%' }),
    }),
  optionsWrapper: ({ props: { maxHeight } }) =>
    css({
      ...(maxHeight && {
        maxHeight,
        overflow: 'hidden scroll',
      }),
    }),
} satisfies Styles<ContextMenuStyleStates>;

export const useStyles = withStyles(styles);
