import { default as Column } from 'components/ui/atoms/Column';
import { default as ContentEditable } from 'components/ui/atoms/ContentEditable';
import { default as Row } from 'components/ui/atoms/Row';
import { default as Typography } from 'components/ui/atoms/Typography';
import { generateClassname } from 'helpers/helpers';
import { forwardRef, useRef, type ChangeEvent } from 'react';
import { default as FormHelperError } from 'shared/components/FormHelperError';
import { useStyles } from './styles';
import { TextAreaProps } from './types';

export const TEXT_AREA_HINT = 'text-area-hint';

const TextArea = forwardRef(
  (
    {
      text,
      onChange,
      errorLabel,
      hintLabel,
      placeholder,
      className,
      maxLength,
      disabled = false,
      allowHtml = false,
      contained = false,
      expand = false,
      sanitizeInput = false,
    }: TextAreaProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const textAreaIdRef = useRef(generateClassname());
    const classes = useStyles({ contained, hasError: !!errorLabel, isDisabled: disabled });

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const input = event.target;

      if (sanitizeInput) {
        const cursorPosition = input.selectionStart;

        const cleanedValue = input.value.replace(/[^a-zA-Z0-9\s&*():;'",.!\?\{\}@-]/g, '');

        if (input.value !== cleanedValue) {
          input.value = cleanedValue;
          input.setSelectionRange(cursorPosition, cursorPosition - 1);
        }
      }

      onChange(input.value);
    };

    return (
      <Column gap={6} css={classes.root} className="ola" inline contained={contained} expand={expand}>
        <Column gap={8} contained={contained} expand={expand}>
          {allowHtml ? (
            <ContentEditable
              ref={ref}
              id={textAreaIdRef.current}
              css={classes.input}
              className={className}
              text={text}
              onChange={onChange}
              allowHtml={allowHtml}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
            />
          ) : (
            <textarea
              id={textAreaIdRef.current}
              css={classes.input}
              className={className}
              value={text}
              onChange={(e) => handleChange(e)}
              placeholder={placeholder}
              disabled={disabled}
            />
          )}
          {typeof maxLength === 'number' && (
            <Row right>
              <Typography variant="supporting.helper">
                {text.length}/{maxLength}
              </Typography>
            </Row>
          )}
        </Column>

        {hintLabel && !errorLabel && (
          <Row gap={8}>
            <Typography variant="supporting.helper" className={TEXT_AREA_HINT}>
              {hintLabel}
            </Typography>
          </Row>
        )}

        {errorLabel && <FormHelperError id={textAreaIdRef.current} message={errorLabel} />}
      </Column>
    );
  },
);

export default TextArea;
