import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 342px;
  `,
  overlayHr: ({ theme }) => css`
    height: 1px;
    background-color: ${theme.colors.neutral.variant[30]};
    margin: 8px 12px;
  `,
  campaignListRoot: css`
    width: 100%;
    height: 100%;
    min-height: 0;
  `,
  body: css`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 0;

    padding: 0 6px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
