import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  fileSearchIcon: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[0]};
    color: ${theme.colors.neutral.variant[80]};
    padding: 4px;
    border-radius: 16px;
    border: 1px solid ${theme.colors.neutral.variant[10]};
    height: calc(100% - 72px);
    max-height: 100vh;
    box-shadow: 0px 0px 5px 3px rgba(67, 83, 98, 0.15);
    z-index: 100;
  `,
  fileSearchIconArrow: ({ theme }) => css`
    fill: ${theme.colors.neutral.variant[0]};
    top: 10px !important;
  `,
  tooltip: css`
    background-color: rgb(47, 62, 76);
    color: rgb(255, 255, 255);
    border-radius: 20px;
    padding: 3px 9px;
    z-index: 1000;
    display: flex;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
