import { motion } from 'framer-motion';

export const Grow = ({
  children,
  className,
  animate = true,
  duration = 500,
}: {
  children: React.ReactNode;
  className?: string;
  animate?: boolean;
  duration?: number;
}) => {
  return (
    <motion.div
      className={className}
      initial={animate ? { width: '0' } : { width: '100%' }}
      animate={{ width: '100%' }}
      exit={animate ? { width: '0' } : { width: '100%' }}
      transition={{ duration: duration / 1000 }}>
      {children}
    </motion.div>
  );
};
