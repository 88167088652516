import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  form: () =>
    css({
      paddingBottom: 32,
      '& > *:not(:last-child)': {
        paddingBottom: 16,
      },
    }),
  search: () =>
    css({
      width: '100%',
      paddingTop: 32,
      marginBottom: 16,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
