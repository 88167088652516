import { ArrowLeft } from '@recruitrobin/robin-theme/web-icons';
import CampaignList from 'components/ui/molecules/CampaignList/CampaignList';
import { CampaignListHeader } from 'components/ui/molecules/CampaignList/CampaignListHeader';
import { useContext } from 'react';
import { default as Logo } from 'shared/components/Logo';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useStyles } from './SidebarOverlayFixed.styles';

const SidebarOverlayFixed = () => {
  const { setIsOpen, setSidebarRef } = useContext(SidebarOverlayFixedContext);
  const classes = useStyles();

  return (
    <div ref={setSidebarRef} css={classes.root}>
      <div css={classes.overlayHeader}>
        <Logo variant={'text'} />
        <div css={classes.overlayArrow} onClick={() => setIsOpen(false)}>
          <ArrowLeft height={24} />
        </div>
      </div>

      <CampaignListHeader />

      <div css={classes.body}>
        <div css={classes.overlayHr} />
        <div css={classes.overlayCampaignList}>
          <CampaignList />
        </div>
      </div>
    </div>
  );
};

export default SidebarOverlayFixed;
