import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    cursor: pointer;
    width: 78px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
