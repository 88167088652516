import { Fragment, useEffect, useRef, useState } from 'react';
import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel } from '@material-ui/core';
import { useStyles } from './Checkbox.styles';
import { CheckboxProps } from './types';
import KeypressClassToggler from 'helpers/keypressClassToggler';
import { PartialBy } from 'config';
import { generateDtiAttribute } from 'helpers/helpers';
import { Check } from '@recruitrobin/robin-theme/web-icons';
import FormHelperError from 'shared/components/FormHelperError';

const Checkbox = ({
  label,
  checked,
  defaultChecked,
  onChange,
  name,
  errorMessage,
  id,
  disableTabIndex = false,
  error = false,
  disabled = false,
}: PartialBy<CheckboxProps, 'label'>) => {
  const classes = useStyles({ error, disabled, emptyLabel: !label });
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const [checkboxId, setCheckboxId] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buttonKeypressHandler = new KeypressClassToggler(checkboxRef.current!, {
      Enter: { onKeyUp: (el) => el.click(), classes: 'm-active' },
    }).init();

    const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
    setCheckboxId(`checkbox-${id}`);

    return () => {
      buttonKeypressHandler.dispose();
    };
  }, []);

  return (
    <Fragment>
      <MuiFormControlLabel
        css={classes.root}
        control={
          <MuiCheckbox
            id={id}
            inputRef={checkboxRef}
            checked={checked}
            onChange={onChange}
            name={name}
            icon={<span css={classes.icon} className="checkbox--icon" />}
            checkedIcon={
              <span css={classes.checkedIcon} className="checkbox--icon checkbox--icon-active">
                <Check size={12} />
              </span>
            }
            defaultChecked={defaultChecked}
            tabIndex={disableTabIndex ? -1 : undefined}
            disableRipple
            {...generateDtiAttribute('checkbox')}
          />
        }
        label={label}
        disabled={disabled}
        aria-disabled={disabled}
        aria-describedby={`error-${checkboxId}`}
      />
      {error && <FormHelperError id={`error-${checkboxId}`} message={errorMessage} disableMarginTop />}
    </Fragment>
  );
};

export default Checkbox;
