import { FilterOrder } from 'clients/types';
import { useMemo, useState } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { SearchCandidateListContext, UsePropsHook } from '../types';

export const useProps: UsePropsHook = () => {
  const [searchListContext, setSearchListContext] = useState<SearchCandidateListContext>(initialSearchListContext);

  return useMemo(() => {
    return {
      searchListContext,
      setSearchListContext,
    };
  }, [searchListContext]);
};

export const initialSearchListContext: SearchCandidateListContext = {
  search: '',
  // eslint-disable-next-line no-constant-condition
  sort: { by: 'updated_at', order: 'latest-actioned' ? FilterOrder.Desc : FilterOrder.Asc },
  status: CANDIDATE_STATUS.CONTACTED,
  labelsFilter: [],
};
