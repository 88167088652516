import { generateDtiAttribute } from 'helpers/helpers';
import { useCallback, useContext, useEffect, useState } from 'react';

import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

import { useStyles } from './Switch.styles';
import { Props } from './Switch.types';
import { ConfirmationPrompt } from 'shared/components/layouts/ConfirmationPrompt/ConfirmationPrompt';

export const Switch = ({
  on: _on = false,
  onChange,
  shouldConfirmEnabling = false,
  shouldConfirmDisabling = false,
  confirmChangeCancelButton,
  confirmChangeConfirmButton,
  confirmChangeDescription,
  confirmChangeTitle,
  id,
  'data-tutorial-id': dataTutorialId,
  ...props
}: Props) => {
  const { dictionary } = useContext(LocalizationContext);

  const [on, setOn] = useState(_on);
  const [openConfirmationPrompt, setOpenConfirmationPrompt] = useState(false);

  useEffect(() => {
    setOn(_on);
  }, [_on, id]);

  const classes = useStyles({ on });

  const onToggle = useCallback(() => {
    setOn(!on);
    if (!on && shouldConfirmEnabling) {
      setOpenConfirmationPrompt(true);
    } else if (on && shouldConfirmDisabling) {
      setOpenConfirmationPrompt(true);
    } else {
      if (onChange) {
        onChange(!on);
      }
    }
  }, [on, setOn, onChange, shouldConfirmEnabling, shouldConfirmDisabling]);

  const onConfirm = useCallback(() => {
    if (onChange) {
      onChange(on);
    }
    setOpenConfirmationPrompt(false);
  }, [on, onChange]);

  return (
    <div css={classes.root} {...props}>
      <div css={classes.button} onClick={onToggle} {...generateDtiAttribute(dataTutorialId, 'switch')} tabIndex={0}>
        <div />
      </div>
      <div css={classes.label} {...generateDtiAttribute(dataTutorialId, 'label-switch')}>
        {props.children}
      </div>
      <ConfirmationPrompt
        open={openConfirmationPrompt}
        setOpen={setOpenConfirmationPrompt}
        title={confirmChangeTitle || dictionary.areYouSure}
        description={confirmChangeDescription || dictionary.confirmDeleteJobBoard}
        onConfirm={onConfirm}
        onCancel={() => setOn(!on)}
        cancelLabel={confirmChangeCancelButton || dictionary.cancel}
        confirmLabel={confirmChangeConfirmButton || dictionary.yes}
      />
    </div>
  );
};
