import { routes } from 'config';
import { useMatch } from 'react-router-dom';

export const usePath = () => {
  const sharedPath = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.sharedCandidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );
  const candidatePath = useMatch<'campaignId' | 'candidateId' | 'userId', string>(
    routes.candidatePage({ campaignId: ':campaignId', candidateId: ':candidateId', userId: ':userId' }),
  );

  const campaignId = parseInt(sharedPath?.params.campaignId || candidatePath?.params.campaignId || '');
  const candidateEsPersonId = sharedPath?.params.candidateId || candidatePath?.params.candidateId;

  return { campaignId: isNaN(campaignId) ? undefined : campaignId, candidateEsPersonId };
};
