import { Column, Row, Typography } from 'components/ui/atoms';
import { useStyles } from './styles';
import { useContext } from 'react';
import { LocalizationContext } from '../LocalizationContext/LocalizationContext';

export const VersionSnackbar = ({ onClick }: { onClick: () => void }) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();

  return (
    <Row spaceBetween alignCenter>
      <Column css={classes.clickable} gap={4} onClick={onClick}>
        <Typography color={(c) => c.monochrome[90]} variant="header.h4">
          {dictionary.newVersionAvailable}
        </Typography>
        <Typography color={(c) => c.monochrome[90]} variant="body.short">
          {dictionary.newVersionAvailableDescription()}
        </Typography>
      </Column>
    </Row>
  );
};
