import { useContext } from 'react';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { PushNotificationErrorResponse } from 'clients/NotificationsClient/NotificationsClient.types';

import { isAxiosError } from 'helpers/clientHelpers';
import { UserNotHaveExpoPushNotificationException } from 'shared/exceptions';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useCallMobileByPushNotification = (params: WrappedHookParams) => {
  const { dictionary } = useContext(LocalizationContext);

  return useCandidateAction<void, any>(
    async ({ candidate }) =>
      notificationsClient.sendPushNotification({
        title: dictionary.tapToCallCandidate(candidate.name),
        body: `${candidate.not_obfuscated_phone}`,
        data: { url: `tel:${candidate.not_obfuscated_phone}` },
      }),
    {
      onError: () => {
        return ({ data }) => {
          if (isAxiosError<PushNotificationErrorResponse>(data)) {
            if (data.response?.status === 404 && data.response?.data === 'User do not have a EXPO Token') {
              throw new UserNotHaveExpoPushNotificationException();
            }
          }

          throw data;
        };
      },
    },
  )(params);
};
