import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    cursor: pointer;
    padding: 4px;
    gap: 8px;
    display: flex;

    &:hover {
      background-color: ${theme.colors.neutral.variant[20]};
      border-radius: 8px;
    }

    &:active,
    &.m-active {
      background-color: ${theme.colors.neutral.variant[20]};
      border-radius: 8px;
      cursor: default;
    }

    & > a {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: 0;
      border-radius: 8px;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
