import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { useLocalStorage } from 'hooks/shared';
import { useMemo, useState } from 'react';
import { initialState } from './CampaignManagerContext.constants';
import { CampaignManagerState } from './CampaignManagerContext.types';

export const useFilters = (): CampaignManagerState['filters'] => {
  const { setValue: setTeamview, value: teamview } = useLocalStorage<boolean>('showTeamCampaigns');
  const { setValue: setStatus, value: status } = useLocalStorage<CampaignOverviewStatus>('statusCampaigns');
  const [name, setName] = useState(initialState.filters.name);

  return useMemo(
    () => ({
      status,
      teamview,
      name,

      setStatus,
      setTeamview,
      setName,
    }),
    [status, teamview, name],
  );
};
