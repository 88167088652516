import { InputLabelWrapperProps } from './types';
import { useStyles } from './InputLabelWrapper.styles';
import { generateDtiAttribute } from 'helpers/helpers';
import { TooltipIcon } from 'shared/components/Tooltip';

/**
 * @deprecated import from `components/ui/molecules/FieldLabelWrapper` instead
 */
const InputLabelWrapper = ({ label, htmlFor, tooltipLabel, disabled = false }: InputLabelWrapperProps) => {
  const classes = useStyles({ disabled, empty: !label && !tooltipLabel });

  return (
    <div css={classes.root}>
      <label htmlFor={htmlFor} {...generateDtiAttribute('label')}>
        {label}
      </label>
      {tooltipLabel && <TooltipIcon title={tooltipLabel} disabled={disabled} />}
    </div>
  );
};

export default InputLabelWrapper;
