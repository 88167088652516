import { hexToRgba } from 'utils/style';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { VisualState } from './Card.types';

const styles = {
  root: ({ theme, props: { rounded } }) =>
    css({
      boxShadow: `0px 6px 6px 0px ${hexToRgba(theme.colors.neutral.variant[60], 0.03)}, 0px 2px 3px 0px ${hexToRgba(theme.colors.neutral.variant[60], 0.1)}`,
      backgroundColor: theme.colors.neutral.variant[0],
      ...(rounded && {
        borderRadius: 7,
      }),
    }),
} satisfies Styles<VisualState>;

export const useStyles = withStyles(styles);
