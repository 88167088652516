import { generateTransitionStyles } from 'styles/theme';
import { TooltipIconStateStyles } from './types';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props }) =>
    css({
      display: 'inline-flex',
      borderRadius: '50%',
      color: theme.colors.neutral.variant[0],
      backgroundColor: theme.colors.neutral.variant[60],
      transition: generateTransitionStyles(['border', 'background-color', 'color']),
      svg: {
        width: '16px',
        height: '16px',
      },
      '&:hover': {
        color: theme.colors.secondary.sky[20],
        backgroundColor: theme.colors.secondary.sky[50],
      },
      ...(props.disabled && {
        color: `${theme.colors.neutral.variant[0]} !important`,
        backgroundColor: `${theme.colors.neutral.variant[30]} !important`,
      }),
    }),
} satisfies Styles<TooltipIconStateStyles>;

export const useStyles = withStyles(styles);
