import { AxiosResponse } from 'axios';
import React, { useContext, useState } from 'react';
import { CornerLeftUp } from '@recruitrobin/robin-theme/web-icons';

import { UserResponse } from 'clients/UserClient/types';
import { userClient } from 'clients/UserClient/UserClient';
import { Row, Typography } from 'components/ui/atoms';
import { TextInput } from 'components/ui/molecules';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

import FieldLabelWrapper from '../../FieldLabelWrapper';
import { useStyles } from './ChangeCampaignOwner.styles';
import { ChangeCampaignOwnerProps, UsersElementsDropdown } from './ChangeCampaignOwner.types';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { LoaderSpinner1 } from 'components/ui/atoms/icons/animated';

export const ChangeCampaignOwner = ({
  onSelectNewOwner,
  campaignId,
  dropdownTriggerChildren,
}: ChangeCampaignOwnerProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const [openChangeOwnerDropdown, setOpenChangeOwnerDropdown] = useState(false);
  const [ownerSearchText, setOwnerSearchText] = useState('');
  const [usersDropdown, setUsersDropdown] = useState<UsersElementsDropdown[]>([]);
  const classes = useStyles({ dropdownOpened: openChangeOwnerDropdown });

  const handleSelectNewOwner = (userId: number) => {
    onSelectNewOwner(campaignId, userId);
  };

  const handleOpenChangeOwnerDropdown = () => {
    setOpenChangeOwnerDropdown(true);
  };

  const handleCloseChangeOwnerDropdown = () => {
    setOpenChangeOwnerDropdown(false);
  };

  const handleChangeOwnerInput = async (text: string) => {
    setOwnerSearchText(text);

    const { data } = (await userClient.index({ limit: 4, is_active: true, search: text })) as AxiosResponse;
    const users: UserResponse[] = data.results;

    const usersToUsersDropdown: UsersElementsDropdown[] = users.map((user) => {
      let hasSpinner = false;

      return {
        children: () => (
          <Row
            spaceBetween
            contained
            onClick={() => {
              hasSpinner = true;
              handleSelectNewOwner(user.id);
            }}
            css={classes.ownersName}
            key={user.id}>
            <Typography variant={(v) => v.body.short} css={classes.textEllipsis} colorv2={(c) => c.neutral.variant[70]}>
              {hasSpinner && <LoaderSpinner1 size={16} css={classes.spinnerChangeOwner} />}
              {user.first_name} {user.last_name}
            </Typography>
            <CornerLeftUp size={16} />
          </Row>
        ),
        value: `${user.id}`,
      };
    });

    setUsersDropdown(usersToUsersDropdown);
  };

  const {
    refs: menuRefs,
    floatingStyles: menuFloatingStyles,
    context: menuContext,
  } = useFloating({
    open: openChangeOwnerDropdown,
    onOpenChange: setOpenChangeOwnerDropdown,
    middleware: [],
  });

  const { getReferenceProps: getMenuReferenceProps, getFloatingProps: getMenuFloatingProps } = useInteractions([
    useClick(menuContext),
    useDismiss(menuContext),
  ]);

  return (
    <div
      css={classes.root}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}>
      <Row onClick={handleOpenChangeOwnerDropdown} {...getMenuReferenceProps()} gap={8}>
        {dropdownTriggerChildren}
      </Row>

      {openChangeOwnerDropdown && (
        <div
          ref={menuRefs.setFloating}
          style={{
            ...menuFloatingStyles,
            backgroundColor: 'white',
            zIndex: 10000,
            borderRadius: '8px',
            right: '0px',
            left: 'auto',
          }}
          css={classes.floatingRoot}
          {...getMenuFloatingProps()}>
          <div onClick={handleCloseChangeOwnerDropdown}>
            <Typography variant="supporting.label" color={(color) => color.monochrome[80]} css={classes.ownersName}>
              {dictionary.currentOwner}
            </Typography>
          </div>
          <Row css={classes.searchOwnerInput}>
            <FieldLabelWrapper label={dictionary.changeOwner} contained>
              <TextInput onChange={handleChangeOwnerInput} text={ownerSearchText} contained />
            </FieldLabelWrapper>
          </Row>
          {usersDropdown.map((userDropdown) => userDropdown.children())}
        </div>
      )}
    </div>
  );
};
