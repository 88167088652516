import { createContext, useEffect, useState } from 'react';
import { useBrowserExtensionLocalStorage } from 'shared/hooks/useBrowserExtension/useBrowserExtensionLocalStorage';
import { dictionary as en } from './languages/en';
import { dictionary as nl } from './languages/nl';
import { ContextProps, LocalizationContextProviderProps, SupportedLanguage, SupportedLocale } from './types';

const initialLanguage: SupportedLanguage =
  window.navigator.language.startsWith('en') || window.location.href.includes('localhost') ? 'en' : 'nl';

const defaultContext: ContextProps = {
  language: initialLanguage,
  locale: initialLanguage === 'en' ? 'en-US' : 'nl-NL',
  dictionary: initialLanguage === 'en' ? en : nl,
  setLanguage: () => null,
};

export const LocalizationContext = createContext(defaultContext);

export const LocalizationContextProvider = ({ children }: LocalizationContextProviderProps) => {
  const browserExtensionLS = useBrowserExtensionLocalStorage();
  const [language, setLanguage] = useState<SupportedLanguage>(
    (localStorage.getItem('languageV2') as SupportedLanguage) || defaultContext.language,
  );
  const dictionaries = { nl, en };
  const dictionary = dictionaries[language];

  const locale: SupportedLocale = language === 'en' ? 'en-US' : 'nl-NL';

  useEffect(() => {
    browserExtensionLS
      .get<SupportedLanguage>('languageV2')
      .then((data) => data && data !== language && setLanguage(data));
  }, []);

  useEffect(() => {
    browserExtensionLS.set('languageV2', language);
    localStorage.setItem('languageV2', language);
  }, [language]);

  return (
    <LocalizationContext.Provider
      value={{
        locale,
        language,
        dictionary,
        setLanguage,
      }}>
      {children}
    </LocalizationContext.Provider>
  );
};
