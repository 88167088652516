import type { CandidateOutreachSendMethod } from 'clients/CampaignsClient/CampaignsClient.types';
import type { UserResponse } from 'clients/UserClient/types';
import type { default as Candidate } from 'model/Candidate/Candidate';
import type { CandidateHistory } from '../useCandidateHistoryQuery.types';

import { queryClient } from 'config/queryClient';
import { CandidateHistoryAction } from 'services/candidates/types';
import { extractLastRefresh } from 'shared/contexts/CandidateContext/searchv3/extractLastRefresh';
import { QueryKey } from 'types/query';

export const candidateHistoryQuerySetValue = ({
  candidate,
  campaignName,
  sendMethod,
  currentUser,
  language,
  content,
}: {
  candidate: Candidate;
  sendMethod: CandidateOutreachSendMethod;
  currentUser: UserResponse | undefined;
  campaignName: string | undefined;
  language: string;
  content: CandidateHistory['content'];
}) => {
  queryClient.setQueryData<CandidateHistory[]>(
    [QueryKey.useCandidateHistoryQuery, { candidateEsPersonId: candidate.es_person_id, status: candidate.status }],
    (d) => {
      if (!d) return d;

      let action: CandidateHistoryAction;

      switch (sendMethod) {
        case 'email':
          action = CandidateHistoryAction.EmailSent;
          break;
        case 'linkedin_inmail':
          action = CandidateHistoryAction.InMailSent;
          break;
        case 'linkedin':
          action = CandidateHistoryAction.LinkedinSent;
          break;
        default:
          action = CandidateHistoryAction.Contacted;
      }

      const newData: CandidateHistory[] = [
        ...d,
        {
          action,
          content,
          by: { id: currentUser?.id ?? -1, name: currentUser?.first_name ?? '$name' },
          campaign: { name: campaignName ?? '$campaign' },
          createdAt: new Date(),
          id: Date.now(),
          timeAgo: extractLastRefresh(new Date(), language),
          user: { id: currentUser?.id ?? -1 },
          message: {
            isNew: false,
            source: sendMethod,
          },
        },
      ];

      return newData;
    },
  );
};
