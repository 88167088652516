import { isObject } from 'helpers/helpers';
import { ErrorHandler } from './ErrorHandler';

/**
 * Types
 */

enum IndeedErrors {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
}

/**
 * Exceptions
 */

export class IndeedServiceResultNullException extends Error {
  constructor() {
    super('Indeed service result null');

    this.name = 'IndeedServiceResultNullException';
  }
}

export class IndeedBadUserInputException extends Error {
  constructor() {
    super('Bad user input');

    this.name = 'IndeedBadUserInputException';
  }
}

/**
 * Check methods and throw
 */

const checkAndThrowBadUserInput = (error: unknown) => {
  if (
    isObject(error) &&
    typeof error.body === 'string' &&
    error.status === 400 &&
    error.body.includes(IndeedErrors.BAD_USER_INPUT)
  ) {
    throw new IndeedBadUserInputException();
  }
};

const checkAndThrowServiceResultNull = (error: unknown, data: unknown) => {
  if (
    isObject(error) &&
    isObject(data) &&
    typeof data.url === 'string' &&
    (error.status === 500 || error.status === 403) &&
    data.url?.match(/resumes\.indeed\.com/g)
  ) {
    throw new IndeedServiceResultNullException();
  }
};

/**
 * Indeed exception controller
 */

export class IndeedException extends ErrorHandler {
  static checkAndThrowError = (
    error: unknown,
    { data, onReject }: { data?: unknown; onReject?: (error: Error) => void } = {},
  ) => {
    return super.checkAndThrowError(
      () => {
        checkAndThrowBadUserInput(error);
        checkAndThrowServiceResultNull(error, data);
      },
      { onReject },
    );
  };
}

/**
 * Helpers
 */
