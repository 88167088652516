import type { OutreachEditorRef } from '@recruitrobin/robin-theme/web-components';
import type { Outreach } from 'clients/OutreachTemplateclient/OutreachTemplateClient.types';
import type { default as Candidate } from 'model/Candidate/Candidate';
import type { CandidateContextActions } from 'shared/contexts/CandidateContext/types';
import type { IndeedProject } from 'shared/hooks/useBrowserExtension/indeed/getProjectsList';

import { OutreachEditorType, OutreachTokenKey } from '@recruitrobin/robin-theme/web-components';
import { OutreachPanelPage } from 'components/ui/organisms/OutreachPanel/utils/OutreachPanelPage';
import { createContext } from 'use-context-selector';
import { notImplemented } from 'utils/notImplemented';

export type OutreachPanelPageInfo = {
  contact?: 'success' | 'unsuccess';
  outreach: {
    werknl: {
      email: string;
      phone: string;
      website: string;
    };
  };
};

export type OutreachModalContextState = {
  candidate: Candidate;
  type: OutreachEditorType;
  refs: {
    subject: OutreachEditorRef | null;
    message: OutreachEditorRef | null;
    setSubject: React.Dispatch<React.SetStateAction<OutreachEditorRef | null>>;
    setMessage: React.Dispatch<React.SetStateAction<OutreachEditorRef | null>>;
  };
  tokens: Record<OutreachTokenKey, string>;
  page: OutreachPanelPage;
  setPage: React.Dispatch<React.SetStateAction<OutreachPanelPage>>;
  pageInfo: OutreachPanelPageInfo;
  setPageInfo: React.Dispatch<React.SetStateAction<OutreachPanelPageInfo>>;
  selectedTemplate: Outreach | null;
  setSelectedTemplate: React.Dispatch<React.SetStateAction<Outreach | null>>;
  actions: CandidateContextActions;
  selectedIndeedProject: IndeedProject | null;
  setSelectedIndeedProject: React.Dispatch<React.SetStateAction<IndeedProject | null>>;

  attachments: File[];
  setAttachments: React.Dispatch<React.SetStateAction<File[]>>;

  onSendMessage: () => Promise<void>;
  onContactSuccesful: () => Promise<void>;
  onContactUnsuccessful: () => Promise<void>;

  onRequestCloseModal: () => void;
  onRequestChangeType: (type: OutreachEditorType) => void;
};

export const initialState: OutreachModalContextState = {
  candidate: null as any,
  actions: null as any,
  page: OutreachPanelPage.Outreach,
  setPage: notImplemented,
  refs: {
    subject: null,
    message: null,
    setMessage: notImplemented,
    setSubject: notImplemented,
  },
  type: OutreachEditorType.Email,
  pageInfo: {
    contact: undefined,
    outreach: {
      werknl: {
        email: '',
        phone: '',
        website: '',
      },
    },
  },
  setPageInfo: notImplemented,
  tokens: {
    '{firstName}': '{firstName}',
    '{fullName}': '{fullName}',
    '{lastCompany}': '{lastCompany}',
    '{lastName}': '{lastName}',
    '{lastPosition}': '{lastPosition}',
    '{location}': '{location}',
    '{nameJobboard}': '{nameJobboard}',
    '{nameVacancy}': '{nameVacancy}',
    '{recruiterFullName}': '{recruiterFullName}',
  },
  selectedTemplate: null,
  setSelectedTemplate: notImplemented,
  selectedIndeedProject: null,
  setSelectedIndeedProject: notImplemented,

  attachments: [],
  setAttachments: notImplemented,

  onContactSuccesful: notImplemented,
  onContactUnsuccessful: notImplemented,
  onSendMessage: notImplemented,

  onRequestCloseModal: notImplemented,
  onRequestChangeType: notImplemented,
};

export const OutreachModalContext = createContext(initialState);
