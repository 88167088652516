import { TextFieldStateStyles } from './types';
import { Styles, withStyles, extractFont, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props }) =>
    css({
      ...extractFont((font) => font.supporting.label, 'web'),
      color: theme.colors.neutral.variant[80],
      display: 'inline-flex',
      flexDirection: 'column',
      input: {
        height: '18px',
      },
      ...(props.disabled && { cursor: 'not-allowed' }),
      ...(props.contained && { width: '100%' }),
    }),
  passwordToggler: ({ theme, props }) =>
    css({
      width: '30px',
      padding: '6px',
      color: theme.colors.neutral.variant[70],
      border: 'none',
      background: 'transparent',
      zIndex: 1,
      cursor: 'pointer',
      '&:hover': {
        color: theme.colors.secondary.sky[60],
      },
      '&.MuiInputAdornment-root': {
        height: '30px',
      },
      '&:focus': {
        color: theme.colors.secondary.sky[60],
        outline: 'none',
      },
      ...(props.disabled && { cursor: 'not-allowed', color: `${theme.colors.neutral.variant[40]}!important` }),
    }),
} satisfies Styles<TextFieldStateStyles>;

export const useStyles = withStyles(styles);
