import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () => css``,
  dropdownOverlay: css`
    width: 300px;
  `,
  content: () => css`
    right: 0;
    position: absolute;
    align-self: center;
  `,

  contextMenuHeader: ({ theme }) =>
    css({
      padding: '6px 16px 12px',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.neutral.variant[30],
    }),
  menuItemSpan: css`
    overflow: hidden;
    text-overflow: ellipsis;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
