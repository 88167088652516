import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    & > div > div > div:not(:first-of-type) {
      display: none;
    }
  `,
  textCounter: css`
    position: absolute;
    user-select: none;
    right: 16px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
