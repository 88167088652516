import { Tooltip as MuiTooltip } from '@material-ui/core';
import { Props } from './types';
import { useStyles } from './Tooltip.styles';
import { sharedUtils } from 'utils';

const Tooltip = ({
  title,
  children,
  placement = 'bottom',
  disabled = false,
  className,
  pointer = false,
  enablePortal = false,
}: Props) => {
  const classes = useStyles({ disabled, pointer });
  const isSafariBrowser = sharedUtils.getPlatform().isSafariBrowser;

  return (
    <MuiTooltip
      css={classes.root}
      className={className}
      title={title ?? ''}
      placement={placement}
      disableFocusListener={disabled}
      disableHoverListener={disabled}
      disableTouchListener={disabled}
      PopperProps={{ disablePortal: isSafariBrowser ? false : !enablePortal }}
      arrow>
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
