import type { WaitBeforeRemove } from './useWaitBeforeRemove';

import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_STATUS, CandidateStatus } from 'services/candidates';
import { CampaignTab } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { CandidateOrchestrator, WrappedHookParams, useCandidateAction } from './useCandidateAction';
import { useSaveOnDb } from './useSaveOnDb';

export const useSaveLabels = (params: WrappedHookParams) => {
  const saveOnDb = useSaveOnDb(params.candidate);
  return useCandidateAction<SaveLabelsHookCallbackParams, SaveLabelsCallbackParams>(
    async ({ candidate, labels }) => {
      await saveOnDb();

      const {
        data: { status: oldCandidateStatus },
      } = await campaignsClient.getCampaignCandidate({
        campaignId: String(candidate.campaignId),
        candidateId: candidate.es_person_id,
      });

      const { data: { reasons = [] } = {} } = await campaignsClient.putCampaignCandidateLabels({
        labels,
        es_person_id: candidate.es_person_id,
        campaign_id: candidate.campaignId,
      });

      return { updatedLabels: reasons, oldCandidateStatus };
    },
    {
      onSuccess: ({ fcParams: { candidate, options: { waitBeforeRemoveFromList } = {} }, gtm }) => {
        const getNewStatus = (updatedLabels: string[]): CandidateStatus => {
          if (!candidate) {
            throw new Error('Candidate cannot be empty');
          }
          const comparer = (s: CandidateStatus) => s === candidate.status;

          const removedLabels = candidate.reasons.filter((label) => !updatedLabels.includes(label));
          const addedLabels = updatedLabels.filter((label) => !candidate.reasons.includes(label));

          const contacted = addedLabels.includes('contacted');
          const interviewScheduled = addedLabels.includes('interviewScheduled');
          const interviewCompleted = addedLabels.includes('interviewCompleted');
          const interviewCancelled = addedLabels.includes('interviewCancelled');
          const notResponding = addedLabels.includes('notResponding');
          const notInterested = addedLabels.includes('notInterested');
          const notOpenToWork = addedLabels.includes('notOpenToWork');
          const leftVoicemail = addedLabels.includes('leftVoicemail');
          const moreInfoSent = addedLabels.includes('moreInfoSent');

          const shouldMoveToContact =
            contacted ||
            interviewCancelled ||
            notResponding ||
            notInterested ||
            notOpenToWork ||
            leftVoicemail ||
            moreInfoSent;
          const shouldMoveToMatch = interviewScheduled || interviewCompleted;

          addedLabels.forEach((label) => gtm.candidateLabelAdded(label));
          removedLabels.forEach((label) => gtm.candidateLabelRemoved(label));

          if (
            shouldMoveToMatch &&
            [
              CANDIDATE_STATUS.NEW,
              CANDIDATE_STATUS.SHORTLISTED,
              CANDIDATE_STATUS.CONTACTED,
              CANDIDATE_STATUS.HIDDEN,
            ].some(comparer)
          ) {
            return CANDIDATE_STATUS.MATCHED;
          }

          if (
            shouldMoveToContact &&
            [CANDIDATE_STATUS.NEW, CANDIDATE_STATUS.SHORTLISTED, CANDIDATE_STATUS.HIDDEN].some(comparer)
          ) {
            gtm.contactCandidate(candidate.campaignId, candidate.es_person_id);

            return CANDIDATE_STATUS.CONTACTED;
          }

          return candidate.status;
        };

        function updateStats({
          candidateStatus,
          newStatus,
          co,
        }: {
          candidateStatus: CandidateStatus;
          newStatus: CandidateStatus;
          co: ReturnType<CandidateOrchestrator>;
        }) {
          if (candidateStatus !== newStatus) {
            co.campaign.remove.fromCurrentList();
            if (candidateStatus !== 'INVISIBLE') {
              co.campaign.decreaseStatOf.list(candidateStatus as CampaignTab);
            }

            if (newStatus === CANDIDATE_STATUS.CONTACTED) {
              co.campaign.increaseStatOf.contactList();
              co.campaign.reset.contactList();
            }
            if (newStatus === CANDIDATE_STATUS.MATCHED) {
              co.campaign.increaseStatOf.matchList();
              co.campaign.reset.matchList();
            }
          }
        }

        return async ({ candidateOrchestrator: co, pageContext, data: { updatedLabels, oldCandidateStatus } }) => {
          const status = getNewStatus(updatedLabels);
          const removedLabels = candidate.reasons.filter((l) => !updatedLabels.includes(l));
          const campaignLabels = co.campaign.get.filterLabels();

          if (pageContext === PageContext.Campaign) {
            co.campaign.update.labels.set(updatedLabels);

            if (removedLabels.length > 0 && removedLabels.every((label) => campaignLabels.includes(label))) {
              co.campaign.remove.fromCurrentList();
              co.campaign.invalidateCurrentListInactiveQueries();
            }

            const removeFromCurrentList = () => {
              if (status !== CANDIDATE_STATUS.NEW || oldCandidateStatus === CANDIDATE_STATUS.NEW) {
                if (oldCandidateStatus === CANDIDATE_STATUS.NEW) {
                  co.campaign.decreaseStatOf.currentJobBoard();
                }

                updateStats({ candidateStatus: oldCandidateStatus, newStatus: status, co });
              }
            };

            if (waitBeforeRemoveFromList) {
              waitBeforeRemoveFromList().then(({ removeFromList }) => {
                if (removeFromList) {
                  removeFromCurrentList();
                }
              });
            } else {
              removeFromCurrentList();
            }
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            co.share.update.locally({ reasons: updatedLabels, status });
          }
        };
      },
    },
  )(params);
};

type SaveLabelsCallbackParams = {
  updatedLabels: string[];
  oldCandidateStatus: CandidateStatus;
};

type SaveLabelsHookCallbackParams = {
  labels: string[];
  options?: {
    waitBeforeRemoveFromList?: WaitBeforeRemove;
  };
};
