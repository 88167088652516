import type { UserEmailSignature } from 'clients/UserClient/types';
import type { CustomUseQueryOptions } from './types';

import { useQuery } from '@tanstack/react-query';
import { userClient } from 'clients/UserClient/UserClient';
import { QueryKey } from 'types/query';

export const useUserEmailSignatureQuery = (options?: CustomUseQueryOptions<UserEmailSignature>) => {
  return useQuery(
    [QueryKey.useUserEmailSignatureQuery],
    async () => {
      const { data } = await userClient.getUserEmailSignature();

      return data[0];
    },
    options,
  );
};
