import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { TableStyleStates } from './types';

export const DEFAULT_CELL_STYLE = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: '0 3px',
  width: '100%',
} as const;

const styles = {
  root: ({ props: { loading } }) =>
    css({
      width: '100%',
      ...(loading && {
        cursor: 'wait',
        '& > *': {
          pointerEvents: 'none',
        },
      }),
    }),
  table: () => css({}),
  thead: () =>
    css({
      '& > div': {
        width: '100%',
      },
    }),
  tbody: ({ props: { bodyHeight = '50vh' } }) =>
    css({
      height: bodyHeight,
    }),
  row: ({ theme }) =>
    css({
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.colors.neutral.variant[0],
      '&.even': {
        backgroundColor: theme.colors.neutral.variant[5],
      },
      '& > div': {
        ...DEFAULT_CELL_STYLE,
        textTransform: 'lowercase',
        '&:first-letter': {
          textTransform: 'capitalize',
        },
      },
    }),
  pagination: () =>
    css({
      marginTop: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
} satisfies Styles<TableStyleStates>;

export const useStyles = withStyles(styles);
