import { useContext, useEffect, useMemo } from 'react';
import { VersionContextProviderProps } from './types';
import { versionCheckerInterval } from 'config/versionChecker';
import { VersionSnackbar } from './VersionSnackbar';
import { LOG_INFO, logger } from 'config/logger';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';

export const useContainer = ({ children }: VersionContextProviderProps) => {
  const { createSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const interval = versionCheckerInterval(({ version }) => {
      createSnackbar(<VersionSnackbar onClick={onClickUpdateVersion} />, { persist: true });

      logger.info(version, LOG_INFO.DIFF_APP_VERSION);
    });

    return () => {
      clearInterval(interval);
    };
  }, []);

  const onClickUpdateVersion = () => {
    window.location.reload();
  };

  return useMemo(() => ({ children }), []);
};
