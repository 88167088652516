import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  shouldCallWerknlOutreachBody: css`
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
      width: 100%;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
