import { BadgeProps } from './types';
import { useStyles } from './styles';
import { Row } from 'components/ui/atoms';
import { useState } from 'react';

const Badge = ({
  label,
  children,
  onClick,
  onContextMenu,
  onDoubleClick,
  className,
  render,
  alignment = 'top left',
  variant = 'default',
  showLabel = true,
  onlyShowLabelOnHover = false,
}: BadgeProps) => {
  const [onHover, setOnHover] = useState(false);
  const classes = useStyles({
    alignment,
    variant,
    hasCustomRender: !!render,
    isDigit: label?.toString().length === 1,
  });

  return (
    <Row
      css={classes.root}
      className={className}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onDoubleClick={onDoubleClick}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}>
      {children}
      {showLabel && (onlyShowLabelOnHover ? onHover : true) && (
        <div css={classes.badgeWrapper}>{render ? render({ label }) : label}</div>
      )}
    </Row>
  );
};

export default Badge;
