import { themeOld } from 'styles/theme';

import type { CustomColor, CustomColorProps, CustomColorStateKeys, PlatformColor, StyleGuideColor } from 'styles/types';

export const extractThemeColorWithState = (
  _color: ((c: StyleGuideColor & { platformColor: PlatformColor }) => CustomColor) | 'currentColor' | undefined,
): Partial<CustomColorProps & Record<CustomColorStateKeys, CustomColorProps>> => {
  if (typeof _color === 'function') {
    const color = _color({ ...themeOld.styleGuide.color, platformColor: { ...themeOld.styleGuide.platformColor } });

    if (typeof color === 'string') {
      return { color };
    }

    return color;
  }

  return { color: _color };
};
