import { Candidate } from 'model';
import { Location } from 'shared/contexts/SearchContext/FiltersContext/types';
import { getClosestLocation } from 'utils/candidates';
import { calculateGeographicDistance } from 'utils/shared/calculateGeographicDistance';
import { CandidateLocation } from './types';

export const extractLocation = (candidate: Candidate, locations: Location[]): CandidateLocation | undefined => {
  if (!candidate.location) return undefined;

  const closestLocation = getClosestLocation(candidate.location.latlon, locations);
  const locationDistance =
    closestLocation && candidate.location?.latlon
      ? `${calculateGeographicDistance(candidate.location.latlon, closestLocation).toFixed(0)}km`
      : undefined;

  return {
    name: candidate.location.name,
    distance: locationDistance,
  };
};
