import { Styles, css, extractFont, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { InputLabelWrapperStyleStates } from './types';

const styles = {
  root: ({ theme, props }) =>
    css({
      ...extractFont((font) => font.supporting.label, 'web'),
      color: theme.colors.neutral.variant[60],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 6,
      gap: 6,
      width: '100%',
      ...(props.disabled && { color: theme.colors.neutral.variant[30] }),
      ...(props.empty && { padding: 0, margin: 0 }),
    }),
} satisfies Styles<InputLabelWrapperStyleStates>;

export const useStyles = withStyles(styles);
