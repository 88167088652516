import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      '&': {
        flexShrink: 1,
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
