import { CandidateStatus, CandidateStatusIcon } from '@recruitrobin/robin-theme/web-components';
import { Clock, Database_01, SearchMd, Star_06, XClose } from '@recruitrobin/robin-theme/web-icons';
import { Column, Row, TextArea, Typography } from 'components/ui/atoms';
import { Button, CampaignNotificationPill, Skeleton } from 'components/ui/molecules';
import { useCallback, useContext } from 'react';
import { ClipLoader } from 'react-spinners';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useStyles } from './styles';
import { MAX_LENGTH, useContainer } from './useContainer';

const SearchInput = () => {
  const { dictionary } = useContext(LocalizationContext);
  const {
    values: { resultsDivRef, search, atsVacancies, ratedCandidates, dropdown, campaignsOverview },
  } = useContainer();
  const { setIsOpen: setCampaignsOverviewOpened } = useContext(SidebarOverlayFixedContext);

  const classes = useStyles({ isTextToSearch: search.isTextToSearch });

  const AtsVacancies = useCallback(() => {
    return (
      <>
        {atsVacancies.value.map(({ name, value }) => (
          <Row key={value} css={classes.resultRow} gap={8} onClick={() => atsVacancies.handleSelect(value)}>
            <Database_01 color={(c) => c.neutral.variant[50]} size={16} />
            <span>{name}</span>
          </Row>
        ))}
      </>
    );
  }, [atsVacancies.value.map(({ value }) => value).join('')]);

  const RatedCandidates = useCallback(() => {
    return (
      <>
        {ratedCandidates.value.map(({ name, es_person_id, status, lastActionMadeBy, campaignId }) => (
          <Row
            key={es_person_id}
            css={classes.resultRow}
            gap={8}
            onClick={() =>
              ratedCandidates.handleSelect({
                campaignId: campaignId,
                candidateId: es_person_id,
                userId: lastActionMadeBy?.id,
              })
            }>
            <CandidateStatusIcon
              status={status as unknown as CandidateStatus}
              color={(c) => c.neutral.variant[50]}
              size={16}
            />
            <Row gap={0}>
              <Typography
                variant={(v) => v.supporting.helper}
                colorv2={(c) => c.neutral.variant[70]}>{`${name} /`}</Typography>
              <Typography variant={(v) => v.supporting['label-tiny']} colorv2={(c) => c.primary.fuchsia[50]}>
                {` ${status} ${dictionary.by} ${lastActionMadeBy?.first_name} ${lastActionMadeBy?.last_name}`}
              </Typography>
            </Row>
          </Row>
        ))}
      </>
    );
  }, [ratedCandidates.value.map(({ es_person_id }) => es_person_id).join('')]);

  const Searches = useCallback(() => {
    return (
      <>
        {campaignsOverview.value.data.length === 0 ? (
          <Row css={classes.resultRow} className="static">
            <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.neutral.variant[70]} ellipsis>
              {dictionary.noDataAvailable}
            </Typography>
          </Row>
        ) : (
          campaignsOverview.value.data.slice(0, 3).map(({ id, name, full_owner_name, new_candidates_count }) => (
            <Row css={classes.resultRow} key={id} gap={8} onClick={() => campaignsOverview.handleSelect(id)}>
              <Clock color={(c) => c.neutral.variant[50]} size={16} />
              <Row gap={0}>
                <Typography variant={(v) => v.supporting.helper} colorv2={(c) => c.neutral.variant[80]} ellipsis>
                  {`${name} /`}
                </Typography>
                <Typography variant={(v) => v.supporting['label-tiny']} colorv2={(c) => c.neutral.variant[50]} ellipsis>
                  {` ${full_owner_name}`}
                </Typography>
              </Row>
              <CampaignNotificationPill
                newCandidates={new_candidates_count}
                // newMessages={1} TODO insert here the indicator of new messages
                onClickNewCandidates={() => campaignsOverview.handleSelect(id)}
                onClickNewMessages={() => campaignsOverview.handleSelect(id, 'CONTACTED')}
              />
            </Row>
          ))
        )}
      </>
    );
  }, [campaignsOverview.value.data.map(({ id }) => id).join(''), dictionary, classes.resultRow]);

  const Separator = useCallback(
    ({ label, visible, viewAll = false }: { label: string; visible: boolean; viewAll?: boolean }) => {
      const { dictionary } = useContext(LocalizationContext);
      if (!visible) return;
      return (
        <div css={classes.separator}>
          <hr />
          <div>
            <Typography variant={(v) => v.supporting['label-tiny']} colorv2={(c) => c.neutral.variant[50]} nowrap>
              {label}
            </Typography>
          </div>
          {viewAll && (
            <div
              className="view-all"
              onClick={() => {
                return setCampaignsOverviewOpened(true);
              }}>
              <Typography variant={(v) => v.supporting['label-tiny']} colorv2={(c) => c.neutral.variant[50]} nowrap>
                {dictionary.viewAll}
              </Typography>
            </div>
          )}
        </div>
      );
    },
    [classes.separator],
  );

  const RowSkeleton = useCallback(() => {
    return (
      <>
        {Array.from({ length: 3 }).map((_, i) => (
          <Row key={i} css={classes.resultRow} className="static">
            <Skeleton variant="text" textVariant="supporting.helper" width={150} />
          </Row>
        ))}
      </>
    );
  }, []);

  return (
    <Row css={classes.root} contained>
      <Row gap={9} contained>
        <Column css={classes.searchInputWrapper} gap={0} contained expand>
          <Column gap={0} contained>
            <Row css={classes.textAreaWrapper} gap={9} contained>
              <Star_06 size={18} color={(c) => c.neutral.variant[40]} />
              <TextArea
                css={classes.input}
                placeholder={dictionary.searchInputPlaceholder}
                resize="auto"
                maxHeight={220}
                maxLength={MAX_LENGTH}
                value={search.value}
                onChange={search.handleChange}
                onEnterKeyDown={search.createTextSearch}
                rows={1}
                onFocus={dropdown.handleOpen}
                onBlur={dropdown.handleClose}
              />
              {search.length > 0 && (
                <div css={classes.clearButton} onClick={() => search.handleChange('')}>
                  <XClose size={18} color={(c) => c.neutral.variant[60]} />
                </div>
              )}
            </Row>
          </Column>

          {dropdown.isOpen && (
            <Column ref={resultsDivRef} css={classes.results} gap={0}>
              <Column gap={0}>
                <Separator label={dictionary.searches} visible viewAll />
                {campaignsOverview.isLoading ? <RowSkeleton /> : <Searches />}
              </Column>

              <Column gap={0} contained>
                <Separator
                  label={dictionary.candidates}
                  visible={ratedCandidates.isLoading || !!ratedCandidates.value.length}
                />
                {ratedCandidates.isLoading ? <RowSkeleton /> : <RatedCandidates />}
              </Column>

              <Column gap={0} contained>
                <Separator label={dictionary.ats} visible={atsVacancies.isLoading || !!atsVacancies.value.length} />
                {atsVacancies.isLoading ? <RowSkeleton /> : <AtsVacancies />}
              </Column>
            </Column>
          )}
        </Column>

        <Button
          css={classes.button}
          icon={
            search.isCreating ? (
              <ClipLoader color="currentColor" size={20} />
            ) : (
              <SearchMd size={18} color={(c) => c.neutral.variant[0]} />
            )
          }
          tooltipLabel={dictionary.search}
          variant="highlight"
          onClick={() => search.createTextSearch()}
          style="filled"
        />
      </Row>
    </Row>
  );
};

export default SearchInput;
