import { ConfirmMonsterboardCreditUsageProps } from './types';
import { useStyles } from './styles';
import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Checkbox, Column, Row, Typography } from 'components/ui/atoms';
import { default as Button } from 'components/ui/molecules/Button';

const ConfirmJobboardCreditUsage = ({
  onConfirm,
  onCancel,
  onChangeShouldSkipNextTime,
  platform,
}: ConfirmMonsterboardCreditUsageProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const classes = useStyles();
  const [shouldSkipNextTime, setShouldSkipNextTime] = useState(false);

  return (
    <Column css={classes.root} gap={24}>
      <Column>
        <Typography variant="body.long" color={(c) => c.monochrome[90]}>
          {dictionary.requestJobboardInformationConfirmationDescription(platform)}
        </Typography>

        <Row gap={8}>
          <label>
            <Checkbox
              checked={shouldSkipNextTime}
              onChange={(checked) => {
                setShouldSkipNextTime(checked);
                onChangeShouldSkipNextTime?.(checked);
              }}
              label={dictionary.dontPerformThisCheckNextTime}
            />
          </label>
        </Row>
      </Column>

      <Row spaceBetween>
        <Button
          label={dictionary.requestJobboardInformationConfirmationCancelText}
          variant="subtle"
          onClick={onCancel}
        />
        <Button
          label={dictionary.requestJobboardInformationConfirmationConfirmText}
          variant="highlight"
          onClick={onConfirm}
        />
      </Row>
    </Column>
  );
};

export default ConfirmJobboardCreditUsage;
