import { Mail_01 } from '@recruitrobin/robin-theme/web-icons';
import { Badge, Row } from 'components/ui/atoms';
import { useCallback, useContext } from 'react';
import { SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useGetCampaignNewRepliesQuery } from 'shared/hooks/useGetCampaignNewRepliesQuery';
import { useStyles } from './NewMessageReplies.styles';

export const NewMessageReplies = () => {
  const { data: newReplies } = useGetCampaignNewRepliesQuery();
  const { setIsOpen } = useContext(SidebarOverlayFixedContext);
  const classes = useStyles();

  const onNavigateToNotification = useCallback(() => {
    setIsOpen(true);
  }, []);

  return (
    <div css={classes.root}>
      <Row alignCenter onClick={onNavigateToNotification} data-tutorial-id="notifications-bell" css={classes.content}>
        <Badge label={newReplies} showLabel={newReplies > 0} variant="highlight" alignment="top right">
          <Mail_01 color={(c) => c.neutral.variant[70]} size={18} />
        </Badge>
      </Row>
    </div>
  );
};
