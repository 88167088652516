import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { DialogStyleStates } from './types';

const styles = {
  root: ({ theme }) =>
    css({
      borderWidth: 1,
      borderRadius: 3,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[0],
      background: theme.colors.neutral.variant[0],
      padding: 24,
      minWidth: 400,
    }),
} satisfies Styles<DialogStyleStates>;

export const useStyles = withStyles(styles);
