import { RobinSkeleton } from 'components/ui/molecules/RobinSkeleton';
import { AnimatePresence } from 'framer-motion';
import { memo, useContext, useEffect, useRef, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { useStyles } from './CandidateCVIframe.styles';

export const CandidateCardCvIframe = memo(
  ({
    cvFileUrl,
    retryWithinMilliseconds = 5000,
    shouldUseGoogleDocs = true,
  }: {
    cvFileUrl: string;
    retryWithinMilliseconds?: number;
    activeJobBoard?: CampaignJobboard;
    shouldUseGoogleDocs?: boolean;
  }) => {
    const classes = useStyles({ shouldUseGoogleDocs });
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [urlTimestamp, setUrlTimestamp] = useState(Date.now());
    const [isLoadingIframe, setIsLoadingIframe] = useState(true);
    const { dictionary } = useContext(LocalizationContext);

    useEffect(() => {
      const reloadIframeAfterTimeout = setTimeout(() => {
        const isLoaded = !iframeRef.current?.contentDocument;

        if (!isLoaded) {
          setUrlTimestamp(Date.now());
        }
      }, retryWithinMilliseconds);

      const verifyIframeIsLoadedInterval = setInterval(() => {
        const isLoaded = !iframeRef.current?.contentDocument;

        if (isLoaded) {
          setIsLoadingIframe(false);
          clearInterval(verifyIframeIsLoadedInterval);
          clearTimeout(reloadIframeAfterTimeout);
        }
      }, 100);

      return () => {
        clearTimeout(reloadIframeAfterTimeout);
        clearInterval(verifyIframeIsLoadedInterval);
      };
    }, [urlTimestamp]);

    const url = shouldUseGoogleDocs
      ? `https://docs.google.com/viewer?embedded=true&url=${cvFileUrl}&printtitle=false&t=${urlTimestamp}`
      : cvFileUrl;

    return (
      <div css={classes.root}>
        <iframe ref={iframeRef} css={classes.iframe} sandbox="allow-scripts allow-same-origin" src={url} />
        <div css={classes.hideOpenAnotherPageIframeButton} />
        <AnimatePresence>
          {isLoadingIframe && <RobinSkeleton loadingMessage={dictionary.loadingCandidateCv} css={classes.skeleton} />}
        </AnimatePresence>
      </div>
    );
  },
);
