import { MultipleSelectStyleStates } from './types';
import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css({
    justifyContent: 'center',
    height: '100%',
    padding: 8,
  }),
  input: ({ theme, props: { disabled } }) =>
    css({
      margin: 0,
      border: 0,
      outline: 0,
      height: 22,
      backgroundColor: 'transparent',
      width: 0,
      minWidth: 30,
      flexGrow: 1,
      textOverflow: 'ellipsis',
      '&::placeholder': {
        color: theme.colors.neutral.variant[40],
        ...(disabled && { color: theme.colors.neutral.variant[30] }),
      },
    }),
  tagsWrapper: css``,
  tagsPadding: css({ maxWidth: 'calc(100% - 50px)' }),
  filterLabel: css`
    cursor: pointer;
    display: flex;
  `,
  button: css`
    display: flex;
  `,
  tooltip: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[70]};
    color: ${theme.colors.neutral.variant[0]};
    border-radius: 4px;
    padding: 3px 9px;
    > div {
      white-space: nowrap;
    }
  `,
  tooltipArrow: ({ theme }) => css`
    fill: ${theme.colors.neutral.variant[70]};
  `,
  filterButton: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[0]};

    > svg {
      color: ${theme.colors.neutral.variant[70]};
      fill: ${theme.colors.neutral.variant[70]};
    }
  `,
  contextMenuHeader: ({ theme }) =>
    css({
      display: 'flex',
      padding: '6px 16px 12px',
      width: '100%',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: theme.colors.neutral.variant[30],
    }),
  portalWrapper: css`
    width: 300px;

    div.rr-menu-item-typography {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `,
} satisfies Styles<MultipleSelectStyleStates>;

export const useStyles = withStyles(styles);
