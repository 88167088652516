const language = localStorage.getItem('languageV2');

const enMessage =
  'Unable to send contact to Robin mobile app. Log in to the app and refresh this page after that to send this candidate to your cell phone.';
const nlMessage =
  'Kan contact niet verzenden naar de mobiele Robin-app. Log in op de app en ververs daarna deze pagina om deze kandidaat naar uw mobiele telefoon te sturen.';

const errorMessage = language === 'en' ? enMessage : nlMessage;

export class UserNotHaveExpoPushNotificationException extends Error {
  constructor(message = errorMessage) {
    super(message);
  }
}
