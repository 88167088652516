import { useEffect, useMemo, useState } from 'react';
import { OffsetScrollListsContextType } from './OffsetScrollListsContext.types';
import { createContext, useContextSelector } from 'use-context-selector';
import { CampaignManagerContext } from '../CampaignManagerContext';

const initialState: OffsetScrollListsContextType = {
  campaignListOffset: 0,
  candidateListOffset: 0,
  setCampaignListOffset: () => {
    throw Error('not implemented');
  },
  setCandidateListOffset: () => {
    throw Error('not implemented');
  },
};

export const OffsetScrollListsContext = createContext<OffsetScrollListsContextType>(initialState);

export const OffsetScrollListsProvider = ({ children }: { children: React.ReactNode }) => {
  const [campaignListOffset, setCampaignListOffset] = useState(initialState.campaignListOffset);
  const [candidateListOffset, setCandidateListOffset] = useState(initialState.candidateListOffset);
  const campaignId = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign?.id);

  useEffect(() => {
    setCandidateListOffset(initialState.candidateListOffset);
  }, [campaignId]);

  const valueMemoized = useMemo<OffsetScrollListsContextType>(() => {
    return {
      campaignListOffset,
      candidateListOffset,
      setCampaignListOffset,
      setCandidateListOffset,
    };
  }, [campaignListOffset, candidateListOffset, setCampaignListOffset, setCandidateListOffset]);

  return <OffsetScrollListsContext.Provider value={valueMemoized}>{children}</OffsetScrollListsContext.Provider>;
};
