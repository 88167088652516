import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  overlay: () =>
    css({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0,0,0,0.5)',
      zIndex: 9,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
