import { Padding, Row } from 'components/ui/atoms';
import { ChevronDown } from 'components/ui/atoms/icons';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from './styles';
import { useDebounce } from 'react-use';
import { sharedUtils } from 'utils';
import { ContextMenuRef, default as ContextMenu } from 'components/ui/molecules/ContextMenu';
import { default as PortalWrapper } from 'components/ui/molecules/PortalWrapper';

import type { SingleSelectOption, SingleSelectProps } from './types';

const SingleSelect = <T extends SingleSelectOption>({
  options,
  onChange,
  onFocus,
  onBlur,
  text = '',
  onTextChange,
  placeholder,
  autocomplete = false,
  contained = false,
  disabled = false,
  keepOpenWhileOptionsNotEmpty = false,
  noOptionsDefaultRender,
  className,
}: SingleSelectProps<T>) => {
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles({ contained, disabled, focused: isFocused, autocomplete });
  const shouldDisableInput = disabled || !autocomplete;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const contextMenuRef = useRef<ContextMenuRef>(null);
  const selectedOption = options.find((opt) => opt.label === text);
  const isTextEmpty = text.length === 0;
  const [isSelectFocused, setIsSelectFocused] = useState(false);
  const [isContextMenuFocused, setIsContextMenuFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useDebounce(() => setIsFocused(isSelectFocused || (isDropdownOpen && isContextMenuFocused)), 6, [
    isSelectFocused,
    isDropdownOpen,
    isContextMenuFocused,
  ]);

  useEffect(() => {
    if (isFocused) {
      onFocus?.();
    } else {
      onBlur?.();
    }
  }, [isFocused]);

  useEffect(() => {
    if (keepOpenWhileOptionsNotEmpty && !disabled) {
      if (options.length > 0 || noOptionsDefaultRender) {
        handleOpenDropdown();
      } else {
        handleCloseDropdown();
      }
    }
  }, [keepOpenWhileOptionsNotEmpty, options, disabled]);

  useEffect(() => {
    if (isSelectFocused && !disabled) {
      const handleKeyDown: DocumentKeyDownEventHandler = (e) => {
        if (autocomplete) {
          if (['ArrowDown', 'ArrowUp', 'Enter'].includes(e.key) || ([' '].includes(e.key) && isTextEmpty)) {
            handleOpenDropdown();

            sharedUtils.postpone(() => {
              const nodeList = contextMenuRef.current?.getMenuOptionNodeList();
              const [selectedItemIndex] = (nodeList &&
                selectedOption &&
                Array.from(nodeList.entries()).find(
                  ([, el]) => el.getAttribute('data-menu-item-id') === `${selectedOption.key}`,
                )) || [0, undefined];
              const indexToFocus: Record<KeyboardEvent['key'], number> = {
                ArrowDown: 0,
                ArrowUp: (nodeList?.length ?? 1) - 1,
                Enter: selectedItemIndex,
              };

              contextMenuRef.current?.setMenuOptionFocus(indexToFocus[e.key]);
            }, 16);
          }
        } else {
          if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter' || e.key === ' ') {
            handleOpenDropdown();
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [isSelectFocused, autocomplete, isDropdownOpen, selectedOption, isTextEmpty]);

  const handleChange = (opt: T) => {
    onChange(opt);
    handleCloseDropdown();
    handleFocusDropdown();
  };

  const handleOpenDropdown = () => {
    if (autocomplete) {
      inputRef.current?.focus();
    }

    setIsDropdownOpen(true);
  };
  const handleCloseDropdown = () => setIsDropdownOpen(false);
  const handleFocusDropdown = () =>
    sharedUtils.postpone(() => (autocomplete ? inputRef.current?.focus() : dropdownRef.current?.focus()));

  const handleSelectFocus: React.FocusEventHandler = () => setIsSelectFocused(true);
  const handleSelectBlur: React.FocusEventHandler = () => setIsSelectFocused(false);
  const handleContextMenuFocus = () => setIsContextMenuFocused(true);
  const handleContextMenuBlur = () => setIsContextMenuFocused(false);

  const handleInput: React.FormEventHandler = (e) => {
    if (autocomplete && isTextEmpty && (e.target as HTMLInputElement).value === ' ') return;

    onTextChange?.(e);
  };

  return (
    <>
      <Row
        ref={dropdownRef}
        css={classes.root}
        className={className}
        contained={contained}
        onClick={handleOpenDropdown}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        tabIndex={shouldDisableInput ? -1 : 0}
        spaceBetween
        alignCenter
        inline>
        <input
          ref={inputRef}
          css={classes.input}
          placeholder={placeholder}
          tabIndex={0}
          readOnly={shouldDisableInput}
          value={text}
          onInput={handleInput}
        />

        <Padding padding={[9, 16, 9, 0]}>
          <ChevronDown size={18} color={(color) => color.monochrome[disabled ? 40 : 80]} />
        </Padding>
      </Row>

      {(options.length > 0 || !!noOptionsDefaultRender) && (
        <PortalWrapper
          visible={isDropdownOpen}
          anchorEl={dropdownRef}
          onRequestClose={() => {
            handleCloseDropdown();
            handleFocusDropdown();
          }}
          contained>
          <ContextMenu
            ref={contextMenuRef}
            options={options}
            onChange={handleChange}
            onFocus={handleContextMenuFocus}
            onBlur={handleContextMenuBlur}
            selectedOption={selectedOption}
            autoFocus={!autocomplete && isDropdownOpen}
            maxHeight={400}
            highlight
            contained>
            {options.length === 0 ? noOptionsDefaultRender : undefined}
          </ContextMenu>
        </PortalWrapper>
      )}
    </>
  );
};

export default SingleSelect;
