import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { RadioGroupStyleState } from './types';

const HORIZONTAL_RADIO_GAP = 0;

const styles = {
  root: ({ props }) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const itemsPerRow = (props.itemsPerRow || 0) >= 2 ? props.itemsPerRow! : 2;
    const horizontalDirectionLabelWidth = (100 / itemsPerRow).toFixed(6);
    const horizontalDirectionLabelGap = ((HORIZONTAL_RADIO_GAP * (itemsPerRow - 1)) / itemsPerRow).toFixed(6);

    return css({
      ...(props.direction === 'horizontal' && {
        '& > .MuiFormControl-root > .MuiFormGroup-root': {
          flexDirection: 'row',
          gap: `${HORIZONTAL_RADIO_GAP}px`,
          '& > label': {
            width: `calc(${horizontalDirectionLabelWidth}% - ${horizontalDirectionLabelGap}px)`,
            marginRight: 0,
          },
        },
      }),
      ...(props.contained && {
        '& > fieldset': {
          width: '100%',
        },
      }),
    });
  },
} satisfies Styles<RadioGroupStyleState>;

export const useStyles = withStyles(styles);
