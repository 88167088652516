import { TransitionPatterns, TransitionStateStyles } from './types';

export const defaultTransitionPatterns: { [key in TransitionPatterns]: any } = {
  fade: {
    opacity: 0,
  },
  fadeGrow: {
    transformOrigin: 'top',
    transform: 'scale(0.98) rotateX(25deg)',
    opacity: 0,
  },
};

export const transitionPatterns: {
  [key in TransitionPatterns]: TransitionStateStyles;
} = {
  fade: {
    entered: { opacity: 1 },
    entering: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  },
  fadeGrow: {
    entered: {
      opacity: 1,
      transform: 'scale(1) rotateX(0deg)',
    },
    entering: {
      opacity: 1,
      transform: 'scale(1) rotateX(0deg)',
    },
    exiting: {
      transform: 'scale(0.98) rotateX(25deg)',
      opacity: 0,
    },
    exited: {
      transform: 'scale(0.98) rotateX(25deg)',
      opacity: 0,
    },
  },
};
