import { useEffect, useRef } from 'react';

import KeypressClassToggler from 'helpers/keypressClassToggler';

import { useStyles } from './SelectOption.styles';
import { SelectOptionProps } from './types';
import { Checkbox } from 'shared/components/CheckboxGroup';

const SelectOption = ({ option, multiple = false, state: { selected } }: SelectOptionProps) => {
  const optionRef = useRef<HTMLSpanElement | null>(null);
  const classes = useStyles({ multiple });

  useEffect(() => {
    const identifier = optionRef.current?.parentElement?.id;
    const manualClick = () => {
      optionRef.current?.parentElement?.click();
    };

    const optionKeypressHandler = new KeypressClassToggler(
      document.body,
      { Space: { onKeyUp: manualClick } },
      { shouldCall: () => !!document.querySelector(`#${identifier}[data-focus="true"]`) },
    ).init();

    return () => {
      optionKeypressHandler.dispose();
    };
  }, []);

  return (
    <span ref={optionRef} css={classes.root}>
      {multiple && <Checkbox checked={selected} disableTabIndex />}
      {option.title}
    </span>
  );
};

export default SelectOption;
