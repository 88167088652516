import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    min-width: 342px;
    height: 100vh;
    border-radius: 0px 14px 0px 0px;
    background-color: ${theme.colors.neutral.variant[0]};
  `,
  overlayHeader: css`
    position: relative;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-left: 24px;
    z-index: 4;
  `,
  body: css`
    height: 100%;
    padding: 0 6px;
  `,
  overlayHr: ({ theme }) => css`
    height: 1px;
    background-color: ${theme.colors.neutral.variant[30]};
    margin: 8px 12px;
  `,
  overlayArrow: ({ theme }) => css`
    position: absolute;
    right: 14px;
    z-index: 1;
    cursor: pointer;
    color: ${theme.colors.neutral.variant[60]};
  `,
  overlayCampaignList: css`
    height: calc(100% - 135px);
    width: 100%;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
