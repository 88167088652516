import { blobToBase64 } from './blobToBase64';

export const blobToHtmlText = async (
  blob: Blob,
  props?: {
    alt?: string;
    height?: string;
    width?: string;
  },
) => {
  const files = await blobToBase64(blob);
  return `<div><!--StartFragment--><img src='${files ?? ''}' alt='${props?.alt}' height='${props?.height}' width='${
    props?.width
  }'/><!--EndFragment--></div>`;
};
