import { AxiosError, AxiosResponse } from 'axios';

export type Error<T = any> = {
  [x in keyof T]: string;
};

export type BaseError<T = any> = AxiosError<T>;

export enum FilterOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type BasePaginatedSort<T> = {
  by: keyof T | string;
  order?: FilterOrder;
};

export type BasePagination = {
  limit?: number;
  offset?: number;
};

export type BasePaginatedFilters<T> = BasePagination & {
  search?: string;
  sort?: BasePaginatedSort<T>;
} & Partial<T>;

export type PaginatedFilters<T = unknown> = BasePaginatedFilters<T>;

export type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export type BaseCallback<T> = Promise<AxiosResponse<T>>;

export type EmptyResponse = {};

export type EmptyResponseCallback = BaseCallback<EmptyResponse>;
