import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  skeletonRoot: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 32px;
    gap: 32px;
  `,
  skeletonHeader: css`
    display: flex;
    flex-direction: column;
  `,
  skeletonBody: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
  skeletonFooter: css`
    display: flex;
    gap: 16px;
    align-self: flex-end;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
