import { useMutation, useQueryClient } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { logger } from 'config/logger';
import { diff } from 'deep-diff';
import { useGTM } from 'hooks/gtm';
import { useCampaignAiGTM } from 'hooks/shared/useCampaignAiGTM';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { QueryKey } from 'types/query';
import { createContext, useContextSelector } from 'use-context-selector';
import { getDifferenceBetweenObjects } from 'utils/shared/getDifferenceBetweenObjects';
import { removeQueryParam } from 'utils/shared/urlUtils';
import { ATSIntegrationContext } from 'views/LoggedIn/components/ATSIntegrations/contexts/ATSIntegrationContext';
import {
  AiSuggestionFiltersType,
  TriggerAiSuggestionsFunctionType,
  aiSuggestionFilters,
  pickAiFiltersFromFiltersArray,
  processAllAiSuggestionChanges,
  verifyAiFiltersDiff,
} from './helpers';
import {
  EducationDegree,
  FilterControlState,
  Filters,
  FiltersContextProps,
  FiltersContextProviderProps,
  FiltersControlStateConfig,
  JobTitle,
  Location,
  SaveSearchFiltersHandler,
  SetSearchFiltersHandler,
} from './types';
import { useContainer } from './useContainer';

const filtersInitialState: Filters = {
  job_titles: [],
  job_title_type: 'all',
  locations: [],
  has_email: false,
  has_phone: false,
  has_insight: false,
  total_experience: { max_experience_months: 1200, min_experience_months: 0 },
  skills: [],
  languages: [],
  updated_at: { key: 'any' },
  employment_type_preference: 'any',
  companies: [],
  industries: [],
  graduation_year: { max_months: 0, min_months: 0 },
  schools: [],
  education_degrees: [],
  education_fields: [],
  sources: [],
  sort_by: 'updated_at',
  default_location: { lat: 0, lon: 0 },
  hide_candidates_statuses: [],
  hide_candidates_period: 7,
};

const hiddenFilters: Array<keyof Filters> = [
  'total_experience',
  'skills',
  'languages',
  'employment_type_preference',
  'companies',
  'education_degrees',
  'graduation_year',
];

export const initialState: FiltersContextProps = {
  isLoading: true,
  isLoadingSaveFilters: true,
  filters: filtersInitialState,
  searchFiltersControlState: {
    hasUnsavedChanges: false,
    loaded: false,
    lastSavedState: filtersInitialState,
    lastSavedAppliedFiltersLength: 0,
    isUsingHiddenFilters: false,
  },
  appliedFiltersLength: 0,
  searchesAsStringArray: [],
  setSearchFilters: () => undefined,
  setSearchFiltersControlState: () => undefined,
  saveSearchFilters: () => Promise.resolve(),
  getAppliedFiltersLength: () => 0,
  asteriskFieldsReached: false,
};

export const FiltersContext = createContext<FiltersContextProps>(initialState);

const IGNORE_FILTER_KEYS_AT_DIFF_COUNT: Array<keyof Filters> = ['default_location'];

const sanitizeDefaultFilterValue = <T extends keyof Filters>(currentKey: T, currentValue: Filters[T]): Filters[T] => {
  if (currentKey === 'total_experience') {
    const currentValueTyped = currentValue as Filters['total_experience'];
    const initialValue = initialState.filters.total_experience;

    return {
      min_experience_months: currentValueTyped.min_experience_months ?? initialValue.min_experience_months,
      max_experience_months: currentValueTyped.max_experience_months ?? initialValue.max_experience_months,
    } as Filters[T];
  }

  return currentValue;
};

const getAppliedFiltersLength = (filters: Filters): number => {
  const diffLength = (Object.keys(initialState.filters) as Array<keyof Filters>).reduce((count, currentKey) => {
    const diffArr = IGNORE_FILTER_KEYS_AT_DIFF_COUNT.includes(currentKey)
      ? undefined
      : diff(initialState.filters[currentKey], sanitizeDefaultFilterValue(currentKey, filters[currentKey]));

    return count + (diffArr ? 1 : 0);
  }, 0);

  return diffLength;
};

export const formatFiltersToPatch = (filters: Filters) => ({
  ...filters,
  locations: filters.locations.map((location) => {
    const distance = `${`${location.distance}`.replace(/[^0-9]/g, '')}km`;

    return { ...location, distance };
  }),
  // job_titles: filters.job_titles.map(({ exclude, isSynonym, key, must }) => ({ exclude, isSynonym, key, must })),
});

export const FiltersContextProvider = ({ children, onSaveFilters, onFiltersChange }: FiltersContextProviderProps) => {
  const campaign = useContextSelector(CampaignManagerContext, (state) => state.selectedCampaign);

  const queryClient = useQueryClient();
  const gtm = useGTM();
  const [searchParams] = useSearchParams();
  const aiGtm = useCampaignAiGTM({ campaignId: campaign?.id });
  const { tenantCVSources, hasATSSource, featureTogglesIsLoading, availableJobBoardsIsLoading } =
    useContext(UserContext);
  const { hasActiveATS } = useContext(ATSIntegrationContext);
  const { isLoading: atsIsLoading } = useContext(ATSIntegrationContext);
  const justCreatedNewCampaign = searchParams.get('justCreatedNewCampaign') === 'true';

  const filtersDependenciesLoaded = useMemo(() => {
    return !featureTogglesIsLoading && !availableJobBoardsIsLoading && !atsIsLoading;
  }, [featureTogglesIsLoading, availableJobBoardsIsLoading, atsIsLoading]);

  const [filtersInitialized, setFiltersInitialized] = useState(false);
  const [filters, setSearchFilters] = useState<Filters>(initialState.filters);
  const [searchFiltersControlState, setSearchFiltersControlState] = useState<FilterControlState>(
    initialState.searchFiltersControlState,
  );
  const [asteriskFieldsReached, setAsteriskFieldsReached] = useState(initialState.asteriskFieldsReached);

  const lastAiFiltersRef = useRef(initialState.filters);
  const aiFiltersDiffRef = useRef<Record<string, { added: any[]; removed: any[] }> | null>(null);
  const { searchesAsStringArray } = useContainer({ searchFiltersControlState });

  useEffect(() => {
    onFiltersChange?.(filters);
  }, [filters]);

  useEffect(() => {
    const skillsAsteriskCount = filters.skills.reduce((count, skill) => count + (skill.key.includes('*') ? 1 : 0), 0);

    setAsteriskFieldsReached(skillsAsteriskCount > 5);
  }, [filters.skills]);

  const getIsUsingHiddenFiltersState = useCallback(
    (filters: Filters) => hiddenFilters.some((key) => !!diff(initialState.filters[key], filters[key])),
    [],
  );

  const triggerAiSuggestionsAdded = ({
    key,
    filters,
    campaignId,
  }: {
    key: AiSuggestionFiltersType;
    filters: Array<JobTitle | Location | EducationDegree>;
    campaignId: number | string;
  }) => {
    const aiSynonymsAddedFilters = filters.filter(
      (item: { isAiCreated?: boolean; isSynonym?: boolean }) => item.isAiCreated || item.isSynonym,
    );

    aiSynonymsAddedFilters.length &&
      aiGtm.aiSuggestionsAdded({
        campaignId,
        campaignFilter: key === 'job_titles' ? 'job_title' : key,
        filters: aiSynonymsAddedFilters,
      });
  };

  const triggerAISuggestionsRemoved = ({
    key,
    filters,
    campaignId,
  }: {
    key: AiSuggestionFiltersType;
    filters: Array<JobTitle | Location | EducationDegree>;
    campaignId: number;
  }) => {
    const aiSynonymsRemovedFilters = filters.filter(
      (item: { isAiCreated?: boolean; isSynonym?: boolean }) => item.isAiCreated || item.isSynonym,
    );

    aiSynonymsRemovedFilters.length &&
      aiGtm.aiSuggestionsRemoved({
        campaignId,
        campaignFilter: key === 'job_titles' ? 'job_title' : key,
        filters: aiSynonymsRemovedFilters,
      });
  };

  const initializeFilters = useCallback(
    (filters: Filters, campaignId: number) => {
      const sanitinizedFilters: Filters = {
        ...filters,
        hide_candidates_statuses: filters.hide_candidates_statuses ?? initialState.filters.hide_candidates_statuses,
        hide_candidates_period: filters.hide_candidates_period ?? initialState.filters.hide_candidates_period,
      };

      setSearchFilters(sanitinizedFilters);
      setSearchFiltersControlState((searchFiltersControlState) => ({
        ...searchFiltersControlState,
        lastSavedState: sanitinizedFilters,
        loaded: true,
        lastSavedAppliedFiltersLength: getAppliedFiltersLength(sanitinizedFilters),
        isUsingHiddenFilters: getIsUsingHiddenFiltersState(filters),
      }));

      lastAiFiltersRef.current = filters;

      if (justCreatedNewCampaign) {
        aiGtm.campaignCreated();
        aiSuggestionFilters.forEach((key) => triggerAiSuggestionsAdded({ campaignId, filters: filters[key], key }));
        removeQueryParam('justCreatedNewCampaign');
      }
    },
    [aiGtm, setSearchFilters, setSearchFiltersControlState],
  );

  useEffect(() => {
    if (campaign && filtersDependenciesLoaded && !filtersInitialized) {
      const hasNewResults = !!campaign.new_candidates_count && campaign.new_candidates_count > 0;

      initializeFilters(
        {
          ...campaign.current_filter.filters,
          sort_by: hasNewResults ? 'updated_at' : campaign.current_filter.filters.sort_by,
        },
        campaign.id,
      );
      setFiltersInitialized(true);
    }
  }, [campaign, filtersDependenciesLoaded, initializeFilters, filtersInitialized]);

  const { mutateAsync: mutateUpdateCampaign, isLoading: isLoadingSaveFilters } = useMutation(
    async ({ campaignId, data }: { campaignId: number; data: any }) => {
      const result = await campaignsClient.updateCampaign(campaignId, data);
      await Promise.all([
        queryClient.invalidateQueries([QueryKey.selectedCampaignContextUseCandidates, { id: campaignId }]),
        queryClient.invalidateQueries([QueryKey.selectedCampaignContextUseFacets, campaignId]),
        queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaign, campaignId]),
      ]);

      return result;
    },
    {},
  );

  const triggerFiltersGAEvents = ({
    currentFilters,
    lastSavedFilters,
    changedFilters,
  }: {
    currentFilters: Filters;
    lastSavedFilters: Filters;
    changedFilters: Array<keyof Filters>;
  }) => {
    if (!campaign) throw Error('campaign undefined');

    changedFilters.forEach((changedFilter) => {
      if (changedFilter === 'job_titles' || changedFilter === 'locations' || changedFilter === 'education_degrees') {
        if (changedFilter === 'job_titles') {
          triggerManualJobTitleAdded({
            currentJobTitles: currentFilters.job_titles,
            lastSavedJobTitles: lastSavedFilters.job_titles,
            campaignId: campaign.id,
          });
        }
      }
    });

    changedFilters.map((name) => gtm.editCampaignFilter(campaign?.id, name));
  };

  const triggerManualJobTitleAdded = ({
    currentJobTitles,
    lastSavedJobTitles,
    campaignId,
  }: {
    currentJobTitles: JobTitle[];
    lastSavedJobTitles: JobTitle[];
    campaignId: number;
  }) => {
    const insertedFilterItems = currentJobTitles.filter(
      ({ key }) => !lastSavedJobTitles.some(({ key: k }) => k === key),
    );

    const manualJobTitleAddedFilters = insertedFilterItems.filter((jobTitle) => !jobTitle.isSynonym);

    manualJobTitleAddedFilters.length &&
      aiGtm.manualJobTitleAdded({
        campaignId,
        filters: manualJobTitleAddedFilters,
      });
  };

  const saveSearchFilters: SaveSearchFiltersHandler = useCallback(
    (getFilters, config) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (res) => {
        try {
          if (!campaign) throw Error('campaign undefined');

          await mutateUpdateCampaign({
            campaignId: config?.campaignId ?? campaign.id,
            data: { current_filter: { filters: formatFiltersToPatch(getFilters?.(filters) ?? filters) } },
          });

          setSearchFiltersControlState((searchFiltersControlState) => ({
            ...searchFiltersControlState,
            hasUnsavedChanges: false,
            lastSavedState: filters,
            lastSavedAppliedFiltersLength: appliedFiltersLength,
          }));

          processAllAiSuggestionChanges(
            aiFiltersDiffRef,
            aiSuggestionFilters,
            campaign.id,
            triggerAiSuggestionsAdded as TriggerAiSuggestionsFunctionType,
            triggerAISuggestionsRemoved as TriggerAiSuggestionsFunctionType,
          );

          lastAiFiltersRef.current = filters;

          onSaveFilters?.();
          return res();
        } catch (e) {
          logger.error(e);
          return res();
        }
      });
    },
    [filters, setSearchFiltersControlState, mutateUpdateCampaign, campaign?.id],
  );

  const appliedFiltersLength = useMemo<number>((): number => {
    return getAppliedFiltersLength(filters);
  }, [filters]);

  const filterAvailableSources = useCallback(
    (baseSources: string[]) => {
      if (tenantCVSources.length === 0) {
        return baseSources;
      }

      const availableSources = [
        ...(hasATSSource || hasActiveATS ? ['ats'] : []),
        ...tenantCVSources.filter(({ enabled }) => enabled).map(({ cv_source }) => cv_source),
      ];

      return baseSources.filter((source) => availableSources.includes(source));
    },
    [hasATSSource, hasActiveATS, tenantCVSources],
  );

  const transformState = useCallback(
    (filters: Filters, config?: FiltersControlStateConfig) => {
      filters.sources = filterAvailableSources(filters.sources) as CampaignJobboard[];

      if (searchFiltersControlState.loaded) {
        setSearchFiltersControlState((searchFiltersControlState) => {
          const filtersDiff = diff(filters, searchFiltersControlState.lastSavedState);

          const lastSavedFilters = searchFiltersControlState.lastSavedState;

          const changedFilters = getDifferenceBetweenObjects(lastSavedFilters, filters) as Array<keyof Filters>;

          triggerFiltersGAEvents({
            currentFilters: filters,
            lastSavedFilters,
            changedFilters,
          });

          return {
            ...searchFiltersControlState,
            hasUnsavedChanges:
              !!filtersDiff && (searchFiltersControlState.hasUnsavedChanges || config?.forceSave !== true),
            isUsingHiddenFilters: getIsUsingHiddenFiltersState(filters),
          };
        });
      }

      return filters;
    },
    [filterAvailableSources, searchFiltersControlState.loaded],
  );

  const setSearchFiltersWithTransformers = useCallback<SetSearchFiltersHandler>(
    (newState, config) => {
      return setSearchFilters((state) => {
        // REFACTOR: this could be encapsulated in a function
        const nState = typeof newState === 'function' ? newState(state) : newState;
        const newFilters = transformState(nState, config);

        const newAiFilters = pickAiFiltersFromFiltersArray(newFilters);
        const oldAiFilters = pickAiFiltersFromFiltersArray(lastAiFiltersRef.current);
        aiFiltersDiffRef.current = verifyAiFiltersDiff(newAiFilters, oldAiFilters);

        if (config?.forceSave && !searchFiltersControlState.hasUnsavedChanges) {
          saveSearchFilters(() => newFilters);
        }

        return newFilters;
      });
    },
    [transformState, saveSearchFilters, searchFiltersControlState.hasUnsavedChanges],
  );

  const isLoading = useMemo(() => {
    return !searchFiltersControlState.loaded || isLoadingSaveFilters;
  }, [searchFiltersControlState.loaded, isLoadingSaveFilters]);

  const contextValue = useMemo<FiltersContextProps>((): FiltersContextProps => {
    return {
      isLoadingSaveFilters,
      isLoading,
      filters,
      searchFiltersControlState,
      appliedFiltersLength,
      searchesAsStringArray,
      setSearchFilters: setSearchFiltersWithTransformers,
      setSearchFiltersControlState,
      saveSearchFilters,
      getAppliedFiltersLength,
      asteriskFieldsReached,
    };
  }, [
    filters,
    searchFiltersControlState,
    appliedFiltersLength,
    searchesAsStringArray,
    setSearchFiltersWithTransformers,
    saveSearchFilters,
    isLoading,
    getAppliedFiltersLength,
    isLoadingSaveFilters,
    asteriskFieldsReached,
  ]);

  return <FiltersContext.Provider value={contextValue}>{children}</FiltersContext.Provider>;
};
