export const generateBinaryUUID = () => {
  const byteArray = new Uint8Array(16);

  for (let i = 0; i < byteArray.length; i++) {
    byteArray[i] = Math.floor(Math.random() * 256);
  }

  byteArray[6] = (byteArray[6] & 0x0f) | 0x40;
  byteArray[8] = (byteArray[8] & 0x3f) | 0x80;

  return byteArray;
};

export const getStringBinaryUUID = () => {
  const binaryUUID = generateBinaryUUID();
  const binaryString = String.fromCharCode(...binaryUUID);

  return binaryString;
};
