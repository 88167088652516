import { ChangeEvent, useContext } from 'react';
import { Dialog } from '@material-ui/core';

import { Card } from 'shared/components/layouts/Card/Card';
import { Button } from 'components/ui/molecules';
import Checkbox from 'shared/components/CheckboxGroup/Checkbox';

import { useStyles } from './ConfirmationPrompt.styles';
import { Props } from './ConfirmationPrompt.types';
import { ClipSpinner } from 'shared/icons/ClipSpinner/ClipSpinner';
import { Check } from '@material-ui/icons';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

export const ConfirmationPrompt = ({
  title,
  description,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  open = false,
  isRequesting = false,
  setOpen = () => null,
  onCancel = () => null,
  onConfirm = () => null,
  onAskAgain = () => null,
  shouldShowAskAgain = false,
}: Props) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onAskAgain(!e.target.checked);
  };

  const handleOnClose = () => {
    onCancel();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleOnClose}>
      <Card>
        <div css={classes.root}>
          <div css={classes.title}>{title}</div>
          <div css={classes.description}>{description}</div>
          {shouldShowAskAgain && (
            <div css={classes.description}>
              <Checkbox label={dictionary.dontPerformThisCheckNextTime} onChange={(e) => handleChange(e)} />{' '}
            </div>
          )}
          <div css={classes.ctas}>
            <Button variant="subtle" onClick={handleOnClose} label={cancelLabel} />

            <Button
              variant="highlight"
              disabled={isRequesting}
              onClick={() => [onConfirm()]}
              icon={isRequesting ? <ClipSpinner variant="currentColor" size={16} /> : <Check />}
              iconAlignment="right"
              label={confirmLabel}
            />
          </div>
        </div>
      </Card>
    </Dialog>
  );
};
