import { useContext } from 'react';
import { InformationTag } from 'components/ui/molecules/index';
import { Users_01 } from '@recruitrobin/robin-theme/web-icons';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

export const AvailableCredits = () => {
  const { userCredits, availableCredits, currentUser } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const isPayPerMatch = currentUser?.tenant.payment_method === 'PAY_PER_MATCH';

  if (!userCredits || availableCredits === undefined) {
    return null;
  }

  return (
    <InformationTag
      type="default"
      size="small"
      icon={<Users_01 size={16} />}
      label={dictionary.availableCredits(availableCredits, userCredits?.total, isPayPerMatch)}
    />
  );
};
