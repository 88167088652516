import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { ColumnStyleStates } from './types';

const styles = {
  root: ({ props: { gap, inline, contained, expand, overflowAuto, alignCenter, fullFlex, start, center, end } }) =>
    css({
      display: inline ? 'inline-flex' : 'flex',
      flexDirection: 'column',
      gap,
      ...(contained && { width: '100%' }),
      ...(expand && { height: '100%' }),
      ...(overflowAuto && { overflow: 'auto' }),
      ...(alignCenter && { alignItems: 'center' }),
      ...(fullFlex && { flex: 1 }),
      ...(start && { justifyContent: 'flex-start' }),
      ...(center && { justifyContent: 'center' }),
      ...(end && { justifyContent: 'flex-end' }),
    }),
} satisfies Styles<ColumnStyleStates>;

export const useStyles = withStyles(styles);
