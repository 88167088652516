import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { atsIntegrationsClient } from 'clients/ATSIntegrationsClient/ATSIntegrationsClient';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { ATSIntegrationContextProps, ATSIntegrationContextProviderProps } from './types';
import { paramBind } from 'helpers/useQueryHelpers';

const defaultContext: ATSIntegrationContextProps = {
  myConnections: [],
  teamConnections: undefined,
  activeATS: undefined,
  hasActiveATS: undefined,
  hasValidATSConnected: undefined,
  isLoading: undefined,
  activeAtsName: undefined,
};

export const ATSIntegrationContext = createContext(defaultContext);

export const ATSIntegrationContextProvider = ({ children }: ATSIntegrationContextProviderProps) => {
  const [activeAtsName, setActiveAtsName] = useState(true);

  const { isLoggedIn } = useContext(UserContext);

  const {
    isLoading,
    data: { data: { my_connections: _myConnections = [], team_connections: _teamConnections = [] } = {} } = {},
  } = useQuery(['getAllATS'], paramBind(atsIntegrationsClient.getAll), { enabled: isLoggedIn });

  const { data: { data: { result: hasValidATSConnected = null } = {} } = {} } = useQuery(
    ['hasValidATSConnected'],
    paramBind(atsIntegrationsClient.hasValidATSConnected),
    { enabled: isLoggedIn },
  );

  const activeATS = useMemo(() => {
    const [activeATS] = [..._teamConnections, ..._myConnections].filter(({ is_active }) => is_active === true);
    return activeATS;
  }, [_teamConnections, _myConnections]);

  useEffect(() => {
    if (activeATS) {
      const atsName = activeATS.name;
      setActiveAtsName(!!atsName);
    }
  }, [hasValidATSConnected, activeATS, setActiveAtsName]);

  const hasActiveATS = useMemo(() => !!activeATS?.id, [activeATS]);

  const myConnections = _myConnections.map((connection: any) => ({
    ...connection,
    is_active: true,
    shouldConfirmChange: false,
  }));

  const teamConnections = _teamConnections.map((connection: any) => ({
    ...connection,
    is_active: false,
    shouldConfirmChange: true,
  }));

  return (
    <ATSIntegrationContext.Provider
      value={{
        myConnections,
        teamConnections,
        activeATS,
        hasActiveATS,
        hasValidATSConnected,
        isLoading,
        activeAtsName,
      }}>
      {children}
    </ATSIntegrationContext.Provider>
  );
};
