/* eslint-disable @typescript-eslint/no-unused-vars*/
type KeyName = string | number;

type KeypressClassTogglerOptions = {
  identifier?: string;
  code: KeyName;
  eventTrigger: 'keyUp' | 'keyDown';
};

export type KeypressClassTogglerCallback = (el: HTMLElement, options: KeypressClassTogglerOptions) => void;

type KeyHandlers = {
  [key in KeyName]: {
    onKeyUp?: KeypressClassTogglerCallback;
    onKeyDown?: KeypressClassTogglerCallback;
    classes?: string | Array<string>;
    stopPropagation?: boolean;
  };
};

type ShouldCallParams = {
  el: HTMLElement;
  identifier?: string;
};

type KeypressOptions = {
  identifier?: string;
  shouldCall?: (params: ShouldCallParams) => boolean;
  preventCall?: (params: ShouldCallParams) => boolean;
  debug?: boolean;
  disablePreventDefault?: boolean;
};

class KeypressClassToggler {
  private el: HTMLElement;
  private keyHandlers: KeyHandlers;
  private options?: KeypressOptions;
  keysPressed: { [key: string]: boolean } = {};

  constructor(el: HTMLElement, keyHandlers: KeyHandlers, options?: KeypressOptions) {
    this.el = el;
    this.keyHandlers = keyHandlers;
    this.options = options;
  }

  init = () => {
    this.el.addEventListener('keydown', this.handleKeyDown);
    this.el.addEventListener('keyup', this.handleKeyUp);

    return this;
  };

  dispose = () => {
    this.el.removeEventListener('keydown', this.handleKeyDown);
    this.el.removeEventListener('keyup', this.handleKeyUp);

    return this;
  };

  private setKeyPressed(e: KeyboardEvent, state: boolean) {
    const keyName = e.code;
    const { onKeyUp, onKeyDown, classes, stopPropagation } = this.keyHandlers[keyName] || {};
    const hasHandler = !!onKeyUp || !!onKeyDown;

    if (stopPropagation) {
      e.stopPropagation();
    }

    if (state) {
      this.keysPressed[keyName] = true;
    } else {
      delete this.keysPressed[keyName];
    }

    if (hasHandler || classes) {
      if (classes) {
        const cs = Array.isArray(classes) ? classes : [classes];

        if (state) {
          this.el.classList.add(...cs);
        } else {
          cs.map((c) => this.el.classList.remove(c));
        }
      }

      const shouldCall =
        this.options?.shouldCall?.({
          el: this.el,
          identifier: this.options?.identifier,
        }) ?? true;

      const currentHandler = state ? onKeyDown : onKeyUp;

      if (shouldCall) {
        currentHandler?.(this.el, {
          identifier: this.options?.identifier,
          code: keyName,
          eventTrigger: state ? 'keyDown' : 'keyUp',
        });
      }

      return true;
    }

    return false;
  }

  private debug = (e: KeyboardEvent) => {
    if (!this.options?.debug) return;

    console.debug('[KeypressClassToggler debug]', { element: this.el, code: e.code });
  };

  private preventDefault = (e: KeyboardEvent) => {
    !this.options?.disablePreventDefault && e.preventDefault();
  };

  private preventCall = (e: KeyboardEvent) => {
    if (this.options?.preventCall && this.options.preventCall({ el: this.el })) {
      return true;
    }
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    this.debug(e);
    if (this.preventCall(e)) {
      return;
    }

    if (this.setKeyPressed(e, true)) {
      this.preventDefault(e);
    }
  };

  private handleKeyUp = (e: KeyboardEvent) => {
    this.debug(e);
    if (this.preventCall(e)) {
      return;
    }

    if (this.setKeyPressed(e, false)) {
      this.preventDefault(e);
    }
  };
}

export default KeypressClassToggler;
