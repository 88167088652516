import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) => css`
    display: flex;
    position: relative;

    width: 100vw;
    height: 100vh;

    overflow: hidden;

    background-color: ${theme.colors.neutral.variant[10]};
  `,
  body: css`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    padding: 0 14px;
  `,
  iframe: ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    background-color: ${theme.colors.neutral.variant[10]};
  `,
  iframeNavbar: css`
    left: 0;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
