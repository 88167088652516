import RadioGroup from './RadioGroup';
// @ts-ignore
import { Controller, useFormContext } from 'react-hook-form';
import { FieldValues, RadioGroupPropsWithUseFormProps } from './types';

type ControlError = { message?: string };

const RadioGroupWithUseForm = <T extends FieldValues>({
  control: parentControl,
  errors: parentErrors,
  ...props
}: RadioGroupPropsWithUseFormProps<T>) => {
  const { errors = parentErrors, control = parentControl } = useFormContext() || {};
  const instanceErrors = errors?.[props.name] as ControlError;

  return (
    <Controller
      as={RadioGroup}
      {...props}
      control={control}
      hasError={props.hasError || !!instanceErrors}
      errorMessage={props.errorMessage || instanceErrors?.message}
      required={!!props.rules?.required}
    />
  );
};

export default RadioGroupWithUseForm;
