import { FormHelperErrorStateStyles } from './types';
import { withStyles, Styles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props }) =>
    css({
      '&.MuiFormHelperText-root': {
        ...(props.disableMarginTop && {
          marginTop: 'unset',
        }),
      },
      '& > span': {
        display: 'inline-flex',
        alignItems: 'center',
        '& > :first-of-type': {
          marginRight: 8,
        },
        color: theme.colors.tertiary.red[60],
      },
    }),
} satisfies Styles<FormHelperErrorStateStyles>;

export const useStyles = withStyles(styles);
