import { ImageBase64ConversionException } from 'shared/exceptions/ImageBase64ConversionException';

export function encodeBase64(str: string) {
  const utf8Bytes = new TextEncoder().encode(str);
  const binaryString = Array.from(utf8Bytes, (byte) => String.fromCharCode(byte)).join('');
  return btoa(binaryString);
}

export function decodeBase64(base64Str: string) {
  const binaryString = atob(base64Str);
  const bytes = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
  return new TextDecoder().decode(bytes);
}

export const convertHtmlImagesToBase64 = async (signature: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(signature, 'text/html');
  const images = doc.querySelectorAll('img');

  const promises: Promise<unknown>[] = [];

  images.forEach((img) => {
    promises.push(
      new Promise<void>((res, rej) => {
        getImageAsBase64(img.src)
          .then((src) => {
            img.src = src;

            res();
          })
          .catch(rej);
      }),
    );
  });

  try {
    await Promise.all(promises);
  } catch (error) {
    console.error(error);

    throw new ImageBase64ConversionException();
  }

  return doc.body.innerHTML;
};

const getImageAsBase64 = (imgUrl: string) => {
  return new Promise<string>((res, rej) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      try {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) throw Error('Canvas context not found while converting images to base64');

        ctx.drawImage(img, 0, 0);

        const dataUrl = canvas.toDataURL('image/png');

        res(dataUrl);
      } catch (error) {
        rej(error);
      }
    };

    img.onerror = (error) => rej(error);

    img.src = imgUrl;
  });
};
