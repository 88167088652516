import { Candidate } from 'model';
import { useEffect } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { useCampaign } from 'shared/contexts/CampaignManagerContext/CampaignManagerContext.useCampaign';
import { UseActionsHook } from '../types';

const failedRequests: Record<string, boolean> = {};

export const useCandidateMatchEffect = (
  candidate: Candidate | undefined,
  { actions }: { actions: ReturnType<UseActionsHook> },
) => {
  const { campaign } = useCampaign(candidate?.campaignId);

  useEffect(() => {
    const attemptFailed = candidate && failedRequests[candidate.es_person_id];

    if (candidate?.status === CANDIDATE_STATUS.NEW && campaign && !actions.isLoading.makeInvisible && !attemptFailed) {
      if (campaign.rated_candidates.some(({ es_person_id }) => candidate.platformMatches.includes(es_person_id))) {
        actions.onMakeInvisible({ alreadyInteracted: true }).catch(() => {
          failedRequests[candidate.es_person_id] = true;
        });
      }
    }
  }, [JSON.stringify(candidate?.platformMatches.sort()), actions.onMakeInvisible]);
};
