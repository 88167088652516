import { SelectStyleStates } from './types';
import { css, Styles, withStyles, extractFont } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme, props }) =>
    css({
      ...extractFont((font) => font.supporting.label, 'web'),
      color: theme.colors.neutral.variant[80],
      display: 'inline-flex',
      flexDirection: 'column',
      input: {
        height: '18px',
      },
      ...(props.disabled && { cursor: 'not-allowed', color: theme.colors.neutral.variant[40] }),
      ...(props.contained && { width: '100%' }),
    }),
  selectWrapper: ({ props }) =>
    css({
      '& > .MuiAutocomplete-root > div > .MuiInputBase-root': {
        width: '100%',
        flexWrap: 'wrap',
        color: 'inherit',
        padding:
          !props.empty && props.multiple
            ? props.showArrowIcon
              ? '4px 37px 4px 8px'
              : '4px 8px 4px 8px'
            : props.showArrowIcon
              ? '9px 40px 9px 16px'
              : '9px 16px 9px 16px',
        '&.MuiAutocomplete-input': {
          opacity: 1,
        },
        input: {
          width: '0px',
          minWidth: '30px',
          flexGrow: 1,
          textOverflow: 'ellipsis',
          padding: 0,
          margin: !props.empty && props.multiple ? '3px' : 0,
        },
        '.MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 9px)',
          right: '16px',
        },
      },
      ...(!props.typeable && {
        '&, input, .MuiInputBase-root': {
          cursor: 'pointer',
          userSelect: 'none',
        },
      }),
    }),
  paper: ({ theme, props }) =>
    css({
      '&.MuiAutocomplete-paper.MuiPaper-rounded': {
        margin: '9px 0',
        '.MuiAutocomplete-option': {
          cursor: 'default',
          '&[data-focus="true"], &[focus="true"]': {
            borderLeftColor: theme.colors.neutral.variant[70],
            backgroundColor: theme.colors.neutral.variant[10],
          },
          ...(props.multiple
            ? {
                '&[aria-selected="true"]': {
                  backgroundColor: 'transparent',
                },
                '&[aria-selected="true"][data-focus="true"], &[aria-selected="true"][focus="true"]': {
                  borderLeftColor: theme.colors.primary.fuchsia[40],
                  backgroundColor: theme.colors.neutral.variant[10],
                },
              }
            : {
                '&[aria-selected="true"]': {
                  borderLeftColor: theme.colors.primary.fuchsia[40],
                  backgroundColor: theme.colors.primary.fuchsia[10],
                },
                '&[aria-selected="true"][data-focus="true"], &[focus="true"]': {
                  borderLeftColor: theme.colors.primary.fuchsia[40],
                  backgroundColor: theme.colors.primary.fuchsia[20],
                },
              }),
          ...(props.disabledOptions && {
            '&, &[data-focus="true"], &[aria-selected="true"][data-focus="true"], &[focus="true"], &[aria-selected="true"][focus="true"]':
              {
                pointerEvents: 'none',
                backgroundColor: theme.colors.tertiary.red[20],
              },
          }),
        },
      },
    }),
  inputRoot: ({ theme, props: { hasError } }) =>
    css({
      ...(hasError && {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.tertiary.red[50],
        },
      }),
    }),
} satisfies Styles<SelectStyleStates>;

export const useStyles = withStyles(styles);
