import { useMemo } from 'react';
import { CampaignManagerContext } from 'shared/contexts/CampaignManagerContext';
import { useContextSelector } from 'use-context-selector';

export const useContainer = () => {
  const { isLoading, count } = useContextSelector(CampaignManagerContext, (state) => state.campaigns);

  const isEmptyState = !isLoading && count === 0;

  return useMemo(() => ({ isEmptyState }), [isEmptyState]);
};
