import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { createContext } from 'use-context-selector';
import { ConfigContextProps } from './types';

export const initialData: ConfigContextProps = {
  isIframe: false,
  campaign: {
    teamview: false,
    status: CampaignOverviewStatus.active,
  },
  showNavbar: false,
  showCampaignOverviewButton: false,
};

export const ConfigContext = createContext<ConfigContextProps>(initialData);
