import { useContext } from 'react';
import { EditTenantUsersListProps, EditTenantUsersListUser } from './types';
import { useStyles } from './EditTenantUsersList.styles';
import EditTenantUsers from '../EditTenantUsers';
import { Plus, X } from 'shared/icons';
import { Button } from 'components/ui/molecules';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { EditTenantUsersModalUser } from '../../types';
import { UserRoles } from 'clients/UserClient/types';

const VirtualizedRow = ({ index, data, style }: ListChildComponentProps<EditTenantUsersListUser[]>) => {
  const classes = useStyles();
  const user = data[index];

  return (
    <div css={classes.componentWrapper} key={`list-${user.id}`} style={style}>
      <div css={classes.sidebar}>
        {index !== 0 && (
          <button type="button" onClick={() => user.onRemoveField?.(user)}>
            <X size={12} />
          </button>
        )}
      </div>
      <EditTenantUsers user={user} onChangeUser={user.onChangeField} />
    </div>
  );
};

const EditTenantUsersList = ({ data, onAddField }: EditTenantUsersListProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  const handleAddField = () => {
    const field: EditTenantUsersModalUser = {
      id: -Date.now(),
      email: '',
      first_name: '',
      last_name: '',
      has_tutorial: true,
      role: UserRoles.Recruiter,
      ats_credential_id: -1,
    };

    onAddField?.(field);
  };

  return (
    <div css={classes.root}>
      <VariableSizeList height={340} width="100%" itemCount={data.length} itemSize={() => 74.9} itemData={data}>
        {VirtualizedRow}
      </VariableSizeList>
      <div css={classes.add}>
        <Button label={dictionary.addAnotherUser} variant="subtle" icon={<Plus size={18} />} onClick={handleAddField} />
      </div>
    </div>
  );
};

export default EditTenantUsersList;
