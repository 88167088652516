const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const dutchPhonePattern =
  /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

export const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(emailPattern);
};

export const validatePhone = (phoneNumber: string) => {
  const phoneNumberFormatted = String(phoneNumber).replace(/(?!\+)\D/g, '');

  return phoneNumberFormatted.toLowerCase().match(dutchPhonePattern);
};
