import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  content: css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 8px;
  `,
  contentHeader: ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    a {
      color: ${theme.colors.neutral.variant[80]};
    }
  `,

  radioButtonsWrapper: css`
    display: flex;
    gap: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: flex-end;
  `,
  radioButton: css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;

    img {
      height: 307px;
    }
  `,
  divider: ({ theme }) => css`
    width: 1px;
    height: 332px;
    background-color: ${theme.colors.neutral.variant[20]};
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
