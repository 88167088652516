import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      position: 'absolute',
      zIndex: 99,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
