import { useEffect, useRef, useState } from 'react';
import { FormControlLabel as MuiFormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import { useStyles } from './Radio.styles';
import { RadioProps } from './types';
import KeypressClassToggler from 'helpers/keypressClassToggler';

const Radio = ({ label, value, checked, onChange, error = false, disabled = false }: RadioProps) => {
  const classes = useStyles({ error, disabled });
  const radioRef = useRef<HTMLInputElement | null>(null);
  const [radioId, setRadioId] = useState('');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const buttonKeypressHandler = new KeypressClassToggler(radioRef.current!, {
      Enter: { onKeyUp: (el) => el.click(), classes: 'm-active' },
    }).init();

    const id = Math.floor(Math.random() * 10000 * Date.now()).toString(16);
    setRadioId(`radio-${id}`);

    return () => {
      buttonKeypressHandler.dispose();
    };
  }, []);

  return (
    <MuiFormControlLabel
      css={classes.root}
      control={
        <MuiRadio
          value={value}
          checked={checked}
          inputRef={radioRef}
          onChange={onChange}
          icon={<span css={classes.icon} className="radio--icon" />}
          checkedIcon={
            <span css={classes.checkedIcon} className="radio--icon radio--icon-active">
              <span className="radio--icon-circle" />
            </span>
          }
          tabIndex={0}
          disableRipple
        />
      }
      label={label}
      disabled={disabled}
      aria-disabled={disabled}
      aria-describedby={`error-${radioId}`}
    />
  );
};

export default Radio;
