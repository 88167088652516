import { ClipLoader } from 'react-spinners';
import { variants } from '../variants';
import { LoaderSizeProps } from 'react-spinners/interfaces';

export const ClipSpinner = ({
  variant = 'primary',
  ...props
}: LoaderSizeProps & {
  variant?: keyof typeof variants;
}) => <ClipLoader color={variants[variant]} {...props} />;
