import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      '& > div': {
        zIndex: 99999,
        position: 'fixed',
        bottom: 24,
        display: 'flex',
        width: '100%',
      },
    }),
  transition: () =>
    css({
      top: 'unset',
      bottom: 0,
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
