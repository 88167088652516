import { TransitionProps } from './types';
import { Transition as RtgTransition } from 'react-transition-group';
import { useStyles } from './Transition.styles';
import { transitionPatterns } from './TransitionPatterns';
import { forwardRef, useEffect, useState } from 'react';

const TransitionWithoutRef = (
  {
    visible,
    children,
    animationDuration = 120,
    duration,
    pattern = 'fade',
    onEntered,
    onExited,
    className,
    persist = false,
  }: TransitionProps,
  ref: React.ForwardedRef<HTMLElement | null>,
) => {
  const [localVisible, setLocalVisible] = useState(false);

  const classes = useStyles({ animationDuration, pattern });
  const transitionStyles = typeof pattern === 'object' ? pattern.states : transitionPatterns[pattern];

  useEffect(() => {
    setLocalVisible(visible);

    if (!persist && duration) {
      const timeout = setTimeout(() => setLocalVisible(!visible), duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [duration, visible]);

  return (
    <RtgTransition
      in={localVisible}
      timeout={persist ? Infinity : animationDuration}
      onEntered={onEntered}
      onExited={onExited}>
      {(state) => {
        return (
          <span
            ref={ref}
            css={classes.root}
            className={className}
            style={state !== 'unmounted' ? { ...transitionStyles[state] } : undefined}>
            {typeof children === 'function' ? children(state !== 'exited') : children}
          </span>
        );
      }}
    </RtgTransition>
  );
};

const Transition = forwardRef(TransitionWithoutRef);

export default Transition;
