import { WerknlLayout } from 'clients/UserClient/types';
import { logger } from 'config/logger';
import { useGTM } from 'hooks/gtm/useGTM';
import { useLocalStorage } from 'hooks/shared/useLocalStorage';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useCallback, useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';
import { SnackbarContext } from 'shared/contexts/SnackbarContext/SnackbarContext';
import { WERKNL_ACTIONS, WERKNL_LAYOUT_CHOSEN } from 'shared/contexts/UserContext/types';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { NoCreditsAvailableForMonsterboard, StopExecution } from 'shared/exceptions';
import { BadRequestException } from 'shared/exceptions/BadRequestException';
import { IndeedBadUserInputException } from 'shared/exceptions/IndeedException';
import { LinkedinFreeInviteLimitReachedException } from 'shared/exceptions/LinkedinException';
import { useCandidateOrchestrator } from '../useCandidateOrchestrator';
import { UseCandidateAction } from './types';

const allowedErrorsToThrow = [
  NoCreditsAvailableForMonsterboard,
  LinkedinFreeInviteLimitReachedException,
  IndeedBadUserInputException,
  BadRequestException,
];

export const useCandidateAction: UseCandidateAction = (fc, extras) => {
  const gtm = useGTM();
  const { currentUser } = useContext(UserContext);
  const { createSnackbar } = useContext(SnackbarContext);
  const { dictionary } = useContext(LocalizationContext);
  const pageContext = usePageContext();
  const candidateOrchestrator = useCandidateOrchestrator();
  const { setValue } = useLocalStorage<number>(`${WERKNL_ACTIONS}-${currentUser?.id}`, 0);
  const { value: werknlLayoutChosen } = useLocalStorage<boolean>(`${WERKNL_LAYOUT_CHOSEN}-${currentUser?.id}`);

  return ({ candidate, setIsLoading, registerWerknlActivity }) => {
    return useCallback(
      async (params) => {
        setIsLoading(true);

        try {
          if (!candidate) {
            throw new TypeError('Something went wrong while creating actions for this candidate');
          }
          const fcParams = { candidate, ...params };

          const fcResponse = await fc(fcParams);

          const co = candidateOrchestrator(candidate);

          await extras?.onSuccess?.({ fcParams: { candidate, ...params }, gtm })?.({
            data: fcResponse,
            pageContext,
            candidateOrchestrator: co,
          });

          if (co.campaign.get.listCount() === 0 && pageContext === PageContext.Campaign) {
            co.campaign.resetCurrentQuery();
          }

          return fcResponse;
        } catch (e) {
          try {
            if (!candidate) {
              throw new TypeError('Something went wrong while creating actions for this candidate');
            }

            if (extras?.onError) {
              const co = candidateOrchestrator(candidate);

              await extras.onError({ fcParams: { candidate, ...params }, gtm })?.({
                data: e,
                pageContext,
                candidateOrchestrator: co,
              });

              if (co.campaign.get.listCount() === 0 && pageContext === PageContext.Campaign) {
                co.campaign.resetCurrentQuery();
              }
            } else {
              throw e;
            }
          } catch (e) {
            if (allowedErrorsToThrow.some((error) => e instanceof error)) {
              throw e;
            }

            if (!(e instanceof StopExecution)) {
              logger.error(e);
              createSnackbar(e instanceof Error ? e.message : dictionary.somethingWentWrong, { variant: 'danger' });

              throw new StopExecution();
            }
          }
        } finally {
          if (
            registerWerknlActivity &&
            !werknlLayoutChosen &&
            currentUser?.werknl_layout === WerknlLayout.New &&
            candidate?.activeJobBoard === CampaignJobboard.Werknl
          ) {
            setValue((prevState) => (prevState ?? 0) + 1);
          }

          setIsLoading(false);
        }
      },
      [gtm, createSnackbar, dictionary, candidate, werknlLayoutChosen, currentUser?.werknl_layout],
    );
  };
};
