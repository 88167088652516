import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

export const getJobsList: GetJobsListHandler = async () => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: 'https://resumes.indeed.com/rpc/dradis/jobs',
    method: 'GET',
    headers: { 'content-type': 'application/json' },
  });

  return JSON.parse(response.body);
};

export type IndeedJob = {
  id: string;
  key: string;
  title: string;
  location: string;
};

type GetJobsListResponse = {
  dradisJobModels: IndeedJob[];
};

type GetJobsListHandler = () => Promise<GetJobsListResponse>;
