export const CAMPAIGN_STATS = {
  NEW: 'NEW',
  SHORTLISTED: 'SHORTLISTED',
  CONTACTED: 'CONTACTED',
  MATCHED: 'MATCHED',
  HIDDEN: 'HIDDEN',
  INVISIBLE: 'INVISIBLE',
} as const;

export const CAMPAIGN_FACETS = {
  Database: 'Database',
  LinkedIn: 'LinkedIn',
  Debanensite: 'Debanensite',
  Indeed: 'Indeed',
  Jobbird: 'Jobbird',
  MonsterboardV2: 'MonsterboardV2',
  NVB: 'NVB',
  'Resume.io': 'Resume.io',
  Techniekwerkt: 'Techniekwerkt',
  Uitzendbureau: 'Uitzendbureau',
  WerkNL: 'WerkNL',
  Studentjob: 'Studentjob',
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // Werkzoeken: 'Werkzoeken',
} as const;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ICampaign {
  export type Stats = Record<keyof typeof CAMPAIGN_STATS, number>;
  export type Facets = Record<keyof typeof CAMPAIGN_FACETS, number>;
}
