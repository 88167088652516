import { styleUtils } from 'utils';
import { SwitchStyleStates } from './types';
import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';
import { generateClassname } from 'helpers/helpers';
import { getConfig } from './Switch.config.styles';

export const switchClassName = generateClassname();

const styles = {
  root: ({ theme, props: { checked, variant } }) => {
    const config = getConfig(variant);
    return css({
      cursor: 'pointer',
      color: checked ? theme.colors.neutral.variant[80] : theme.colors.neutral.variant[40],
      '&:hover, &:hover:focus-within, &:hover:focus-visible': {
        color: checked ? theme.colors.neutral.variant[80] : theme.colors.neutral.variant[50],
        [`& > .${switchClassName}`]: {
          backgroundColor: checked ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
        },
      },
      '&:focus-within, &:focus-visible': {
        color: checked ? theme.colors.neutral.variant[80] : theme.colors.neutral.variant[40],
        [`& > .${switchClassName}`]: {
          outline: 0,
          backgroundColor: checked ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
          '&:after': {
            width: config.switcherRadius,
            height: config.switcherRadius,
            top: config.rootTop,
            left: config.rootLeft(checked),
          },
        },
      },
    });
  },
  switch: ({ theme, props: { checked, variant } }) => {
    const config = getConfig(variant);
    return css({
      position: 'relative',
      width: config.switcherWidth,
      height: config.switcherHeight,
      display: 'flex',
      padding: 2,
      borderRadius: config.switcherBorderRadius,
      backgroundColor: checked ? theme.colors.secondary.emerald[40] : theme.colors.neutral.variant[40],
      transition: styleUtils.generateTransitionStyles('background-color', '180ms', 'linear'),
      '&:after': {
        position: 'absolute',
        content: '""',
        left: config.switcherLeft(checked),
        borderRadius: config.switcherBorderRadius,
        top: config.switcherTop,
        width: config.switcherRadius,
        height: config.switcherRadius,
        backgroundColor: theme.colors.neutral.variant[0],
        transition: styleUtils.generateTransitionStyles(['left', 'width', 'height', 'top'], '180ms', 'linear'),
      },
    });
  },
} satisfies Styles<SwitchStyleStates>;

export const useStyles = withStyles(styles);
