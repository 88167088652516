import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      '& > *:not(:first-of-type)': {
        marginLeft: 8,
      },
    }),
  button: ({ theme }) => css`
    && > button {
      &:hover {
        background-color: ${theme.colors.neutral.variant[20]};
      }

      &.button-focused {
        background-color: ${theme.colors.neutral.variant[20]};
      }

      &:active,
      &.m-active,
      &.force-pressed-state {
        background-color: ${theme.colors.neutral.variant[30]};
      }
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
