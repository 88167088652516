import React from 'react';
import { extractThemeColorWithState } from 'utils/style';
import { IconHOCType } from '../types';

const IconHOC: IconHOCType = (icon, defaultProps) => {
  return ({ color, size = 24, pointer = false, className }) => {
    return React.createElement(icon, {
      style: { flexShrink: 0 },
      className,
      width: size,
      height: size,
      ...extractThemeColorWithState(color ?? defaultProps?.color ?? 'currentColor'),
      ...(pointer && { cursor: 'pointer' }),
    });
  };
};

export default IconHOC;
