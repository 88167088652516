import { Loader } from '@recruitrobin/robin-theme/web-components';
import { Suspense } from 'react';
import { useStyles } from './RoutesSuspense.styles';

export const RoutesSuspense = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <Suspense
      fallback={
        <div css={classes.root}>
          <Loader size={48} />
        </div>
      }>
      {children}
    </Suspense>
  );
};
