import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { sidebarAnimationConfig, SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { useStyles } from './NavBarSidebarSpacer.styles';

/**
 * Is used to follow the Sidebar width expansion and then simulate the necessary space to push
 * the SearchInput and adapt it's width
 */
export const NavBarSidebarSpacer = () => {
  const classes = useStyles();
  const { isOpen, sidebarRef } = useContext(SidebarOverlayFixedContext);
  const [sidebarWidth, setSidebarWidth] = useState(0);

  useEffect(() => {
    const ref = sidebarRef;

    if (ref) {
      const { width } = ref.getBoundingClientRect();

      setSidebarWidth(width);

      return () => setSidebarWidth(0);
    }
  }, [sidebarRef]);

  return (
    <AnimatePresence>
      {isOpen && <motion.div css={classes.root} {...sidebarAnimationConfig} style={{ width: sidebarWidth }} />}
    </AnimatePresence>
  );
};
