import { useStyles } from './styles';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';
import type { ColumnProps } from './types';

const Column = forwardRef((props: ColumnProps, ref: ForwardedRef<HTMLDivElement>) => {
  const {
    id,
    gap = 16,
    className,
    children,
    onClick,
    onFocus,
    onBlur,
    tabIndex,
    inline = false,
    contained = false,
    expand = false,
    overflowAuto = false,
    alignCenter = false,
    fullFlex = false,
    start = false,
    center = false,
    end = false,
  } = props;
  const classes = useStyles({
    gap,
    inline,
    contained,
    expand,
    alignCenter,
    fullFlex,
    overflowAuto,
    start,
    center,
    end,
  });

  return (
    <div
      ref={ref}
      id={id}
      css={classes.root}
      className={className}
      tabIndex={tabIndex}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}>
      {children}
    </div>
  );
});

export default Column;
