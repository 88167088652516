import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';

export const getJobDescription: GetJobDescriptionHandler = async ({ id }) => {
  const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
    url: `https://resumes.indeed.com/rpc/dradis/jobs/${id}`,
  });

  return JSON.parse(response.body);
};

type GetJobDescriptionResponse = {};

type GetJobDescriptionHandler = (params: { id: string }) => Promise<GetJobDescriptionResponse>;
