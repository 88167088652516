import type { Certification } from 'clients/CampaignsClient/CandidateResponse';
import { copyObject } from 'utils/shared';

export const getLastCertifications = (certifications: Certification[]) => {
  const certificationsWithoutUndefinedDates = certifications.filter((certification) =>
    Boolean(certification?.dates?.start),
  );
  const certificationsWithUndefinedDates = certifications.filter((certification) =>
    Boolean(!certification?.dates?.start),
  );

  const sortedCertifications = copyObject(certificationsWithoutUndefinedDates).reverse();

  const undefinedAndSortedCertifications = sortedCertifications.concat(certificationsWithUndefinedDates);

  return undefinedAndSortedCertifications.map(({ name, dates }) => ({
    name,
    startYear: dates?.start?.year,
  }));
};
