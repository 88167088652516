import { generateTransitionStyles } from 'styles/theme';

import { CheckboxStyleState } from './types';
import { withStyles, Styles, css, Theme } from '@recruitrobin/robin-theme/web-styles';
import { SerializedStyles } from '@emotion/react';
import { generateClassname } from 'helpers/helpers';

export const checkboxHoveringParent = generateClassname();

const iconBase: ({ theme }: { theme: Theme }) => SerializedStyles = ({ theme }) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '16px',
    padding: 0,
    borderRadius: '2px',
    color: theme.colors.neutral.variant[0],
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.colors.neutral.variant[30],
    backgroundColor: theme.colors.neutral.variant[0],
  });

const styles = {
  root: ({ theme, props }) =>
    css({
      '.checkbox--icon': {
        transition: generateTransitionStyles(['background-color', 'border-color', 'color']),
      },
      '&.MuiFormControlLabel-root': {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'unset',
        '.MuiFormControlLabel-label': {
          padding: '9px 9px 9px 0',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        },
      },
      '.MuiButtonBase-root.MuiIconButton-root:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },
      [`.${checkboxHoveringParent}:hover &, &:hover, .${checkboxHoveringParent} .Mui-focusVisible, .${checkboxHoveringParent}:hover .Mui-focusVisible`]:
        {
          '.checkbox--icon': {
            borderColor: theme.colors.neutral.variant[40],
          },
          '.checkbox--icon.checkbox--icon-active': {
            borderColor: theme.colors.primary.fuchsia[40],
            backgroundColor: theme.colors.primary.fuchsia[40],
          },
        },
      '.Mui-focusVisible, &:hover .Mui-focusVisible': {
        '.checkbox--icon.checkbox--icon-active': {
          borderColor: theme.colors.primary.fuchsia[60],
          backgroundColor: theme.colors.primary.fuchsia[60],
        },
      },
      ...(props.error && {
        '.checkbox--icon': {
          borderColor: 'maroon',
          backgroundColor: 'blueviolet',
        },
      }),
      ...(props.disabled && {
        '&:hover .checkbox--icon:not(.checkbox--icon-active), &:hover .checkbox--icon, .checkbox--icon': {
          borderColor: 'bisque',
          backgroundColor: 'black',
        },
      }),
    }),
  icon: ({ theme }) => iconBase({ theme }),
  checkedIcon: ({ theme }) =>
    css(
      iconBase({ theme }),
      css({
        '&.checkbox--icon-active': {
          borderColor: theme.colors.primary.fuchsia[40],
          backgroundColor: theme.colors.primary.fuchsia[40],
        },
      }),
    ),
} satisfies Styles<CheckboxStyleState>;

export const useStyles = withStyles(styles);
