/* eslint-disable @typescript-eslint/no-unused-vars*/

import { ButtonBase as MuiButtonBase, Typography as MuiTypography } from '@material-ui/core';
import { generateDtiAttribute, getTextOfComponent } from 'helpers/helpers';
import { forwardRef, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from 'shared/components/Tooltip';
import { useStyles } from './styles';
import { ButtonProps } from './types';
import { default as KeypressClassToggler } from 'helpers/keypressClassToggler';
import { default as withSkeleton, SkeletonProps } from 'components/HOC/withSkeleton';

const skeletonProps: SkeletonProps = {
  variant: 'square',
  height: 36,
  width: 36,
};

const ButtonWithoutSkeleton = (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
  const {
    label,
    icon,
    onClick,
    onContextMenu,
    submit,
    tabIndex,
    tooltipLabel,
    iconAlignment = 'left',
    variant = 'default',
    style = 'tonal',
    disabled = false,
    loading = false,
    className,
    nowrap = false,
    contained = false,
    preserveText = false,
  } = props;
  const classes = useStyles({
    variant,
    style,
    disabled,
    iconAlignment,
    labelExists: !!label,
    iconExists: !!icon,
    nowrap,
    contained,
    preserveText,
  });
  const ariaText = getTextOfComponent(label || (tooltipLabel ?? 'default tooltip'));
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (typeof ref === 'function' && buttonRef.current) {
      ref(buttonRef.current);
    } else if (typeof ref !== 'function' && ref !== null) {
      ref.current = buttonRef.current;
    }
  }, [ref]);

  useEffect(() => {
    if (tabIndex === -1 || !buttonRef.current) return;

    const buttonKeypressHandler = new KeypressClassToggler(buttonRef.current, {
      Enter: { onKeyUp: (el) => el.click(), classes: 'm-active' },
    }).init();

    return () => {
      buttonKeypressHandler.dispose();
    };
  }, [tabIndex]);

  const handleDisabledClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Tooltip
      title={tooltipLabel as string}
      disabled={!!contextMenuAnchorEl || !!label || !tooltipLabel}
      className={className}
      enablePortal>
      <span
        style={{
          display: 'inline-block',
          position: 'relative',
          ...(contained && { width: '100%' }),
          borderRadius: variant === 'small-rounded' ? '50%' : 8,
        }}
        onClick={disabled ? handleDisabledClick : undefined}>
        <MuiButtonBase
          ref={buttonRef}
          css={classes.root}
          focusVisibleClassName="button-focused"
          onClick={onClick}
          disabled={loading || disabled}
          type={submit ? 'submit' : 'button'}
          aria-label={ariaText}
          aria-disabled={disabled}
          tabIndex={tabIndex}
          disableRipple
          onContextMenu={onContextMenu}
          {...generateDtiAttribute(props['data-tutorial-id'], 'button')}>
          <div css={classes.contentWrapper}>
            {loading ? (
              <ClipLoader color="currentColor" size={18} />
            ) : (
              icon && <span className="content-wrapper--icon">{icon}</span>
            )}
            {label && (
              <MuiTypography
                css={classes.label}
                variant="button"
                className="content-wrapper--label"
                {...generateDtiAttribute('label')}>
                {label}
              </MuiTypography>
            )}
          </div>
        </MuiButtonBase>
      </span>
    </Tooltip>
  );
};

const Button = withSkeleton(forwardRef(ButtonWithoutSkeleton), skeletonProps);

export default Button;
