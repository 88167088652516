import { CSSObject, keyframes } from '@emotion/react';
import { StyleGuideTypographyFlatVariants } from 'styles/types';
import { SkeletonStyleStates } from './types';
import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const VARIABLE_PREFIX = 'rr-skeleton';

const skeletonAnimation = keyframes`
    0% { background: var(--${VARIABLE_PREFIX}-background); }
    50% { background: var(--${VARIABLE_PREFIX}-background); }
    67% { background: var(--${VARIABLE_PREFIX}-highlight); }
    81% { background: var(--${VARIABLE_PREFIX}-highlight); }
    100%{ background: var(--${VARIABLE_PREFIX}-background); }
`;

const textVariantStyles: Record<StyleGuideTypographyFlatVariants, CSSObject> = {
  'header.h1': { borderRadius: 8, height: 17, margin: '7px 0 6px' },
  'header.h2': { borderRadius: 7.5, height: 15, margin: '6px 0' },
  'header.h3': { borderRadius: 7, height: 14, margin: '7px 0 6px' },
  'header.h4': { borderRadius: 6.5, height: 13, margin: '6px 0 5px' },
  'header.h5': { borderRadius: 5.5, height: 11, margin: '6px 0 5px' },
  'header.h6': { borderRadius: 5, height: 10, margin: '5px 0' },
  'body.long': { borderRadius: 5.5, height: 11, margin: '6px 0' },
  'body.short': { borderRadius: 5, height: 10, margin: '4px 0' },
  'supporting.code': { borderRadius: 5, height: 10, margin: '5px 0' },
  'supporting.codeInline': { borderRadius: 4.5, height: 9, margin: '4px 0 3px' },
  'supporting.label': { borderRadius: 4.5, height: 9, margin: '4px 0' },
  'supporting.labelTiny': { borderRadius: 3.5, height: 7, margin: '3px 0' },
  'supporting.helper': { borderRadius: 4.5, height: 9, margin: '5px 0 4px' },
};

const styles = {
  root: ({ theme }) =>
    css({
      [`--${VARIABLE_PREFIX}-background`]: theme.colors.neutral.variant[20],
      [`--${VARIABLE_PREFIX}-highlight`]: theme.colors.neutral.variant[10],
      display: 'inline-flex',
      background: `--${VARIABLE_PREFIX}-background`,
      animationName: skeletonAnimation,
      animationDuration: '1750ms',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
    }),
  text: ({ props: { textVariant = 'header.h1', width } }) => css({ ...textVariantStyles[textVariant], width }),
  circle: ({ props: { size } }) => css({ borderRadius: '50%', width: size, height: size }),
  square: ({ props: { height, width } }) => css({ borderRadius: 8, width, height }),
  logo: ({ props: { size = 111 } }) =>
    css({
      'div.bottom-rounded': {
        height: size / 2,
        width: size / 2,
        borderRadius: `0 0 ${size / 2}px 0`,
      },
      'div.top-rounded': {
        height: size / 2,
        width: size / 2,
        borderRadius: `0 ${size / 2}px 0 0`,
      },
    }),
} satisfies Styles<SkeletonStyleStates>;

export const useStyles = withStyles(styles);
