import { Question } from '@recruitrobin/robin-theme/web-icons';
import { Row } from 'components/ui/atoms';
import { routes } from 'config';
import { useGTM } from 'hooks/gtm';
import { useCallback } from 'react';
import { useStyles } from './KnowledgeBaseButton.styles';

const KnowledgeBaseButton = () => {
  const classes = useStyles();
  const gtm = useGTM();

  const onNavigateToHelpCenter = useCallback(() => {
    gtm.clickedHelpCenterButton();
    window.open(routes.helpCenter, '_blank');
  }, []);

  return (
    <Row css={classes.content} onClick={onNavigateToHelpCenter} data-tutorial-id="knowledge-base">
      <Question color={(c) => c.neutral.variant[70]} size={18} />
    </Row>
  );
};

export default KnowledgeBaseButton;
