import { Job } from 'clients/CampaignsClient/CandidateResponse';
import type { Dictionary } from 'shared/contexts/LocalizationContext/types';
import { getFullDuration } from 'utils/shared/getDuration';
import { CandidateExperience } from './types';

export const extractExperiences = (experiences: Job[], dictionary: Dictionary): CandidateExperience[] => {
  return experiences.map(({ role, dates, company, description }) => {
    const startYear = dates.start?.year;
    const endYear = dates.end?.year ?? dictionary.current;

    return {
      position: role,
      company: company?.name || undefined,
      date: `${startYear} - ${endYear}`,
      description,
      timeInMonths: getFullDuration(dates)(dictionary),
    };
  });
};
