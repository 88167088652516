import { hexToRgba } from 'utils/style';

import { MenuStyleStates } from './types';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      '& > .MuiPaper-root.MuiPaper-rounded': {
        padding: '6px 0',
        backgroundColor: theme.colors.neutral.variant[0],
        border: `1px solid ${theme.colors.neutral.variant[40]}`,
        boxShadow: `0px 2px 4px ${hexToRgba(theme.colors.neutral.variant[40], 0.5)}`,
        '& > .MuiList-padding': {
          padding: 0,
        },
      },
    }),
} satisfies Styles<MenuStyleStates>;

export const useStyles = withStyles(styles);
