import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { NavStyleStates } from './types';

const styles = {
  root: css({
    position: 'relative',
    zIndex: 2,
    padding: '14px 24px 14px 0',
    width: 'calc(100% - 64px)',
  }),
  controlsContainer: ({ theme }) => css`
    height: 36px;
    padding: 4px 6px 4px 11px;
    gap: 20px;
    border-radius: 29px;
    background-color: ${theme.colors.neutral.variant[0]};
  `,
  divider: ({ theme }) =>
    css({
      width: 1,
      height: '100%',
      backgroundColor: theme.colors.neutral.variant[20],
    }),
  logoContainer: css`
    display: flex;
    flex-direction: column;
  `,
  buttons: css`
    display: flex;
    padding-left: 24px;
  `,
} satisfies Styles<NavStyleStates>;

export const useStyles = withStyles(styles);
