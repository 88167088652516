import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { CampaignOverviewResponse, CampaignResponse } from 'clients/CampaignsClient/CampaignsClient.types';
import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { routes } from 'config';
import { queryClient } from 'config/queryClient';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryKey } from 'types/query';
import DialogContext from '../DialogContext';
import { LocalizationContext } from '../LocalizationContext/LocalizationContext';
import { ActionableCampaign, CampaignManagerFilters } from './CampaignManagerContext.types';
import { setCampaign } from './CampaignManagerContext.useCampaign';
import { useCampaigns } from './CampaignManagerContext.useCampaigns';
import { CandidateStatus } from 'services/candidates';

export const useInjectCampaignActions = ({
  filters: { status, teamview, name },
  selectedCampaignOverviewId,
  onSelectCampaignOverviewId,
}: {
  filters: CampaignManagerFilters;
  selectedCampaignOverviewId: number | undefined;
  onSelectCampaignOverviewId: (id: number | undefined) => void;
}) => {
  const { removeCampaignFromOverview: _removeCampaignFromOverview } = useCampaigns({ status, teamview, name });
  const { dictionary } = useContext(LocalizationContext);
  const { createDialog } = useContext(DialogContext);
  const navigate = useNavigate();

  const removeCampaignFromOverview = useCallback(
    async (id: number) => {
      return _removeCampaignFromOverview(id).then((data) => {
        const isSelected = id === selectedCampaignOverviewId;

        if (!isSelected || !data?.pages) return;

        const [firstAvailableCampaign] = data.pages.flatMap((campaign) => campaign.results);

        if (!firstAvailableCampaign) {
          onSelectCampaignOverviewId(undefined);
          navigate(routes.search({ campaignId: '' }));

          return;
        }

        onSelectCampaignOverviewId(firstAvailableCampaign.id);
        navigate(routes.search({ campaignId: firstAvailableCampaign.id }));
      });
    },
    [selectedCampaignOverviewId, _removeCampaignFromOverview],
  );

  const handleSelect = (id: number, initialTab?: CandidateStatus) => {
    onSelectCampaignOverviewId(id);
    navigate(routes.search({ campaignId: id, tab: initialTab }));
    queryClient.resetQueries([QueryKey.campaignManagerContextUseCampaign]);
  };

  const handleDuplicate = (id: number) => {
    return new Promise<void>((res, rej) =>
      campaignsClient
        .copyCampaign(id)
        .then(async ({ data: { id: duplicatedCampaignId } }) => {
          onSelectCampaignOverviewId(duplicatedCampaignId);
          await queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns]);

          res();
        })
        .catch(rej),
    );
  };

  const handleChangeOnwer = (campaignId: number, userId: number) => {
    return new Promise<void>((res, rej) => {
      campaignsClient
        .changeOwner({ campaignId, userId })
        .then(async () => {
          await queryClient.invalidateQueries([QueryKey.campaignManagerContextUseCampaigns]);
          res();
        })
        .catch(rej);
    });
  };

  const handleToggleStatus = useCallback(
    (id: number, oldStatus: CampaignOverviewStatus) => {
      const status =
        oldStatus === CampaignOverviewStatus.active ? CampaignOverviewStatus.inactive : CampaignOverviewStatus.active;

      return new Promise<void>((res, rej) => {
        campaignsClient
          .updateCampaign(id, { status })
          .then(() => removeCampaignFromOverview(id).finally(res))
          .catch(rej);
      });
    },
    [removeCampaignFromOverview],
  );

  const handleDelete = useCallback(
    (id: number) => {
      return new Promise<void>((res, rej) => {
        createDialog({ description: dictionary.confirmDeleteCampaign })
          .then(() => {
            campaignsClient
              .deleteCampaign(id)
              .then(() => {
                removeCampaignFromOverview(id).finally(res);
              })
              .catch(rej);
          })

          .catch(res);
      });
    },
    [removeCampaignFromOverview],
  );

  const handleUpdateAts = useCallback((id: number, ats: { ats_id: string | null; ats_name: string | null }) => {
    return new Promise<void>((res, rej) => {
      campaignsClient
        .updateCampaign(id, ats)
        .then(() => {
          setCampaign(id, (campaign) => ({ ...campaign, ...ats, is_ats_configured: true }));
          res();
        })
        .catch(rej);
    });
  }, []);

  const handleUpdateName = useCallback((id: number, name: string) => {
    return new Promise<void>((res, rej) => {
      campaignsClient
        .updateCampaign(id, { name })
        .then(() => {
          setCampaign(id, (campaign) => ({ ...campaign, name }));
          res();
        })
        .catch(rej);
    });
  }, []);

  const injectCampaignActions = useCallback(
    (campaign: CampaignOverviewResponse | CampaignResponse): ActionableCampaign => {
      return {
        select: (initialTab?: CandidateStatus) => handleSelect(campaign.id, initialTab),
        duplicate: () => handleDuplicate(campaign.id),
        toggleStatus: () => handleToggleStatus(campaign.id, status),
        delete: () => handleDelete(campaign.id),
        changeOwner: (campaignId: number, userId: number) => handleChangeOnwer(campaignId, userId),
        updateAts: (ats) => handleUpdateAts(campaign.id, ats),
        updateName: (name) => handleUpdateName(campaign.id, name),
      };
    },
    [status, teamview, handleDelete, handleToggleStatus],
  );

  return injectCampaignActions;
};
