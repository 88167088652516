import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar as MuiSnackbar } from '@material-ui/core';

import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';

import { ContextProps, LayoutContextProviderProps } from './types';

const defaultContext: ContextProps = {
  snackbarState: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
  },
  mainMenuState: {
    open: false,
  },
  searchFiltersState: {
    open: true,
  },
  setSearchFiltersState: () => null,
  setSnackbarState: () => null,
  setMainMenuState: () => null,
  closeSnackbar: () => null,
  changesSaved: () => null,
  validationError: () => null,
  genericError: () => null,
  Snackbar: null,
};

export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {
  const [snackbarState, setSnackbarState] = useState(defaultContext.snackbarState);
  const [mainMenuState, setMainMenuState] = useState(defaultContext.mainMenuState);
  const [searchFiltersState, setSearchFiltersState] = useState(defaultContext.searchFiltersState);
  const { dictionary } = useContext(LocalizationContext);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  }, [snackbarState]);

  const changesSaved = useCallback(() => {
    setSnackbarState({
      open: true,
      message: dictionary.changesSaved,
      severity: 'success',
      autoHideDuration: 2000,
    });
  }, [dictionary]);

  const validationError = useCallback((message = 'One or more fields are incorrect.', autoHideDuration = 2000) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'error',
      autoHideDuration,
    });
  }, []);

  const genericError = useCallback(
    (message = dictionary.somethingWentWrong, autoHideDuration = 2000) => {
      setSnackbarState({
        open: true,
        message: message,
        severity: 'error',
        autoHideDuration,
      });
    },
    [dictionary],
  );

  const Snackbar = useMemo(
    () => (
      <MuiSnackbar open={snackbarState.open} autoHideDuration={snackbarState.autoHideDuration} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={snackbarState.severity} style={{ textAlign: 'center' }}>
          {snackbarState.message}
        </Alert>
      </MuiSnackbar>
    ),
    [snackbarState, closeSnackbar],
  );

  return (
    <LayoutContext.Provider
      value={{
        searchFiltersState,
        setSearchFiltersState,
        snackbarState,
        mainMenuState,
        setSnackbarState,
        setMainMenuState,
        closeSnackbar,
        changesSaved,
        validationError,
        genericError,
        Snackbar,
      }}>
      {children}
    </LayoutContext.Provider>
  );
};
