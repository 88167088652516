import { sourceIconsMap } from 'helpers/visualHelpers';

export const jobBoardLabel = (key: keyof typeof sourceIconsMap | string): string => {
  switch (key) {
    case 'ats':
      return 'ATS';
    case 'cvt':
      return 'Linkedin';
    case 'monsterboard_v2':
      return 'Monsterboard';
    case 'nvb':
      return 'Nationale Vacaturebank';
    case 'werknl':
      return 'WerkNL';
    case 'indeed':
      return 'Indeed';
    case 'debanensite':
      return 'deBanenSite';
    case 'techniekwerkt':
      return 'TechniekWerkt';
    case 'jobbird':
      return 'JobBird';
    case 'uitzendbureau':
      return 'Uitzendbureau';
    case 'resumeio':
      return 'Resume.io';
    case 'studentjob':
      return 'Studentjob';
    /// TODO WERKZOEKEN: Remove this after release new jobboard
    // case 'werkzoeken':
    //   return 'Werkzoeken';
    default:
      return '';
  }
};
