import { css, extractTransition, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
  `,
  iframeNestedContainer: css`
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    left: 0;
    top: 0;

    & > * {
      opacity: 0;
      position: absolute;
      top: 100vh;
      left: 100vw;
      width: 100%;
      height: 100%;
      ${extractTransition((f) => f.cubic, { property: 'opacity', duration: 330 })};

      &.visible {
        top: 0;
        left: 0;
        opacity: 1;
      }
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
