import { Column, Row, Typography } from 'components/ui/atoms';
import { useStyles } from './ConnectEmailModal.styles';
import { useContext, useState, type ChangeEvent } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { Button } from '@recruitrobin/robin-theme/web-components';
import { AccountStatus } from 'shared/contexts/CandidateContext/CandidateEmailSettingsContext.types';
import { useLocalStorage } from 'hooks/shared';
import { Checkbox } from 'shared/components/CheckboxGroup';
import { useContainer } from 'shared/contexts/CandidateContext/CandidateEmailSettingsContext.useContainer';
import { UserContext } from 'shared/contexts/UserContext/UserContext';

type ConnectEmailModalProps = {
  emailStatus: AccountStatus;
  setVisibility: (visible: boolean) => void;
};

export const DISMISS_CONNECT_YOUR_EMAIL_MODAL = 'dismiss-connect-your-email-modal';

export const ConnectEmailModal = ({ emailStatus, setVisibility }: ConnectEmailModalProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const { currentUser } = useContext(UserContext);
  const classes = useStyles();
  const [isDismissCheckboxChecked, setIsDismissCheckboxChecked] = useState(false);

  const label =
    emailStatus !== AccountStatus.NonExistent ? dictionary.connectEmailAccount : dictionary.reconnectEmailAccount;

  const content =
    emailStatus === AccountStatus.NonExistent
      ? dictionary.remindToConnectEmail(currentUser?.first_name ?? '')
      : dictionary.remindToReconnectEmail(currentUser?.first_name ?? '');

  const { setValue: setDismiss } = useLocalStorage(DISMISS_CONNECT_YOUR_EMAIL_MODAL);

  const handleToogleDismissModal = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    setIsDismissCheckboxChecked(checked);
  };

  const { openOAuthPopup: handleReconnectAccount } = useContainer({
    onClosePopup: () => {
      isDismissCheckboxChecked && setDismiss(true);
      setVisibility(false);
    },
  });

  const handleCloseModal = () => {
    isDismissCheckboxChecked && setDismiss(true);
    setVisibility(false);
  };

  return (
    <Column>
      <Typography variant={(v) => v.heading[3]} colorv2={(c) => c.neutral.variant[80]}>
        {label}
      </Typography>
      <Typography variant={(v) => v.body.short} colorv2={(c) => c.neutral.variant[70]}>
        {content}
      </Typography>
      {emailStatus === AccountStatus.NonExistent && (
        <Checkbox label={dictionary.dontShowThisAgain} onChange={handleToogleDismissModal} />
      )}
      <Row css={classes.actions}>
        <Button onClick={handleCloseModal} type="subtle1" style="text" label={dictionary.cancel} />
        <Button type="highlight" style="filled" label={label} onClick={handleReconnectAccount} />
      </Row>
    </Column>
  );
};
