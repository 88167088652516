import { Theme, Styles, withStyles, css, extractFont } from '@recruitrobin/robin-theme/web-styles';
import { SearchInputStyleStates } from './types';
import { generateTransitionStyles } from 'utils/style';

const INPUT = {
  MAX_HEIGHT: 350,
  MIN_HEIGHT: 36,
};

const sharedStyles = {
  scrollbar: ({ theme }: { theme: Theme }) => ({
    '::-webkit-scrollbar': {
      width: 4,
      transform: 'scale(0.5)',
    },
    '::-webkit-scrollbar-track': {
      opacity: 0,
      transform: 'scale(0.5)',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.colors.neutral.variant[30],
      borderRadius: 10,
      transform: 'scale(0.5)',
      '&:hover': {
        background: theme.colors.neutral.variant[60],
      },
    },
  }),
};

const styles = {
  root: () =>
    css({
      width: '100%',
      height: INPUT.MIN_HEIGHT,
      position: 'relative',
      '& > div': {
        position: 'absolute',
        justifyContent: 'center',
      },
    }),
  searchInputWrapper: ({ theme, props: { isTextToSearch } }) =>
    css({
      maxWidth: 566,
      height: 'auto',
      maxHeight: INPUT.MAX_HEIGHT,
      background: theme.colors.neutral.variant[0],
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[20],
      borderRadius: 8,
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
      ...(!isTextToSearch && {
        transition: generateTransitionStyles(['max-height']),
      }),
    }),
  textAreaWrapper: () =>
    css({
      padding: '8px 16px',
    }),
  input: ({ theme }) =>
    css({
      ...extractFont((font) => font.body.short, 'web'),
      color: theme.colors.neutral.variant[80],
      width: '100%',
      height: 18,
      border: 0,
      outline: 0,
      padding: 0,
      resize: 'none',
      '::placeholder': {
        ...extractFont((font) => font.supporting.label, 'web'),
        color: theme.colors.neutral.variant[40],
      },
      ...sharedStyles.scrollbar({ theme }),
    }),
  results: ({ theme }) =>
    css({
      height: 'auto',
      maxHeight: INPUT.MAX_HEIGHT - 60,
      display: 'flex',
      overflow: 'auto',
      ...sharedStyles.scrollbar({ theme }),
    }),
  button: () =>
    css({
      boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
      height: INPUT.MIN_HEIGHT,
      '&& > button': {
        height: INPUT.MIN_HEIGHT,
        width: INPUT.MIN_HEIGHT,
        borderRadius: 8,
      },
    }),
  lengthCounter: ({ theme }) =>
    css({
      ...extractFont((font) => font.supporting['label-tiny'], 'web'),
      color: theme.colors.neutral.variant[50],
      paddingRight: 13,
      marginTop: 9,
      marginBottom: 14,
    }),
  resultRow: ({ theme }) =>
    css({
      flexShrink: 0,
      padding: '6px 12px',
      height: 30,
      width: '100%',
      borderLeftWidth: 2,
      borderLeftStyle: 'solid',
      borderLeftColor: 'transparent',
      cursor: 'pointer',
      transition: generateTransitionStyles(['background', 'border-left-color'], (d) => d.long),
      '& > span': {
        ...extractFont((font) => font.supporting.helper, 'web'),
        color: theme.colors.neutral.variant[80],
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '&:hover:not(.static)': {
        borderLeftColor: theme.colors.neutral.variant[70],
        background: theme.colors.neutral.variant[5],
      },
      '& > div': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    }),
  clearButton: ({ theme }) =>
    css({
      cursor: 'pointer',
      transition: generateTransitionStyles(['background'], (d) => d.long),
      height: 18,
      borderRadius: 3,
      '&:hover': {
        background: theme.colors.neutral.variant[10],
      },
    }),
  separator: ({ theme }) =>
    css({
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      padding: '0px 10px 0px 10px',
      alignItems: 'center',
      '& > div': {
        zIndex: 1,
        backgroundColor: theme.colors.neutral.variant[0],
        paddingRight: '5px',
        display: 'flex',
        alignItems: 'center',
      },
      '.view-all': {
        paddingLeft: '5px',
        cursor: 'pointer',
      },
      '& > hr': {
        height: 1,
        position: 'absolute',
        borderTop: `1px solid ${theme.colors.neutral.variant[30]}`,
        left: 0,
        right: 0,
        zIndex: 0,
        margin: '0px 10px 0px 10px',
      },
    }),
  textNoWrap: () => {
    css({
      whiteSpace: 'nowrap',
    });
  },
} satisfies Styles<SearchInputStyleStates>;

export const useStyles = withStyles(styles);
