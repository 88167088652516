import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useRequestCV = (params: WrappedHookParams) => {
  return useCandidateAction<void, RequestCVCallbackParams>(async ({ candidate }) => {
    const { data } = await campaignsClient.getCampaignCandidateCV({
      campaignId: `${candidate.campaignId}`,
      candidateId: candidate.es_person_id,
      options: { responseType: 'blob' },
    });

    return data;
  })(params);
};

export type RequestCVCallbackParams = any;
