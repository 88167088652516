import type { Candidate } from 'model';
import type { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';

import { useMemo } from 'react';

export const useProps: UsePropsHook = (candidate) => {
  const candidateEsPersonId = candidate?.es_person_id || '';
  const candidateName = candidate?.name || '';
  const notObfuscatedPhone = candidate?.not_obfuscated_phone;
  const notObfuscatedEmail = candidate?.not_obfuscated_email;
  const labels = candidate?.reasons || [];
  const phone = candidate?.contact_info.phone;
  const email = candidate?.contact_info.email;
  const lastRefresh = candidate?.lastRefresh ?? '';
  const isNoLongerAvailable = candidate ? !candidate.isAvailable : false;
  const isAlreadyInteracted = candidate ? candidate.alreadyInteracted : false;
  const campaignId = candidate?.campaignId;

  const activeJobboard = candidate?.activeJobBoard;

  return useMemo(
    () => ({
      candidateEsPersonId,
      candidateName,
      notObfuscatedPhone,
      notObfuscatedEmail,
      labels,
      phone,
      email,
      activeJobboard,
      lastRefresh,
      isNoLongerAvailable,
      isAlreadyInteracted,
      campaignId,
    }),
    [
      candidateEsPersonId,
      candidateName,
      notObfuscatedPhone,
      notObfuscatedEmail,
      JSON.stringify(labels.sort()),
      phone,
      email,
      activeJobboard,
      lastRefresh instanceof Date ? lastRefresh.valueOf() : lastRefresh,
      isNoLongerAvailable,
      isAlreadyInteracted,
      campaignId,
    ],
  );
};

type UsePropsHook = (candidate: Candidate | undefined) => {
  candidateEsPersonId: string;
  candidateName: string;
  campaignId: number | undefined;
  notObfuscatedPhone: string | undefined;
  notObfuscatedEmail: string | undefined;
  labels: string[];
  phone: string | undefined;
  email: string | undefined;
  activeJobboard: CampaignJobboard | undefined;
  lastRefresh: Date | string;
  isNoLongerAvailable: boolean;
  isAlreadyInteracted: boolean;
};
