import { Education } from 'clients/CampaignsClient/CandidateResponse';
import { sharedUtils } from 'utils';
import { copyObject } from 'utils/shared';

export const getLastEducations = (_educations: Education[], length?: number) => {
  const educations = copyObject(_educations).reverse().slice(0, length);

  return educations.map((education) => {
    const educationName: Array<string> = [];
    if (education.degree) {
      educationName.push(education.degree);
    }

    if (education.field) {
      const prefix = education.degree ? ', ' : '';

      educationName.push(prefix, education.field);
    }

    if (education.school?.name) {
      const prefix = education.degree || education.field ? ' @ ' : '';

      educationName.push(prefix, education.school.name);
    }

    return {
      name: educationName.join(''),
      description: education.description,
      startYear: education.dates.start?.year,
      endYear: education.dates.end?.year,
      duration: sharedUtils.getDuration(education.dates),
      fullDuration: sharedUtils.getFullDuration(education.dates),
    };
  });
};
