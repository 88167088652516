import { CandidateCV } from 'components/ui/organisms/CandidateDetails/CandidateDetailsContent/CandidateCVAnchor/CandidateCV';
import Candidate from 'model/Candidate/Candidate';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import { CandidateStatus } from 'types/candidate';
import { createContext, useContextSelector } from 'use-context-selector';
import { SelectedCampaignContext } from '../SelectedCampaignContext';
import { useIsCvAvailable } from '../UserContext/CandidateContext.useIsCvAvailable';
import { useStyles } from './LoadedCvsContext.styles';

type LoadedCvs = {
  getRenderedCv: (candidate: Candidate) => {
    init: (node: HTMLElement) => void;
    dispose: () => void;
  } | null;
};

const initialState: LoadedCvs = {
  getRenderedCv: () => {
    throw Error('not implemented');
  },
};

export const LoadedCvsContext = createContext(initialState);

export const LoadedCvsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  const iframeContainerRef = useRef<HTMLDivElement | null>(null);
  const isCvAvailable = useIsCvAvailable();

  const [activeNode, setActiveNode] = useState<HTMLElement | null>(null);
  const [renderedList, setRenderedList] = useState<Record<string, HTMLDivElement | null>>({});
  const [renderList, setRenderList] = useState<Record<string, React.ReactNode>>({});

  const candidates = useContextSelector(SelectedCampaignContext, (state) => state.candidates.data);

  const getKeyFromCandidate = ({ es_person_id, status }: Candidate) => {
    const sufix = status === CandidateStatus.Matched ? '-matched' : '';
    const key = `${es_person_id}${sufix}`;

    return key;
  };

  const setCv = useCallback((candidate: Candidate, node: React.ReactNode) => {
    const key = getKeyFromCandidate(candidate);

    setRenderList((list) => (key in list ? list : { ...list, [key]: node }));
  }, []);

  const setIframeContainerVisibility = useCallback((visibility: 'hidden' | 'visible') => {
    const iframeContainer = iframeContainerRef.current;

    if (iframeContainer) {
      iframeContainer.style.visibility = visibility;
    }
  }, []);

  const getRenderedCv = useCallback<LoadedCvs['getRenderedCv']>(
    (candidate) => {
      const key = getKeyFromCandidate(candidate);
      const renderedNode = renderedList[key];

      if (!renderedNode) {
        if (candidate) {
          setCv(candidate, <CandidateCV candidate={candidate} />);
        }

        return null;
      }

      return {
        init: (node) => {
          renderedNode.classList.add('visible');

          setActiveNode(node);
        },
        dispose: () => {
          renderedNode.classList.remove('visible');

          setActiveNode(null);
        },
      };
    },
    [renderedList],
  );

  useDebounce(() => !activeNode && setIframeContainerVisibility('hidden'), 350, [activeNode]);

  useEffect(() => {
    activeNode && setIframeContainerVisibility('visible');
  }, [activeNode]);

  useEffect(() => {
    if (activeNode) {
      let animationFrameId: number;

      const updateIframeContainerPosition = () => {
        const activeNodeParent = activeNode?.parentElement?.parentElement?.parentElement;
        const iframeContainer = iframeContainerRef.current;

        if (iframeContainer && activeNodeParent) {
          const parentRect = activeNodeParent.getBoundingClientRect();
          iframeContainer.style.top = `${parentRect.top}px`;
          iframeContainer.style.left = `${parentRect.left}px`;
          iframeContainer.style.width = `${parentRect.width}px`;
          iframeContainer.style.height = `${parentRect.height}px`;

          return parentRect;
        }

        return { top: 0, left: 0 };
      };

      const updateIframePosition = () => {
        const iframeContainer = iframeContainerRef.current?.firstChild as HTMLDivElement;

        if (iframeContainer) {
          const { top, left, width, height } = activeNode.getBoundingClientRect();

          if (top === 0 && left === 0 && width === 0 && height === 0) return;
          const parentRect = updateIframeContainerPosition();

          iframeContainer.style.top = `${top - parentRect.top}px`;
          iframeContainer.style.left = `${left - parentRect.left}px`;
          iframeContainer.style.width = `${width}px`;
          iframeContainer.style.height = `${height}px`;
        }

        animationFrameId = requestAnimationFrame(updateIframePosition);
      };

      updateIframePosition();

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId);
        }
      };
    }
  }, [activeNode, renderList]);

  const shouldSetCv = (candidate: Candidate) =>
    isCvAvailable(candidate) &&
    renderList[candidate.es_person_id] !== null &&
    candidate.status !== CandidateStatus.Matched;

  useEffect(() => {
    candidates.forEach((candidate) => {
      if (shouldSetCv(candidate)) {
        setCv(candidate, <CandidateCV candidate={candidate} />);
      }
    });
  }, [candidates, renderList]);

  const value = useMemo<LoadedCvs>(() => ({ getRenderedCv }), [getRenderedCv]);

  const _setRenderedCv = useCallback((key: string, ref: HTMLDivElement | null) => {
    setRenderedList((list) => (key in list ? list : { ...list, [key]: ref }));
  }, []);

  return (
    <LoadedCvsContext.Provider value={value}>
      {children}
      <div ref={iframeContainerRef} css={classes.root} id="loaded-cvs">
        <div css={classes.iframeNestedContainer}>
          {Object.entries(renderList).map(
            ([key, node]) =>
              node && (
                <div key={key} ref={(ref) => _setRenderedCv(key, ref)} data-es-id={key}>
                  {node}
                </div>
              ),
          )}
        </div>
      </div>
    </LoadedCvsContext.Provider>
  );
};
