import { BaseError } from 'clients/types';
import { extractErrorMessageFromAxiosResponse } from 'helpers/clientHelpers';
import { useContext, useEffect, useRef, useState } from 'react';
// @ts-ignore
import { FormProvider, useForm } from 'react-hook-form';
import {
  EditTenantUsersModalChangedUsers,
  EditTenantUsersModalFormFields,
  EditTenantUsersModalProps,
  EditTenantUsersModalUser,
} from './types';
import { useStyles } from './EditTenantUsersModal.styles';
import { EditTenantUsersList } from './components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { userClient } from 'clients/UserClient/UserClient';
import { EditTenantUsersListUser } from './components/EditTenantUsersList/types';
import { paramBind } from 'helpers/useQueryHelpers';
import { useGTM } from 'hooks/gtm';
import { LayoutContext } from 'shared/contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { default as Modal, CloseModalHandler } from 'shared/components/Modal';
import { default as TextField } from 'shared/components/TextField';
import { UserRoles } from 'clients/UserClient/types';

const DEFAULT_USERS: EditTenantUsersModalUser[] = [
  { id: -1, first_name: '', last_name: '', email: '', has_tutorial: true, role: UserRoles.Admin },
];

const DEFAULT_USERS_STATE = {
  added: [],
  modified: [],
  removed: [],
};

const EditTenantUsersModal = ({ visible, onClose, tenant }: EditTenantUsersModalProps) => {
  const queryClient = useQueryClient();
  const gtm = useGTM();
  const { setSnackbarState } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [changedUsers, setChangedUsers] = useState<EditTenantUsersModalChangedUsers>(DEFAULT_USERS_STATE);
  const [currentUsers, setCurrentUsers] = useState<EditTenantUsersModalUser[]>(DEFAULT_USERS);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const classes = useStyles();
  const methods = useForm<EditTenantUsersModalFormFields>({
    defaultValues: {},
  });

  const { data: response = null } = useQuery(
    [tenantClientCacheKeys.getUsers, tenant.id],
    paramBind(tenantClient.getUsers),
    { enabled: visible },
  );
  const responseData = (response?.data || []).filter(({ is_active }) => is_active);

  useEffect(() => {
    if (responseData.length > 0) {
      setCurrentUsers(
        responseData.map(
          ({
            id,
            first_name,
            last_name,
            email,
            has_tutorial,
            role = UserRoles.Recruiter,
            ats_credential_id = 0,
            team_id = 0,
          }) => ({
            id,
            first_name,
            last_name,
            email,
            has_tutorial: !!has_tutorial,
            role,
            ats_credential_id,
            team_id,
          }),
        ),
      );
    }
  }, [response]);

  const disposeModal = () => {
    setCurrentUsers(DEFAULT_USERS);
    setChangedUsers(DEFAULT_USERS_STATE);
    queryClient.invalidateQueries([tenantClientCacheKeys.getUsers]);
  };

  const onSubmit = async () => {
    if (!response) return;
    setIsLoading(true);

    try {
      const { added, removed, modified } = changedUsers;

      const createUsersPromise = Promise.all(
        added.map(
          ({ first_name, last_name, email, has_tutorial, role, ats_credential_id, team_id }) =>
            userClient.create({
              email,
              first_name,
              last_name,
              is_active: true,
              send_activation_email: true,
              should_change_password: true,
              tenant_id: tenant.id,
              tenant_code: tenant.code,
              has_tutorial,
              role,
              ...(ats_credential_id === -1 || !ats_credential_id ? undefined : { ats_credential_id }),
              ...(team_id === -1 || !team_id ? undefined : { team_id }),
            }),
          gtm.createUser(),
        ),
      );
      const removeUsersPromise = Promise.all(
        removed
          .filter(({ id }) => id > 0)
          .map(({ id }) => {
            userClient.delete(id);
            gtm.editUser();
          }),
      );
      const modifyUsersPromise = Promise.all(
        modified.map(
          ({ team_id, ats_credential_id, ...user }) =>
            userClient.update({
              ...user,
              ats_credential_id: ats_credential_id === -1 || !ats_credential_id ? null : ats_credential_id,
              team_id: team_id === -1 || !team_id ? null : team_id,
            }),
          gtm.editUser(),
        ),
      );

      await Promise.all([createUsersPromise, removeUsersPromise, modifyUsersPromise]);

      setSnackbarState({
        open: true,
        message: dictionary.updateUsersSuccessfully,
        severity: 'success',
        autoHideDuration: 3000,
      });

      handleCloseModal('success');
      disposeModal();
    } catch (e) {
      setSnackbarState({
        open: true,
        message: extractErrorMessageFromAxiosResponse(e as BaseError),
        severity: 'error',
        autoHideDuration: 3000,
      });
    }

    setIsLoading(false);
  };

  const handleCloseModal: CloseModalHandler = (reason) => {
    if (reason === 'confirm') {
      return formRef.current?.requestSubmit();
    }

    onClose(reason);
  };

  const handleAddUser = (user: EditTenantUsersListUser) => {
    setChangedUsers({
      ...changedUsers,
      added: [...changedUsers.added, user],
    });

    setCurrentUsers([...currentUsers, user]);
  };

  const handleRemoveUser = (user: EditTenantUsersListUser) => {
    if (changedUsers.added.some(({ id }) => id === user.id)) {
      return setChangedUsers({
        ...changedUsers,
        added: changedUsers.added.filter(({ id }) => id !== user.id),
      });
    }

    setChangedUsers({
      ...changedUsers,
      removed: [...changedUsers.removed, user],
    });

    setCurrentUsers(currentUsers.filter((currentUser) => user.id !== currentUser.id));
  };

  const handleChangeField = (user: EditTenantUsersListUser) => {
    const someOldUserHasBeenChanged =
      response?.data &&
      response.data.some(
        (responseUser) =>
          user.id === responseUser.id &&
          (user.email !== responseUser.email ||
            user.first_name !== responseUser.first_name ||
            user.last_name !== responseUser.last_name ||
            user.has_tutorial !== responseUser.has_tutorial ||
            user.role !== responseUser.role ||
            user.ats_credential_id !== responseUser.ats_credential_id ||
            user.team_id !== responseUser.team_id),
      );

    if (someOldUserHasBeenChanged) {
      return setChangedUsers({
        ...changedUsers,
        modified: [...changedUsers.modified.filter((oldModifiedUser) => user.id !== oldModifiedUser.id), user],
      });
    }

    if (user.id < 0) {
      setChangedUsers({
        ...changedUsers,
        added: [...changedUsers.added.filter((oldUser) => user.id !== oldUser.id), user],
      });
    }
  };

  const handleSearch = (text: string) => {
    if (responseData.length > 0) {
      const searchLowerCase = text.toLowerCase();
      const [firstUser, ...users] = responseData.map(({ role = UserRoles.Recruiter, ...user }) => ({
        ...user,
        has_tutorial: !!user.has_tutorial,
        role,
      }));

      const search = ({ first_name, last_name, email }: EditTenantUsersModalUser) => {
        const nameLowerCase = `${first_name} ${last_name}`.toLowerCase();
        const emailLowerCase = email.toLowerCase();

        return nameLowerCase.includes(searchLowerCase) || emailLowerCase.includes(searchLowerCase);
      };

      setCurrentUsers([
        changedUsers.modified.find((modifiedUser) => firstUser.id === modifiedUser.id) || firstUser,
        ...users.filter((searchUser) => {
          if (changedUsers.modified.some((user) => searchUser.id === user.id)) {
            return false;
          }

          return !changedUsers.removed.find((user) => searchUser.id === user.id) && search(searchUser);
        }),
        ...changedUsers.modified.filter(search),
        ...changedUsers.added.filter(search),
      ]);
    }
  };

  return (
    <Modal
      title={dictionary.editTenantUsers}
      subtitle={dictionary.editTenantUsersSubtitle}
      visible={visible}
      onClose={handleCloseModal}
      isLoading={isLoading}>
      <div css={classes.search}>
        <TextField label={dictionary.search} onChange={handleSearch} contained />
      </div>
      <FormProvider {...methods}>
        <form ref={formRef} css={classes.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <EditTenantUsersList
            data={currentUsers.map((user) => ({
              ...user,
              onChangeField: handleChangeField,
              onRemoveField: handleRemoveUser,
              tenant_id: tenant.id,
            }))}
            onAddField={handleAddUser}
          />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default EditTenantUsersModal;
