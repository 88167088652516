import { FieldValues, TextFieldWithUseFormProps } from './types';
import TextField from './TextField';
// @ts-ignore
import { Controller, useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { flatten } from 'flat';

type ControlError = { message?: string };

const TextFieldWithUseForm = <T extends FieldValues>({
  control: parentControl,
  errors: parentErrors,
  ...props
}: TextFieldWithUseFormProps<T>) => {
  const [error, setError] = useState<string>();
  const { errors = parentErrors, control = parentControl } = useFormContext() || {};
  const isArrayField = !!`${props.name}`.match(/\[\d+\]/);
  const flatErrors = isArrayField
    ? Object.entries(flatten(errors as any, { maxDepth: 3 }) as any).reduce((errors, [k, v]) => {
        const [key, index, lastKey] = k.split('.');

        return {
          ...errors,
          [`${key}[${index}].${lastKey}`]: v,
        };
      }, {})
    : ({} as any);
  const instanceErrors = error ?? ((isArrayField ? flatErrors?.[props.name] : errors?.[props.name]) as ControlError);
  const maxLengthRule = props.rules?.maxLength;

  return (
    <Controller
      as={TextField}
      {...props}
      rules={{
        ...props.rules,
        validate: (value: string) => props.rules?.validate?.(value) || error || undefined,
      }}
      control={control}
      hasError={props.hasError || !!instanceErrors}
      errorMessage={
        props.errorMessage || (typeof instanceErrors === 'object' ? instanceErrors.message : instanceErrors)
      }
      setError={setError}
      required={props.rules?.required === true}
      maxLength={(typeof maxLengthRule === 'object' ? maxLengthRule.value : maxLengthRule) as number}
    />
  );
};

export default TextFieldWithUseForm;
