import { createTheme } from '@material-ui/core/styles';
import { PropertiesHyphen } from 'csstype';
import {
  Font,
  PlatformColor,
  StyleGuide,
  StyleGuideColor,
  StyleGuidePatterns,
  StyleGuideSpacing,
  StyleGuideTypography,
  Theme,
} from './types';
import { theme, extractFont } from '@recruitrobin/robin-theme/web-styles';

type CSSPropName = keyof PropertiesHyphen;

/**
 * @deprecated import from `utils/style` instead
 */
export const generateTransitionStyles = (
  props: CSSPropName | CSSPropName[],
  duration: string = themeOld.styleGuide.patterns.transition.duration.short,
  animFunction: string = themeOld.styleGuide.patterns.transition.function.default,
) => {
  const fProps: Array<CSSPropName> = Array.isArray(props) ? props : [props];

  return fProps.map((prop) => `${prop} ${duration} ${animFunction}`).join(', ');
};

const debugColor = '';

const color: StyleGuideColor = {
  monochrome: {
    0: debugColor || '#FFFFFF',
    5: debugColor || '#FBFBFD',
    10: debugColor || '#F6F7F9',
    20: debugColor || '#ECEEF3',
    30: debugColor || '#D6D9DF',
    40: debugColor || '#C1C4CA',
    50: debugColor || '#ACAEB5',
    60: debugColor || '#9798A0',
    70: debugColor || '#81828A',
    80: debugColor || '#6B6C73',
    90: debugColor || '#53565B',
    100: debugColor || '#3C3F43',
  },
  byzantine: {
    20: debugColor || '#FAEAF8',
    30: debugColor || '#E8C0E1',
    40: debugColor || '#DB8BCE',
    60: debugColor || '#BB2AA2',
    80: debugColor || '#92217E',
    90: debugColor || '#801D6E',
  },
  navy: {
    10: debugColor || '#E8F5FC',
    20: debugColor || '#D3EDFC',
    30: debugColor || '#A3D9F9',
    40: debugColor || '#6CB4E0',
    60: debugColor || '#1769A3',
    80: debugColor || '#00305C',
  },
  mint: {
    10: debugColor || '#EBFCF3',
    20: debugColor || '#D9FCE9',
    30: debugColor || '#86EBC8',
    40: debugColor || '#00C388',
    60: debugColor || '#008F64',
    80: debugColor || '#056B4C',
  },
  orange: {
    10: debugColor || '#FFF1E5',
    20: debugColor || '#FFDEC2',
    40: debugColor || '#FFB677',
    60: debugColor || '#FF8C2A',
    70: debugColor || '#CC6210',
    80: debugColor || '#C25A00',
    90: debugColor || '#A64200',
  },
  maroon: {
    10: debugColor || '#FAF3F2',
    20: debugColor || '#F0D7D3',
    40: debugColor || '#CC857A',
    70: debugColor || '#AD1703',
    80: debugColor || '#991805',
    90: debugColor || '#801705',
  },
};

const typography: StyleGuideTypography<Font> = {
  header: {
    h1: {
      fontFamily: 'Outfit',
      fontWeight: 700,
      fontSize: '23px',
      lineHeight: '130%',
      color: color.monochrome[100],
      letterSpacing: '0.008em',
    },
    h2: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: '21px',
      lineHeight: '130%',
      color: color.monochrome[90],
      letterSpacing: '0.008em',
    },
    h3: {
      fontFamily: 'Outfit',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '135%',
      color: color.monochrome[90],
      letterSpacing: '0.006em',
    },
    h4: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '135%',
      color: color.monochrome[90],
      letterSpacing: '0.005em',
    },
    h5: {
      fontFamily: 'Outfit',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '140%',
      color: color.monochrome[90],
      letterSpacing: '0.005em',
    },
    h6: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '140%',
      color: color.monochrome[100],
      letterSpacing: '0.0025em',
    },
  },
  body: {
    long: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '145%',
      color: color.monochrome[80],
      letterSpacing: '0.005em',
    },
    short: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '125%',
      color: color.monochrome[80],
      letterSpacing: '0.01em',
    },
  },
  supporting: {
    code: {
      fontFamily: 'Roboto Mono',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '140%',
      color: color.monochrome[90],
      letterSpacing: '0em',
    },
    codeInline: {
      fontFamily: 'Roboto Mono',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '130%',
      color: color.monochrome[90],
      letterSpacing: '-0.01em',
    },
    helper: {
      fontFamily: 'Outfit',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '135%',
      color: color.monochrome[80],
      letterSpacing: '0.005em',
    },
    label: {
      fontFamily: 'Outfit',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '130%',
      color: color.monochrome[90],
      letterSpacing: '0.01em',
    },
    labelTiny: {
      fontFamily: 'Outfit',
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '130%',
      color: color.monochrome[90],
      letterSpacing: '0.005em',
      textTransform: 'uppercase',
    },
  },
};

const platformColor: PlatformColor = {
  recruitrobin: debugColor || '#fa4b0f',
  indeed: debugColor || '#085ff7',
  linkedin: debugColor || '#0072b1',
  monster: debugColor || '#6e46ae',
  nvb: debugColor || '#ff9320',
  werknl: debugColor || '#ff6600',
  debanensite: debugColor || '#215294',
  techniekwerkt: debugColor || '#ed6c19',
  jobbird: debugColor || '#292929',
  uitzendbureau: debugColor || '#0096C3',
  resumeio: debugColor || '#2F8DEB',
  studentjob: debugColor || '#000000',
  /// TODO WERKZOEKEN: Remove this after release new jobboard
  // werkzoeken: debugColor || '#F83B3B',
  ats: debugColor || '#696c69',
  whatsapp: debugColor || '#25D366',
};

const patterns: StyleGuidePatterns = {
  transition: {
    duration: {
      short: '80ms',
      long: '120ms',
    },
    function: {
      default: 'cubic-bezier(.22,.9,.53,1)',
    },
  },
  debounce: {
    input: 250,
    slider: 500,
    select: 500,
  },
};

const spacing: StyleGuideSpacing = {
  button: {
    marginLeft: 8,
  },
};

export const styleGuide: StyleGuide = {
  color,
  typography,
  platformColor,
  patterns,
  spacing,
};

export const themeOld: Theme = {
  spacing: {
    unit: 8,
  },
  size: {
    unit: 8,
  },
  zIndex: {
    header: 100,
  },
  borderRadius: {
    xs: 2,
    sm: 4,
    md: 8,
    lg: 16,
    round: '50%',
  },
  components: {
    timeline: {
      experience: {
        base: {
          primary: '#ececec',
          primaryDark: '#dcdcdc',
          highlighted: '#94CA8E',
          highlightedLight: '#bfdfbc',
        },
        job: {
          primary: '#abddfc',
          primaryDark: '#84adc6',
        },
        education: {
          primary: '#c5eeec',
          primaryDark: '#92b5b3',
        },
      },
    },
    plan: {
      base: {
        primaryBlue: '#51A3CC',
        primaryDarkBlue: '#26688a',
        primaryOrange: '#ff9800',
        initBlue: '#244fff',
      },
    },
  },
  typography: {
    headers: {
      h1: 48,
      h2: 36,
      h3: 28,
      h4: 18,
    },
    main: {
      bodySmall: 12,
      bodyNormal: 13,
      bodyMedium: 14,
      bodyLarge: 16,
      title: 18,
      titleLarge: 22,
      titleHuge: 30,
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 500,
      heavy: 700,
    },
    family: {
      main: '"Rubik", sans-serif',
    },
  },
  styleGuide,
};

export const muiTheme = createTheme({
  styleGuide,
  typography: {
    fontFamily: '"Outfit", sans-serif',
    h1: { ...styleGuide.typography.header.h1 },
    h2: { ...styleGuide.typography.header.h2 },
    h3: { ...styleGuide.typography.header.h3 },
    h4: { ...styleGuide.typography.header.h4 },
    h5: { ...styleGuide.typography.header.h5 },
    h6: { ...styleGuide.typography.header.h6 },
  },
  palette: {
    primary: {
      main: themeOld.styleGuide.color.byzantine[60],
      light: themeOld.styleGuide.color.byzantine[20],
    },
    secondary: {
      main: themeOld.styleGuide.color.orange[80],
      light: themeOld.styleGuide.color.orange[60],
    },
    success: {
      main: themeOld.styleGuide.color.mint[40],
      dark: themeOld.styleGuide.color.mint[60],
    },
    warning: {
      light: '#FABB00',
      main: '#EF8800',
    },
    error: {
      main: themeOld.styleGuide.color.maroon[70],
    },
  },
  overrides: {
    MuiMenuItem: {
      root: {
        ...themeOld.styleGuide.typography.supporting.helper,
        color: themeOld.styleGuide.color.monochrome[90],
        minHeight: 'auto',
      },
    },
    MuiListItem: {
      gutters: {
        paddingTop: '6px',
        paddingRight: '12px',
        paddingBottom: '6px',
        paddingLeft: '12px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '6px',
        paddingRight: '0.5px',
        paddingBottom: '6px',
        paddingLeft: '0.5px',
      },
    },
    MuiFormControlLabel: {
      root: {
        cursor: 'default',
        marginLeft: '-9px',
        '&.Mui-disabled': {
          cursor: 'not-allowed',
        },
      },
      label: {
        ...themeOld.styleGuide.typography.body.short,
        color: themeOld.styleGuide.color.monochrome[90],
        cursor: 'default',
        letterSpacing: '0.01em',
        '&.Mui-disabled': {
          cursor: 'not-allowed',
          color: themeOld.styleGuide.color.monochrome[40],
        },
      },
    },
    MuiFormLabel: {
      root: {
        ...themeOld.styleGuide.typography.supporting.label,
        color: themeOld.styleGuide.color.monochrome[80],
        '&.Mui-error, &.Mui-focused': {
          color: themeOld.styleGuide.color.monochrome[80],
        },
      },
    },
    MuiInputAdornment: {
      root: {
        height: 'auto',
        color: theme.colors.neutral.gray[60],
        'input.Mui-disabled + &': {
          color: 'transparent',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        ...themeOld.styleGuide.typography.supporting.helper,
        color: themeOld.styleGuide.color.monochrome[90],
        marginTop: '6px',
        '&.Mui-error': {
          color: themeOld.styleGuide.color.maroon[70],
        },
      },
      contained: {
        marginLeft: '0',
        marginRight: '0',
      },
    },
    MuiSlider: {
      root: {
        color: themeOld.styleGuide.color.monochrome[80],
        '&.Mui-disabled': {
          color: themeOld.styleGuide.color.monochrome[40],
        },
      },
      rail: {
        color: themeOld.styleGuide.color.monochrome[40],
        opacity: 1,
        '.Mui-disabled &': {
          color: themeOld.styleGuide.color.monochrome[30],
        },
      },
      track: {
        transition: generateTransitionStyles(['color']),
      },
      mark: {
        opacity: 0,
      },
      markActive: {
        opacity: 0,
      },
      markLabel: {
        ...themeOld.styleGuide.typography.supporting.labelTiny,
        color: themeOld.styleGuide.color.monochrome[80],
        '.Mui-disabled &': {
          color: themeOld.styleGuide.color.monochrome[40],
        },
      },
      markLabelActive: {
        color: themeOld.styleGuide.color.monochrome[80],
        '.Mui-disabled &': {
          color: themeOld.styleGuide.color.monochrome[40],
        },
      },
    },
    MuiInputBase: {
      root: {
        background: theme.colors.neutral.variant[10],
        transition: generateTransitionStyles(['background-color', 'border-color', 'color']),
        '&.Mui-error input': {
          // backgroundColor: themeOld.styleGuide.color.maroon[10],
        },
      },
      input: {
        ...(extractFont((font) => font.body.short, 'web') as any),
        color: theme.colors.neutral.variant[80],
        padding: '9px 16px',
        background: theme.colors.neutral.variant[10],
        borderRadius: 0,
        letterSpacing: '0.01em',
        '&::placeholder': {
          color: theme.colors.neutral.variant[40],
          opacity: 1,
        },
        transition: generateTransitionStyles(['background-color', 'border-color', 'color']),
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '& .MuiInputBase-input': {
          background: theme.colors.neutral.variant[10],
        },
        '&:hover, &:hover .MuiInputBase-input': {
          backgroundColor: theme.colors.neutral.variant[5],
        },
        '&.Mui-focused, &.Mui-focused .MuiInputBase-input': {
          backgroundColor: themeOld.styleGuide.color.monochrome[0],
        },
        '&.Mui-disabled, &.Mui-disabled .MuiInputBase-input': {
          cursor: 'not-allowed',
          color: theme.colors.neutral.variant[30],
          backgroundColor: theme.colors.neutral.variant[10],
          '&::placeholder': {
            color: theme.colors.neutral.variant[30],
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.neutral.variant[10],
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.neutral.variant[30],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.primary.fuchsia[30],
          borderWidth: 1,
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.neutral.variant[10],
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.colors.tertiary.red[50],
        },
      },
      input: {
        padding: '9px 16px',
        '&[type="password"]::-ms-reveal, &[type="password"]::-ms-clear': {
          display: 'none',
        },
      },
      multiline: {
        padding: '9px 16px',
        '& > textarea': {
          resize: 'vertical',
        },
      },
      notchedOutline: {
        transition: generateTransitionStyles(['background-color', 'border-color', 'color']),
        borderColor: themeOld.styleGuide.color.monochrome[20],
      },
      adornedEnd: {
        paddingRight: '10px',
      },
    },
    MuiTypography: {
      button: {
        ...themeOld.styleGuide.typography.supporting.label,
        color: 'inherit',
        lineHeight: '18px',
      },
    },
    MuiTooltip: {
      arrow: {
        color: theme.colors.neutral.variant[80],
      },
      tooltip: {
        ...themeOld.styleGuide.typography.supporting.helper,
        backgroundColor: theme.colors.neutral.variant[80],
        color: theme.colors.neutral.variant[0],
        padding: '3px 9px',
        borderRadius: '3px',
      },
      tooltipPlacementBottom: {
        margin: '12px 0 0 !important',
      },
    },
    MuiChip: {
      root: {
        ...(extractFont((font) => font.supporting.label, 'web') as any),
        color: theme.colors.neutral.variant[70],
      },
      icon: {
        color: themeOld.styleGuide.color.byzantine[60],
      },
      deleteIcon: {
        color: theme.colors.neutral.variant[70],
      },
      colorSecondary: {
        color: themeOld.styleGuide.color.mint[40],
        backgroundColor: themeOld.styleGuide.color.mint[20],
      },
      deleteIconColorSecondary: {
        color: themeOld.styleGuide.color.mint[40],
        '&:hover': {
          color: themeOld.styleGuide.color.mint[60],
        },
      },
    },
    MuiPaper: {
      root: {
        '&.MuiPaper-rounded': {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: theme.colors.neutral.variant[10],
          borderRadius: 8,
          boxShadow: '0px 6px 6px 0px rgba(96, 113, 128, 0.03), 0px 2px 3px 0px rgba(96, 113, 128, 0.10)',
        },
        '&.MuiAutocomplete-paper.MuiPaper-rounded .MuiAutocomplete-listbox': {
          padding: '6px 0',
        },
        '&.MuiAutocomplete-paper.MuiPaper-rounded .MuiAutocomplete-option': {
          ...themeOld.styleGuide.typography.body.short,
          color: themeOld.styleGuide.color.monochrome[90],
          borderLeft: '2px solid transparent',
          height: '30px',
          minHeight: 'auto',
        },
        '&.MuiAutocomplete-paper.MuiPaper-rounded .MuiAutocomplete-groupLabel': {
          ...extractFont((font) => font.supporting['label-tiny'], 'web'),
          color: theme.colors.neutral.variant[40],
          height: '25px',
          lineHeight: '25px',
          textTransform: 'uppercase',
        },
      },
      rounded: {
        borderRadius: 8,
      },
    },
  },
  zIndex: {
    modal: 90,
  },
});

export const placeholderSharedStyle = {
  backgroundColor: `${themeOld.styleGuide.color.monochrome[20]} !important`,
  color: 'transparent !important',
  animationName: 'fadeInAndOut',
  animationDuration: '1000ms',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
};
