import { Button, Dialog, Loader, Typography } from '@recruitrobin/robin-theme/web-components';
import { Bell_01 } from '@recruitrobin/robin-theme/web-icons';
import { WerknlLayout } from 'clients/UserClient/types';
import { useCallback, useContext, useMemo, useState } from 'react';
import { Radio } from 'shared/components/RadioGroup';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { usePreventScroll } from 'shared/hooks/usePreventScroll';
import { useStyles } from './WerknlCandidateInfoPresentationDialog.styles';
import { WerknlCandidateInfoPresentationDialogProps } from './WerknlCandidateInfoPresentationDialog.types';
import { useLocalStorage } from 'hooks/shared';
import { WERKNL_LAYOUT_CHOSEN } from 'shared/contexts/UserContext/types';

export const WerknlCandidateInfoPresentationDialog = ({
  isVisible,
  onConfirm,
}: WerknlCandidateInfoPresentationDialogProps) => {
  const classes = useStyles();
  const { updateCurrentUser, currentUser } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [werknlLayout, setWerknlLayout] = useState<WerknlLayout>();
  const { setValue: setWerknlLayoutChosen } = useLocalStorage<boolean>(`${WERKNL_LAYOUT_CHOSEN}-${currentUser?.id}`);
  usePreventScroll(isVisible);

  const handleRadioChange = (option: WerknlLayout) => {
    setWerknlLayout(option);
  };

  const srcNewImg = useMemo(() => {
    return werknlLayout === WerknlLayout.New ? '/new-selected.png' : '/new.png';
  }, [werknlLayout]);

  const srcLegacyImg = useMemo(() => {
    return werknlLayout === WerknlLayout.Legacy ? '/legacy-selected.png' : '/legacy.png';
  }, [werknlLayout]);

  const handleSave = useCallback(() => {
    if (updateCurrentUser) {
      setIsLoading(true);
      updateCurrentUser({
        werknl_layout: werknlLayout,
      })
        .then(() => {
          setWerknlLayoutChosen(true);
          onConfirm();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [updateCurrentUser, werknlLayout]);

  return (
    <Dialog
      isVisible={isVisible}
      onConfirm={handleSave}
      renderButtons={
        <Button
          endIcon={isLoading && <Loader size={16} />}
          label={dictionary.confirmSelection}
          onClick={handleSave}
          disabled={!werknlLayout}
          style="filled"
          type="highlight"
        />
      }
      confirmLabel={dictionary.confirmSelection}
      maxWidth={853}
      body={
        <div css={classes.content}>
          <div css={classes.contentHeader}>
            <Bell_01 color={(c) => c.neutral.variant[60]} size={24} />
            <Typography variant={(v) => v.heading[3]} color={(c) => c.neutral.variant[80]}>
              {dictionary.werknlCandidateInfoPresentation}
            </Typography>
            <Typography variant={(v) => v.body.short} color={(c) => c.neutral.variant[80]}>
              {dictionary.werknlCandidateInfoPresentationDescription}
            </Typography>
          </div>

          <Typography variant={(v) => v.supporting.helper} color={(c) => c.neutral.variant[60]}>
            {dictionary.werknlCandidateInfoPresentationNote}
          </Typography>

          <div css={classes.radioButtonsWrapper}>
            <div css={classes.radioButton} onClick={() => handleRadioChange(WerknlLayout.Legacy)}>
              <Radio
                label={dictionary.old}
                value={WerknlLayout.Legacy}
                checked={werknlLayout === WerknlLayout.Legacy}
                onChange={() => handleRadioChange(WerknlLayout.Legacy)}
              />
              <img src={srcLegacyImg}></img>
            </div>
            <div css={classes.divider} />
            <div css={classes.radioButton} onClick={() => handleRadioChange(WerknlLayout.New)}>
              <Radio
                label={dictionary.new}
                value={WerknlLayout.New}
                checked={werknlLayout === WerknlLayout.New}
                onChange={() => handleRadioChange(WerknlLayout.New)}
              />
              <img src={srcNewImg}></img>
            </div>
          </div>
        </div>
      }
    />
  );
};
