import { useMemo, useState } from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { createContext } from 'use-context-selector';
import { CustomGTMContextProps, CustomGTMContextProviderProps } from './types';

const initialState: CustomGTMContextProps = {
  config: {
    user_id: -1,
    tenant_id: -1,
  },
  setConfig: () => {
    throw '[CustomGTMContext - setConfig] Provide CustomGTMContextProvider';
  },
};

export const CustomGTMContext = createContext<CustomGTMContextProps>(initialState);

export const CustomGTMContextProvider = ({ children }: CustomGTMContextProviderProps) => {
  const [config, setConfig] = useState(initialState.config);

  const gtmParams = { id: 'GTM-5NS8HQ8' };

  const valueMemoized = useMemo(() => {
    return { config, setConfig };
  }, [config]);

  return (
    <CustomGTMContext.Provider value={valueMemoized}>
      <GTMProvider state={{ ...gtmParams, dataLayer: config }}>{children}</GTMProvider>
    </CustomGTMContext.Provider>
  );
};
