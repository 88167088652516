import { useQuery } from '@tanstack/react-query';
import { Tenant, tenantClient, tenantClientCacheKeys } from 'clients/Admin';
import { FilterOrder, PaginatedResponse } from 'clients/types';
import { paramBind } from 'helpers/useQueryHelpers';
import { useEffect, useState } from 'react';
import { TableHeaderCurrentSortState } from 'shared/components/Table';
import { TableActions, TableBody, TableFilters } from './components';
import { useStyles } from './Dashboard.styles';
import { DashboardTableFilters } from './types';

const DEFAULT_TENANTS = {
  count: 0,
  results: [],
  next: null,
  previous: null,
};

const Dashboard = () => {
  const classes = useStyles();
  const [tenants, setTenants] = useState<PaginatedResponse<Tenant>>(DEFAULT_TENANTS);
  const [filters, setFilters] = useState<DashboardTableFilters>({
    page: 1,
    batchSize: 25,
    search: '',
    sort: { by: 'id', order: FilterOrder.Asc },
  });

  const {
    isLoading: isLoadingTenants,
    data: response = undefined,
    refetch: retrieveTableData,
  } = useQuery(
    [
      tenantClientCacheKeys.index,
      {
        offset: (filters.page - 1) * filters.batchSize,
        limit: filters.batchSize,
        search: filters.search,
        sort: filters.sort,
      },
    ],
    paramBind(tenantClient.index),
  );

  useEffect(() => {
    if (response?.data) {
      setTenants({
        ...tenants,
        ...(response && (response.data as PaginatedResponse<Tenant>)),
      });
    }
  }, [response]);

  const handleChangeBatchSize = (value = 25) => {
    setFilters({ ...filters, batchSize: value });
  };

  const handleSearch = (value = '') => {
    setFilters({ ...filters, page: 1, search: value });
  };

  const handleChangePage = (value = 1) => {
    setFilters({ ...filters, page: value });
  };

  const handleSort = (value: TableHeaderCurrentSortState<keyof Tenant>) => {
    setFilters({ ...filters, sort: { by: value.identifier, order: value.state } });
  };

  return (
    <div css={classes.root}>
      <div>
        <TableFilters onChangeBatchSize={handleChangeBatchSize} onSearch={handleSearch} />
        <TableActions />
      </div>
      <div>
        <TableBody
          retrieveTableData={retrieveTableData}
          tenants={tenants.results}
          paginationConfigs={{
            total: tenants.count,
            perPage: filters.batchSize,
            currentPage: filters.page,
            onChange: handleChangePage,
          }}
          onSort={handleSort}
          loading={isLoadingTenants}
        />
      </div>
    </div>
  );
};

export default Dashboard;
