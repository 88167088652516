import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { useContainer } from './CampaignListInfiniteLoader.useContainer';
import CampaignListItem from './CampaignListItem';
import { useContextSelector } from 'use-context-selector';
import { OffsetScrollListsContext } from 'shared/contexts/OffsetScrollListsContext';

export const CampaignListInfiniteLoader = () => {
  const { campaigns, count, isItemLoaded, loadMoreItems } = useContainer();
  const initialOffset = useContextSelector(OffsetScrollListsContext, (state) => state.campaignListOffset);
  const setOffset = useContextSelector(OffsetScrollListsContext, (state) => state.setCampaignListOffset);

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={count} loadMoreItems={loadMoreItems} threshold={3}>
      {({ ref, onItemsRendered }) => (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              ref={ref}
              onItemsRendered={onItemsRendered}
              /**
               * '+6' refers to the right padding of its parent. Since there's
               * no way to change FixedSizeList styles (like padding),
               * this is a workaround to correctly position the scrollbar.
               */
              width={width + 6}
              height={height}
              itemCount={count}
              itemSize={48}
              initialScrollOffset={initialOffset}
              onScroll={(e) => setOffset(e.scrollOffset)}
              itemData={campaigns}>
              {CampaignListItem}
            </FixedSizeList>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
