import { createContext } from 'use-context-selector';
import { VersionContextProps, VersionContextProviderProps } from './types';
import { useContainer } from './useContainer';

const initialState: VersionContextProps = null;

export const VersionContext = createContext<VersionContextProps>(initialState);

export const VersionContextProvider = ({ children }: VersionContextProviderProps) => {
  const { children: memoizedChildren } = useContainer({ children });

  return <VersionContext.Provider value={initialState}>{memoizedChildren}</VersionContext.Provider>;
};
