import { NavBar } from 'components/ui/organisms/NavBar';
import { useStyles } from './SearchV3Navbar.styles';
import { SerializedStyles } from '@emotion/react';

export const SearchV3Navbar = ({ navbarContainerCss }: { navbarContainerCss?: SerializedStyles }) => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <div css={[classes.absolute, navbarContainerCss]}>
        <NavBar />
      </div>
    </div>
  );
};
