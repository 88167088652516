import { Typography } from '@recruitrobin/robin-theme/web-components';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import EmptyStateImage from 'shared/img/campaignListEmptyState.svg';
import { useStyles } from './CampaignListEmptyState.styles';

export const CampaignListEmptyState = () => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div css={classes.root}>
      <div css={classes.text}>
        <Typography variant={(v) => v.heading[3]} color={(c) => c.neutral.variant[80]}>
          {dictionary.noSearchesFound}
        </Typography>
        <Typography variant={(v) => v.body.short} color={(c) => c.neutral.variant[80]}>
          {dictionary.campaignListEmptyStateMessage}
        </Typography>
      </div>

      <div css={classes.svg}>
        <img src={EmptyStateImage} alt={dictionary.noSearchesFound} />
      </div>
    </div>
  );
};
