import { Candidate } from 'model';

export type CandidateCVProps = {
  candidate: Candidate;
};

export enum RequestCvErrorType {
  notAvailable = 'notAvailable',
  formatIssues = 'formatIssues',
}

export type UseRequestCvHandler = (
  candidate: Candidate,
) => { isPdf: true; data: Blob; error?: never } | { isPdf: false; data: string | null; error?: RequestCvErrorType };
