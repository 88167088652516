import { AnimationProps } from 'framer-motion';
import { createContext, useEffect, useState } from 'react';
import { SidebarOverlayFixedContextType } from './SidebarOverlayFixedContext.types';

const initialState: SidebarOverlayFixedContextType = {
  isOpen: false,
  setIsOpen: () => {
    throw Error('setIsOpen function must be overridden');
  },
  sidebarRef: null,
  setSidebarRef: () => {
    throw Error('not implemented');
  },
};

export const sidebarAnimationConfig: AnimationProps = {
  initial: { maxWidth: 0.5 },
  animate: { maxWidth: 365 },
  exit: { maxWidth: 0.5 },
  transition: { duration: 0.32, ease: [0.22, 0.9, 0.53, 1] },
};

export const SidebarOverlayFixedContext = createContext(initialState);

export const SidebarOverlayFixedProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sidebarRef, setSidebarRef] = useState(initialState.sidebarRef);

  useEffect(() => {
    if (!window.location.pathname.includes('/search')) {
      setIsOpen(false);
    }
  }, [window.location.pathname]);

  return (
    <SidebarOverlayFixedContext.Provider value={{ isOpen, setIsOpen, sidebarRef, setSidebarRef }}>
      {children}
    </SidebarOverlayFixedContext.Provider>
  );
};
