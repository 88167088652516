import { Styles, withStyles, css, extractFont } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      alignItems: 'center',
    }),
  button: ({ theme, props: { on } }) =>
    css({
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      width: 48,
      height: 24,
      borderRadius: 34,
      borderWidth: 2,
      borderStyle: 'solid',
      marginRight: 8,
      transitionDuration: '300ms',
      flex: '0 0 auto',
      backgroundColor: on ? theme.colors.secondary.emerald[40] : theme.colors.neutral.variant[40],
      borderColor: on ? theme.colors.secondary.emerald[40] : theme.colors.neutral.variant[40],
      '& > div': {
        borderRadius: '50%',
        height: 18,
        width: 18,
        borderStyle: 'solid',
        borderColor: theme.colors.neutral.variant[0],
        marginRight: 8,
        backgroundColor: theme.colors.neutral.variant[0],
        flexShrink: 0,
        marginLeft: 1,
        transition: 'width 300ms, margin-left 300ms, height 300ms',
        ...(on && {
          marginLeft: 25,
        }),
      },
      '&:hover': {
        backgroundColor: on ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
        borderColor: on ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
      },
      '&:focus-within, &:focus-visible': {
        outline: 0,
        backgroundColor: on ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
        borderColor: on ? theme.colors.secondary.emerald[50] : theme.colors.neutral.variant[50],
        '& > div': {
          marginLeft: 0,
          height: 20,
          width: 20,
          ...(on && {
            marginLeft: 24,
          }),
        },
      },
    }),
  label: ({ theme }) =>
    css({
      ...extractFont((font) => font.supporting.label, 'web'),
      color: theme.colors.neutral.variant[80],
    }),
} satisfies Styles<any>;

export const useStyles = withStyles(styles);
