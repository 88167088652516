import { hosts } from 'config';
import { BaseClient } from 'clients/BaseClient/BaseClient';
import {
  CreateOutreachTemplateHandler,
  DeleteOutreachTemplateHandler,
  GetOutreachTemplateByIdHandler,
  GetOutreachTemplateByTypeHandler,
  UpdateOutreachTemplateHandler,
} from './OutreachTemplateClient.types';

export class OutreachTemplateClient extends BaseClient {
  createOutreachTemplate: CreateOutreachTemplateHandler = ({ message, name, subject, type, sign_off }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/outreach_template/${type}/`,
      method: 'POST',
      data: {
        message,
        name,
        subject,
        sign_off,
      },
    });
  };

  updateOutreachTemplate: UpdateOutreachTemplateHandler = ({ message, name, subject, type, id, sign_off }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/outreach_template/${type}/${id}/`,
      method: 'PUT',
      data: {
        message,
        name,
        subject,
        sign_off,
      },
    });
  };

  getOutreachTemplateById: GetOutreachTemplateByIdHandler = ({ type, id }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/outreach_template/${type}/${id}/`,
      method: 'GET',
    });
  };

  getOutreachTemplateByType: GetOutreachTemplateByTypeHandler = ({ type }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/outreach_template/${type}/`,
      method: 'GET',
    });
  };

  deleteOutreachTemplate: DeleteOutreachTemplateHandler = ({ id, type }) => {
    return this.request({
      url: `${hosts.notificationsApi()}/outreach_template/${type}/${id}/`,
      method: 'DELETE',
    });
  };
}

export const outreachTemplateClient = new OutreachTemplateClient();
