import { default as Dialog } from 'components/ui/molecules/Dialog';
import { createContext, useMemo } from 'react';
import { useStyles } from './styles';
import { DialogContextProps, DialogContextProviderProps } from './types';
import { useContainer } from './useContainer';

const initialData: DialogContextProps = {
  createDialog: () => {
    throw new TypeError('You must provide createDialog');
  },
  createPersistentDialog: () => {
    throw new TypeError('You must provide createPersistentDialog');
  },
};

export const DialogContext = createContext<DialogContextProps>(initialData);

export const DialogContextProvider = ({ children }: DialogContextProviderProps) => {
  const {
    states: { visible, labels, persist },
    functions: { handleConfirm, handleCancel, createDialog, createPersistentDialog },
  } = useContainer();
  const classes = useStyles();

  const value = useMemo<DialogContextProps>(() => ({ createDialog, createPersistentDialog }), []);

  return (
    <DialogContext.Provider value={value}>
      {children}
      {visible && (
        <div css={classes.overlay}>
          <Dialog labels={labels} onConfirm={handleConfirm} onCancel={handleCancel} persist={persist} />
        </div>
      )}
    </DialogContext.Provider>
  );
};
