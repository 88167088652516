import { Styles, withStyles, css, extractFont } from '@recruitrobin/robin-theme/web-styles';
import { TextInputStyleStates } from './types';

const styles = {
  root: css``,
  input: ({ theme, props: { hasError, isDisabled } }) =>
    css({
      ...extractFont((font) => font.body.short, 'web'),
      color: theme.colors.neutral.variant[80],
      minWidth: 200,
      margin: 0,
      padding: '9px 16px',
      background: theme.colors.neutral.variant[10],
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.neutral.variant[10],
      borderRadius: 8,
      width: '100%',
      '&:hover': {
        background: theme.colors.neutral.variant[5],
        borderColor: theme.colors.neutral.variant[30],
      },
      '&:focus, &:focus:hover': {
        background: theme.colors.neutral.variant[0],
        borderColor: theme.colors.primary.fuchsia[30],
        outline: 0,
      },
      ...(hasError && {
        '&, &:hover': {
          borderColor: theme.colors.tertiary.red[50],
        },
      }),
      ...(isDisabled && {
        '&, &:hover': {
          color: theme.colors.neutral.variant[30],
          background: theme.colors.neutral.variant[10],
          borderColor: theme.colors.neutral.variant[10],
        },
      }),
    }),
} satisfies Styles<TextInputStyleStates>;

export const useStyles = withStyles(styles);
