import { useEffect } from 'react';

export const usePreventScroll = (shouldBlockScroll: boolean) => {
  useEffect(() => {
    if (shouldBlockScroll) {
      document.body.classList.add('overflow-off');

      return () => {
        document.body.classList.remove('overflow-off');
      };
    }
  }, [shouldBlockScroll]);
};
