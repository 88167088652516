import { FormHelperText as MuiFormHelperText } from '@material-ui/core';
import { Props } from './types';
import { useStyles } from './FormHelperError.styles';
import { AlertCircleSolid } from '@recruitrobin/robin-theme/web-icons';

const FormHelperError = ({ id, message, disableMarginTop = false }: Props) => {
  const classes = useStyles({ disableMarginTop });

  return (
    <MuiFormHelperText id={id} css={classes.root}>
      <span>
        <AlertCircleSolid color={(c) => c.tertiary.red[60]} size={18} />
        <span>{message}</span>
      </span>
    </MuiFormHelperText>
  );
};

export default FormHelperError;
