import { Candidate } from 'model';
import { UseActionsHook } from '../types';
import { useCandidateMatchEffect } from './useCandidateMatchEffect';

export const useCandidateEffects = (
  candidate: Candidate | undefined,
  { actions }: { actions: ReturnType<UseActionsHook> },
) => {
  useCandidateMatchEffect(candidate, { actions });
};
