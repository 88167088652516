import { useRef } from 'react';
import { SnackbarWrapperProps } from './types';
import { useStyles } from './SnackbarWrapper.styles';
import { SnackbarId } from 'shared/components/Snackbar';
import { default as Transition } from 'shared/components/Transition';

const VISIBILITY_DURATION = 3000;
const TRANSITION_ANIMATION_DURATION = 300;

const SnackbarWrapper = ({ snackbars }: SnackbarWrapperProps) => {
  const classes = useStyles();
  const snackbarPosition = useRef<Record<SnackbarId, number>>({});

  if (snackbars.length === 0) {
    snackbarPosition.current = {};
    return null;
  }

  return (
    <div css={classes.root}>
      {snackbars.map(([snackbarId, { component: snackbar, config }]) => {
        const currIndex = Object.keys(snackbarPosition.current).length;
        const snackPos = snackbarPosition.current[snackbarId] ?? (snackbarPosition.current[snackbarId] = currIndex);
        const duration = config?.duration ?? VISIBILITY_DURATION;

        return (
          <div key={snackbarId} style={{ left: 56 + snackPos * 24 }}>
            <Transition
              css={classes.transition}
              animationDuration={TRANSITION_ANIMATION_DURATION}
              duration={duration + TRANSITION_ANIMATION_DURATION * 2}
              pattern={{
                default: { transform: 'translateY(calc(100% + 24px))' },
                states: {
                  entering: { transform: 'translateY(calc(100% + 24px))' },
                  entered: { transform: 'translateY(0)' },
                  exiting: { transform: 'translateY(0)' },
                  exited: { transform: 'translateY(calc(100% + 24px))' },
                },
              }}
              persist={config?.persist}
              visible>
              {snackbar}
            </Transition>
          </div>
        );
      })}
    </div>
  );
};

export default SnackbarWrapper;
