import { useState, useRef, useEffect } from 'react';
import { browserExtensionClient } from 'clients/BrowserExtensionClient/BrowserExtensionClient';
import type { LinkedinRecruiterMessagingResponse } from './types';
import { useQuery } from '@tanstack/react-query';
import { useBrowserExtension } from './useBrowserExtension';
import { notificationsClient } from 'clients/NotificationsClient/NotificationsClient';
import { crawlerClient } from 'clients/CrawlerClient/CrawlerClient';

const REFETCH_INTERVAL = 30 * 1000; // 30 seconds
const GET_UNREAD_LINKEDIN_RECRUITER_MESSAGES =
  'https://www.linkedin.com/talent/api/talentMailboxSummary?decoration=%28numUnseenMessages%2CinboxHomeUrl%2CrecentMessages*%28read%2CcreatedAt%2Csubject%2Cbody%2Curl%2CsenderFirstName%2CsenderLastName%2CsenderProfile~%28entityUrn%2CfirstName%2ClastName%2Cheadline%2CprofilePicture%2CvectorProfilePicture%2CpublicProfileUrl%2CfollowerCount%2CnetworkDistance%2CautomatedActionProfile%29%29%29';

export function useUnreadLinkedInRecruiterMessages() {
  const { linkedinProStatus } = useBrowserExtension();

  const invalidationCountRef = useRef(0);
  const [shouldRunCountUnreadQuery, setShouldRunCountUnreadQuery] = useState(true);

  useQuery(
    ['countUnreadLinkedinRecruiterMessages'],
    async () => {
      const response = await browserExtensionClient.sendMessage('proxyHttpRequest', {
        url: GET_UNREAD_LINKEDIN_RECRUITER_MESSAGES,
        method: 'GET',
        addHeadersFrom: 'linkedInClassic',
      });

      return response;
    },
    {
      enabled: linkedinProStatus === 'Logged In' && shouldRunCountUnreadQuery,
      refetchInterval: REFETCH_INTERVAL,
      onSuccess: async (data) => {
        const jsonData: LinkedinRecruiterMessagingResponse = JSON.parse(data.body);

        if (jsonData.status === 403 || jsonData.status === 400 || jsonData.status === 401) {
          if (invalidationCountRef.current < 3) {
            invalidationCountRef.current += 1;
          } else {
            setShouldRunCountUnreadQuery(false);
          }
          throw new Error(`HTTP ${jsonData.status} Error`);
        }

        const messages = await Promise.all(
          jsonData.recentMessages.map(async (message) => {
            const linkedinCandidateId = message.senderProfile.split(':ts_profile:')[1];
            const threadId = message.url.split('/conversation/')[1];

            try {
              const candidate = await crawlerClient.getLinkedinRecruiterCandidate(linkedinCandidateId);
              const esPersonId = `cvt-${candidate.data.candidate.candidate.positionGroupView.profileId}`;

              return {
                es_person_id: esPersonId,
                message_id: String(message.createdAt),
                thread_id: threadId,
              };
            } catch (error) {
              return null;
            }
          }),
        );

        const filteredMessages = messages.filter((message) => message !== null);

        notificationsClient.saveLinkedinRecruiterMessagesNotifications({ messages: filteredMessages });
      },
    },
  );

  useEffect(() => {
    invalidationCountRef.current = 0;
    setShouldRunCountUnreadQuery(true);
  }, [linkedinProStatus]);
}
