import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    height: 64px;
    flex-shrink: 0;
  `,
  absolute: css`
    display: flex;
    width: 100%;
    height: 64px;
    position: absolute;
    z-index: 3;
    top: 0;
    left: 64px;
    justify-content: space-between;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
