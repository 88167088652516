import { ButtonGroup, LargeIconButton } from '@recruitrobin/robin-theme/web-components';
import { LogOut_01, LogOut_01Fill, RobinIcon } from '@recruitrobin/robin-theme/web-icons';
import { Column, Typography } from 'components/ui/atoms';
import { routes } from 'config';
import { AnimatePresence, motion } from 'framer-motion';
import { useGTM } from 'hooks/gtm';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { sidebarAnimationConfig, SidebarOverlayFixedContext } from 'shared/contexts/SidebarOverlayFixedContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { useStyles } from './Sidebar.styles';
import SidebarNav from './SidebarNav';
import SidebarOverlayFixed from './SidebarOverlayFixed';

const SIDEBAR_MENU_ICONS_SIZE = 20;

const Sidebar = () => {
  const classes = useStyles();
  const { logout } = useContext(UserContext);
  const { language, setLanguage, dictionary } = useContext(LocalizationContext);
  const { isOpen: isFixedOpen } = useContext(SidebarOverlayFixedContext);

  const navigate = useNavigate();
  const gtm = useGTM();

  const onClickLogo = useCallback(() => {
    gtm.clickedRobinLogo();
    navigate(routes.search());
  }, []);

  return (
    <div css={[classes.root]}>
      <Column css={[classes.sidebarIconsNavColumn]}>
        <div>
          <div css={classes.logo} onClick={onClickLogo}>
            <RobinIcon height={64} />
          </div>
          <SidebarNav />
        </div>
        <div css={[classes.languageMenu]}>
          {language === 'en' && (
            <div onClick={() => setLanguage('nl')} css={[classes.languageMenuOptions]}>
              <Typography variant="body.long">EN</Typography>
            </div>
          )}
          {language === 'nl' && (
            <div onClick={() => setLanguage('en')} css={[classes.languageMenuOptions]}>
              <Typography variant="body.long">NL</Typography>
            </div>
          )}
          <ButtonGroup gap={10}>
            <LargeIconButton
              icon={(selected) =>
                selected ? (
                  <LogOut_01Fill size={SIDEBAR_MENU_ICONS_SIZE} />
                ) : (
                  <LogOut_01 size={SIDEBAR_MENU_ICONS_SIZE} />
                )
              }
              onClick={() => logout()}
              tooltip={<Typography variant="supporting.helper">{dictionary.logout}</Typography>}
            />
          </ButtonGroup>
        </div>
      </Column>
      <AnimatePresence>
        {isFixedOpen && (
          <motion.div css={classes.sidebarFixed} {...sidebarAnimationConfig}>
            <SidebarOverlayFixed />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Sidebar;
