import type { UserResponse } from 'clients/UserClient/types';

import { useQuery } from '@tanstack/react-query';
import { userClient } from 'clients/UserClient/UserClient';
import { QueryKey } from 'types/query';
import { CustomUseQueryOptions } from './types';

export const useCurrentUserQuery = (options?: CustomUseQueryOptions<UserResponse>) => {
  return useQuery(
    [QueryKey.useCurrentUserQuery],
    async () => {
      const { data: user } = await userClient.getCurrent();

      return user;
    },
    options,
  );
};
