import { PortalWrapperProps } from './types';

import { default as Transition } from 'shared/components/Transition';
import { default as ReactDOM } from 'react-dom';
import { useStyles } from './styles';
import { useContainer } from './useContainer';

const appRoot = document.getElementById('root') as HTMLElement;

const ANIMATION_DURATION = 120;

const PortalWrapper = (props: PortalWrapperProps) => {
  const classes = useStyles();
  const {
    refs: { portalRef, childWrapperRef },
    states: { animationVisible, allowedToMount, alignment },
  } = useContainer({ ...props, animationDuration: ANIMATION_DURATION });

  if (!props.visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div ref={portalRef} css={classes.root} data-alignment={alignment}>
      {allowedToMount && (
        <Transition pattern="fade" visible={animationVisible} animationDuration={ANIMATION_DURATION}>
          {
            <span ref={childWrapperRef} style={{ display: 'block', height: 'auto' }}>
              {props.children}
            </span>
          }
        </Transition>
      )}
    </div>,
    appRoot,
  );
};

export default PortalWrapper;
