import { CSSProperties } from 'react';
import { BadgeAlignment, BadgeStyleStates } from './types';
import { css, extractFont, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      position: 'relative',
      height: '100%',
    }),
  badgeWrapper: ({ theme, props }) =>
    css({
      ...extractFont((font) => font.supporting['label-tiny'], 'web'),
      position: 'absolute',
      zIndex: 9,
      ...(!props.hasCustomRender && {
        borderWidth: 1,
        borderRadius: '100px',
        borderStyle: 'solid',
        padding: '0 4px',
      }),
      ...(props.isDigit && {
        padding: 1,
        width: 16,
        height: 16,
        textAlign: 'center',
      }),
      ...(!props.hasCustomRender &&
        props.variant === 'default' && {
          color: theme.colors.neutral.variant[0],
          background: theme.colors.neutral.variant[60],
          borderColor: theme.colors.neutral.variant[60],
        }),
      ...(!props.hasCustomRender &&
        props.variant === 'secondary' && {
          color: theme.colors.neutral.variant[0],
          background: theme.colors.neutral.variant[30],
          borderColor: theme.colors.neutral.variant[30],
        }),
      ...(!props.hasCustomRender &&
        props.variant === 'error' && {
          color: theme.colors.neutral.variant[0],
          background: theme.colors.tertiary.red[50],
          borderColor: theme.colors.tertiary.red[50],
        }),
      ...(!props.hasCustomRender &&
        props.variant === 'highlight' && {
          color: theme.colors.neutral.variant[0],
          background: theme.colors.primary.fuchsia[50],
          borderColor: theme.colors.primary.fuchsia[50],
        }),
      ...(!props.hasCustomRender &&
        props.variant === 'info' && {
          color: theme.colors.neutral.variant[0],
          background: theme.colors.neutral.variant[70],
          borderColor: theme.colors.neutral.variant[70],
        }),
      ...badgeAlignment[props.alignment],
    }),
} satisfies Styles<BadgeStyleStates>;

const badgeAlignment: Record<BadgeAlignment, Pick<CSSProperties, 'top' | 'right' | 'bottom' | 'left' | 'transform'>> = {
  'top left': {
    transform: 'translate(-30%, -30%)',
    top: 0,
    right: 'unset',
    bottom: 'unset',
    left: 0,
  },
  'top right': {
    transform: 'translate(50%, -50%)',
    top: 0,
    right: 0,
    bottom: 'unset',
    left: 'unset',
  },
  'bottom right': {
    transform: 'translate(30%, 30%)',
    top: 'unset',
    right: 0,
    bottom: 0,
    left: 'unset',
  },
  'bottom left': {
    transform: 'translate(-30%, 30%)',
    top: 'unset',
    right: 'unset',
    bottom: 0,
    left: 0,
  },
};

export const useStyles = withStyles(styles);
