import { LOG_LEVEL, LoggerLevels } from './types';

export const fancyLog = ({ level, prefix = 'Logger' }: { level: LoggerLevels; prefix?: string }, ...props: any[]) => {
  const getStyles = (level: LoggerLevels) => {
    if (level === LOG_LEVEL.WARN) {
      return 'background-color: #FF8C2A; color: black; padding: 2px 2px; border-radius: 3px;';
    }

    if (level === LOG_LEVEL.INFO) {
      return 'background-color: #1769A3; color: black; padding: 2px 2px; border-radius: 3px;';
    }

    if (level === LOG_LEVEL.ERROR) {
      return 'background-color: #AD1703; color: white; padding: 2px 2px; border-radius: 3px;';
    }
  };

  console[level](`%c[${prefix}]`, getStyles(level), ...props);
};

export const withLogger = <T extends (...args: any[]) => any>(
  {
    level,
    debug,
    prefix = 'Logger',
  }: { level: LoggerLevels; debug: boolean; consoleWhenError?: boolean; prefix?: string },
  cb: T,
  ...props: Parameters<T>
) => {
  if (debug) {
    fancyLog({ level, prefix }, ...props);
  }

  cb(...props);
};
