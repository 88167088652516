import { useQueryClient } from '@tanstack/react-query';
import { Candidate } from 'model';
import { useCallback } from 'react';
import { useCandidateOrchestratorForCampaign } from './useCandidateOrchestratorForCampaign';
import { useCandidateOrchestratorForSearch } from './useCandidateOrchestratorForSearch';
import { useCandidateOrchestratorForShare } from './useCandidateOrchestratorForShare';

export const useCandidateOrchestrator = () => {
  const queryClient = useQueryClient();
  const coCampaign = useCandidateOrchestratorForCampaign();
  const coSearch = useCandidateOrchestratorForSearch();
  const coShare = useCandidateOrchestratorForShare();

  const invalidateCredits = useCallback(() => queryClient.invalidateQueries(['userCredits']), [queryClient]);

  return useCallback(
    (candidate: Candidate) => ({
      search: coSearch(candidate),
      campaign: coCampaign(candidate),
      share: coShare(),
      invalidateCredits,
    }),
    [coCampaign, coSearch, coShare, invalidateCredits],
  );
};
