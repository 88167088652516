import { cssBreakpoints } from 'styles/breakpoints';
import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: ({ theme }) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
      [cssBreakpoints.mobile]: {
        padding: 16,
      },
      '& > *': {
        margin: 8,
      },
      hr: {
        margin: 0,
        width: '100%',
        border: 0,
        borderBottom: `1px solid ${theme.colors.neutral.variant[30]}`,
      },
      '& > div': {
        maxWidth: 350,
      },
    }),
  logo: () =>
    css({
      height: 100,
      [cssBreakpoints.tablet]: {
        display: 'none',
      },
    }),
  title: () =>
    css({
      fontSize: 18,
      fontWeight: 500,
    }),
  description: () =>
    css({
      textAlign: 'left',
      fontSize: 14,
      fontWeight: 300,
    }),
  form: () =>
    css({
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: 8,
      },
      button: {
        cursor: 'pointer',
      },
    }),
  error: ({ theme }) =>
    css({
      color: theme.colors.tertiary.red[80],
      fontSize: 12,
      width: '100%',
      textAlign: 'left',
      margin: 0,
      marginLeft: 32,
      marginTop: -16,
    }),
  popup: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      padding: 24,
      fontFamily: '"Outfit", sans-serif',
    }),
  popupIcon: ({ theme }) =>
    css({
      textAlign: 'center',
      color: theme.colors.secondary.emerald[40],
      svg: {
        width: 80,
        height: 80,
      },
    }),
  popupTitle: () =>
    css({
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 500,
      margin: 8,
    }),
  popupDescription: () =>
    css({
      textAlign: 'center',
      fontSize: 14,
      margin: 8,
      button: {
        marginTop: 32,
      },
    }),
  keyVisual: () =>
    css({
      height: 100,
      [cssBreakpoints.mobile]: {
        height: 'auto',
        width: '100%',
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
