import { AnimateSuspense } from 'components/ui/molecules/AnimateSuspense';
import ErrorBoundary from 'components/ui/molecules/ErrorBoundary';
import { RobinSkeleton } from 'components/ui/molecules/RobinSkeleton';
import { AnimatePresence } from 'framer-motion';
import { memo, useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './CandidateCV.styles';
import { CandidateCVProps } from './CandidateCV.types';
import { CandidateCVErrorFallback } from './CandidateCVErrorFallback';
import { CandidateCVRender } from './CandidateCVRender';

export const CandidateCV = memo(({ candidate }: CandidateCVProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div css={classes.root}>
      <ErrorBoundary fallback={({ error }) => <CandidateCVErrorFallback error={error} />}>
        <AnimateSuspense
          fallback={({ isVisible }) => (
            <AnimatePresence>
              {isVisible && <RobinSkeleton loadingMessage={dictionary.loadingCandidateCv} css={classes.skeleton} />}
            </AnimatePresence>
          )}>
          <CandidateCVRender candidate={candidate} />
        </AnimateSuspense>
      </ErrorBoundary>
    </div>
  );
});
