import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { generateTransitionStyles } from 'utils/style';
import { defaultTransitionPatterns } from './TransitionPatterns';

import { TransitionStylesState } from './types';

const styles = {
  root: ({ props: { animationDuration, pattern } }) =>
    css({
      ...(typeof pattern === 'object' ? pattern.default : defaultTransitionPatterns[pattern]),
      transition: generateTransitionStyles(['opacity', 'transform'], `${animationDuration}ms`),
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
    }),
} satisfies Styles<TransitionStylesState>;

export const useStyles = withStyles(styles);
