import { Menu as MuiMenu } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { MenuChangeHandler, MenuCloseReason, MenuItemType, MenuProps, MenuStyleStates } from './types';
import { useStyles } from './Menu.styles';
import MenuItem from './MenuItem';
import { cx } from '@emotion/css';
import { generateDtiAttribute } from 'helpers/helpers';

const createStateValues = <T extends MenuItemType>(props: MenuProps<T>): MenuStyleStates => {
  return {
    disabled: props.disabled ?? false,
  };
};

const Menu = <T extends MenuItemType>(props: MenuProps<T>): ReactElement<any, any> => {
  const { menuItems, anchorEl, onChange, onClose, className } = props;
  const classes = useStyles(createStateValues<T>(props));
  const [anchorElBoundingClientRect, setAnchorElBoundingClientRect] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (anchorEl) {
      disableScroll();
      setAnchorElBoundingClientRect(anchorEl.getBoundingClientRect());
    } else {
      enableScroll();
    }
  }, [anchorEl]);

  useEffect(() => {
    return () => {
      enableScroll();
    };
  }, []);

  const handleChange: MenuChangeHandler<T> = (selectedItem) => {
    onChange?.(selectedItem);
  };

  const handleClose = (_: {}, reason: MenuCloseReason) => {
    onClose?.(reason);
  };

  const disableScroll = () => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
  };

  return (
    <MuiMenu
      className={cx(className)}
      css={classes.root}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      PaperProps={{
        style: { marginTop: anchorElBoundingClientRect?.height, marginLeft: anchorElBoundingClientRect?.width },
      }}
      {...generateDtiAttribute(props['data-tutorial-id'], 'menu')}>
      {menuItems.map((props) => {
        return <MenuItem key={props.value} {...props} onClick={() => handleChange(props)} />;
      })}
    </MuiMenu>
  );
};

export default Menu;
