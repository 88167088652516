import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';
import { TableFilterStateStyles } from './types';

const styles = {
  root: () =>
    css({
      display: 'flex',
      alignItems: 'flex-end',
      '& > div': {
        '&:not(:nth-of-type(1))': {
          marginLeft: 16,
        },
      },
      '& > div:nth-of-type(1)': {
        maxWidth: 350,
      },
      '& > div:nth-of-type(2)': {
        display: 'flex',
        alignItems: 'center',
        '& > *:last-child': {
          marginLeft: 8,
        },
      },
    }),

  searchCompanyInputContent: () =>
    css({
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    }),
} satisfies Styles<TableFilterStateStyles>;

export const useStyles = withStyles(styles);
