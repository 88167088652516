import Sidebar from 'components/ui/organisms/Sidebar/Sidebar';
import { useStyles } from './SearchV3Sidebar.styles';

export const SearchV3Sidebar = () => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <Sidebar />
    </div>
  );
};
