import { KnowledgeBaseButtonStyleStates } from './types';
import { css, Styles, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css``,
  content: () =>
    css({
      cursor: 'pointer',
      gap: 4,
    }),
} satisfies Styles<KnowledgeBaseButtonStyleStates>;

export const useStyles = withStyles(styles);
