import CampaignList from 'components/ui/molecules/CampaignList/CampaignList';
import { useStyles } from './SearchV3List.styles';

export const SearchV3List = () => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <CampaignList />
    </div>
  );
};
