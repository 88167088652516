import { Typography } from '@recruitrobin/robin-theme/web-components';
import { ReactComponent as SadFace } from 'components/ui/atoms/icons/SadFace/sad-face.svg';
import { useStyles } from './CandidateCVUnavailable.styles';

export const CandidateCVUnavailable = ({ message }: { message: string }) => {
  const classes = useStyles();

  return (
    <div css={classes.root}>
      <SadFace />

      <Typography color={(c) => c.neutral.variant[60]} variant={(v) => v.body.short}>
        {message}
      </Typography>
    </div>
  );
};
