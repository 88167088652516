import CampaignList from 'components/ui/molecules/CampaignList/CampaignList';
import CampaignListHeader from 'components/ui/molecules/CampaignList/CampaignListHeader/CampaignListHeader';
import { useStyles } from './SidebarOverlayFloating.styles';

export default function SidebarOverlayFloating() {
  const classes = useStyles();
  return (
    <div css={classes.root}>
      <CampaignListHeader />

      <div css={classes.body}>
        <div css={classes.overlayHr} />
        <div css={classes.campaignListRoot}>
          <CampaignList />
        </div>
      </div>
    </div>
  );
}
