import { Component } from 'react';
import { NotAllowedException } from 'shared/exceptions/NotAllowedException';
import { ErrorBoundaryProps } from './types';

const whiteListException = [NotAllowedException];

const shouldLog = (error: unknown) => {
  if (whiteListException.some((exception) => error instanceof exception)) {
    return false;
  }

  return true;
};

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  state: { error: unknown } = { error: undefined };

  constructor(props: ErrorBoundaryProps) {
    super(props);
  }

  static getDerivedStateFromError(error: unknown) {
    if (shouldLog(error)) {
      console.error('[ErrorBoundary] getDerivedStateFromError', { error });
    }

    return { error };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: unknown, info: unknown) {}

  render() {
    const { children, fallback } = this.props;
    const { error } = this.state;

    if (!!error && fallback) {
      return typeof fallback === 'function' ? fallback({ error }) : fallback;
    }

    return children;
  }
}

export default ErrorBoundary;
