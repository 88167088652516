import { CampaignOverviewStatus } from 'clients/CampaignsClient/CampaignOverviewStatus.types';
import { useLocalStorage } from 'hooks/shared';
import { useEffect, useState } from 'react';
import {
  getCampaignStatus,
  getShouldEnableTeamview,
  getShowCampaignOverviewButton,
  getShowNavbar,
} from './CandidateContext.utils';
import { ConfigContext, initialData } from './ConfigContext';
import { useBrowserExtensionSyncResize } from './ConfigContext.useBrowserExtensionSyncResize';
import { ConfigContextProps, ConfigContextProviderProps } from './types';
const SHOW_NAVBAR_PARAM = 'show-navbar';

export const ConfigContextProvider = ({ children }: ConfigContextProviderProps) => {
  const { value: showNavbarParam, setValue: setShowNavbarParam } = useLocalStorage(SHOW_NAVBAR_PARAM);
  const [isIframe, setIsIframe] = useState(initialData.isIframe);
  const [showNavbar, setShowNavbar] = useState(initialData.showNavbar);
  const [showCampaignOverviewButton, setShowCampaignOverviewButton] = useState(initialData.showCampaignOverviewButton);
  const { setValue: setTeamview, value: teamview } = useLocalStorage<boolean>(
    'showTeamCampaigns',
    initialData.campaign.teamview,
  );
  const { setValue: setStatus, value: status } = useLocalStorage<CampaignOverviewStatus>(
    'statusCampaigns',
    initialData.campaign.status,
  );

  useBrowserExtensionSyncResize({ enabled: isIframe });

  useEffect(() => {
    const isIframe = window.top !== window.self;
    const campaignTeamView = getShouldEnableTeamview();
    const campaignStatus = getCampaignStatus();
    const shouldShowNavbar = getShowNavbar({ showNavbarParam: showNavbarParam as string, setShowNavbarParam });
    const shouldShowCampaignOverviewButton = getShowCampaignOverviewButton();

    if (isIframe) {
      setIsIframe(true);
      setTeamview(campaignTeamView);
      setShowNavbar(shouldShowNavbar);
      setStatus(campaignStatus);
      setShowCampaignOverviewButton(shouldShowCampaignOverviewButton);
    }
  }, []);

  const value: ConfigContextProps = {
    isIframe,
    campaign: { teamview, status },
    showNavbar,
    showCampaignOverviewButton,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};
