import { useContext, useState } from 'react';
import { Typography } from 'components/ui/atoms';
import { TableFilterBatchOptionType, TableFiltersProps } from './types';
import { useStyles } from './TableFilters.styles';
import { useDebounce } from 'react-use';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { SingleSelect, TextInput } from 'components/ui/molecules';
import { generateDtiAttribute } from 'helpers/helpers';

const BATCH_SIZES = [5, 10, 25, 50, 100, 250];

const BATCH_OPTIONS: TableFilterBatchOptionType[] = BATCH_SIZES.map((size) => ({
  key: `${size}`,
  label: `${size}`,
  value: size,
}));

const TableFilters = ({ onSearch, onChangeBatchSize }: TableFiltersProps) => {
  const { dictionary } = useContext(LocalizationContext);
  const [search, setSearch] = useState<string>('');
  const [batchSize, setBatchSize] = useState<TableFilterBatchOptionType>(BATCH_OPTIONS[2]);
  const classes = useStyles();

  useDebounce(() => onSearch?.(search), 250, [search]);

  useDebounce(() => onChangeBatchSize?.(batchSize.value), 500, [batchSize]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleChangeBatchSize = (option: TableFilterBatchOptionType) => {
    setBatchSize(option);
  };

  return (
    <div css={classes.root}>
      <div css={classes.searchCompanyInputContent}>
        <Typography
          variant={(v) => v.supporting.label}
          colorv2={(c) => c.neutral.variant[60]}
          {...generateDtiAttribute('label')}>
          {dictionary.searchCompany}
        </Typography>
        <TextInput onChange={handleSearch} text={search} />
      </div>
      <div>
        <SingleSelect options={BATCH_OPTIONS} text={batchSize.label} onChange={handleChangeBatchSize} />
        <Typography variant={(v) => v.supporting.label} colorv2={(c) => c.neutral.variant[80]}>
          {dictionary.entriesPerPage}
        </Typography>
      </div>
    </div>
  );
};

export default TableFilters;
