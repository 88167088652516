const defaultVariantConfig = {
  rootRadius: 20,
  switcherRadius: 18,
  rootLeft: (checked: boolean) => (checked ? 'calc(100% - 22px)' : 2),
  switcherLeft: (checked: boolean) => (checked ? 'calc(100% - 22px)' : 3),
  switcherTop: 3,
  rootTop: 2,
  switcherBorderRadius: 15,
  switcherWidth: 48,
  switcherHeight: 24,
};

const smallerVariantConfig = {
  rootRadius: 13,
  switcherRadius: 13,
  rootLeft: (checked: boolean) => (checked ? 'calc(100% - 14.5px)' : 1.33),
  switcherLeft: (checked: boolean) => (checked ? 'calc(100% - 14.5px)' : 1.33),
  switcherTop: 1.33,
  rootTop: 1.33,
  switcherBorderRadius: 10,
  switcherWidth: 32,
  switcherHeight: 16,
};

const smallestVariantConfig = {
  rootRadius: 5,
  switcherRadius: 5,
  rootLeft: (checked: boolean) => (checked ? 'calc(100% - 7px)' : 2),
  switcherLeft: (checked: boolean) => (checked ? 'calc(100% - 7px)' : 2),
  switcherTop: 2,
  rootTop: 2,
  switcherBorderRadius: 5,
  switcherWidth: 14,
  switcherHeight: 9,
};

export const getConfig = (variant: 'default' | 'smaller' | 'smallest') => {
  switch (variant) {
    case 'smaller':
      return smallerVariantConfig;
    case 'smallest':
      return smallestVariantConfig;
    case 'default':
    default:
      return defaultVariantConfig;
  }
};
