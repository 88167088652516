let previousPromise: ((params: { removeFromList: boolean }) => void) | null = null;

export const useWaitBeforeRemove = () => {
  return {
    createWaitBeforeRemove: (callback?: () => void) => {
      return () =>
        new Promise<{ removeFromList: boolean }>((res) => {
          callback?.();

          previousPromise?.({ removeFromList: false });
          previousPromise = res;
        });
    },
    resolveLastWait: ({ removeFromList }: { removeFromList: boolean }) => {
      previousPromise?.({ removeFromList });
    },
  };
};

export type WaitBeforeRemove = () => Promise<{ removeFromList: boolean }>;
