import Skeleton, { SkeletonProps } from 'components/ui/molecules/Skeleton';
import { forwardRef } from 'react';

export type WithSkeletonProps = {
  isLoading?: boolean;
};

/**
 * @deprecated
 * The new version of Skeletons must be used wrapping a component, which causes conflicts with this method.
 * All code with this method should be updated.
 */
const withSkeleton = <P extends object, T>(Component: React.ComponentType<P>, skeletonProps?: SkeletonProps) => {
  return forwardRef<T, P & WithSkeletonProps>(({ isLoading = false, ...props }, ref) => {
    if (ref && isLoading) {
      if (typeof ref === 'function') {
        ref(null);
      } else {
        ref.current = null;
      }
    }

    return isLoading ? <Skeleton {...(skeletonProps as SkeletonProps)} /> : <Component ref={ref} {...(props as P)} />;
  });
};

export type { SkeletonProps };
export default withSkeleton;
