import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { logger } from 'config/logger';
import { delay } from 'helpers/helpers';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { CANDIDATE_STATUS } from 'services/candidates';
import { StopExecution } from 'shared/exceptions';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';

export const useMakeInvisible = (params: WrappedHookParams) => {
  return useCandidateAction<MakeInvisibleHookCallbackParams | undefined>(
    async ({ candidate }) => {
      await campaignsClient.createOrUpdateCampaignCandidate({
        campaignId: candidate.campaignId,
        esPersonId: candidate.es_person_id,
        status: CANDIDATE_STATUS.INVISIBLE,
        is_unlocked: candidate.isUnlocked,
      });
    },
    {
      onSuccess: ({ fcParams: { alreadyInteracted = false } }) => {
        return async ({ candidateOrchestrator: co, pageContext }) => {
          if (pageContext === PageContext.Campaign && alreadyInteracted) {
            co.campaign.markAs.alreadyInteracted();
            await delay(3000);
            co.campaign.decreaseStatOf.currentJobBoard();
            co.campaign.remove.fromCurrentList();
            co.campaign.decreaseStatOf.currentList();
          }
        };
      },
      onError: () => {
        return ({ data }) => {
          logger.error(data);
          throw new StopExecution();
        };
      },
    },
  )(params);
};

type MakeInvisibleHookCallbackParams = {
  alreadyInteracted?: boolean;
};
