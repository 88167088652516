import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 50px;
    padding: 16px 16px 8px 8px;
    gap: 12px;
  `,
  searchCount: ({ theme }) => css`
    background-color: ${theme.colors.neutral.variant[80]};
    color: ${theme.colors.neutral.variant[0]};
    border-radius: 8px;
    padding: 2px 8px 2px 8px;
  `,
  switch: css`
    & > div:first-of-type {
      white-space: nowrap;
    }
  `,
  flexDiv: css`
    display: flex;
    align-items: center;
  `,
  searchGap: css`
    gap: 8px;
  `,
  filterGap: css`
    gap: 8px;
  `,
  paddingLoader: css`
    padding: 4px 0;
  `,
  statusOptions: ({ theme }) => css`
    cursor: pointer;
    padding: 4px;

    &:hover {
      background-color: ${theme.colors.neutral.variant[10]};
      border-radius: 8px;
    }

    &:active,
    &.m-active {
      background-color: ${theme.colors.neutral.variant[20]};
      border-radius: 8px;
      cursor: default;
    }

    & > a {
      width: 100%;
      display: flex;
      align-items: center;
      text-decoration: 0;
      border-radius: 8px;
    }
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
