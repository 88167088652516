/* eslint-disable @typescript-eslint/no-unused-vars*/

import { useCallback } from 'react';
import { FieldValues, OptionType, SelectWithUseFormProps } from './types';
// @ts-ignore
import { Controller, useFormContext } from 'react-hook-form';
import Select from './Select';

const SelectWithUseForm = <T extends FieldValues, K extends OptionType>({
  control: parentControl,
  errors: parentErrors,
  name,
  options,
  ...props
}: SelectWithUseFormProps<T, K>) => {
  const { control = parentControl } = useFormContext() || {};

  const bindOnChange = useCallback(
    (onChange: any) =>
      ({ value }: OptionType) => {
        onChange(value);

        if (props.onChange) {
          // @ts-ignore
          props.onChange(value);
        }
      },
    [props],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ onChange, value }: any) => {
        const bindValue = options.find(({ value: v }) => v == value);

        return <Select {...props} options={options} onChange={bindOnChange(onChange)} value={bindValue} />;
      }}
    />
  );
};

export default SelectWithUseForm;
