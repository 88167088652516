import { CampaignJobboard } from 'shared/contexts/SelectedCampaignContext/SelectedCampaignContext.types';

export const extractCandidateIdWithJobBoardFromMatches = (
  candidateMatches: string[],
): Partial<Record<CampaignJobboard, string>> => {
  return candidateMatches.reduce((candidateIdsWithJobBoard, currentCandidateIdWithJobBoard) => {
    const [jobBoardId] = currentCandidateIdWithJobBoard.split('-') as [CampaignJobboard];

    return { ...candidateIdsWithJobBoard, [jobBoardId]: currentCandidateIdWithJobBoard };
  }, {});
};
