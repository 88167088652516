import { Outlet } from 'react-router-dom';
import { CampaignManagerContextProvider } from 'shared/contexts/CampaignManagerContext';
import { CandidateOutreachModalContextProvider } from 'shared/contexts/CandidateOutreachModalContext';
import { ConfigContext } from 'shared/contexts/ConfigContext/ConfigContext';
import { OffsetScrollListsProvider } from 'shared/contexts/OffsetScrollListsContext';
import { SidebarOverlayFixedProvider } from 'shared/contexts/SidebarOverlayFixedContext';
import { useContextSelector } from 'use-context-selector';
import { useStyles } from './SearchV3MainLayout.styles';
import { SearchV3Navbar } from './SearchV3Navbar';
import { SearchV3Sidebar } from './SearchV3Sidebar';

const SearchV3Providers = ({ children }: { children: React.ReactNode }) => {
  return (
    <CandidateOutreachModalContextProvider>
      <CampaignManagerContextProvider>
        <OffsetScrollListsProvider>
          <SidebarOverlayFixedProvider>{children}</SidebarOverlayFixedProvider>
        </OffsetScrollListsProvider>
      </CampaignManagerContextProvider>
    </CandidateOutreachModalContextProvider>
  );
};

export const SearchV3MainLayout = () => {
  const isIframe = useContextSelector(ConfigContext, (state) => state.isIframe);
  const showNavbar = useContextSelector(ConfigContext, (state) => state.showNavbar);
  const classes = useStyles();

  if (isIframe) {
    return (
      <SearchV3Providers>
        <div css={classes.iframe}>
          {showNavbar && <SearchV3Navbar navbarContainerCss={classes.iframeNavbar} />}
          <Outlet />
        </div>
      </SearchV3Providers>
    );
  }

  return (
    <SearchV3Providers>
      <div css={classes.root}>
        <SearchV3Sidebar />
        <div css={classes.body}>
          <SearchV3Navbar />
          <Outlet />
        </div>
      </div>
    </SearchV3Providers>
  );
};
