import type { CandidateStatus } from 'services/candidates';
import type { CandidateAction, CandidateSecondaryAction } from 'services/candidates/types';

export type AvailableActions = Record<
  CandidateStatus,
  {
    actions: CandidateAction[];
    extraOptions: Partial<Record<CandidateAction, CandidateSecondaryAction[]>>;
  }
>;

/*
 * This function returns an object that defines the available actions
 * and 'more options' for CandidateDetails component basing it on the
 * candidate's current status
 */
export const getAvailableActions = (): AvailableActions => {
  return {
    NEW: {
      actions: ['CONTACT', 'SHOW-MORE'],
      extraOptions: {
        'SHOW-MORE': ['MATCH', 'SHORTLIST-TO-ANOTHER-SEARCH', 'SHARE-PROFILE'],
      },
    },
    SHORTLISTED: {
      actions: ['CONTACT', 'SHARE-PROFILE', 'SHOW-MORE'],
      extraOptions: {
        'SHOW-MORE': ['MATCH', 'SHORTLIST-TO-ANOTHER-SEARCH'],
      },
    },
    CONTACTED: {
      actions: ['SHARE-PROFILE', 'CONTACT', 'MATCH'],
      extraOptions: {},
    },
    MATCHED: {
      actions: ['DOWNLOAD', 'CONTACT', 'SHARE-PROFILE', 'SHOW-MORE'],
      extraOptions: {
        'SHOW-MORE': ['RETRY-TO-SEND-TO-ATS'],
      },
    },
    HIDDEN: {
      actions: ['SHARE-PROFILE', 'SHOW-MORE'],
      extraOptions: {
        'SHOW-MORE': ['MATCH'],
      },
    },
    INVISIBLE: {
      actions: [],
      extraOptions: {},
    },
  };
};
