import { createContext, useState } from 'react';
import { ResetPasswordAuthentication, ResetPasswordContextProps, ResetPasswordContextProviderProps } from './types';

const defaultContext: ResetPasswordContextProps = {
  setAuthentication: () => false,
  authentication: undefined,
};

export const ResetPasswordContext = createContext(defaultContext);

export const ResetPasswordContextProvider = ({ children }: ResetPasswordContextProviderProps) => {
  const [authentication, setAuthentication] = useState<ResetPasswordAuthentication>();

  return (
    <ResetPasswordContext.Provider
      value={{
        setAuthentication,
        authentication,
      }}>
      {children}
    </ResetPasswordContext.Provider>
  );
};
