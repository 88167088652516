export const CANDIDATE_UNLOCK_REASON = {
  CONTACT_INFO: 'CONTACT_INFO',
  CV: 'CV',
} as const;

export const CANDIDATE_UNLOCK_REASON_FRIENDLY: { [URF in ICandidate.UnlockReason]: Lowercase<URF> } = {
  CONTACT_INFO: 'contact_info',
  CV: 'cv',
} as const;

export namespace ICandidate {
  export type UnlockReason = keyof typeof CANDIDATE_UNLOCK_REASON;
  export type UnlockReasonFriendly = Lowercase<UnlockReason>;
}

export enum CandidateStatus {
  New = 'NEW',
  Shortlisted = 'SHORTLISTED',
  Contacted = 'CONTACTED',
  Matched = 'MATCHED',
  Hidden = 'HIDDEN',
}
