import { Styles, css, withStyles } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }),
  content: ({ theme }) =>
    css({
      position: 'relative',
      borderRadius: 8,
      padding: '32px 40px',
      backgroundColor: theme.colors.neutral.variant[0],
      '&:focus-visible': {
        outline: 0,
      },
    }),
  closeButton: () =>
    css({
      position: 'absolute',
      top: 24,
      right: 24,
    }),
  header: () =>
    css({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }),
  subtitle: () => css({}),
  body: () => css({}),
  actions: () =>
    css({
      display: 'flex',
      justifyContent: 'flex-end',
      '& > :not(:first-of-type)': {
        marginLeft: 8,
      },
    }),
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
