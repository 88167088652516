import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-right: 6px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
