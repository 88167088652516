import { Animated, Column, Typography } from 'components/ui/atoms';
import { Skeleton } from 'components/ui/molecules';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './RobinSkeleton.styles';

export const RobinSkeleton = ({
  className,
  disableAnimation = false,
  loadingMessage,
}: {
  className?: string;
  disableAnimation?: boolean;
  loadingMessage?: string;
}) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Animated.FadeInOut
      css={classes.root}
      className={className}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: disableAnimation ? 1 : 0 }}>
      <Column center alignCenter gap={25}>
        <Skeleton variant="logo" size={111} />
        <Typography variant={(v) => v.body.short} colorv2={(color) => color.neutral.variant[60]}>
          {loadingMessage ?? dictionary.pleaseWait}...
        </Typography>
      </Column>
    </Animated.FadeInOut>
  );
};
