import { TenantClientCacheKeys, TenantClientCacheKeysConfig, TenantClientMethods } from './types';

const TENANT_CLIENT_PREFIX = 'tenantClientCacheKeys';

const tenantClientMethodsConfig: TenantClientCacheKeysConfig = {
  create: true,
  get: true,
  getUsers: true,
  impersonateUser: true,
  index: true,
  patch: true,
  delete: true,
  getCvSources: true,
  createCvSources: true,
  updateCvSources: true,
  getAtsCredentials: true,
  getTeams: true,
  getTenantEmailSettings: true,
  createTenantEmailSettings: true,
  updateTenantEmailSettings: true,
};

const tenantClientMethods: TenantClientMethods[] = Object.entries(tenantClientMethodsConfig)
  .map(([key, value]) => (value ? key : null))
  .filter(Boolean) as TenantClientMethods[];

export const tenantClientCacheKeys: TenantClientCacheKeys = tenantClientMethods.reduce<TenantClientCacheKeys>(
  (acc, key) => ({
    ...acc,
    [key]: `${TENANT_CLIENT_PREFIX}.${key}`,
  }),
  {} as TenantClientCacheKeys,
);
