/* eslint-disable @typescript-eslint/no-unused-vars*/

import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useStyles } from './styles';
import { MenuItem, Padding, Typography } from 'components/ui/atoms';
import { default as Column } from 'components/ui/atoms/Column';

import type { ContextMenuForwardRef, ContextMenuOption, ContextMenuProps, ContextMenuRef } from './types';

const ContextMenuWithoutRef = <T extends ContextMenuOption>(
  {
    className,
    options,
    selectedOption,
    onChange,
    onFocus,
    onBlur,
    children,
    header,
    variant = 'default',
    autoFocus = false,
    contained = false,
    prefixVariant = 'icon',
    highlight = false,
    maxHeight,
  }: ContextMenuProps<T>,
  ref: React.Ref<ContextMenuRef>,
) => {
  const classes = useStyles({ contained, maxHeight });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const menuItemsRef = useRef<NodeListOf<HTMLDivElement> | null>(null);
  const miFocusRef = useRef(0);
  const [isFocused, setIsFocused] = useState(false);

  useImperativeHandle(ref, () => ({
    setMenuOptionFocus: (index) => {
      const el = menuItemsRef.current?.[index];

      if (el) {
        el.focus();
        miFocusRef.current = index;
      }
    },
    getMenuOptionNodeList: () => wrapperRef.current?.querySelectorAll<HTMLDivElement>('[data-menu-item-id]') || null,
  }));

  useEffect(() => {
    const handleFocusIn: DocumentFocusInEventHandler = (e) => {
      setIsFocused(Boolean(e.target === wrapperRef.current || wrapperRef.current?.contains(e.target as HTMLElement)));
    };

    document.addEventListener('focusin', handleFocusIn);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
    };
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      const elementList = (menuItemsRef.current =
        wrapperRef.current.querySelectorAll<HTMLDivElement>('[data-menu-item-id]'));

      if (autoFocus) {
        if (
          typeof selectedOption === 'function' ||
          (selectedOption && !Array.isArray(selectedOption) && selectedOption.key)
        ) {
          const [elementIndex, elementToFocus] = (elementList &&
            Array.from(elementList.entries()).find(([i, el]) => {
              const elKey = el.getAttribute('data-menu-item-id');

              return typeof selectedOption === 'function'
                ? selectedOption(options.find((o) => `${o.key}` === elKey) as T)
                : elKey === `${selectedOption.key}`;
            })) || [0, undefined];

          if (elementToFocus) {
            elementToFocus.focus();
            miFocusRef.current = elementIndex;
          }
        } else {
          elementList[miFocusRef.current]?.focus();
        }
      }

      if (isFocused) {
        const handleDocumentKeyDown: DocumentKeyDownEventHandler = (e) => {
          if (['Tab', 'ArrowDown', 'ArrowUp', ' '].includes(e.key)) {
            e.preventDefault();
          }

          const focusedEl = {
            focusNext() {
              if (miFocusRef.current + 1 >= elementList.length) miFocusRef.current = -1;

              elementList[++miFocusRef.current].focus();
            },
            focusPrevious() {
              if (miFocusRef.current - 1 < 0) miFocusRef.current = elementList.length;

              elementList[--miFocusRef.current].focus();
            },
            click() {
              elementList[miFocusRef.current].click();
            },
          };

          const shouldFocusNext = e.key === 'ArrowDown' || (e.key === 'Tab' && !e.shiftKey);
          const shouldFocusPrevious = e.key === 'ArrowUp' || (e.key === 'Tab' && e.shiftKey);
          const shouldClick = e.key === ' ' || e.key === 'Enter';

          if (shouldFocusNext) focusedEl.focusNext();
          if (shouldFocusPrevious) focusedEl.focusPrevious();
          if (shouldClick) focusedEl.click();
        };

        document.addEventListener('keydown', handleDocumentKeyDown);

        return () => {
          document.removeEventListener('keydown', handleDocumentKeyDown);
          menuItemsRef.current = null;
          miFocusRef.current = 0;
        };
      }
    }
  }, [isFocused, options.length]);

  const { grouped: groupedOpts, ungrouped: ungroupedOpts } = useMemo(() => {
    return options.reduce<{ grouped: Record<string, T[]>; ungrouped: T[] }>(
      ({ grouped, ungrouped }, opt) => {
        if (opt.group) {
          return {
            grouped: { ...grouped, [opt.group]: [...(grouped[opt.group] ?? []), opt] },
            ungrouped,
          };
        }

        return { grouped, ungrouped: [...ungrouped, opt] };
      },
      { grouped: {}, ungrouped: [] },
    );
  }, [options]);

  const isSelected = useCallback(
    (opt: T) => {
      if (Array.isArray(selectedOption)) {
        return selectedOption.some((o) => o.key === opt.key);
      }

      if (typeof selectedOption === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-types
        return (selectedOption as Function)(opt);
      }

      return selectedOption === opt;
    },
    [selectedOption],
  );

  return (
    <Column
      ref={wrapperRef}
      gap={0}
      css={classes.root}
      className={className}
      onFocus={onFocus}
      onBlur={onBlur}
      tabIndex={0}
      inline>
      {header}

      <Column gap={0}>
        <Column css={classes.optionsWrapper} gap={0}>
          {Object.entries(groupedOpts).map(([group, opts], i) => (
            <Column key={`gr-${i}`} gap={0}>
              <Padding padding={[12, 16, 4]}>
                <Typography variant="supporting.labelTiny" color={(color) => color.monochrome[70]}>
                  {group}
                </Typography>
              </Padding>
              {opts.map((opt, _i) => (
                <MenuItem
                  key={`mi-${_i}`}
                  onClick={() => onChange(opt)}
                  selected={isSelected(opt)}
                  menuItemId={!opt.key ? `gr-${i}-mi-${_i}` : `${opt.key}`}
                  prefixVariant={!opt.icon ? prefixVariant : undefined}
                  withDivisor={opt.withDivisor}
                  icon={opt.icon}
                  variant={opt.variant}
                  disabled={opt.disabled}
                  tooltip={opt.tooltip}
                  disableMouseEvents={opt.disableMouseEvents}
                  highlight={highlight}>
                  {opt.children || opt.label}
                </MenuItem>
              ))}
            </Column>
          ))}

          {ungroupedOpts.map((opt, i) => (
            <MenuItem
              key={`ungr-mi-${i}`}
              onClick={() => onChange(opt)}
              selected={isSelected(opt)}
              menuItemId={!opt.key ? `ungr-mi-${i}` : `${opt.key}`}
              prefixVariant={prefixVariant}
              withDivisor={opt.withDivisor}
              icon={opt.icon}
              variant={opt.variant}
              highlight={highlight}
              disabled={opt.disabled}
              tooltip={opt.tooltip}
              disableMouseEvents={opt.disableMouseEvents}>
              {opt.children || opt.label}
            </MenuItem>
          ))}
        </Column>

        {children}
      </Column>
    </Column>
  );
};

const ContextMenu = forwardRef(ContextMenuWithoutRef) as ContextMenuForwardRef;

export default ContextMenu;
