import { AsYouType } from 'libphonenumber-js';

export const formatPhoneDutch = (phoneNumber: string): string => {
  const onlyNumbers = `${phoneNumber}`.replace(/\D/g, '');
  const phoneNumberFormatted = new AsYouType({
    defaultCountry: 'NL',
  }).input(onlyNumbers ? `+${onlyNumbers}` : '');

  return phoneNumberFormatted;
};
